import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import {
    selectAddUserStepperSelectedAccess,
    selectAddUserStepperSelectedAccessLevel,
    selectAddUserStepperSelectedUser, selectAddUserStepperSelectedUserType,
} from '../../../store/selectors/grant-revoke-permissions.selector';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { map, Observable, Subscription, take } from 'rxjs';
import {
    AdobeAnalyticsCallToAction,
    AnalyticsCtaType,
    CtaCallUsLinkLocations,
} from '../../../services/adobe-launch/adobe-launch.cta';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../services/adobe-launch/adobe-launch.process';
import { AdobeAnalyticsEvent } from '../../../services/adobe-launch/adobe-launch.events';
import { environment } from '../../../../environments/environment.development-external';
import { Store } from '@ngrx/store';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';
import { ZciCookieService } from '../../../services/zci-cookie/zci-cookie.service';
import { AccessTypeSelection } from '../../../../common/enums/access-type-selection.enum';
import { Content } from '../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../utils/content/content.util';
import { UserDetailsDto } from '../../../../common/dtos/user-details.dto';
import { CONSTANTS, CONTENT_PLACEHOLDERS } from '../../../../common/constants/constants';
import { resetGrantRevokePermissionState } from '../../../store/actions/grant-revoke-permissions.action';
import { UserTypeSelection } from '../../../enums/user-type-selection.enum';
import { APPLICATION_ROUTES } from '../../../constants/application-routes';
import { Router } from '@angular/router';
import { AdobeTagging } from '../../../utils/decorators/dev/adobe-launch';
import { adobeConfigData } from './gaf-confirmation-page-mvp-adobe';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-confirmation-page-mvp-component',
    templateUrl: 'gaf-confirmation-page-component-mvp.html',
    styleUrls: ['/gaf-confirmation-page-component-mvp.scss'],
})
export class GafConfirmationPageMvpComponent implements OnInit, OnDestroy {

    accountId: string;

    readonly accessTypeSelection = AccessTypeSelection;

    accessLevel$: Observable<string> = this.store.select(selectAddUserStepperSelectedAccessLevel);

    selectedUserType$: Observable<UserTypeSelection> = this.store.select(selectAddUserStepperSelectedUserType);

    existingUser$: string = 'EXISTING';

    newUser$: string = 'NEW';

    selectedAccess$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    forceSurveyZeroHeight: boolean = true;

    @HostListener('window:MDigital_Form_Displayed')
    removeForcedHeightZero() {
        this.forceSurveyZeroHeight = false;
    }

    content: Content = ContentUtil.content;

    addUserAccessNamePlaceHolder: string = CONTENT_PLACEHOLDERS.FULL_NAME;

    addUserAccessFirstNamePlaceHolder: string = CONTENT_PLACEHOLDERS.FIRST_NAME;

    selectedUser$: Observable<UserDetailsDto>;

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    subs = new Subscription();

    accessType: AccessTypeSelection;

    accountBalancesUrl: string;

    adobeTrackActionLocationInfoOnly: CtaCallUsLinkLocations =
        CtaCallUsLinkLocations.ZCI_ACCESS_REQUEST_SUBMITTED_INFO_ONLY;

    adobeTrackActionLocationLimited: CtaCallUsLinkLocations =
        CtaCallUsLinkLocations.ZCI_ACCESS_REQUEST_SUBMITTED_LIMITED;

    adobeLocationData: CtaCallUsLinkLocations;

    constructor(
        private readonly store: Store,
        protected readonly adobeLaunchService: AdobeLaunchService,
        private readonly breakpointObserverService: BreakpointObserverService,
        private readonly zciCookieService: ZciCookieService,
        private readonly router: Router,
    ) {
    }

    @Input() nextStepClicked: EventEmitter<any>;
    @Input() nextStep: EventEmitter<any>;
    @Input() uniquePin: string;

    ngOnInit() {
        this.selectedUserType$.subscribe((randomName ) => {
            console.log(randomName);
        })
        this.accessLevel$.subscribe((randomName2 ) => {
            console.log(randomName2);
        })
        this.subs.add(this.getAccessType());
        this.trackPageLoad();
        this.selectedUser$ = this.store.select(selectAddUserStepperSelectedUser);
        this.accountBalancesUrl = this.composeAccountBalancesUrl();
        this.adobeLocationData =
            this.accessType === this.accessTypeSelection.INFORMATION_ONLY
                ? this.adobeTrackActionLocationInfoOnly
                : this.adobeTrackActionLocationLimited;
    }

    trackPageLoad() {
        switch (this.accessType) {
            case this.accessTypeSelection.INFORMATION_ONLY:
                this.adobeLaunchService.pageLoad(
                    AdobePageName.GAF_ACCESS_REQUEST_SUBMITTED_INFO_ONLY,
                );
                break;
            case this.accessTypeSelection.LIMITED_ACCESS:
                this.adobeLaunchService.pageLoad(
                    AdobePageName.GAF_ACCESS_REQUEST_SUBMITTED_LIMITED,
                );
                break;
        }
    }

    getAccessType(): Subscription {
        return this.selectedAccess$.pipe(take(1)).subscribe((accessType) => {
            this.accessType = accessType;
        });
    }

    get fullName$(): Observable<string> {
        return this.selectedUser$.pipe(
            map((account) => `${account?.firstName} ${account?.middleName} ${account?.lastName}`),
        );
    }

    get firstName$(): Observable<string> {
        return this.selectedUser$.pipe(
            map((account) => `${account?.firstName}`),
        );
    }

    goBackToTriage() {
        const callToActionData: AdobeAnalyticsCallToAction = {
            location: this.adobeLocationData,
            ctaName: AdobeAnalyticsProcessStep.GAF_ADD_ANOTHER_PERSON,
            ctaType: AnalyticsCtaType.BUTTON,
        };
        this.adobeLaunchService.trackAction(callToActionData);
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
        });

        this.resetGrantRevokePermissionState();
    }

    composeAccountBalancesUrl(): string {
        const accountBalancesUrl = environment.ACCOUNT_BALANCES_URL;

        if (environment.isProduction) {
            return accountBalancesUrl;
        }

        const nonProdPortNumber =
            this.zciCookieService.nonProdPortNumber || CONSTANTS.DEFAULT_NON_PROD_PORT;

        return accountBalancesUrl.replace('port', nonProdPortNumber);
    }

    resetGrantRevokePermissionState(): void {
        this.store.dispatch(resetGrantRevokePermissionState());
    }

    returnToAccessPermissions(): void {
        this.router.navigate([APPLICATION_ROUTES.OWN_ACCOUNTS]);
    }

    tagGoToMyAccountsButtonInfoOnly(): void {}

    tagGoToMyAccountsButtonLimited(): void {}

    tagGoToMyAccountsButtonFullAuthority(): void {}

    tagMyAccountsAccessLevel() {
        if (this.accessType === AccessTypeSelection.INFORMATION_ONLY) {
            this.tagGoToMyAccountsButtonInfoOnly();
        }
        if (this.accessType === AccessTypeSelection.LIMITED_ACCESS) {
            this.tagGoToMyAccountsButtonLimited();
        }
        if (this.accessType === AccessTypeSelection.FULL_AUTHORITY) {
            this.tagGoToMyAccountsButtonFullAuthority();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.resetGrantRevokePermissionState();
    }

    protected readonly UserTypeSelection = UserTypeSelection;
}
