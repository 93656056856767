import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../../services/adobe-launch/adobe-launch.cta';

export const configData = {

    adobeInfoOnlyPageLoad: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_AGREEMENT_STEP_INFO_ONLY_ACCESS],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_ACCESS_AGREEMENT_INFO_ONLY,
                },
            ],
        },
    ],

    adobeLimitedOnlyPageLoad: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_AGREEMENT_STEP_LIMITED_ACCESS],
        },
    ],

    onNextStepLoad: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GAF_ACCESS_AGREEMENT_INFO_ONLY,
                    ctaName: AdobeAnalyticsProcessStep.GAF_STEP_AGREE_AND_CONTINUE,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],

}
