import { Injectable } from '@angular/core';
import { ViewTypeForPageLoad } from 'src/app/interfaces/view-type.enum';

import { ZciCookieService } from '../zci-cookie/zci-cookie.service';
import { AdobeAnalyticsCallToAction } from './adobe-launch.cta';
import { AdobeAnalyticsEvent } from './adobe-launch.events';
import { AdobePageName } from './adobe-launch.pages';
import { AdobeAnalyticsProcess } from './adobe-launch.process';
import { DEFAULT_PAGE, USER_DETECTED } from './adobe-launch.service.config';
import {
    AdobeAnalyticsSubPageViewed,
    AdobeLaunchServiceInterface,
    DataLayerEvent,
} from './adobe-launch.service.interface';

@Injectable({
    providedIn: 'root',
})
export class AdobeLaunchService implements AdobeLaunchServiceInterface {
    // any Adobe event will be processed one at a time
    // we set 100ms timeout between events to make sure Adobe has time to process it
    private delayTime: number = 100;

    private taskQueue: Function[] = [];

    private isStarted: boolean = false;

    addToQueue(executable: Function): void {
        this.taskQueue.push(executable);
        this.start();
    }

    private start(): void {
        if (this.isStarted) return;

        this.isStarted = true;

        setTimeout(this.processQueue.bind(this), this.delayTime);
    }

    private processQueue() {
        const nextTask: Function = this.taskQueue.shift();
        if (nextTask && typeof nextTask === 'function') {
            setTimeout(this.processQueue.bind(this), this.delayTime);
            nextTask();
        } else {
            this.isStarted = false;
        }
    }

    private isInitialized: boolean = false;

    pageNamePrefix: string;

    constructor(private readonly zciCookieService: ZciCookieService) {}

    get isAdobeInitialized(): boolean {
        return this.isInitialized;
    }

    /**
     * @param {AdobeAnalyticsSubPageViewed} Obj To be fired on any page on the site where the user
     *                                          initiates a subpage view via a click or scroll.
     * @param {string} Obj.subPageID captures any interactions with in the page such as
     *                              the tab clicks, accordion labels etc. Useful in SPA to identify
     *                              unique id for tabs or any other component on the page.
     * @param {boolean} Obj.spClicked Indicator of if the user initiated the subpage page view
     *                  via a click or scroll into view
     */

    public subPageViewed({ subPageID, spClicked }: AdobeAnalyticsSubPageViewed): void {
        const toExecute = () => {
            this.updateDataLayer({
                event: AdobeAnalyticsEvent.SUB_PAGE_VIEWED,
                subPage: {
                    subPageID,
                    spClicked: String(spClicked),
                },
            });
        };
        this.addToQueue(toExecute);
    }

    /**
     * @param {AdobeAnalyticsCallToAction} Obj The "CTA click" event should fire any time a user
     *                                          clicks on a call to action.
     * @param {string} Obj.ctaName Captures the name of the call to action. Examples:
     *                                      Select A Year
     *                                      Change
     *                                      View your transaction history
     Tip: use label (content)
     * @param {AnalyticsCtaType} Obj.ctaType Captures the type of CTA (button, link or transaction,
     *                                      education). Ex.: Text Link, Grey Button, Button
     * @param {string} Obj.location Captures the location of the CTA. Examples:
     *                              Contribution Page - Your Contributions Block
     *                              Contribution Page - Your Contribution Sources
     *                              Contribution Page - Related Links Block
     */

    public trackAction({ ctaName, ctaType, location }: AdobeAnalyticsCallToAction): void {
        const toExecute = () => {
            const ctaEvent = {
                event: AdobeAnalyticsEvent.CALL_TO_ACTION,
                callToAction: {
                    location,
                    ctaName,
                    ctaType,
                },
                fireTrackLink: {
                    fireTrackLink: 'true',
                },
            };

            this.updateDataLayer(ctaEvent);
        };

        this.addToQueue(toExecute);
    }

    /**
     * According to: https://confluence.vanguard.com/display/ETM/Retail+-+Datalayer+-+Tech+Spec
     * Use this to track page load.
     * @param pageName unique name for the page.
     * @param viewType optional parameter.
     */

    public pageLoad(pageName: AdobePageName, viewType?: ViewTypeForPageLoad): void {
        const toExecute = () => {
            this.resetDataLayer();
            this.updateDataLayer({
                event: AdobeAnalyticsEvent.PAGE_LOADED,
                page: {
                    pageName: this.getPageNamePrefix() + pageName, //c18, v18
                    pageTemplate: viewType, //v157
                },
            });
            this.userDetected();
            this.pageLoadComplete();
            this.isInitialized = true;
        };

        this.addToQueue(toExecute);
    }

    public trackProcess(event: AdobeAnalyticsEvent, process: AdobeAnalyticsProcess): void {
        const toExecute = () => {
            this.updateDataLayer({
                event,
                process,
            });
        };

        this.addToQueue(toExecute);
    }

    private userDetected(): void {
        /* istanbul ignore next */
        const sPoId = this.zciCookieService.sPOID || USER_DETECTED.NOT_APPLICABLE;

        const user = {
            loginStatus: USER_DETECTED.LOGIN_STATUS,
            sPOID: sPoId,
            age: USER_DETECTED.NOT_APPLICABLE,
        };

        const userDet = {
            event: AdobeAnalyticsEvent.USER_DETECTED,
            user,
        };

        this.updateDataLayer(userDet);
    }

    private pageLoadComplete(): void {
        this.updateDataLayer({
            event: AdobeAnalyticsEvent.PAGE_LOAD_COMPLETED,
        });
    }

    private getPageNamePrefix(): string {
        if (!this.pageNamePrefix) {
            this.pageNamePrefix = this.setupPageNamePrefix();
        }
        return this.pageNamePrefix;
    }

    private setupPageNamePrefix(): string {
        let userLocale = DEFAULT_PAGE.userLocale;
        let lang = DEFAULT_PAGE.lang;

        if (navigator.languages && navigator.languages.length >= 2) {
            navigator.languages.forEach((each) => {
                if (each.indexOf('-') === -1) {
                    /* istanbul ignore next */
                    lang = each ? each.toLowerCase() : DEFAULT_PAGE.lang;
                }
                if (each.indexOf('-') !== -1 && each.split('-')[1]) {
                    userLocale = each.split('-')[1].toLowerCase();
                }
            });
        }
        return userLocale + ':' + lang + DEFAULT_PAGE.prefix;
    }

    private updateDataLayer(event: DataLayerEvent): void {
        /* istanbul ignore next */
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }

    private resetDataLayer(): void {
        if (window.dataLayer) {
            window.dataLayer.length = 0;
        }
    }
}
