<div>
    <p  id="pin"
        class="c11n-text-3xl">{{ uniquePin }}<br><c11n-button
        buttonType="button"
        size="small"
        variant="secondary"
        [labelText]="content.addUserFlow.confirmation.copyPinButton"
        data-cy="copy-pin-button"
        iconName="copy"
        iconPosition="leading"
        (clickEvent)="copyPinToClipboard()"></c11n-button></p>
</div>
