<zci-step-wrapper
    [title]="content.removeAccessFlow.selectUser.title"
    [nextButtonLabelText]="content.stepper.nextButtonLabelText"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickContactUs)="onContactUs(AdobeAnalyticsProcessStep.RAF_SELECT_USER_TO_REMOVE)"
    [currentStep]="currentStep"
    [totalSteps]="content.removeAccessFlow.stepperTitles"
    (clickQuit)="onQuit(AdobeAnalyticsProcessStep.RAF_SELECT_USER_TO_REMOVE)">
    <c11n-banner
        role="alert"
        class="c11n-space-stack-3x"
        [size]="'small'"
        [variant]="'error'"
        [hasCloseControl]="false"
        *ngIf="selectUserFormControl.invalid && selectUserFormControl.dirty"
        data-cy="select-user-error-banner">
        <div
            class="c11n-text-md--crop"
            *ngIf="selectUserFormControl.invalid && selectUserFormControl.dirty">
            {{ content.removeAccessFlow.selectUser.validationMessage.required }}
        </div>
    </c11n-banner>

    <div class="c11n-space-stack-2x custom-radio-style">
        <c11n-radio-group
            [groupId]="radioGroupId"
            [hideLegend]="true"
            [legendText]="content.removeAccessFlow.selectUser.title"
            [formGroup]="selectUserFormGroup"
            [variant]="'tiles'">
            <c11n-radio
                *ngFor="let user of users$ | async; index as i"
                [focusLabel]="false"
                [labelText]="constructUserName(user)">
                <input
                    c11nRadioInput
                    type="radio"
                    [name]="selectUserFormControlName"
                    [value]="user"
                    [formControlName]="selectUserFormControlName" />
            </c11n-radio>
        </c11n-radio-group>
    </div>
    <zci-raf-full-auth-note></zci-raf-full-auth-note>
</zci-step-wrapper>
