import { Injectable } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable, of, switchMap, takeWhile, tap } from 'rxjs';

export interface PaginationProp<T> {
    data$: Observable<T[]>;
    hasMore$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    nextCount$: Observable<number>;
    loadMore: Function;
}

@Injectable()
export class PaginatorService {
    private _loadedCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    private _hasMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _nextCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _total: number = 0;

    private _limit: number;

    nextCount$: Observable<number> = this._nextCount$.asObservable();

    hasMore$: Observable<boolean> = this._hasMore$.asObservable();

    isLoading$: Observable<boolean> = this._isLoading$.asObservable();

    initPaginator<T>(dataSource: Observable<T[]>, limit: number = 5): Observable<T[]> {
        return dataSource.pipe(
            tap(() => {
                this._limit = limit;
            }),
            takeWhile((source: T[]) => !!source),
            switchMap((source: T[]) => {
                const originalSource = of(source);
                if (this._limit >= source.length) {
                    return originalSource;
                } else {
                    return originalSource.pipe(
                        tap(this.initPartialSourceLoad),
                        mergeMap(this.getPartialSource),
                    );
                }
            }),
        );
    }

    loadMore() {
        const currentCount = this._nextCount$.getValue();

        const nextLoadedCount = this._loadedCount$.getValue() + currentCount;

        this._nextCount$.next(this.calculateNextCount(nextLoadedCount));
        this._loadedCount$.next(nextLoadedCount);
    }

    reset() {
        this._loadedCount$.next(null);
        this._hasMore$.next(false);
        this._total = null;
        this._nextCount$.next(null);
    }

    private calculateNextCount(loadedCount: number): number {
        const diff = this._total - loadedCount;
        return diff >= this._limit ? this._limit : diff;
    }

    private getPartialSource = <T>(source: T[]): Observable<T[]> => {
        return this._loadedCount$.pipe(
            tap(() => this._isLoading$.next(true)),
            map((loadedCount: number) => {
                if (!loadedCount || loadedCount === this._total) {
                    this._hasMore$.next(false);
                    return source;
                }
                return source.slice(0, loadedCount);
            }),
            tap(() => this._isLoading$.next(false)),
        );
    };

    private initPartialSourceLoad = (source: any[]) => {
        this._total = source.length;
        this._hasMore$.next(true);
        this._loadedCount$.next(this._limit);
        this._nextCount$.next(this.calculateNextCount(this._limit));
    };
}
