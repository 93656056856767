<div
    class="container row d-flex justify-content-center zci-margin-top c11n-space-stack-10x c11n-space--force"
>
    <div>
        <div class="col-12 no-padding survey-breakpoint-adjustment justify-content-xl-around">
            <div class="c11n-space-stack-6x c11n-space--force col-12 col-xl-7 padding-right">
                <c11n-icon
                    class="custom-green-icon"
                    [name]="'success-fill'"
                    size="medium"
                    scale="larger"
                    data-cy="add-another-person-confirmation-icon"
                >
                </c11n-icon>
                <h1
                    class="c11n-text-2xl-headline c11n-space-stack-3x c11n-space--force"
                    data-cy="page-title"
                >
                    {{ (accessLevelContent$ | async).heading }}
                </h1>
                <div
                    class="c11n-text-md c11n-space-stack-6x d-flex small-text-align justify-content-center c11n-space--force"
                    data-cy="page-title"
                >
                    {{ (accessLevelContent$ | async).subheading }}
                </div>
                <div class="button-wrapper c11n-space-stack-10x c11n-space--force">
                    <c11n-button
                        buttonType="button"
                        size="medium"
                        variant="primary"
                        (clickEvent)="goToDashboard()"
                        [labelText]="granteeSuccessPageContentInfoOnly.navViewMyDashboard"
                        [fullWidth]="isMobileView$ | async"
                        [href]="dashboardUrl"
                    ></c11n-button>
                </div>
            </div>
            <div
                class="d-flex col-12 col-xl-5 mx-auto survey-size-adjustment"
                id="csat-embedded-survey"
                [ngClass]="{ 'survey-height': forceSurveyZeroHeight }"
            ></div>
        </div>
    </div>
</div>
