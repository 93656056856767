import { Injectable, Injector } from '@angular/core';

import { LoggerService } from '../logger/logger.service';

/**
 * This should be the same taxonomy that is passed to the index.html
 */

/**
 * @summary Used for update VGN (Vanguard Global Navigation)
 *
 *
 * @usageNotes Usage:
 * Please only use the following methods from outside:
 * - setMinimalNav
 *
 *
 * @see VGN doc:
 * http://bitbucket.vanguard.com:7990/pages/RETVGN/vanguard-global-navigation.docs/master/browse/latest/docs/index.html#/api/vgn/globalHeader
 **/

@Injectable({
    providedIn: 'root',
})
export class VgnService {
    /**
     * @description Used for caching.
     * We do not want to call VGN when current nav is already the desired one.
     */
    private readonly loggerService: LoggerService;

    constructor(injector: Injector) {
        this.loggerService = injector.get<LoggerService>(LoggerService);
    }

    setMinimalNav(minimalNav: boolean): void {
        const v8nav: Element = document.querySelector('vg-vgn-nav');
        if (v8nav) {
            if (minimalNav) {
                v8nav.setAttribute('no-nav', '4');
            } else {
                v8nav.removeAttribute('no-nav');
            }
        }
    }
}
