import { Content } from '../../../../common/interfaces/content.interface';
import {
    ActionNavOverflowMenuComponent,
    ActionNavOverflowMenuComponentProps,
} from '../../action-nav-overflow-menu/action-nav-overflow-menu.component';

type OtherAccountTableHeaderTitles = Content['home']['otherAccountTable']['headers'];

export enum OtherAccountsTableProps {
    AccName = 'accName',
    AccType = 'accType',
    RoleType = 'roleType',
    Actions = 'actions',
}

export interface OtherAccountTableRow {
    [OtherAccountsTableProps.AccName]: string;
    [OtherAccountsTableProps.AccType]: string;
    [OtherAccountsTableProps.RoleType]: string;
    [OtherAccountsTableProps.Actions]: ActionNavOverflowMenuComponentProps;
}

export const getOtherAccountsTableCols = (headerTitles: OtherAccountTableHeaderTitles) => [
    {
        title: headerTitles.accountName,
        dataKey: OtherAccountsTableProps.AccName,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.accountType,
        dataKey: OtherAccountsTableProps.AccType,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.accessType,
        dataKey: OtherAccountsTableProps.RoleType,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.actions,
        dataKey: OtherAccountsTableProps.Actions,
        sortable: false,
        visible: true,
        customCell: ActionNavOverflowMenuComponent,
    },
];
