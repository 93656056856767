import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root',
})
export class InternalNavigationGuard implements CanDeactivate<CanComponentDeactivate>, CanActivate {
    constructor(private router: Router) {}

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        return false;
    }

    canActivate(): boolean {
        const isInternal: boolean = !!this.router.getCurrentNavigation().extras?.state?.internal;
        if (!isInternal) {
            this.router.navigate(['']);
        }
        return isInternal;
    }
}
