import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-16/modal-dialog';
import { Observable, Subscription, take } from 'rxjs';

import { UserDetailsDto } from '../../../../../common/dtos/user-details.dto';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { IneligibleReasons } from '../../../../../common/enums/ineligible-scenarios.enum';
import { Content } from '../../../../../common/interfaces/content.interface';
import { AccessLevelWithLabel } from '../../../../interfaces/access-level-with-label.interface';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsProcessSideStep,
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { updateAddUserStepperSelectedAccess } from '../../../../store/actions/add-user-stepper-flow.action';
import * as accountPermissionsSelector from '../../../../store/selectors/account-permissions.selector';
import {
    selectAddUserStepperSelectedAccess,
    selectAddUserStepperSelectedUser,
    selectAddUserStepperSelectedUserName,
} from '../../../../store/selectors/grant-revoke-permissions.selector';
import accountPermissionUtils from '../../../../utils/account-permission/account-permission.util';
import { ContentUtil, getAccessLevelsWithLabel } from '../../../../utils/content/content.util';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { StepsOfAddUserToOneSelectedAccount } from '../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { AddUserStepBaseComponent } from '../add-user-step-base.component';
import { adobeConfigData } from './gaf-select-access-step-adobe';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-select-access-step',
    templateUrl: './gaf-select-access-step.component.html',
})
export class GafSelectAccessStepComponent
    extends AddUserStepBaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('selectAccessModalDialogue', { static: false })
    selectAccessModalDialogue!: ModalDialogComponent;

    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    radioGroupId: string = 'access-selection-group';

    content: Content = ContentUtil.content;

    selectedUser$: Observable<UserDetailsDto> = this.store.select(selectAddUserStepperSelectedUser);

    accessLevels: AccessLevelWithLabel[] = getAccessLevelsWithLabel(this.content);

    userName$: Observable<string> = this.store.select(selectAddUserStepperSelectedUserName);

    accessLevel$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    selectedLevelFormControlName: string = 'selectedLevel';

    constructUserName: Function = accountPermissionUtils.constructUserName;

    isCompareAccessLevelToggleClicked: boolean = false;

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_SELECT_ACCESS_STEP;

    private subs: Subscription = new Subscription();

    ineligibleReasons: IneligibleReasons[] = [];

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        protected adobeLaunchService: AdobeLaunchService,
    ) {
        super(adobeLaunchService);
    }

    ngOnInit(): void {
        this.adobeLaunchService.pageLoad(AdobePageName.GAF_SELECT_ACCESS_LEVEL);
        this.subs.add(
            this.store
                .select(selectAddUserStepperSelectedAccess)
                .pipe(take(1))
                .subscribe((data) => this.setSelectedLevelFormControlName(data)),
        );
        this.subs.add(
            this.levelFormGroup
                .get(this.selectedLevelFormControlName)
                .valueChanges.subscribe((data) => {
                    const processSideStep =
                        data === AccessTypeSelection.INFORMATION_ONLY
                            ? AdobeAnalyticsProcessSideStep.GAF_RADIO_BUTTON_INFORMATION_ONLY
                            : AdobeAnalyticsProcessSideStep.GAF_RADIO_BUTTON_LIMITED_ACCESS;
                    this.adobeLaunchService.trackProcess(
                        AdobeAnalyticsEvent.PROCESS_SIDE_STEP_SUCCESS,
                        {
                            processSideStep,
                            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                        },
                    );
                }),
        );
        let accountId = '';
        this.route.paramMap.subscribe((params) => {
            accountId = params.get('id');
        });

        this.store
            .select(accountPermissionsSelector.selectedAccountIsIneligibleFor(accountId))
            .subscribe((data) => {
                this.ineligibleReasons = data;
            });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    levelFormGroup: FormGroup = new FormGroup({
        [this.selectedLevelFormControlName]: new FormControl(null, [Validators.required]),
    });

    get selectedLevelFormControl(): AbstractControl {
        return this.levelFormGroup.get(this.selectedLevelFormControlName);
    }

    onNextStepLoad(): void {
        this.selectedLevelFormControl.markAsDirty();

        if (this.selectedLevelFormControl.valid) {
            this.store.dispatch(
                updateAddUserStepperSelectedAccess({
                    selectedAccess: this.selectedLevelFormControl.value,
                }),
            );

            if (
                this.ineligibleReasons.includes(IneligibleReasons.MANAGED_ACCOUNT) &&
                this.selectedLevelFormControl.value === AccessTypeSelection.LIMITED_ACCESS
            ) {
                this.selectAccessModalDialogue.openModalDialog();
            } else {
                this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.ACCESS_AGREEMENT);
                this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                });
            }
        }
    }

    onContinueModal(): void {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.ACCESS_AGREEMENT);
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
            processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
        });
    }

    onBack(): void {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.SELECT_USER);
    }

    /**
     * we could update the logic below as needed.
     * The current need to listen for the toggle click is to know if a user uses the toggle at least ones before hitting the next button on the stepper
     * So that adobe tag will include it once per next button click
     */
    onDetailsToggle(value: 'open' | 'close') {
        this.isCompareAccessLevelToggleClicked = value === 'open';
    }

    private setSelectedLevelFormControlName(value: AccessTypeSelection): void {
        this.levelFormGroup.controls[this.selectedLevelFormControlName].setValue(value);
    }
}
