<div class="zci-container-md">
    <p class="c11n-space-stack-2x">
        <c11n-icon
            custom-c11n
            class="custom-c11n-svg--fill-error custom-c11n-svg--size-large"
            [name]="'error-fill'"
            scale="larger"></c11n-icon>
    </p>
    <h1 class="c11n-text-xl-headline c11n-space-stack-1_5x" data-cy="page-title">
        {{ content.addUserFlow.unsuccessfulAttempt.pageTitle }}
    </h1>
    <p>{{ content.addUserFlow.unsuccessfulAttempt.paragraph[0] }}</p>
    <p class="c11n-space-stack-3x">{{ content.addUserFlow.unsuccessfulAttempt.paragraph[1] }}</p>
    <p>{{ content.addUserFlow.unsuccessfulAttempt.paragraph[2] }}</p>
    <p class="c11n-space-stack-3x">{{ content.addUserFlow.unsuccessfulAttempt.paragraph[3] }}</p>
    <a
        c11n-link
        variant="primary-reinforced"
        [routerLink]="'/' + appRoutes.OWN_ACCOUNTS"
        data-cy="access-center-link"
        >{{ content.addUserFlow.unsuccessfulAttempt.returnLink }}</a
    >
</div>
