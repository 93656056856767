<zci-step-wrapper
    [title]="content.addUserFlow.step5.title"
    [nextButtonLabelText]="content.stepper.submitButtonLabelText"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickQuit)="onQuit(location)"
    (clickContactUs)="onContactUs(location)"
    [totalSteps]="content.addUserFlow.stepperTitles"
    [currentStep]="currentStep">
    <div class="review-container" data-cy="review-and-submit-container">
        <p class="c11n-text-md-bold c11n-space-stack-2x">
            {{
                content.addUserFlow.step5.description
                    | replaceParam
                        : [
                              ['userName', userName$ | async],
                              ['accessLevelAccess', accessLevel$ | async | lowercase]
                          ]
            }}
        </p>
        <ul role="list" class="review-container__account-list">
            <li>{{ accountName$ | async }}</li>
        </ul>
    </div>

    <div *ngIf="hasCoOwner$ | async" data-cy="review-and-submit-note">
        <span class="c11n-text-md-bold">{{ content.addUserFlow.step5.note }}</span>
        <span class="c11n-text-md">{{
            content.addUserFlow.step5.noteDescriptionPartOne
                | replaceParam
                    : [
                          ['firstName', firstName$ | async],
                          ['firstName', firstName$ | async]
                      ]
        }}</span>
        <span class="c11n-text-md-bold">{{
            content.addUserFlow.step5.noteDescriptionPartTwo
        }}</span>
        <span class="c11n-text-md">{{ content.addUserFlow.step5.noteDescriptionPartThree }}</span>
    </div>
</zci-step-wrapper>
