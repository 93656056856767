import { Component, Input, OnChanges } from '@angular/core';
import { ACCOUNT_ID_PARAM, APPLICATION_ROUTES } from 'src/app/constants/application-routes';

import { IneligibleReasons } from '../../../../common/enums/ineligible-scenarios.enum';
import { NavOverflowExtendedData } from '../../../interfaces/nav-overflow-extended-data.interface';
import { OwnAccountsService } from '../../../services/own-accounts/own-accounts.service';
import { ContentUtil } from '../../../utils/content/content.util';
import { OwnAccountTileInputDataProps } from '../../../utils/sort/sort.service';

@Component({
    selector: 'zci-own-account-tile',
    templateUrl: './own-account-tile.component.html',
})
export class OwnAccountTileComponent implements OwnAccountTileInputDataProps, OnChanges {
    content = ContentUtil.content;

    navOverflowMenuConfig: NavOverflowExtendedData;

    @Input() accId: string;

    @Input() accName: string;

    @Input() accType: string;

    @Input() agentsCount: number;

    @Input() accountIsIneligibleFor: Array<IneligibleReasons>;

    @Input() grantorIsIneligibleFor: Array<IneligibleReasons>;

    @Input() hasIneligibleError: boolean;

    constructor(private readonly ownAccountsService: OwnAccountsService) {}

    ngOnChanges() {
        let ineligibleReasons = [...this.grantorIsIneligibleFor, ...this.accountIsIneligibleFor];

        ineligibleReasons = ineligibleReasons.sort();

        if (ineligibleReasons.includes(IneligibleReasons.MANAGED_ACCOUNT)) {
            ineligibleReasons.pop();
        }

        const ineligibleReason = ineligibleReasons[ineligibleReasons.length - 1];

        if (this.accId) {
            this.navOverflowMenuConfig = this.ownAccountsService.getNavOverflowMenu(
                this.agentsCount,
                this.accId,
                ineligibleReason,
                this.hasIneligibleError,
            );
        }
    }

    get usersOnAccountPath(): string {
        return APPLICATION_ROUTES.USERS_ON_ACCOUNT.CHILD_PATH.replace(
            `:${ACCOUNT_ID_PARAM}`,
            this.accId,
        );
    }
}
