import { AdobeConfig } from '../../../utils/decorators/dev/adobe-launch/adobe-launch.interfaces';
import { AdobeMethods } from '../../../services/adobe-launch/adobe-launch.methods';
import { AnalyticsCtaType, CtaNames } from '../../../services/adobe-launch/adobe-launch.cta';
import { IneligibleReasons } from '../../../../common/enums/ineligible-scenarios.enum';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';

export const adobeConfigData: Record<IneligibleReasons, AdobeConfig> = {
    [IneligibleReasons.ZERO_BALANCE]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_ZERO_BALANCE],
            },
        ],
        goBackToAccessPermissions: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: 'error_zero_balance_account',
                        ctaName: CtaNames.RETURN_TO_ACCESS_PERMISSIONS,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
    },
    [IneligibleReasons.WEB_REG_OR_REREG_HOLD]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_WEB_REG_OR_REREG_HOLD],
            },
        ],
        goBackToAccessPermissions: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: 'error_web_registration',
                        ctaName: CtaNames.RETURN_TO_ACCESS_PERMISSIONS,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
    },
    [IneligibleReasons.ADDRESS_HOLD]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_ADDRESS_HOLD],
            },
        ],
        goBackToAccessPermissions: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: 'error_address_change',
                        ctaName: CtaNames.RETURN_TO_ACCESS_PERMISSIONS,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
    },
    [IneligibleReasons.TRUST_OR_ORGANIZATION_ACCOUNT]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_TRUST_OR_ORGANIZATION_ACCOUNT],
            },
        ],
    },
    [IneligibleReasons.PLEDGED_ACCOUNT]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_PLEDGED_ACCOUNT],
            },
        ],
        goBackToAccessPermissions: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: 'error_pledged_account',
                        ctaName: CtaNames.RETURN_TO_ACCESS_PERMISSIONS,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
    },
    [IneligibleReasons.NONRESIDENT_ALIEN]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_NONRESIDENT_ALIEN],
            },
        ],
        goBackToAccessPermissions: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: 'error_non_resident_alien',
                        ctaName: CtaNames.RETURN_TO_ACCESS_PERMISSIONS,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
    },
    [IneligibleReasons.SUBJECT_TO_B_NOTICE]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_SUBJECT_TO_B_NOTICE],
            },
        ],
        goBackToAccessPermissions: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: 'error_call_us_notice_tin',
                        ctaName: CtaNames.RETURN_TO_ACCESS_PERMISSIONS,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
    },
    [IneligibleReasons.MANAGED_ACCOUNT]: {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [AdobePageName.GRANTOR_INELIGIBLE_MANAGED_ACCOUNT],
            },
        ],
    },
};
