import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../logger/logger.service';

interface PendingRequestExistingUserPayload {
    sagId: string;
    granteePoid: string;
}

export interface PendingRequestNewUserPayload {
    sagId: string;
    granteeEmail: string;
}

export type PendingRequestPayload =
    | PendingRequestExistingUserPayload
    | PendingRequestNewUserPayload;

export interface PendingRequestResponse {
    hasPendingRequest: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class PendingRequestService {
    constructor(
        private httpClient: HttpClient,
        private loggerService: LoggerService,
    ) {}

    checkRequestForExistingUser(
        sagId: string,
        granteePoid: string,
    ): Observable<PendingRequestResponse> {
        return this.checkRequest({
            sagId,
            granteePoid,
        });
    }

    checkRequestForNewUser(
        sagId: string,
        granteeEmail: string,
    ): Observable<PendingRequestResponse> {
        return this.checkRequest({
            sagId,
            granteeEmail,
        });
    }

    private checkRequest(body: PendingRequestPayload): Observable<PendingRequestResponse> {
        return this.httpClient
            .post<PendingRequestResponse>(
                `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.CHECK_PENDING_REQUEST}`,
                body,
            )
            .pipe(
                catchError((e) => {
                    this.loggerService.error(
                        `${
                            PendingRequestService.name
                        }: error while checking pending request for ${JSON.stringify(body)}`,
                    );
                    return throwError(() => e);
                }),
            );
    }
}
