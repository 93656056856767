import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { IneligibleReasons } from '../../../../common/enums/ineligible-scenarios.enum';
import { IneligiblePageContent } from '../../../../common/interfaces/ineligible-page-content.interface';
import { content } from '../../../../content/zci/content';
import { environment } from '../../../../environments/environment';
import { APPLICATION_ROUTES } from '../../../constants/application-routes';
import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';
import { VgnService } from '../../../services/vgn/vgn.service';
import { ContentUtil } from '../../../utils/content/content.util';
import {
    AdobeConfig,
    AdobeTag,
} from '../../../utils/decorators/dev/adobe-launch/adobe-launch.interfaces';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import { adobeConfigData } from './ineligible-page.component.adobe';

@Component({
    selector: 'zci-ineligible-page',
    templateUrl: './ineligible-page.component.html',
    styleUrls: ['./ineligible-page.component.scss'],
})
export class IneligiblePageComponent implements OnInit, OnDestroy {
    content = ContentUtil.content || content;

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    pageContent: IneligiblePageContent;

    isTrustOrOrgAccountType = false;

    agentAuthorizationFormUrl: string;

    private adobeData: AdobeConfig;

    constructor(
        private readonly vgnService: VgnService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly breakpointObserverService: BreakpointObserverService,
        private adobeLaunchService: AdobeLaunchService,
    ) {}

    ngOnInit(): void {
        this.vgnService.setMinimalNav(false);
        this.route.paramMap.subscribe((params) => {
            const ineligibleReason = params.get('id');
            if (Number(ineligibleReason) === IneligibleReasons.TRUST_OR_ORGANIZATION_ACCOUNT) {
                this.agentAuthorizationFormUrl = environment.AGENT_AUTHORIZATION_FORM_URL;
                this.isTrustOrOrgAccountType = true;
            }

            this.pageContent = this.content.ineligibleScenarios[ineligibleReason];

            this.adobeData = adobeConfigData[ineligibleReason];

            this.applyAdobeTagging('ngOnInit');
        });
    }

    ngOnDestroy(): void {
        this.vgnService.setMinimalNav(true);
    }

    goBackToAccessPermissions() {
        this.applyAdobeTagging('goBackToAccessPermissions');
        this.router.navigate([APPLICATION_ROUTES.OWN_ACCOUNTS]);
    }

    applyAdobeTagging(key: keyof this) {
        if (!this.adobeData) {
            return;
        }
        const tagData: AdobeTag[] = this.adobeData[key as string];
        if (!tagData) {
            return;
        }
        tagData.forEach((t: AdobeTag) =>
            this.adobeLaunchService[t.method].apply(this.adobeLaunchService, t.data),
        );
    }
}
