import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

export enum PERMISSION_REQUEST_ERRORS {
    NO_ERROR,
    RECORD_NOT_FOUND,
    RECORD_EXPIRED,
    PIN_IS_INCORRECT,
    TOO_MANY_ATTEMPTS,
}

//TODO replace with actual endpoint when available
@Injectable({
    providedIn: 'root',
})
export class PinValidationService {
    constructor(private readonly httpClient: HttpClient) {}

    validatePin(pin: string, token: string): Observable<{ error: PERMISSION_REQUEST_ERRORS }> {
        return this.httpClient
            .post<{ error: PERMISSION_REQUEST_ERRORS }>(
                `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.AUTHENTICATE_PIN}`,
                {
                    pin,
                    token,
                },
            )
            .pipe(
                catchError((error) => {
                    return of({ error: PERMISSION_REQUEST_ERRORS.RECORD_NOT_FOUND });
                }),
            );
    }
}
