<div class="c11n-space-stack-4x d-flex justify-content-end">
    <button class="d-flex contact-us-button" (click)="contactUs()" data-cy="contact-us">
        <c11n-icon
            class="contact-us-button__icon"
            name="chat-internal"
            size="small"
            altText="Contact us icon"></c11n-icon>
        <span class="contact-us-button__label">{{ content.removeAccessFlow.contactUs }}</span>
    </button>
    <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
    <c11n-button
        [labelText]="content.stepper.quitButtonLabelText"
        (clickEvent)="openModalDialog()"
        iconName="remove"
        iconPosition="trailing"
        buttonType="button"
        size="small"
        variant="secondary"
        data-cy="quit-button"></c11n-button>
    <c11n-modal-dialog
        [variant]="'warning'"
        [primaryButtonLabel]="content.quitModal.primaryButtonText"
        [secondaryButtonLabel]="content.quitModal.secondaryButtonText"
        [headingText]="content.quitModal.headingText"
        [hideBody]="true"
        (primaryClick)="quit()"
        (secondaryClick)="stayOnCurrentStep()"
        [maxWidth]="660"
        [useContentWidth]="false"
        data-cy="error-modal"
        #quitButtonModalDialog>
    </c11n-modal-dialog>
</div>

<div class="c11n-space-stack-3x">
    <zci-responsive-step-indicator
        [stepLabels]="totalSteps"
        [currentStep]="currentStep"></zci-responsive-step-indicator>
</div>

<h1 class="c11n-text-xl-headline" [ngClass]="'c11n-space-stack-' + titleMargin" aria-live="polite">
    {{ title }}
</h1>

<div class="c11n-space-stack-6x">
    <ng-content></ng-content>
</div>

<div class="button-wrapper">
    <c11n-button
        *ngIf="showNextButton"
        buttonType="button"
        size="medium"
        variant="primary"
        [labelText]="nextButtonLabelText"
        [isWaiting]="isWaiting$ | async"
        [fullWidth]="isMobileView$ | async"
        (clickEvent)="nextButton()"
        data-cy="stepper-next-button"></c11n-button>

    <c11n-button
        *ngIf="showBackButton"
        buttonType="button"
        size="medium"
        variant="secondary"
        [labelText]="content.stepper.backButtonLabelText"
        [fullWidth]="isMobileView$ | async"
        (clickEvent)="backButton()"
        data-cy="stepper-back-button"></c11n-button>
</div>
