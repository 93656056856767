import { createAction, props } from '@ngrx/store';

import { OwnAccountDTO } from '../../../common/dtos/own-account.dto';
import { UserDetailsDto } from '../../../common/dtos/user-details.dto';

export const updateRemoveUserStepperSelectedAccount = createAction(
    '[Remove User Stepper Flow] Update Selected Account',
    props<{ selectedAccount: OwnAccountDTO }>(),
);

export const updateRemoveUserStepperSelectedUser = createAction(
    '[Remove User Stepper Flow] Update Selected User',
    props<{ selectedUser: UserDetailsDto }>(),
);
