import {
    Component,
    EventEmitter,
    HostBinding,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DrawerComponent } from '@vg-constellation/angular-16/drawer';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { VgnService } from 'src/app/services/vgn/vgn.service';
import { selectAccountById } from 'src/app/store/selectors/account-permissions.selector';
import { ContentUtil } from 'src/app/utils/content/content.util';
import { OwnAccountDTO } from 'src/common/dtos/own-account.dto';
import { Content } from 'src/common/interfaces/content.interface';

import { ACCOUNT_ID_PARAM, APPLICATION_ROUTES } from '../../../constants/application-routes';
import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';
import { updateAddUserStepperSelectedAccount } from '../../../store/actions/add-user-stepper-flow.action';
import { PageComponent } from '../page.component';
import { StepsOfAddUserToOneSelectedAccount } from './enums/steps-of-add-user-to-one-selected-account.enum';

@Component({
    selector: 'zci-add-user-stepper-page',
    templateUrl: './add-user-stepper-page.component.html',
    styleUrls: ['./add-user-stepper-page.component.scss'],
})
export class AddUserStepperPageComponent extends PageComponent implements OnInit, OnDestroy {
    @ViewChild('gafDrawer', { static: false }) drawerComponent!: DrawerComponent;

    content: Content = ContentUtil.content;

    accountId: string;

    stepperSteps = StepsOfAddUserToOneSelectedAccount;

    @Output() toUnsuccessfulAttempt: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.push') pushContent: boolean = false;

    @HostBinding('class.gaf-drawer-open')
    drawerOpen: boolean = false;

    currentStep$: BehaviorSubject<StepsOfAddUserToOneSelectedAccount> =
        new BehaviorSubject<StepsOfAddUserToOneSelectedAccount>(this.stepperSteps.TRIAGE);

    isMobileView$: Observable<boolean> = this.breakpointObserver.isMobileView$;

    isTabletView$: Observable<boolean> = this.breakpointObserver.isTabletView$;

    contactPhone: string = ContentUtil.globalRMSupportContactPhoneNumber;

    title: string = ContentUtil.wealthManagementSupportContent;

    // eslint-disable-next-line max-params
    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly store: Store,
        private readonly vgnService: VgnService,
        private readonly breakpointObserver: BreakpointObserverService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.vgnService.setMinimalNav(true);
        this.accountId = this.activatedRoute.snapshot.params[ACCOUNT_ID_PARAM];
        this.storeSelectedAccount();
    }

    ngOnDestroy(): void {
        this.vgnService.setMinimalNav(false);
    }

    toggleDrawer(): void {
        if (this.drawerOpen) {
            this.closeContactUs();
        } else {
            this.openContactUs();
        }
    }

    onDrawerStateChange(event: any): void {
        this.drawerOpen = event.isDrawerOpen;
        if (!this.drawerComponent.isModal) {
            this.pushContent = event.isDrawerOpen;
        }
    }

    onStepChange(newStep: StepsOfAddUserToOneSelectedAccount): void {
        this.scrollTop();
        this.closeContactUs();
        if (!newStep) {
            return;
        }

        if (newStep === StepsOfAddUserToOneSelectedAccount.UNSUCCESSFUL_ATTEMPT) {
            this.toUnsuccessfulAttempt.emit();
            return;
        }

        if (newStep === StepsOfAddUserToOneSelectedAccount.QUIT) {
            this.router.navigate([APPLICATION_ROUTES.OWN_ACCOUNTS]);
            return;
        }

        this.currentStep$.next(newStep);
    }

    private openContactUs(): void {
        this.drawerComponent.openDrawer();
    }

    private closeContactUs(): void {
        this.drawerComponent.closeDrawer();
    }

    private scrollTop(): void {
        window.scrollTo(0, 0);
    }

    private storeSelectedAccount() {
        this.store
            .select(selectAccountById(this.accountId))
            .pipe(
                take(1),
                tap((selectedAccount: OwnAccountDTO) => {
                    this.store.dispatch(
                        updateAddUserStepperSelectedAccount({
                            selectedAccount,
                        }),
                    );
                }),
            )
            .subscribe();
    }
}
