<div class="main-content">
    <ng-container [ngSwitch]="currentStep$ | async">
        <zci-add-user-triage-page
            *ngSwitchCase="stepperSteps.TRIAGE"
            [currentStep]="currentStep$ | async"
            (stepChange)="onStepChange($event)"
            (contactUs)="toggleDrawer()">
        </zci-add-user-triage-page>

        <zci-gaf-select-user-step
            *ngSwitchCase="stepperSteps.SELECT_USER"
            [accId]="accountId"
            [currentStep]="currentStep$ | async"
            (stepChange)="onStepChange($event)"
            (contactUs)="toggleDrawer()">
        </zci-gaf-select-user-step>

        <zci-gaf-select-access-step
            *ngSwitchCase="stepperSteps.SELECT_ACCESS_TYPE"
            [currentStep]="currentStep$ | async"
            (stepChange)="onStepChange($event)"
            (contactUs)="toggleDrawer()">
        </zci-gaf-select-access-step>

        <zci-gaf-access-agreement-step
            *ngSwitchCase="stepperSteps.ACCESS_AGREEMENT"
            [currentStep]="currentStep$ | async"
            (stepChange)="onStepChange($event)"
            (contactUs)="toggleDrawer()">
        </zci-gaf-access-agreement-step>

        <zci-gaf-review-step
            *ngSwitchCase="stepperSteps.REVIEW_AND_SUBMIT"
            [currentStep]="currentStep$ | async"
            (stepChange)="onStepChange($event)"
            (contactUs)="toggleDrawer()">
        </zci-gaf-review-step>
    </ng-container>
</div>
<aside
    c11n-drawer
    class="gaf-drawer"
    [isModal]="isTabletView$ | async"
    [drawerWidth]="(isMobileView$ | async) ? '100%' : '480px'"
    (drawerState)="onDrawerStateChange($event)"
    #gafDrawer
    data-cy="contact-us-drawer">
    <ng-container>
        <zci-advisor
            [name]="title"
            [phoneNumber]="contactPhone"
            class="representative-card__details">
            <p class="c11n-text-xl-headline c11n-space-stack-6x drawer-title">
                {{ content.addUserFlow.contactUs }}
            </p>
        </zci-advisor>
    </ng-container>
</aside>
