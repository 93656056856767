import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsProcessStep } from '../../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../../services/adobe-launch/adobe-launch.cta';

export function getAdobeConfigData(granteeFlow: 'existing' | 'new') {
    let pageName: string;

    if (granteeFlow === 'new') {
        pageName = ''; //TODO REPLACE WITH REAL VALUE;
    } else {
        pageName = AdobePageName.GRANTEE_SUCCESS_GRANTEE_ON_LIST;
    }

    return {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [pageName],
            },
        ],
        goToDashboard: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: AdobeAnalyticsProcessStep.GRANTEE_AUTH_AGREEMENT_ON_LIST,
                        ctaName: AdobeAnalyticsProcessStep.GRANTEE_VIEW_MY_DASHBOARD,
                        ctaType: AnalyticsCtaType.BUTTON,
                        fireTrackLink: true,
                    },
                ],
            },
        ],
    };
}
