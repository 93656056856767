import { development } from './environment.development';
import { Environment } from './environment.interface';

export const environment: Environment = {
    ...development,
    GLOBAL_NAV_URL: 'https://satccc.vgcontent.info',
    GLOBAL_NAV_TAXONOMY: 'https://n4v.webt.vanguard.com/secure-site-components.js',
    GLOBAL_NAV_STYLES: 'https://n4v.webt.vanguard.com/nav-preload.css',
    VG_API_ENDPOINTS: {
        ACCOUNT_ROLES: 'https://apicenter.rett.c1.vanguard.com/retail/accounts-roles-management',
        ACCOUNT_AGGREGATOR: 'https://apicenter.rett.c1.vanguard.com/retail/accounts/graphql',
        CLIENT: 'https://apicenter.rett.c1.vanguard.com/retail/clients-management/graphql',
        CDM: 'https://cdm-client-search-internal.ecs.retailinvest1t.c1.vanguard.com/client-search-oauth/v2/details',
        SEW: 'https://apicenter.rett.c1.vanguard.com/retail/clients/sag-enrollment',
        TP1: 'https://apicenter.rett.c1.vanguard.com/retail/accounts/tax-profile',
        RESTRICTIONS_MANAGEMENT:
            'https://apicenter.rett.c1.vanguard.com/retail/clients/restrictions-management/restrictions/?restrictionLevel=ACCOUNT',
        CDX: {
            URL: 'https://catapii.ecs.us-east-1.itlt.c1.vanguard.com/cdx-Common-Document-Exchange-webservice',
            PATH_PREFIX: '/cdx/jwt',
            ACTION: '/upload',
            APP_CODE: '/fnp8',
            OBJECT_STORE: '/FileNetISCatRetObjectStore',
        },
        OAUTH_INTERNAL: 'https://oauthi.opst.c1.vanguard.com/auth/oauth/v2/vgauthorize',
        OAUTH: 'https://oauthe.opst.c1.vanguard.com/auth/oauth/v2/vgauthorize',
        CC0: 'https://apicenter.rett.c1.vanguard.com/retail/client-contacts-management',
        CE3: 'https://apicenter.rett.c1.vanguard.com/retail/clients/employments',
        CTP: 'https://apicenter.rett.c1.vanguard.com/retail/clients/control-person/',
    },
    UHNW_DPOC_URL: 'https://dpoc.webt.vanguard.com/build/web-components.esm.js',
};
