import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AccountsInfo } from '../../../common/dtos/accounts-info.dto';

export const accountUsersDataLoadedSuccess = createAction(
    '[Account Permission API] Account Users Permission Loaded Success',
    props<{ data: AccountsInfo }>(),
);

export const accountUsersDataLoadedError = createAction(
    '[Account Permission API] Account Users Permission Loaded Error',
    props<{ error: HttpErrorResponse }>(),
);
