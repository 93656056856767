import {
    SEMRESATTRS_SERVICE_NAME,
    SEMRESATTRS_SERVICE_NAMESPACE,
} from '@opentelemetry/semantic-conventions';
import { detectResourcesSync, Resource } from '@opentelemetry/resources';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { WebTracerProvider, BatchSpanProcessor } from '@opentelemetry/sdk-trace-web';
import { ZoneContextManager } from '@opentelemetry/context-zone-peer-dep';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { browserDetector } from '@opentelemetry/opentelemetry-browser-detector';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';


const application = new Resource({
    [SEMRESATTRS_SERVICE_NAME]: 'access-permission-frontend',
    [SEMRESATTRS_SERVICE_NAMESPACE]: 'access-permission',
});

const web = location.href.match(/\.web([et])?\.vanguard\.com/);
const isLocal = (matches: RegExpMatchArray | null): matches is null => matches === null;
const sysLevelSuffix = isLocal(web) ? 'e' : web[1] || 'p';
const url = `https://gto-ocp.icproxy.sec${sysLevelSuffix}.c1.vanguard.com/v1`;

function registerTracesProvider(application: Resource, url: string) {
    const resources = detectResourcesSync({ detectors: [browserDetector] });
    const resource = resources.merge(application);
    const provider = new WebTracerProvider({ resource });
    const exporter = new OTLPTraceExporter({ url });
    provider.addSpanProcessor(new BatchSpanProcessor(exporter));

    const contextManager = new ZoneContextManager();
    const propagator = new W3CTraceContextPropagator();
    provider.register({ contextManager, propagator });
}

const productionLike = sysLevelSuffix !== 'e';
const BypassSampling = new Resource({ 'vg.otel.sampling': 'bypass' });
const resource = application.merge(productionLike ? BypassSampling : null);

registerInstrumentations({ instrumentations: [new XMLHttpRequestInstrumentation()]});
registerTracesProvider(resource, `${url}/traces`);
