import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, retry, switchMap } from 'rxjs';

import { AccountPermissionsService } from '../../services/account-permissions/account-permissions.service';
import {
    accountUsersDataLoadedError,
    accountUsersDataLoadedSuccess,
} from '../actions/account-permissions-api.action';
import * as AccountPermissionPageActions from '../actions/account-permissions-page.action';
import { LoggerService } from '../../services/logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class AccountPermissionsApiEffect {
    constructor(
        private readonly accountPermissionsService: AccountPermissionsService,
        private readonly store: Store,
        private readonly actions$: Actions,
        private readonly logger: LoggerService,
    ) {}

    loadAccountUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AccountPermissionPageActions.loadAccountUsers),
            switchMap(() => {
                return this.accountPermissionsService.getAccountUsers().pipe(
                    retry(2),
                    map((result) => {
                        this.logger.info('Accounts successfully loaded')
                        return accountUsersDataLoadedSuccess({
                            data: result,
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.logger.error('Accounts failed to load')
                        return of(accountUsersDataLoadedError({ error }));
                    }),
                );
            }),
        );
    });
}
