<h1 class="c11n-text-xl-headline">{{ content?.addUserFlow.mvp.triage.pageTitle }}</h1>
<div class="justify-content-center negative-margin col-12 col-sm-9 col-md-9 col-lg-8 col-xl-8 col-xxl-8">
    <zci-triage-mvp-tile
        tabindex="0"
        role="button"
        [title]="content.addUserFlow.mvp.triage.existingGrantee.title"
        (click)="navigateSelectUser()"
        (keydown.enter)="navigateSelectUser()"
        (keydown.space)="navigateSelectUser()"
    >
    </zci-triage-mvp-tile>
    <zci-triage-mvp-tile
        tabindex="0"
        role="button"
        [title]="content.addUserFlow.mvp.triage.newGrantee.title"
        [description]="content.addUserFlow.mvp.triage.newGrantee.description"
        (click)="navigateAddSomeoneElse()"
        (keydown.enter)="navigateAddSomeoneElse()"
        (keydown.space)="navigateAddSomeoneElse()"
    >
    </zci-triage-mvp-tile>
    <zci-triage-mvp-tile
        tabindex="0"
        role="button"
        [title]="content.addUserFlow.mvp.triage.nonVgGrantee.title"
        [description]="content.addUserFlow.step1.nonVgGrantee.description"
        (click)="navigateToFormFromNonVGUser()"
        (keydown.enter)="navigateToFormFromNonVGUser()"
        (keydown.space)="navigateToFormFromNonVGUser()"
    >
    </zci-triage-mvp-tile>
    <zci-triage-mvp-tile
        tabindex="0"
        role="button"
        [title]="content.addUserFlow.mvp.triage.orgTrust.title"
        [description]="content.addUserFlow.mvp.triage.orgTrust.description"
        (click)="navigateToFormFromOrgTrust()"
        (keydown.enter)="navigateToFormFromOrgTrust()"
        (keydown.space)="navigateToFormFromOrgTrust()"
    >
    </zci-triage-mvp-tile>
</div>
