import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { filter, map, Observable, take, tap } from 'rxjs';
import { ineligibleScenariosForGranteeInGranteeFlow } from '../store/actions/ineligible-scenarios.action';
import { select, Store } from '@ngrx/store';
import { selectGranteeState } from '../store/selectors/grantee.selector';
import { GranteeIneligibleReasons } from '../../common/enums/ineligible-scenarios.enum';
import { ErrorPage } from '../services/generic-error/error-page-utils';
import { ErrorService } from '../services/generic-error/error.service';

@Injectable({
    providedIn: 'root',
})
export class IneligibleScenariosResolver implements Resolve<any> {
    constructor(
        private store: Store,
        private errorService: ErrorService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        this.store.dispatch(ineligibleScenariosForGranteeInGranteeFlow());

        return this.store.pipe(
            select(selectGranteeState),
            filter((granteeState) => !granteeState.isLoading && !granteeState.error),
            map((granteeState) => granteeState.granteeIneligibleReason),
            tap((reason: GranteeIneligibleReasons) => {
                if (reason === GranteeIneligibleReasons.FOREIGN_ADDRESS) {
                    this.errorService.showError(ErrorPage.GRANTEE_FOREIGN_ADDRESS);
                }
                if (reason == GranteeIneligibleReasons.ASSOCIATED_OR_CONTROL_PERSON) {
                    this.errorService.showError(ErrorPage.GRANTEE_CANT_COMPLETE_ONLINE);
                }
            }),
            take(1),
        );
    }
}
