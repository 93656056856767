import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../../services/adobe-launch/adobe-launch.cta';


export const adobeConfigData = {

    triggerInfoOnlyAccessAdobePageName: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_REVIEW_AND_SUBMIT_INFO_ONLY_ACCESS],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_REVIEW_AND_SUBMIT_INFO_ONLY,
                },
            ],
        },
    ],

    triggerLimitedAccessJointOwnerPageName: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_REVIEW_AND_SUBMIT_LIMITED_ACCESS_JOINT],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_REVIEW_AND_SUBMIT_LIMITED_JOINT,
                },
            ],
        },
    ],

    triggerLimitedAccessIndividualPageName: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_REVIEW_AND_SUBMIT_LIMITED_ACCESS_INDIVIDUAL],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_REVIEW_AND_SUBMIT_LIMITED_INDIVIDUAL,
                },
            ],
        },
    ],

    onNextStepLoadAdobeEventInfoOnly: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GAF_REVIEW_AND_SUBMIT_INFO_ONLY,
                    ctaName: AdobeAnalyticsProcessStep.GAF_SUBMIT,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],

    onNextStepLoadAdobeEventLimited: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_REVIEW_AND_SUBMIT_LIMITED_SUBMIT,
                },
            ],
        },
    ],

    onNextStepLoadAdobeEventFullAuthority: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GAF_REVIEW_AND_SUBMIT_FULL_AUTHORITY,
                    ctaName: AdobeAnalyticsProcessStep.GAF_SUBMIT,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ]
}
