import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, retry, switchMap } from 'rxjs';

import { ClientsService } from '../../services/clients/clients.service';
import {
    cdmDataLoadedError,
    cdmDataLoadedSuccess,
} from '../actions/grant-revoke-permission.api.action';
import * as grantRevokePermissionActions from '../actions/grant-revoke-permissions.action';
@Injectable({
    providedIn: 'root',
})
export class GrantRevokePermissionApiEffect {
    constructor(
        private readonly clientSearchService: ClientsService,
        private readonly actions$: Actions,
    ) {}

    searchClient$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(grantRevokePermissionActions.searchClient),
            switchMap((action) => {
                return this.clientSearchService.searchClient(action.clientSearchQuery).pipe(
                    retry(2),
                    map((client) => {
                        return cdmDataLoadedSuccess({ client });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status !== 400) {
                            return of(cdmDataLoadedError({ error }));
                        } else return of(cdmDataLoadedSuccess({ client: null }));
                    }),
                );
            }),
        );
    });
}
