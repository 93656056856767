function getContentWithDynamicNumber(text: string, num?: number) {
    return text.replace('{{}}', num?.toString() || '');
}

function addClassToLinks(htmlString: string): string {
    const regex = /<a([^>]*)>/g;
    return htmlString.replace(regex, '<a class="c11n-link"$1>');
}

export default {
    getContentWithDynamicNumber,
    addClassToLinks,
};
