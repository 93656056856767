import { Component, Input } from '@angular/core';

import { ContentUtil } from '../../utils/content/content.util';

@Component({
    selector: 'zci-back-link',
    templateUrl: './back-link.component.html',
})
export class BackLinkComponent {
    content = ContentUtil.content;

    linkIconName = 'link-left';

    classNames = 'c11n-text-md';

    @Input() link: string;

    @Input() isExternal: boolean = false;
}
