<section
    class="full-auth-note-section col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9"
    *ngIf="true">
    <p class="c11n-text-sm">
        <strong>{{ content.removeAccessFlow.selectUser.fullAuthorityNote.title }}</strong>
    </p>
    <p class="c11n-text-sm">
        {{ content.removeAccessFlow.selectUser.fullAuthorityNote.description }}
    </p>
</section>
