import { Component, OnInit, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';

import { ZciRouteData } from '../../app-routing.config';
import { GatekeeperService } from '../../services/gatekeeper/gatekeeper.service';

@Component({
    selector: 'zci-dynamic-route',
    templateUrl: './dynamic-route.component.html',
})
export class DynamicRouteComponent implements OnInit {
    component$: Observable<Type<Component>>;

    constructor(
        private readonly gatekeeperService: GatekeeperService,
        private readonly activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.component$ = this.getComponentBasedOnGatekeeper();
    }

    private getComponentBasedOnGatekeeper(): Observable<Type<Component>> {
        const routeData: ZciRouteData = <ZciRouteData>this.activatedRoute.snapshot.data;

        const { featureId, onComponent, offComponent } = routeData.dynamicRouteComponent || {};

        if (!(featureId && onComponent && offComponent)) {
            throw new Error(
                `Please add the proper route data under this path: ${this.activatedRoute.snapshot.routeConfig.path}`,
            );
        }

        return this.gatekeeperService.isToggleActive(featureId).pipe(
            map((isEnabled: boolean) => {
                return isEnabled ? onComponent : offComponent;
            }),
            catchError(() => {
                return of(offComponent);
            }),
        );
    }
}
