import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Content } from '../../../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../../../utils/content/content.util';

@Component({
    selector: 'zci-complete-our-form',
    templateUrl: './gaf-complete-our-form.component.html',
    styleUrls: ['./gaf-complete-our-form.component.scss'],
})
export class GafCompleteOurFormComponent {
    content: Content = ContentUtil.content;

    environment = environment;
}
