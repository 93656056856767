import { createAction, props } from '@ngrx/store';

import { ClientSearchQuery } from '../../../common/dtos/client-search.query.dto';
import { UserDetailsDto } from '../../../common/dtos/user-details.dto';
import { ApiState } from '../state/api.state';

export const resetGrantRevokePermissionState = createAction(
    '[Grant/Revoke Permission] Reset All State',
);

export const resetGrantRevokePermissionStateExceptAttemptCounter = createAction(
    '[Grant/Revoke Permission] Reset All State Except Attempt Counter',
);

export const incrementSearchPersonAttemptCounter = createAction(
    '[Grant/Revoke Permission] Add User Search Counter Increment',
);

export const resetSearchPersonAttemptCounter = createAction(
    '[Grant/Revoke Permission] Add User Search Counter Reset',
);

export const searchClient = createAction(
    '[Add User Stepper Flow] Search client',
    props<{ clientSearchQuery: ClientSearchQuery }>(),
);

export const addNewUser = createAction(
    '[Add User Stepper Flow] Add New User',
    props<{ addedUser: ApiState<UserDetailsDto> }>(),
);
