import { Environment } from './environment.interface';
import { production } from './environment.production';

export const environment: Environment = {
    ...production,
    GLOBAL_NAV_URL: 'https://ccc.vgcontent.info',
    GLOBAL_NAV_TAXONOMY: 'https://n4v.web.vanguard.com/secure-site-components.js',
    GLOBAL_NAV_STYLES: 'https://n4v.web.vanguard.com/nav-preload.css',
    VG_API_ENDPOINTS: {
        ACCOUNT_ROLES: 'https://apicenter.retp.c1.vanguard.com/retail/accounts-roles-management',
        ACCOUNT_AGGREGATOR: 'https://apicenter.retp.c1.vanguard.com/retail/accounts/graphql',
        CLIENT: 'https://apicenter.retp.c1.vanguard.com/retail/clients-management/graphql',
        CDM: 'https://cdm-client-search-internal.ecs.retailinvest1p.c1.vanguard.com/client-search-oauth/v2/details',
        SEW: 'https://apicenter.retp.c1.vanguard.com/retail/clients/sag-enrollment',
        TP1: 'https://apicenter.retp.c1.vanguard.com/retail/accounts/tax-profile',
        RESTRICTIONS_MANAGEMENT:
            'https://apicenter.retp.c1.vanguard.com/retail/clients/restrictions-management/restrictions/?restrictionLevel=ACCOUNT',
        CDX: {
            URL: 'https://cdx.itlp.c1.vanguard.com',
            PATH_PREFIX: '/cdx/jwt',
            ACTION: '/upload',
            APP_CODE: '/fnp8',
            OBJECT_STORE: '/FileNetISPrdRetObjectStore',
        },
        OAUTH_INTERNAL: 'https://oauthi.opsp.c1.vanguard.com/auth/oauth/v2/vgauthorize',
        OAUTH: 'https://oauthe.opsp.c1.vanguard.com/auth/oauth/v2/vgauthorize',
        CC0: 'https://apicenter.retp.c1.vanguard.com/retail/client-contacts-management',
        CE3: 'https://apicenter.retp.c1.vanguard.com/retail/clients/employments',
        CTP: 'https://apicenter.retp.c1.vanguard.com/retail/clients/control-person/',
    },
    UHNW_DPOC_URL: 'https://dpoc.web.vanguard.com/build/web-components.esm.js',
};
