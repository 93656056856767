import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { UserDetailsDto } from '../../../common/dtos/user-details.dto';
import {
    GranteeIneligibleReasons,
    IneligibleReasons,
} from '../../../common/enums/ineligible-scenarios.enum';

@Injectable({
    providedIn: 'root',
})
export class IneligibleScenariosService {
    constructor(private readonly httpClient: HttpClient) {}

    getIneligibleScenariosForGrantor(): Observable<Array<IneligibleReasons>> {
        return this.httpClient.get<Array<IneligibleReasons>>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.INELIGIBLE_SCENARIOS_GRANTOR}`,
        );
    }

    getIneligibleScenariosForGrantee(
        userDetails: UserDetailsDto,
    ): Observable<{ granteeIneligibleReason: GranteeIneligibleReasons }> {
        return this.httpClient.post<{ granteeIneligibleReason: GranteeIneligibleReasons }>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.INELIGIBLE_SCENARIOS_GRANTEE}`,
            userDetails,
        );
    }

    getIneligibleScenariosForGranteeInGranteeFlow(): Observable<{
        granteeIneligibleReason: GranteeIneligibleReasons;
    }> {
        return this.httpClient.get<{ granteeIneligibleReason: GranteeIneligibleReasons }>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.INELIGIBLE_SCENARIOS_GRANTEE_IN_GRANTEE_FLOW}`,
        );
    }
}
