import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { roleTypes } from '../../../content/zci/role-types';
import { AccessPermissionsService } from '../../services/access-permissions/access-permissions.service';
import * as accessPermissionsActions from '../actions/access-permissions.actions';

@Injectable({
    providedIn: 'root',
})
export class AccessPermissionsApiEffect {
    constructor(
        private actions$: Actions,
        private accessPermissionsService: AccessPermissionsService,
    ) {}

    grantPermission$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accessPermissionsActions.grantPermission),
            switchMap((action) => {
                return this.accessPermissionsService
                    .grantPermission(
                        action.granteeDetails,
                        action.grantorDetails,
                        action.accountName,
                    )
                    .pipe(
                        map((result) => {
                            return accessPermissionsActions.grantPermissionSuccess({
                                data: result,
                                roleType: roleTypes.roles[56].code, // 'GIPR'
                                accessLevel: action.granteeDetails.accessLevel,
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(accessPermissionsActions.grantPermissionError({ error }));
                        }),
                    );
            }),
        );
    });

    grantPermissionMvp$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accessPermissionsActions.grantPermissionMvp),
            switchMap((action) => {
                return this.accessPermissionsService
                    .grantPermissionMvp(
                        action.granteeDetails,
                        action.grantorDetails,
                        action.accountName,
                    )
                    .pipe(
                        map((result) => {
                            return accessPermissionsActions.grantPermissionSuccess({
                                data: result,
                                roleType: roleTypes.roles[56].code, // 'GIPR'
                                accessLevel: action.granteeDetails.accessLevel,
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(accessPermissionsActions.grantPermissionError({ error }));
                        }),
                    );
            }),
        );
    });

    removePermission$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accessPermissionsActions.removePermission),
            switchMap((action) => {
                return this.accessPermissionsService
                    .removePermission(action.granteeDetails, action.grantorDetails)
                    .pipe(
                        map((result) => {
                            return accessPermissionsActions.removePermissionSuccess({
                                data: result,
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(accessPermissionsActions.removePermissionError({ error }));
                        }),
                    );
            }),
        );
    });
}
