import { AdobeMethods } from '../../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsEvent } from '../../../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsErrorCode,
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../../services/adobe-launch/adobe-launch.process';
import {AnalyticsCtaType} from "../../../../../services/adobe-launch/adobe-launch.cta";

export const adobeConfigData = {
    ngOnInit: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_TELL_US_ABOUT_THIS_PERSON_PAGE],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_TELL_US_ABOUT_THIS_PERSON,
                },
            ],
        },
    ],
    adobeTaggingForEmailError: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_ERROR,
                {
                    errorCode: AdobeAnalyticsErrorCode.EMAIL_ERROR,
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                },
            ],
        },
    ],
    adobeTaggingForPhoneNumberError: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_ERROR,
                {
                    errorCode: AdobeAnalyticsErrorCode.PHONE_NUMBER_ERROR,
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                },
            ],
        },
    ],

    onNextStepLoad: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GAF_TELL_US_ABOUT_THIS_PERSON,
                    ctaName: AdobeAnalyticsProcessStep.GAF_CONTINUE,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ]
};
