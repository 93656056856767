import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavigationMenuData } from '@vg-constellation/angular-16/navigation';
import { BehaviorSubject, combineLatestWith, map, Observable, tap } from 'rxjs';
import { AccountsUsersDto } from 'src/common/dtos/accounts-users.dto';
import { environment } from 'src/environments/environment';

import { APPLICATION_ROUTES } from '../../../constants/application-routes';
import { ZciNavigationItemId, ZciNavigationService } from '../../../services/zci-navigation';
import { getLoadingState, selectAccountUsers } from '../../../store/selectors/account-permissions.selector';
import { ApiState } from '../../../store/state/api.state';
import { ContentUtil } from '../../../utils/content/content.util';
import { PageComponent } from '../page.component';

@Component({
    selector: 'zci-account-permissions-page',
    templateUrl: './account-permissions-page.component.html',
})
export class AccountPermissionsPageComponent extends PageComponent {

    appRoutes = APPLICATION_ROUTES;

    content = ContentUtil.content;

    contactPhone: string = ContentUtil.globalRMSupportContactPhoneNumber;

    contactTitle: string = ContentUtil.wealthManagementSupportContent;

    backHref = environment.ACCOUNT_SETTINGS_URL;

    currentNavItem$: BehaviorSubject<ZciNavigationItemId | null> =
        new BehaviorSubject<ZciNavigationItemId | null>(null);

    initNavItem$: Observable<ZciNavigationItemId | null> = this.store
        .select(selectAccountUsers)
        .pipe(
            combineLatestWith(this.activatedRoute.queryParams),
            map(([{ data }]) =>
                this.zciNavigationService.getInitSelectedNavItem(data, this.router.url),
            ),
            tap((initNavItem: ZciNavigationItemId | null) => {
                /* istanbul ignore next */
                const initURL: string = APPLICATION_ROUTES[initNavItem] ?? '';
                this.router.navigate([initURL]);
                this.currentNavItem$.next(initNavItem);
            }),
        );

    navMenuData$: Observable<NavigationMenuData> = this.store.select(selectAccountUsers).pipe(
        map(({ data }: ApiState<AccountsUsersDto>) => data),
        map((data: AccountsUsersDto) => this.zciNavigationService.getAvailableNavItems(data)),
    );

    isLoading$: Observable<boolean> = this.store.select(getLoadingState);

    private readonly zciNavigationService: ZciNavigationService;

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private injector: Injector,
    ) {
        super();
        this.zciNavigationService = this.injector.get<ZciNavigationService>(ZciNavigationService);
    }

    handleNavItemChange(navItem: string): void {
        this.currentNavItem$.next(<ZciNavigationItemId>navItem);
    }
}
