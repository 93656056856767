import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, take, tap } from 'rxjs';

import { OwnAccountDTO } from '../../../../common/dtos/own-account.dto';
import { ACCOUNT_ID_PARAM } from '../../../constants/application-routes';
import { resetGrantRevokePermissionState } from '../../../store/actions/grant-revoke-permissions.action';
import { updateRemoveUserStepperSelectedAccount } from '../../../store/actions/remove-user-stepper-flow.action';
import { selectAccountById } from '../../../store/selectors/account-permissions.selector';
import { selectConfirmationNumber } from '../../../store/selectors/grant-revoke-permissions.selector';
import { PageComponent } from '../page.component';

@Component({
    selector: 'zci-remove-user-from-account-page',
    templateUrl: './remove-user-from-account-page.component.html',
    styleUrls: ['./remove-user-from-account-page.component.scss'],
})
export class RemoveUserFromAccountPageComponent extends PageComponent implements OnInit, OnDestroy {
    accountId: string;

    selectConfirmationNumber$: Observable<string> = this.store.select(selectConfirmationNumber);

    constructor(private readonly activatedRoute: ActivatedRoute, private store: Store) {
        super();
    }

    ngOnInit(): void {
        this.accountId = this.activatedRoute.snapshot.params[ACCOUNT_ID_PARAM];
        this.storeSelectedAccount();
    }

    ngOnDestroy(): void {
        this.store.dispatch(resetGrantRevokePermissionState());
    }

    private storeSelectedAccount() {
        this.store
            .select(selectAccountById(this.accountId))
            .pipe(
                take(1),
                tap((selectedAccount: OwnAccountDTO) => {
                    this.store.dispatch(
                        updateRemoveUserStepperSelectedAccount({
                            selectedAccount: selectedAccount,
                        }),
                    );
                }),
            )
            .subscribe();
    }
}
