import { createReducer, on } from '@ngrx/store';
import { granteeInitialState } from '../state/grantee-initial.state';
import {
    ineligibleScenariosForGranteeInGranteeFlow,
    ineligibleScenariosForGranteeInGranteeFlowError,
    ineligibleScenariosForGranteeInGranteeFlowSuccess,
} from '../actions/ineligible-scenarios.action';
import { updateAccessTypeInGranteeFlow } from '../actions/grantee-flow.actions';

export const granteeReducer = createReducer(
    granteeInitialState,

    on(ineligibleScenariosForGranteeInGranteeFlow, (state) => {
        return {
            ...state,
            isLoading: true,
            error: null,
        };
    }),

    on(
        ineligibleScenariosForGranteeInGranteeFlowSuccess,
        (state, { granteeIneligibleReason: GranteeIneligibleReasons }) => {
            return {
                ...state,
                isLoading: false,
                error: null,
                granteeIneligibleReason: GranteeIneligibleReasons,
            };
        },
    ),

    on(ineligibleScenariosForGranteeInGranteeFlowError, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    }),

    on(updateAccessTypeInGranteeFlow, (state, { accessType }) => {
        return {
            ...state,
            accessType,
        };
    }),
);
