import { Component } from '@angular/core';

@Component({
    template: '',
})
export abstract class PageComponent {
    private readonly pageTop = {
        xCoord: 0,
        yCoord: 0,
    };

    constructor() {
        window.scrollTo(this.pageTop.xCoord, this.pageTop.yCoord);
    }
}
