import { AdobeAnalyticsEvent } from '../../../services/adobe-launch/adobe-launch.events';
import { AdobeMethods } from '../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsProcessType } from '../../../services/adobe-launch/adobe-launch.process';

export const adobeConfigData = {
    ngOnInit: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.RAF_CONFIRMATION],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_COMPLETE,
                {
                    processType: AdobeAnalyticsProcessType.RAF_REMOVE_ACCESS_FLOW,
                },
            ],
        },
    ],
};
