import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'zci-pending-chip',
    templateUrl: './pending-chip.component.html',
    styleUrls: ['./pending-chip.component.scss'],
})
export class PendingChipComponent {
    @HostBinding('class.is-inline') @Input() isInline = false;
}
