import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from 'src/app/services/logger/logger.service';

import { RoleTypeMapper } from '../../services/role-type-mapper/role-type.mapper';

@Pipe({
    name: 'mapRoleType',
})
export class MapRoleTypePipe implements PipeTransform {
    constructor(private logger: LoggerService, private roleTypeMapper: RoleTypeMapper) {}

    transform(code: string) {
        const userFriendlyValue = this.roleTypeMapper.mapCodeToRoleType(code);
        if (!userFriendlyValue) {
            this.logger.warn(
                `Mapping warn - Role Type | Unable to map ${code} to its user friendly value`,
            );
        }
        return userFriendlyValue;
    }
}
