<div
    class="view-selector__option"
    [class.view-selector__option--active]="viewType === ViewType.Card"
    data-cy="card-view-toggle-button">
    <input
        type="radio"
        id="cardView"
        [value]="ViewType.Card"
        [ngModel]="viewType"
        (ngModelChange)="handleViewTypeChange($event)"
        tabindex="0" />
    <label
        for="cardView"
        [ngClass]="
            viewType === ViewType.Card
                ? 'c11n-link c11n-link--secondary'
                : 'c11n-link c11n-link--normal'
        ">
        <c11n-icon
            custom-c11n
            name="view-grid"
            size="medium"
            altText=""
            class="custom-c11n-svg--fill-black custom-c11n-svg--size-small"></c11n-icon>
        <span class="c11n-space-inline-left-0_5x c11n-link__content">{{ cardViewLabel }}</span>
    </label>
</div>

<div class="view-selector__separator"></div>

<div
    class="view-selector__option"
    [class.view-selector__option--active]="viewType === ViewType.Table"
    data-cy="table-view-toggle-button">
    <input
        type="radio"
        id="tableView"
        [value]="ViewType.Table"
        [ngModel]="viewType"
        (ngModelChange)="handleViewTypeChange($event)"
        tabindex="0" />
    <label
        for="tableView"
        [ngClass]="
            viewType === ViewType.Table
                ? 'c11n-link c11n-link--secondary'
                : 'c11n-link c11n-link--normal'
        ">
        <c11n-icon
            custom-c11n
            name="view-list"
            size="medium"
            altText=""
            class="custom-c11n-svg--fill-black custom-c11n-svg--size-small"></c11n-icon>
        <span class="c11n-space-inline-left-0_5x c11n-link__content">{{ tableViewLabel }}</span>
    </label>
</div>
