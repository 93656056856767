import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { APPLICATION_ROUTES } from 'src/app/constants/application-routes';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { TitleService } from 'src/app/services/title/title.service';
import { selectContactPersonType } from 'src/app/store/selectors/account-permissions.selector';
import { CONTENT_PLACEHOLDERS } from 'src/common/constants/constants';

import {
    ContentUtil,
    returnAllowedActions,
    returnAllowedCompareAccessLevelActionsList,
} from '../../../utils/content/content.util';
import { PageComponent } from '../page.component';
@Component({
    selector: 'zci-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent extends PageComponent implements OnInit {
    private horizontalHeaderIndexOfVerticalHeader = 0;

    appRoutes = APPLICATION_ROUTES;

    content = ContentUtil.content;

    allowedActions = returnAllowedCompareAccessLevelActionsList(this.content);

    contactPersonType$ = this.store.select(selectContactPersonType);

    contactPersonTypePlaceHolder: string = CONTENT_PLACEHOLDERS.CONTACT_PERSON_TYPE;

    eligibleAccounts = {
        matrix: this.content.about.tableEligibleAccounts.matrix,
        accountNames: this.content.about.tableEligibleAccounts.headers.vertical,
        accessTypes: this.content.about.tableEligibleAccounts.headers.horizontal.filter(
            (_, index) => index !== this.horizontalHeaderIndexOfVerticalHeader,
        ),
    };

    constructor(
        private readonly adobeLaunchService: AdobeLaunchService,
        private titleService: TitleService,
        private store: Store,
    ) {
        super();
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.content.about.pageTitle);
        this.adobeLaunchService.pageLoad(AdobePageName.ABOUT_PAGE);
    }

    getAllowedActions = (column: number): string[] => returnAllowedActions(column, this.content);

    getEligibleAccounts = (column: number): string[] =>
        this.eligibleAccounts.accountNames.filter(
            (_, row) => this.eligibleAccounts.matrix[row][column],
        );
}
