<c11n-card
    density="medium"
    [isCardClickable]="true"
>
    <div cardBody class="card-body">
        <div class="card-section-sm">
            <p class="c11n-link__row">
                <a c11n-link class="c11n-text-md" variant="secondary-reinforced" href="" (click)="$event.preventDefault()">{{ data?.accessLevel }}</a>
                <c11n-icon name="link-right" class="c11n-icon" size="small" altText=""></c11n-icon>
            </p>
            <p class="c11n-text-sm">{{ data?.completion }}</p>
            <p class="c11n-text-sm" *ngIf="data?.notaryRequired">{{ data?.notaryRequired }}</p>
        </div>
        <ul class="card-section-md">
            <li *ngFor="let info of data?.info" class="c11n-text-sm"> {{ info }} </li>
        </ul>
    </div>
</c11n-card>
