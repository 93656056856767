import {
    Component,
    Directive,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    RadioComponent,
    RadioGroupComponent,
    RadioInputComponent,
} from '@vg-constellation/angular-16/radio';
import { LinkComponent } from '@vg-constellation/angular-16/link';
import { Observable, Subscription } from 'rxjs';
import { ContentUtil } from '../../../../utils/content/content.util';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { DrawerComponent } from '@vg-constellation/angular-16/drawer';
import { BreakpointObserverService } from '../../../../services/breakpoint-observer/breakpoint-observer.service';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { getAdobeConfigData } from './grantee-regulatory-questions-adobe';
import { ErrorService } from 'src/app/services/generic-error/error.service';
import { ErrorPage } from 'src/app/services/generic-error/error-page-utils';

@Directive()
class GranteeRegulatoryQuestionsComponent implements OnInit, OnDestroy {
    @ViewChild('controlQuestionsDrawer', { static: false }) drawerComponent!: DrawerComponent;

    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<any> = new EventEmitter<any>();

    @HostBinding('class.control-questions-drawer-open')
    drawerOpen: boolean = false;

    readonly subs: Subscription = new Subscription();
    granteeFAQDrawerContent = ContentUtil.granteeFragmentsContent.faqgrFrequentlyAskedQuestions;
    granteeRegulatoryQuestionContent =
        ContentUtil.granteeFragmentsContent.frmcGranteeFlow.frmcRegulatoryQuestions;

    vanguardEmployeeControlName: string = 'vanguardEmployee';
    associatedPersonControlName: string = 'associatedPerson';
    controlPersonControlName: string = 'controlPerson';

    isMobileView$: Observable<boolean> = this.breakpointObserver.isMobileView$;

    isTabletView$: Observable<boolean> = this.breakpointObserver.isTabletView$;

    controlQuestionFormGroup: FormGroup = new FormGroup({
        [this.vanguardEmployeeControlName]: new FormControl(null, [Validators.required]),
        [this.associatedPersonControlName]: new FormControl(null, [Validators.required]),
        [this.controlPersonControlName]: new FormControl(null, [Validators.required]),
    });

    constructor(
        private readonly breakpointObserver: BreakpointObserverService,
        private errorService: ErrorService,
    ) {}

    get vanguardEmployee(): AbstractControl {
        return this.controlQuestionFormGroup.get(this.vanguardEmployeeControlName);
    }

    get associatedPerson(): AbstractControl {
        return this.controlQuestionFormGroup.get(this.associatedPersonControlName);
    }

    get controlPerson(): AbstractControl {
        return this.controlQuestionFormGroup.get(this.controlPersonControlName);
    }

    ngOnInit(): void {
        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        this.controlQuestionFormGroup.markAllAsTouched();

        if (this.isControlOrAssociatedPerson()) {
            this.errorService.showError(ErrorPage.GRANTEE_CANT_COMPLETE_ONLINE);
        }

        this.nextStepReady.emit(this.controlQuestionFormGroup.valid);
    }

    private isControlOrAssociatedPerson(): boolean {
        return (
            this.vanguardEmployee.value === '1' ||
            this.associatedPerson.value === '1' ||
            this.controlPerson.value === '1'
        );
    }

    handleDrawerLinkSpace(event: Event): void {
        event.preventDefault();
        this.toggleDrawer();
    }

    toggleDrawer(): void {
        if (this.drawerOpen) {
            this.closeFaqDrawer();
        } else {
            this.openFaqDrawer();
        }
    }

    onDrawerStateChange(event: any): void {
        this.drawerOpen = event.isDrawerOpen;
    }

    private openFaqDrawer(): void {
        this.drawerComponent.openDrawer();
    }

    private closeFaqDrawer(): void {
        this.drawerComponent.closeDrawer();
    }
}

@AdobeTagging(getAdobeConfigData('existing'))
@Component({
    selector: 'zci-existing-grantee-regulatory-questions',
    templateUrl: 'grantee-regulatory-questions.component.html',
    styleUrls: ['./grantee-regulatory-questions.component.scss'],
    standalone: true,
    imports: [
        LinkComponent,
        RadioInputComponent,
        RadioComponent,
        RadioGroupComponent,
        ReactiveFormsModule,
        AsyncPipe,
        DrawerComponent,
    ],
})
export class ExistingGranteeRegulatoryQuestionsComponent extends GranteeRegulatoryQuestionsComponent {}

@AdobeTagging(getAdobeConfigData('new'))
@Component({
    selector: 'zci-new-grantee-regulatory-questions',
    templateUrl: 'grantee-regulatory-questions.component.html',
    styleUrls: ['./grantee-regulatory-questions.component.scss'],
    standalone: true,
    imports: [
        LinkComponent,
        RadioInputComponent,
        RadioComponent,
        RadioGroupComponent,
        ReactiveFormsModule,
        AsyncPipe,
        DrawerComponent,
    ],
})
export class NewGranteeRegulatoryQuestionsComponent extends GranteeRegulatoryQuestionsComponent {}
