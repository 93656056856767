<ng-container *ngIf="!isInitialized">
    <c11n-spinner
        id="dpoc-loader"
        [size]="'medium'"
        [colorMode]="'on-light'"
        [spinnerStatus]="'loading'">
    </c11n-spinner>
</ng-container>

<ng-container *ngIf="!isDPOC">
    <ng-content></ng-content>
    <c11n-icon
        name="chat-internal"
        size="medium"
        scale="larger"
        altText=""
        class="headset"
        data-cy="representative-image"></c11n-icon>
    <div>
        <p class="c11n-text-md-bold c11n-space-stack-1x" data-cy="representative-name">
            {{ name }}
        </p>
        <p class="c11n-text-sm c11n-space-stack-1x" data-cy="representative-phone">
            {{ phoneNumber }}
        </p>
    </div>
</ng-container>

<ng-container *ngIf="isDPOC">
    <uhnw-dpoc-contact-us
        [class]="{
            'is-hidden': !isInitialized
        }"
        is-shown-schedule-appointment="false"
        is-shown-expand-icon="false"
        layout="{{ layout }}"
        header-title="{{ isDPOC ? 'Team' : '' }}"
        app-code="ZCI"></uhnw-dpoc-contact-us>
</ng-container>
