import { Component, Input } from '@angular/core';

import { NavOverflowExtendedData } from '../../interfaces/nav-overflow-extended-data.interface';

@Component({
    selector: 'zci-tile',
    templateUrl: './tile.component.html',
    styleUrls: ['./tile.component.scss'],
})
export class TileComponent {
    @Input() navOverflowMenuConfig: NavOverflowExtendedData;

    @Input() isRequestPending: boolean;
}
