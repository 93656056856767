import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorContent } from '../../../services/generic-error/error.service';
import { environment } from '../../../../environments/environment.development-external';
import { VgnService } from '../../../services/vgn/vgn.service';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';
import { ErrorPage } from '../../../services/generic-error/error-page-utils';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import {
    AdobeConfig,
    AdobeTag,
} from '../../../utils/decorators/dev/adobe-launch/adobe-launch.interfaces';
import { Store } from '@ngrx/store';
import { selectGranteeAccessType } from '../../../store/selectors/grantee.selector';
import { getAdobeConfigData } from './generic-error.component.adobe';

@Component({
    selector: 'zci-generic-error',
    templateUrl: './generic-error.component.html',
    styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit, OnDestroy {
    private adobeData: AdobeConfig;
    private subs: Subscription = new Subscription();

    errorContent: ErrorContent;
    errorPage: ErrorPage;

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    constructor(
        private vgnService: VgnService,
        private router: Router,
        private breakpointObserverService: BreakpointObserverService,
        private adobeLaunchService: AdobeLaunchService,
        private readonly store: Store,
    ) {}

    ngOnInit() {
        this.errorContent = this.router.lastSuccessfulNavigation.extras?.state?.errorContent;
        this.errorPage = this.router.lastSuccessfulNavigation.extras?.state?.errorPage;

        this.subs.add(this.store.select(selectGranteeAccessType).subscribe((accessType) => {            
            this.adobeData = getAdobeConfigData(accessType)[this.errorPage];
            this.applyAdobeTagging('ngOnInit');
        }));

        this.vgnService.setMinimalNav(true);
    }

    ngOnDestroy() {
        this.vgnService.setMinimalNav(false);
        this.subs.unsubscribe();
    }

    onPrimaryButtonClick() {
        this.applyAdobeTagging('onPrimaryButtonClick');
        window.location.href = this.errorContent.primaryButton.url;
    }

    onSecondaryButtonClick() {
        this.applyAdobeTagging('onSecondaryButtonClick');
        window.location.href = this.errorContent.secondaryButton.url;
    }

    applyAdobeTagging(key: keyof this) {
        if (!this.adobeData) {
            return;
        }
        const tagData: AdobeTag[] = this.adobeData[key as string];
        if (!tagData) {
            return;
        }
        tagData.forEach((t: AdobeTag) =>
            this.adobeLaunchService[t.method].apply(this.adobeLaunchService, t.data),
        );
    }

    protected readonly environment = environment;
}
