import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ContentUtil } from '../../utils/content/content.util';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    constructor(private titleService: Title) {}

    setTitle(pageTitle: string) {
        const content = ContentUtil.content;
        const title = [content.appTitle, pageTitle].join(content.titleSeparator);
        this.titleService.setTitle(title);
    }

    getTitle(): string {
        return this.titleService.getTitle();
    }
}
