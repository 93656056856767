import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, Subscription, take } from 'rxjs';

import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { UserTypeSelection } from '../../../../enums/user-type-selection.enum';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { grantPermission } from '../../../../store/actions/access-permissions.actions';
import { selectUserInfo } from '../../../../store/selectors/account-permissions.selector';
import {
    selectAddUserStepperData,
    selectAddUserStepperSelectedAccess,
    selectAddUserStepperSelectedAccessLevelLabel,
    selectAddUserStepperSelectedAccount,
    selectAddUserStepperSelectedAccountName,
    selectAddUserStepperSelectedUserFirstName,
    selectAddUserStepperSelectedUserName,
} from '../../../../store/selectors/grant-revoke-permissions.selector';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { StepsOfAddUserToOneSelectedAccount } from '../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { AddUserStepBaseComponent } from '../add-user-step-base.component';
import { adobeConfigData } from './gaf-review-step-adobe';
import { ReplaceParamPipe } from '../../../../pipes/replace-param/replace-param.pipe';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-review-step',
    templateUrl: './gaf-review-step.component.html',
    styleUrls: ['./gaf-review-step.component.scss'],
})
export class GafReviewStepComponent extends AddUserStepBaseComponent implements OnInit, OnDestroy {
    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    userName$: Observable<string> = this.store.select(selectAddUserStepperSelectedUserName);

    firstName$: Observable<string> = this.store.select(selectAddUserStepperSelectedUserFirstName);

    accountName$: Observable<string> = this.store.select(selectAddUserStepperSelectedAccountName);

    hasCoOwner$: Observable<boolean> = this.store
        .select(selectAddUserStepperSelectedAccount)
        .pipe(map((ownAccount) => ownAccount?.owners.length > 0));

    readonly accessTypeSelection = AccessTypeSelection;

    accessLevel$: Observable<string> = this.store.select(
        selectAddUserStepperSelectedAccessLevelLabel,
    );

    selectedAccess$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    subs = new Subscription();

    location: CtaCallUsLinkLocations;

    constructor(private readonly store: Store, protected adobeLaunchService: AdobeLaunchService) {
        super(adobeLaunchService);
    }

    ngOnInit(): void {
        this.subs.add(
            this.selectedAccess$.pipe(take(1)).subscribe((accessType) => {
                switch (accessType) {
                    case this.accessTypeSelection.INFORMATION_ONLY:
                        this.adobeLaunchService.pageLoad(
                            AdobePageName.GAF_REVIEW_AND_SUBMIT_INFO_ONLY_ACCESS,
                        );
                        this.location = CtaCallUsLinkLocations.ZCI_REVIEW_STEP_INFO_ONLY;
                        break;
                    case this.accessTypeSelection.LIMITED_ACCESS:
                        this.adobeLaunchService.pageLoad(
                            AdobePageName.GAF_REVIEW_AND_SUBMIT_LIMITED_ACCESS,
                        );
                        this.location = CtaCallUsLinkLocations.ZCI_REVIEW_STEP_LIMITED_ACCESS;
                        break;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onNextStepLoad() {
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
            processStep: AdobeAnalyticsProcessStep.GAF_SUBMIT,
        });
        this.subs.add(
            combineLatest([
                this.store.select(selectAddUserStepperData),
                this.store.select(selectUserInfo),
            ]).subscribe(([addUserStepperData, grantorInfo]) => {
                this.store.dispatch(
                    grantPermission({
                        granteeDetails: {
                            granteeId:
                                addUserStepperData.selectedUserType === UserTypeSelection.EXISTING
                                    ? addUserStepperData.selectedUser.clientPoid
                                    : addUserStepperData.addedUser.data.clientPoid,
                            granteeName:
                                addUserStepperData.selectedUserType === UserTypeSelection.EXISTING
                                    ? addUserStepperData?.selectedUser?.firstName +
                                      ' ' +
                                      addUserStepperData.selectedUser.lastName
                                    : addUserStepperData?.addedUser?.data?.firstName +
                                      ' ' +
                                      addUserStepperData.addedUser.data.lastName,
                            accessLevel: addUserStepperData.selectedAccess,
                        },
                        grantorDetails: {
                            accountId: addUserStepperData.selectedAccount.accountId,
                            grantorName: grantorInfo.firstName + ' ' + grantorInfo.lastName,
                            serviceAgreementId:
                                addUserStepperData.selectedAccount.serviceAgreementId,
                        },
                        accountName: addUserStepperData.selectedAccount.accountName,
                    }),
                );
            }),
        );
    }

    onBack() {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.ACCESS_AGREEMENT);
    }
}
