import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, take } from 'rxjs';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { selectAddUserStepperSelectedAccess } from '../../../../store/selectors/grant-revoke-permissions.selector';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { configData } from './gaf-mvp-access-agreement.adobe';
import { ContentUtil } from '../../../../utils/content/content.util';

@AdobeTagging(configData)
@Component({
    selector: 'zci-gaf-mvp-access-agreement',
    templateUrl: 'gaf-mvp-access-agreement.component.html',
    styleUrls: ['gaf-mvp-access-agreement.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
    ],
})
export class GafMvpAccessAgreementComponent implements OnInit, OnDestroy {
    readonly accessTypeSelection = AccessTypeSelection;

    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Input() locationChanged: EventEmitter<any>;

    selectedAccess$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    accessAgreementHeading = ContentUtil.grantorFragmentContent.add_user.frmcTrmAgreement.heading;

    infoOnlyAccessAgreement = ContentUtil.grantorFragmentContent.add_user.frmcTrmAgreement.frmcTrmAgreementInformationOnly;

    limitedOnlyAccessAgreement = ContentUtil.grantorFragmentContent.add_user.frmcTrmAgreement.frmcTrmAgreementLimitedAccess;

    isInfoOnly: boolean = false;

    isLimitedOnly: boolean = false;

    private subs: Subscription = new Subscription();

    constructor(
        private store: Store,
        protected adobeLaunchService: AdobeLaunchService
    ) {}

    ngOnInit(): void {
        this.subs.add(
            this.selectedAccess$.pipe(take(1)).subscribe((accessType) => {
                switch (accessType) {
                    case this.accessTypeSelection.INFORMATION_ONLY:
                        this.adobeInfoOnlyPageLoad();
                        this.isInfoOnly = true;
                        this.locationChanged.emit('access_agreement_info_only');
                        break;
                    case this.accessTypeSelection.LIMITED_ACCESS:
                        this.adobeLimitedOnlyPageLoad();
                        this.isLimitedOnly = true;
                        this.locationChanged.emit('access_agreement_for_limited');
                        break;
                }
            }),
        );
        this.subs.add(
            this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this))
        );
    }

    private adobeInfoOnlyPageLoad(): void {}

    private adobeLimitedOnlyPageLoad(): void {}

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        this.nextStepReady.emit(true);
    }
}
