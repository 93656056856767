import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of, tap, combineLatest } from 'rxjs';

import { ContentService } from '../services/content/content.service';
import { ContentUtil } from '../utils/content/content.util';
import { deepUpdate } from '../../aem/aem-utils';
import { modifiedGranteeContent } from '../../content/zci/grantee-content-modified';
import { modifiedGrantorContent } from '../../content/zci/grantor-content-modified';

@Injectable({
    providedIn: 'root',
})
export class ContentResolver implements Resolve<any> {
    constructor(private readonly contentService: ContentService) {}

    resolve(): Observable<any> {
        if (
            ContentUtil.content &&
            ContentUtil.granteeFragmentsContent &&
            ContentUtil.grantorFragmentContent
        ) {
            return of([
                ContentUtil.content,
                ContentUtil.granteeFragmentsContent,
                ContentUtil.grantorFragmentContent,
            ]);
        }

        return combineLatest([
            this.contentService.getContent(),
            this.contentService.getGranteeGraphqlContent(),
            this.contentService.getGrantorGraphqlContent(),
        ]).pipe(
            tap(([content, granteeFragmentsContent, grantorFragmentContent]) => {
                ContentUtil.content = content;

                ContentUtil.granteeFragmentsContent = deepUpdate(
                    modifiedGranteeContent,
                    granteeFragmentsContent,
                );
                ContentUtil.grantorFragmentContent = deepUpdate(
                    modifiedGrantorContent,
                    grantorFragmentContent,
                );
            }),
        );
    }
}
