import { Component, Input } from '@angular/core';

import { content } from '../../../../content/zci/content';
import { environment } from '../../../../environments/environment.development-internal';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@vg-constellation/angular-16/icon';

@Component({
    selector: 'zci-triage-mvp-tile',
    templateUrl: './triage-mvp-tile.component.html',
    styleUrls: ['./triage-mvp-tile.component.scss'],
    standalone: true,
    imports: [CommonModule, IconComponent],
})
export class TriageMvpTileComponent {
    @Input() title: string;

    @Input() description: string;

    @Input() link: string;

    protected readonly environment = environment;

    protected readonly content = content;
}
