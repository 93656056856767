<zci-tile
    [navOverflowMenuConfig]="navOverflowMenuConfig"
    [isRequestPending]="false"
    data-cy="user-tile">
    <a class="c11n-space-stack-1x zci-text--bold">
        {{ usrName }}
    </a>
    <a *ngIf="!hasSelectedAccount" class="c11n-space-stack-1x">
        {{ accName }}
    </a>
    <p *ngIf="!hasSelectedAccount" class="c11n-space-stack-1x">
        {{ accType | mapAccType }}
    </p>
    <p class="c11n-space-stack-1x zci-text--disabled zci-text--bold">
        {{ roleType | mapRoleType }}
    </p>
</zci-tile>
