<zci-view-selector
    *ngIf="!isMobileView"
    [(ngModel)]="viewType"
    (ngModelChange)="onViewTypeChange()"
    [location]="content.home.accountsNavTab.otherAccountsItemTitle"
    class="c11n-space-stack-4x"></zci-view-selector>

<ng-container *ngIf="viewType === ViewType.Card">
    <div class="row g-4 c11n-space-stack-5x">
        <div class="col-lg-6" *ngFor="let acc of accsPaginated.data$ | async">
            <zci-observable-account-tile
                [accName]="acc.accName"
                [accType]="acc.accType"
                [roleType]="acc.roleType"></zci-observable-account-tile>
        </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="accsPaginated.hasMore$ | async">
        <c11n-button
            class="load-more-button"
            (clickEvent)="accsPaginated.loadMore()"
            [labelText]="loadMoreAccountsLabel$ | async"
            [buttonType]="'button'"
            size="medium"
            variant="secondary"
            colorMode="on-light"
            [isWaiting]="accsPaginated.isLoading$ | async">
        </c11n-button>
    </div>
</ng-container>

<ng-container *ngIf="viewType === ViewType.Table">
    <div class="c11n-space-stack-5x c11n-space--force">
        <zci-table
            [tableColDef]="otherAcctsTableCols"
            [tableData]="otherAcctsDataTable$ | async"
            [ariaLabelledby]="''"
            [dataCy]="'zci-table'"
            [sortable]="true"
            (sort)="onColumnSort($event)"></zci-table>
    </div>
</ng-container>
