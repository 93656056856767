import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, Subscription, take } from 'rxjs';
import { AdobeAnalyticsEvent } from 'src/app/services/adobe-launch/adobe-launch.events';

import { CONSTANTS, CONTENT_PLACEHOLDERS } from '../../../../common/constants/constants';
import { UserDetailsDto } from '../../../../common/dtos/user-details.dto';
import { AccessTypeSelection } from '../../../../common/enums/access-type-selection.enum';
import { Content } from '../../../../common/interfaces/content.interface';
import { environment } from '../../../../environments/environment';
import {
    AdobeAnalyticsCallToAction,
    AnalyticsCtaType,
    CtaCallUsLinkLocations,
} from '../../../services/adobe-launch/adobe-launch.cta';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';
import { ZciCookieService } from '../../../services/zci-cookie/zci-cookie.service';
import { resetGrantRevokePermissionState } from '../../../store/actions/grant-revoke-permissions.action';
import {
    selectAddUserStepperSelectedAccess,
    selectAddUserStepperSelectedAccessLevel,
    selectAddUserStepperSelectedUser,
} from '../../../store/selectors/grant-revoke-permissions.selector';
import { ContentUtil } from '../../../utils/content/content.util';

@Component({
    selector: 'zci-add-user-confirmation-page',
    templateUrl: './add-user-confirmation-page.component.html',
    styleUrls: ['./add-user-confirmation-page.component.scss'],
})
export class AddUserConfirmationPageComponent implements OnInit, OnDestroy {
    accountId: string;

    readonly accessTypeSelection = AccessTypeSelection;

    accessLevel$: Observable<string> = this.store.select(selectAddUserStepperSelectedAccessLevel);

    selectedAccess$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    content: Content = ContentUtil.content;

    addUserAccessNamePlaceHolder: string = CONTENT_PLACEHOLDERS.FULL_NAME;

    selectedUser$: Observable<UserDetailsDto>;

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    subs = new Subscription();

    accessType: AccessTypeSelection;

    accountBalancesUrl: string;

    adobeTrackActionLocationInfoOnly: CtaCallUsLinkLocations =
        CtaCallUsLinkLocations.ZCI_ACCESS_REQUEST_SUBMITTED_INFO_ONLY;

    adobeTrackActionLocationLimited: CtaCallUsLinkLocations =
        CtaCallUsLinkLocations.ZCI_ACCESS_REQUEST_SUBMITTED_LIMITED;

    adobeLocationData: CtaCallUsLinkLocations;

    constructor(
        private readonly store: Store,
        protected readonly adobeLaunchService: AdobeLaunchService,
        private readonly breakpointObserverService: BreakpointObserverService,
        private readonly zciCookieService: ZciCookieService,
    ) {}

    ngOnInit(): void {
        this.subs.add(this.getAccessType());
        this.trackPageLoad();
        this.selectedUser$ = this.store.select(selectAddUserStepperSelectedUser);
        this.accountBalancesUrl = this.composeAccountBalancesUrl();
        this.adobeLocationData =
            this.accessType === this.accessTypeSelection.INFORMATION_ONLY
                ? this.adobeTrackActionLocationInfoOnly
                : this.adobeTrackActionLocationLimited;
    }

    trackPageLoad() {
        switch (this.accessType) {
            case this.accessTypeSelection.INFORMATION_ONLY:
                this.adobeLaunchService.pageLoad(
                    AdobePageName.GAF_ACCESS_REQUEST_SUBMITTED_INFO_ONLY,
                );
                break;
            case this.accessTypeSelection.LIMITED_ACCESS:
                this.adobeLaunchService.pageLoad(
                    AdobePageName.GAF_ACCESS_REQUEST_SUBMITTED_LIMITED,
                );
                break;
        }
    }

    getAccessType(): Subscription {
        return this.selectedAccess$.pipe(take(1)).subscribe((accessType) => {
            this.accessType = accessType;
        });
    }

    get fullName$(): Observable<string> {
        return this.selectedUser$.pipe(
            map((account) => `${account?.firstName} ${account?.middleName} ${account?.lastName}`),
        );
    }

    goBackToTriage() {
        const callToActionData: AdobeAnalyticsCallToAction = {
            location: this.adobeLocationData,
            ctaName: AdobeAnalyticsProcessStep.GAF_ADD_ANOTHER_PERSON,
            ctaType: AnalyticsCtaType.BUTTON,
        };
        this.adobeLaunchService.trackAction(callToActionData);
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
        });

        this.resetGrantRevokePermissionState();
    }

    composeAccountBalancesUrl(): string {
        const accountBalancesUrl = environment.ACCOUNT_BALANCES_URL;

        if (environment.isProduction) {
            return accountBalancesUrl;
        }

        const nonProdPortNumber =
            this.zciCookieService.nonProdPortNumber || CONSTANTS.DEFAULT_NON_PROD_PORT;

        return accountBalancesUrl.replace('port', nonProdPortNumber);
    }

    resetGrantRevokePermissionState(): void {
        this.store.dispatch(resetGrantRevokePermissionState());
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.resetGrantRevokePermissionState();
    }
}
