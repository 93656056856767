import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import { AdobeAnalyticsEvent } from '../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessSideStep,
    AdobeAnalyticsProcessType,
} from '../../services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from '../../services/adobe-launch/adobe-launch.service';
import {
    ContentUtil,
    returnAllowedActions,
    returnAllowedCompareAccessLevelActionsList,
} from '../../utils/content/content.util';

@Component({
    selector: 'zci-access-comparison',
    templateUrl: './access-comparison.component.html',
    styleUrls: ['./access-comparison.component.scss'],
})
export class AccessComparisonComponent {
    content = ContentUtil.content;

    allowedActions = returnAllowedCompareAccessLevelActionsList(this.content);

    accessTypeSelection = AccessTypeSelection;

    @Input() showFullAuthorityBanner: boolean;

    @Output() detailsToggle: EventEmitter<'open' | 'close'> = new EventEmitter<'open' | 'close'>();

    constructor(private adobeLaunchService: AdobeLaunchService) {}

    getAllowedActions = (column: number): string[] => returnAllowedActions(column, this.content);

    // TODO c11n-details component doesn't register as hidden in cypress when collapsed, added manual content hide
    showDetailsContent = false
    onDetailsToggle(event: 'open' | 'close'): void {
        this.showDetailsContent = event === 'open';
        this.detailsToggle.emit(event);
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_SIDE_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
            processSideStep: AdobeAnalyticsProcessSideStep.GAF_COMPARE_ACCESS_LEVELS_DROPDOWN,
        });
    }

    handleAgentAuthorizationFormUrl(): void {
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_SIDE_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
            processSideStep: AdobeAnalyticsProcessSideStep.GAF_AGENT_AUTHORIZATION_FORM,
        });

        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_blank');
    }
}
