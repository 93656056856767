import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ViewType, ViewTypeForPageLoad } from 'src/app/interfaces/view-type.enum';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer/breakpoint-observer.service';

@Component({ template: '' })
export abstract class TabComponent {
    ViewType = ViewType;

    viewType: ViewType;

    isMobileView: boolean;

    breakpointObserver: BreakpointObserverService;

    changeDetector: ChangeDetectorRef;

    constructor(injector: Injector) {
        this.changeDetector = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
        this.breakpointObserver =
            injector.get<BreakpointObserverService>(BreakpointObserverService);
    }

    onViewTypeChange() {
        this.changeDetector.detectChanges();
    }

    protected subscribeOnBreakpointChange() {
        this.breakpointObserver.isMobileView$
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobileView: boolean) => {
                if (isMobileView) {
                    this.viewType = ViewType.Card;
                }
                this.isMobileView = Boolean(isMobileView);
                this.changeDetector.detectChanges();
            });
    }

    protected abstract saveViewTypeSelection(): void;

    protected abstract restoreViewTypeSelection(): void;

    protected abstract get destroy(): Subject<void>;

    getViewTypeForPageLoad(viewType: ViewType): ViewTypeForPageLoad {
        return viewType === ViewType.Card
            ? ViewTypeForPageLoad.CARD_VIEW
            : ViewTypeForPageLoad.TABLE_VIEW;
    }
}
