import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, Subscription, take } from 'rxjs';

import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { UserTypeSelection } from '../../../../enums/user-type-selection.enum';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { grantPermissionMvp } from '../../../../store/actions/access-permissions.actions';
import { selectUserInfo } from '../../../../store/selectors/account-permissions.selector';
import {
    selectAddUserStepperData,
    selectAddUserStepperSelectedAccess,
    selectAddUserStepperSelectedAccessLevelLabel,
    selectAddUserStepperSelectedAccount,
    selectAddUserStepperSelectedAccountName,
    selectAddUserStepperSelectedUserFirstName,
    selectAddUserStepperSelectedUserName,
    selectAddUserStepperSelectedUserType,
} from '../../../../store/selectors/grant-revoke-permissions.selector';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { Content } from '../../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../../utils/content/content.util';
import { adobeConfigData } from './gaf-review-and-submit-step-mvp-adobe';
import { CommonModule } from '@angular/common';
import { ReplaceParamPipe } from '../../../../pipes/replace-param/replace-param.pipe';
import { AddUserStepperFlow } from '../../../../interfaces/add-user-stepper-flow.interface';
import {
    GranteeDetailsInterface,
    GrantorDetailsInterface,
} from '../../../../../common/interfaces/access-permissions.interface';
import { UserDetailsDto } from '../../../../../common/dtos/user-details.dto';
import { gatherPinAndDto } from '../../../../store/actions/add-someone-else.action';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-review-and-submit-step-mvp',
    templateUrl: './gaf-review-and-submit-step-mvp.component.html',
    styleUrls: ['./gaf-review-and-submit-step-mvp.component.scss'],
    standalone: true,
    imports: [CommonModule, ReplaceParamPipe],
})
export class GafReviewAndSubmitStepMvpComponent implements OnInit, OnDestroy {
    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<any> = new EventEmitter<any>();
    @Input() locationChanged: EventEmitter<any> = new EventEmitter<any>();

    content: Content = ContentUtil.content;

    userName$: Observable<string> = this.store.select(selectAddUserStepperSelectedUserName);

    firstName$: Observable<string> = this.store.select(selectAddUserStepperSelectedUserFirstName);

    accountName$: Observable<string> = this.store.select(selectAddUserStepperSelectedAccountName);

    hasCoOwner$: Observable<boolean> = this.store
        .select(selectAddUserStepperSelectedAccount)
        .pipe(map((ownAccount) => ownAccount?.owners.length > 0));

    readonly accessTypeSelection = AccessTypeSelection;

    selectedAccount$ = this.store.select(selectAddUserStepperSelectedAccount);

    accessLevel$: Observable<string> = this.store.select(
        selectAddUserStepperSelectedAccessLevelLabel,
    );

    selectedAccess$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    selectedUserType: Observable<UserTypeSelection> = this.store.select(
        selectAddUserStepperSelectedUserType,
    );

    subs: Subscription = new Subscription();

    location: CtaCallUsLinkLocations;

    accessType: AccessTypeSelection;

    constructor(private readonly store: Store) {}

    ngOnInit(): void {
        this.accountName$ = combineLatest([this.selectedAccount$, this.accountName$]).pipe(
            map(([account, name]) =>
                this.getAccountNicknameWithoutNumber(name, account?.accountNickname),
            ),
        );

        this.subs.add(
            this.selectedAccess$.pipe(take(1)).subscribe((accessType) => {
                this.selectedAccessType(accessType);
            }),
        );

        this.subs.add(
            this.nextStepClicked.subscribe(() => {
                this.onNextStepLoad();
                this.tagNextStepLoadAdobeEvent();
            }),
        );
    }

    selectedAccessType(accessType: AccessTypeSelection): void {
        this.accessType = accessType;
        switch (accessType) {
            case this.accessTypeSelection.INFORMATION_ONLY:
                this.triggerInfoOnlyAccessAdobePageName();
                this.location = CtaCallUsLinkLocations.ZCI_REVIEW_STEP_INFO_ONLY;
                this.locationChanged.emit('review_submit_info_only');
                break;
            case this.accessTypeSelection.LIMITED_ACCESS:
                this.subs.add(
                    this.hasCoOwner$.pipe(take(1)).subscribe((hasCoOwner) => {
                        if (hasCoOwner) {
                            this.triggerLimitedAccessJointOwnerPageName();
                        } else {
                            this.triggerLimitedAccessIndividualPageName();
                        }
                    }),
                );
                this.location = CtaCallUsLinkLocations.ZCI_REVIEW_STEP_LIMITED_ACCESS;
                this.locationChanged.emit('review_submit_limited');
                break;
        }
    }

    private triggerInfoOnlyAccessAdobePageName(): void {}

    private triggerLimitedAccessIndividualPageName(): void {}

    private triggerLimitedAccessJointOwnerPageName(): void {}

    onNextStepLoadAdobeEventInfoOnly(): void {}

    onNextStepLoadAdobeEventLimited(): void {}

    onNextStepLoadAdobeEventFullAuthority(): void {}

    tagNextStepLoadAdobeEvent(): void {
        switch (this.accessType) {
            case AccessTypeSelection.INFORMATION_ONLY:
                this.onNextStepLoadAdobeEventInfoOnly();
                break;
            case AccessTypeSelection.LIMITED_ACCESS:
                this.onNextStepLoadAdobeEventLimited();
                break;
            case AccessTypeSelection.FULL_AUTHORITY:
                this.onNextStepLoadAdobeEventFullAuthority();
                break;
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    grantPermissionExisting(addUserStepperData: AddUserStepperFlow, grantorInfo: UserDetailsDto) {
        this.store.dispatch(
            grantPermissionMvp({
                granteeDetails: this.composeGranteeDetails(addUserStepperData),
                grantorDetails: this.composeGrantorDetails(addUserStepperData, grantorInfo),
                accountName: addUserStepperData.selectedAccount.accountName,
            }),
        );
    }

    grantPermissionNonExisting(
        addUserStepperData: AddUserStepperFlow,
        grantorInfo: UserDetailsDto,
    ) {
        this.store.dispatch(
            gatherPinAndDto({
                addSomeoneElseDto: {
                    accessLevel: addUserStepperData.selectedAccess,
                    accountId: addUserStepperData.selectedAccount.accountId,
                    acctName: addUserStepperData.selectedAccount.accountName,
                    sagId: addUserStepperData.selectedAccount.serviceAgreementId,
                    granteeFirstName: addUserStepperData.addedUser.data.firstName,
                    granteeLastName: addUserStepperData.addedUser.data.lastName,
                    granteeEmail: addUserStepperData.addedUser.data.email,
                    granteePhoneNumber: addUserStepperData.addedUser.data.phoneNumber,
                    grantorName: grantorInfo.firstName + ' ' + grantorInfo.lastName,
                },
            }),
        );
    }

    onNextStepLoad() {
        this.subs.add(
            combineLatest([
                this.store.select(selectAddUserStepperData),
                this.store.select(selectUserInfo),
            ]).subscribe(([addUserStepperData, grantorInfo]) => {
                if (addUserStepperData.selectedUserType === UserTypeSelection.EXISTING) {
                    this.grantPermissionExisting(addUserStepperData, grantorInfo);
                } else {
                    this.grantPermissionNonExisting(addUserStepperData, grantorInfo);
                }
            }),
        );
    }

    private composeGranteeDetails(addUserStepperData: AddUserStepperFlow): GranteeDetailsInterface {
        return {
            granteeId: this.composeGranteeId(addUserStepperData),
            granteeName: this.composeGranteeName(addUserStepperData),
            accessLevel: addUserStepperData.selectedAccess,
        };
    }

    private composeGrantorDetails(
        addUserStepperData: AddUserStepperFlow,
        grantorInfo: any,
    ): GrantorDetailsInterface {
        return {
            accountId: addUserStepperData.selectedAccount.accountId,
            grantorName: grantorInfo.firstName + ' ' + grantorInfo.lastName,
            serviceAgreementId: addUserStepperData.selectedAccount.serviceAgreementId,
        };
    }

    private composeGranteeId(addUserStepperData: AddUserStepperFlow): string {
        return addUserStepperData.selectedUserType === UserTypeSelection.EXISTING
            ? addUserStepperData.selectedUser.clientPoid
            : addUserStepperData.addedUser.data.clientPoid;
    }

    private composeGranteeName(addUserStepperData: AddUserStepperFlow): string {
        return addUserStepperData.selectedUserType === UserTypeSelection.EXISTING
            ? addUserStepperData?.selectedUser?.firstName +
                  ' ' +
                  addUserStepperData.selectedUser.lastName
            : addUserStepperData?.addedUser?.data?.firstName +
                  ' ' +
                  addUserStepperData.addedUser.data.lastName;
    }

    getAccountNicknameWithoutNumber(accountName: string, nickname: string) {
        if (!nickname || typeof nickname !== 'string') {
            return accountName;
        }

        const nickNameParts = nickname?.split('—');

        return `${nickNameParts[0].trim()} — ${accountName}`;
    }
}
