import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { AccessTypeSelection } from '../../../../common/enums/access-type-selection.enum';
import { selectGranteeAccessType } from '../../../store/selectors/grantee.selector';
import { FlowKey } from '../../../services/flow-config-service/flow-config.service';
import { StepperComponent } from '../../stepper/stepper.component';
import { PageComponent } from '../page.component';

@Component({
    selector: 'zci-grantee-landing-page',
    templateUrl: './grantee-landing-page.component.html',
    styleUrls: ['./grantee-landing-page.component.scss'],
})
export class GranteeLandingPageComponent extends PageComponent implements OnInit {
    // TODO If there is an error, redirect to error page

    accessLevel$: Observable<AccessTypeSelection> = this.store.select(selectGranteeAccessType);

    flowKey: FlowKey;

    isCurrentStepStandalone: boolean;

    @ViewChild(StepperComponent)
    stepper: StepperComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly store: Store,
    ) {
        super();
    }

    ngOnInit(): void {
        this.route.data
            .pipe(
                tap((data) => {
                    // TODO FIX THIS AGAIN
                    /* istanbul ignore next */
                    this.flowKey = data.validateToken.isExistingClient
                        ? 'EXISTING_GRANTEE_LIMITED'
                        : data.validateToken?.accessLevel === AccessTypeSelection.INFORMATION_ONLY
                          ? 'NEW_GRANTEE_INFO_ONLY'
                          : 'NEW_GRANTEE_LIMITED';
                }),
            )
            .subscribe();

        this.isCurrentStepStandalone = this.stepper?.isCurrentStepStandalone;
    }
}
