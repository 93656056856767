import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
    selector: 'zci-fake-step-three',
    templateUrl: './fake-step-three.component.html',
    standalone: true,
    styleUrls: ['./fake-step-three.component.css'],
})
export class FakeStepThreeComponent{

    @Input() nextStepClicked: EventEmitter<any>;
    @Input() nextStepReady: EventEmitter<any>;
    @Input() locationChanged: EventEmitter<any>;

}
