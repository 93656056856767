import { AppModule } from 'src/app/app.module';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';

function AdobePageLoad(...params: Parameters<AdobeLaunchService['pageLoad']>) {
    return function (constructor: Function) {
        const originalOnInit = constructor.prototype.ngOnInit;

        constructor.prototype.ngOnInit = function (...args) {
            if (originalOnInit && typeof originalOnInit === 'function') {
                originalOnInit.apply(this, args);
            }
            const adobeLaunchService =
                AppModule.injector.get<AdobeLaunchService>(AdobeLaunchService);
            adobeLaunchService.pageLoad.apply(adobeLaunchService, params);
        };
    };
}

export default AdobePageLoad;
