import { createAction, props } from '@ngrx/store';
import { AddSomeoneElseDto } from '../../../common/dtos/add-someone-else.dto';
import { HttpErrorResponse } from '@angular/common/http';


export const gatherPinAndDto = createAction(
    '[Access Permissions] Grant Permissions Get Unique Pin',
    props<{
        addSomeoneElseDto: AddSomeoneElseDto;
    }>(),
);

export const gatherPinAndDtoSuccess = createAction(
    '[Access Permissions] Grant Permissions Has Successfully Got Unique Pin',
    props<{
        data: string;
    }>(),
);

export const gatherPinAndDtoError = createAction(
    '[Access Permissions] Grant Permissions Can Not Get Unique Pin',
    props<{
        error: HttpErrorResponse;
    }>(),
);
