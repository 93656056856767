import { I18nPluralPipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { PageComponent } from 'src/app/components/pages/page.component';
import { TitleService } from 'src/app/services/title/title.service';
import { selectAccountById } from 'src/app/store/selectors/account-permissions.selector';
import { OwnAccountDTO } from 'src/common/dtos/own-account.dto';

import { Content } from '../../../../common/interfaces/content.interface';
import { APPLICATION_ROUTES } from '../../../constants/application-routes';
import { ContentUtil } from '../../../utils/content/content.util';

@Component({
    selector: 'zci-users-on-account-page',
    templateUrl: './users-on-account-page.component.html',
})
export class UsersOnAccountPageComponent extends PageComponent implements OnInit {
    content: Content = ContentUtil.content;

    appRoutes = APPLICATION_ROUTES;

    accountId: string;

    account$: Observable<OwnAccountDTO>;

    usersCountMap: { [k: string]: string } = {
        '=1': this.content.usersOnAccount.oneUser,
        other: this.content.usersOnAccount.multipleUsers,
    };

    private readonly store: Store;

    private readonly i18nPluralPipe: I18nPluralPipe;

    private readonly titleService: TitleService;

    activatedRoute: ActivatedRoute;

    constructor(private injector: Injector) {
        super();

        this.store = this.injector.get<Store>(Store);
        this.i18nPluralPipe = this.injector.get<I18nPluralPipe>(I18nPluralPipe);
        this.titleService = this.injector.get<TitleService>(TitleService);
        this.activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.content.usersOnAccount.pageTitle);
        this.accountId = this.activatedRoute.snapshot.params.id;
        this.account$ = this.store.select(selectAccountById(this.accountId));
    }

    get accName(): Observable<string> {
        return this.account$.pipe(map((account) => account.accountName));
    }

    get usersCount(): Observable<string> {
        return this.account$.pipe(
            map(
                (account) =>
                    `${account.grantees.length} ${this.i18nPluralPipe.transform(
                        account.grantees.length,
                        this.usersCountMap,
                    )}`,
            ),
        );
    }
}
