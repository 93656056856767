import {Component, OnDestroy, OnInit} from '@angular/core';
import { ContentUtil } from '../../../utils/content/content.util';
import { environment } from 'src/environments/environment';
import { GranteeContent } from '../../../utils/content/aem-content-types';
import { AdobePageLoad, AdobeTrackAction } from '../../../utils/decorators/dev/adobe-launch';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { AnalyticsCtaType, CtaCallUsLinkLocations, CtaNames } from '../../../services/adobe-launch/adobe-launch.cta';
import { VgnService } from '../../../services/vgn/vgn.service';

@AdobePageLoad(AdobePageName.GRANTEE_WEB_REG)
@Component({
    selector: 'zci-grantee-web-reg-page',
    templateUrl: './grantee-web-reg-page.component.html',
    styleUrls: ['./grantee-web-reg-page.component.scss'],
})
export class GranteeWebRegPageComponent implements OnInit, OnDestroy {
    constructor( private readonly vgnService: VgnService ) {}

    content: GranteeContent = ContentUtil.granteeFragmentsContent;
    environment = environment;

    ngOnInit(): void {
        this.vgnService.setMinimalNav(true);
    }

    ngOnDestroy(): void {
        this.vgnService.setMinimalNav(false);
    }

	@AdobeTrackAction({
        ctaName: CtaNames.GET_STARTED,
        ctaType: AnalyticsCtaType.BUTTON,
        location: CtaCallUsLinkLocations.ZCI_GRANTEE_NOT_WEB_REGISTERED,
    })
    onGetStartedClicked(): void {}
}
