import { RoleTypesDto } from '../../common/dtos/role-types.dto';

export const roleTypes: RoleTypesDto = {
    roles: [
        { code: 'SHBN', name: 'Shareholder beneficiary' },
        { code: 'GASR', name: 'Granted authorized signer - Org Res' },
        { code: 'TRRE', name: 'Trustee receiver' },
        { code: 'TRST', name: 'Trustee' },
        { code: 'SGIB', name: 'Income beneficiary' },
        { code: 'CUNM', name: 'Custodian nominee firm' },
        { code: 'OWTR', name: 'Owner' },
        { code: 'BNBN', name: 'Beneficiary beneficiary' },
        { code: 'SGTS', name: 'Sungard secondary owner' },
        { code: 'CUCM', name: 'Custodian committee' },
        { code: 'SHPS', name: 'Shareholder plan sponsor' },
        { code: 'BNAL', name: 'Beneficiary alias' },
        { code: 'CLHS', name: 'Clearing hub' },
        { code: 'SGIA', name: 'Investimate advisor' },
        { code: 'EXAL', name: 'Executor alias' },
        { code: 'SGAT', name: 'Attorney' },
        { code: 'SX80', name: 'Rpt Pkg Recipient' },
        { code: 'CUSD', name: 'Custodian' },
        { code: 'SGIP', name: 'INCOME/PRIN beneficiary' },
        { code: 'NPB1', name: '403B external participant' },
        { code: 'CUCU', name: 'Custodian custodian' },
        { code: 'SGTT', name: 'Sungard primary owner' },
        { code: 'SGBC', name: 'Business/Corporation' },
        { code: 'CUPA', name: 'Custodian Attorney-in-Fact' },
        { code: 'SGAC', name: 'Accountant' },
        { code: 'GASF', name: 'Auth signer full agent - Org Res' },
        { code: 'CUEA', name: 'Custodian escrow agent' },
        { code: 'SGPB', name: 'Principal beneficiary' },
        { code: 'GFOA', name: 'Granted family office agent' },
        { code: 'SX90', name: 'Trust check recipient' },
        { code: 'IPPV', name: 'IIG interested party' },
        { code: 'SHAL', name: 'Shareholder alias' },
        { code: 'GASD', name: 'Auth signer durable lmtd agent - Org Res' },
        { code: 'SX70', name: 'Tax beneficiary' },
        { code: 'CUUS', name: 'Custodian usufruct' },
        { code: 'NPQ2', name: '403B external participant' },
        { code: 'IPHR', name: 'Interested party hard recipient' },
        { code: 'OW', name: 'Owner' },
        { code: 'CUAL', name: 'Custodian alias' },
        { code: 'GASL', name: 'Auth signer limited agent - Org Res' },
        { code: 'IPIR', name: 'Interested party secondary recipient' },
        { code: 'CUCO', name: 'Custodian conservator' },
        { code: 'SGCB', name: 'Contingent beneficiary' },
        { code: 'GDLA', name: 'Granted durable limited authorization' },
        { code: 'GFAA', name: 'Granted full agent authorization' },
        { code: 'SGFP', name: 'Financial plan recipient' },
        { code: 'GLAS', name: 'Granted limited authorized signer - Org Res' },
        { code: 'NPQ1', name: '403B external participant' },
        { code: 'SHRM', name: 'Shareholder remainderman' },
        { code: 'SX02', name: 'Fee statement' },
        { code: 'SGAO', name: 'Account officer' },
        { code: 'TRTR', name: 'Trustee trustee' },
        { code: 'SHLP', name: 'Shareholder limited partner' },
        { code: 'CUGU', name: 'Custodian guardian' },
        { code: 'NPB2', name: '403B external participant' },
        { code: 'BN', name: 'Beneficiary' },
        { code: 'GIPR', name: 'Granted interested party' },
        { code: 'TRAL', name: 'Trustee alias' },
        { code: 'NPPT', name: '403B external participant' },
        { code: 'SGAM', name: 'Account mailing address' },
        { code: 'EXEX', name: 'Executor executor' },
        { code: 'SGQR', name: 'Quarterly report recipient' },
        { code: 'EXAD', name: 'Executor administrator' },
        { code: 'BEBE', name: 'Benefactor benefactor' },
        { code: 'SHSH', name: 'Shareholder shareholder' },
        { code: 'BEAL', name: 'Benefactor alias' },
        { code: 'SGOT', name: 'Other type' },
        { code: 'USER', name: 'IIO firm user' },
        { code: 'SGCF', name: 'CO-Fiduciary' },
        { code: 'SHMN', name: 'Shareholder minor' },
        { code: 'SHNO', name: 'Shareholder naked owner' },
        { code: 'SHJS', name: 'Shareholder joint shareholder' },
        { code: 'SX40', name: '5498 payee' },
        { code: 'CULT', name: 'Custodian life tenant' },
        { code: 'GLAA', name: 'Granted limited agent authorization' },
        { code: 'SGIO', name: 'Investment officer' },
        { code: 'ASPT', name: 'IIG authorized signer' },
        { code: 'SX91', name: 'Pension check recipient' },
        { code: 'CUPL', name: 'Custodian pledgee' },
        { code: 'SHGP', name: 'Shareholder general partner' },
        { code: 'SX92', name: 'Trade advice recipient' },
    ],
};
