import { ErrorHandler, Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { LoggerService } from '../logger/logger.service';

@Injectable()
export class AppGlobalErrorHandler implements ErrorHandler {
    constructor(private logger: LoggerService) {}

    handleError(error: any): void {
        this.logger.error(`${error.message === '' ? error.name : error.message}`, error.stack);

        if (environment.development) {
            this.logger.error(error);
        }
    }
}
