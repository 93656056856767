import { createAction, props } from '@ngrx/store';
import { UserTypeSelection } from 'src/app/enums/user-type-selection.enum';

import { OwnAccountDTO } from '../../../common/dtos/own-account.dto';
import { UserDetailsDto } from '../../../common/dtos/user-details.dto';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';

export const updateAddUserStepperSelectedAccount = createAction(
    '[Add User Stepper Flow] Update Selected Account',
    props<{ selectedAccount: OwnAccountDTO }>(),
);

export const updateAddUserStepperSelectedUserType = createAction(
    '[Add User Stepper Flow] Update Selected User Type',
    props<{ selectedUserType: UserTypeSelection }>(),
);

export const updateAddUserStepperSelectedUser = createAction(
    '[Add User Stepper Flow] Update Selected User',
    props<{ selectedUser: UserDetailsDto }>(),
);

export const updateAddUserStepperAddedUser = createAction(
    '[Add User Stepper Flow] Update Added User',
    props<{ addedUser: UserDetailsDto }>(),
);

export const updateAddUserStepperSelectedAccess = createAction(
    '[Add User Stepper Flow] Update Selected Access',
    props<{ selectedAccess: AccessTypeSelection }>(),
);
