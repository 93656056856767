import { AnalyticsCtaType } from '../../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../../services/adobe-launch/adobe-launch.events';
import { AdobeMethods } from '../../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../../services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsProcessSideStep,
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../../services/adobe-launch/adobe-launch.process';

export const adobeConfigData = {
    onBack: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GAF_SELECT_USER,
                    ctaName: AdobeAnalyticsProcessStep.GAF_BACK,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],
    ngOnInit: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_SELECT_USER],
        },
    ],
    onNextStepLoad: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                },
            ],
        },
    ],
    onAddSomeoneElse: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_SIDE_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processSideStep: AdobeAnalyticsProcessSideStep.GAF_ADD_SOMEONE_ELSE,
                },
            ],
        },
    ],
};
