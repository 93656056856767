import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { selectContactPersonType } from 'src/app/store/selectors/account-permissions.selector';
import { CONTENT_PLACEHOLDERS } from 'src/common/constants/constants';

import { APPLICATION_ROUTES } from '../../../../../constants/application-routes';
import { TitleService } from '../../../../../services/title/title.service';
import { ContentUtil } from '../../../../../utils/content/content.util';
import { PageComponent } from '../../../page.component';

@Component({
    selector: 'zci-remove-from-account-page',
    templateUrl: './remove-from-account-page.component.html',
})
export class RemoveFromAccountPageComponent extends PageComponent implements OnInit {
    content = ContentUtil.content;

    appRoutes = APPLICATION_ROUTES;

    contactPersonType$ = this.store.select(selectContactPersonType);

    contactPersonTypePlaceHolder: string = CONTENT_PLACEHOLDERS.CONTACT_PERSON_TYPE;

    constructor(
        private readonly adobeLaunchService: AdobeLaunchService,
        private titleService: TitleService,
        private store: Store,
    ) {
        super();
    }

    ngOnInit(): void {
        this.titleService.setTitle(
            this.content.accountInterstitialPages.removeAccessFromAccount.pageTitle,
        );
        this.adobeLaunchService.pageLoad(AdobePageName.OBSERVABLE_ACCOUNTS_REMOVE_ACCESS_PAGE);
    }
}
