<zci-step-wrapper
    [title]="content.removeAccessFlow.review.title"
    [nextButtonLabelText]="content.stepper.submitButtonLabelText"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickContactUs)="onContactUs(AdobeAnalyticsProcessStep.RAF_REVIEW_AND_SUBMIT)"
    [currentStep]="currentStep"
    [totalSteps]="content.removeAccessFlow.stepperTitles"
    (clickQuit)="onQuit(AdobeAnalyticsProcessStep.RAF_REVIEW_AND_SUBMIT)">
    <div class="review-container" data-cy="raf-review-and-submit-container">
        <p class="c11n-text-md-bold c11n-space-stack-1_5x">
            {{ description | replaceParam: 'granteeName':constructUserName(selectedUser) }}
        </p>
        <ul role="list" class="review-container__account-list">
            <li *ngFor="let account of selectedAccounts" class="c11n-text-md">
                {{ account.accountName }}
            </li>
        </ul>
    </div>
</zci-step-wrapper>
