<div class="main-content">
    <ng-container [ngSwitch]="currentStep$ | async">
        <zci-raf-select-user-step
            *ngSwitchCase="stepperSteps.SELECT_USER"
            [currentStep]="stepperSteps.SELECT_USER"
            (contactUs)="toggleDrawer()"
            (stepChange)="onStepChange($event)"></zci-raf-select-user-step>
        <zci-raf-review-step
            *ngSwitchCase="stepperSteps.REVIEW"
            [currentStep]="stepperSteps.REVIEW"
            (contactUs)="toggleDrawer()"
            (stepChange)="onStepChange($event)"
            [selectedUser]="selectedUser$ | async"
            [selectedAccounts]="selectedAccounts$ | async"
            [description]="content.removeAccessFlow.review.description"></zci-raf-review-step>
    </ng-container>
</div>

<aside
    c11n-drawer
    class="raf-drawer"
    [isModal]="isTabletView$ | async"
    [drawerWidth]="(isMobileView$ | async) ? '100%' : '480px'"
    (drawerState)="onDrawerStateChange($event)"
    #rafDrawer
    data-cy="contact-us-drawer">
    <ng-container>
        <zci-advisor
            [name]="contactTitle"
            [phoneNumber]="contactPhone"
            class="representative-card__details">
            <p class="c11n-text-xl-headline c11n-space-stack-6x drawer-title">
                {{ content.addUserFlow.contactUs }}
            </p>
        </zci-advisor>
    </ng-container>
</aside>
