<div class="container">
    <div class="row">
        <div class="error-content col-xl-6 col-md-10 col-sm-12">
            <c11n-icon custom-c11n class="custom-c11n-svg--fill-error custom-c11n-svg--size-large c11n-space-stack-3x"
                       [name]="errorContent?.icon || 'error-fill'"
                       [scale]="'larger'"></c11n-icon>

            <h1 class="c11n-text-2xl-headline c11n-space-stack-3x" data-cy="page-title">
                {{ errorContent.title }}
            </h1>

            <div class="error-text-container c11n-space-stack-6x" [innerHTML]="errorContent.text"></div>

            <div class="error-buttons-container">
                <c11n-button
                    (clickEvent)="onPrimaryButtonClick()"
                    [labelText]="errorContent.primaryButton.label"
                    [fullWidth]="isMobileView$ | async"></c11n-button>
                <c11n-button
                    *ngIf="errorContent.secondaryButton"
                    (clickEvent)="onSecondaryButtonClick()"
                    [labelText]="errorContent.secondaryButton.label"
                    [variant]="'secondary'"
                    [fullWidth]="isMobileView$ | async"></c11n-button>
            </div>
            <a
                c11n-link
                [href]=""
                variant="primary-reinforced"
            >{{ errorContent.link }}</a>
        </div>
    </div>
</div>
