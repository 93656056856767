import { Content } from '../../../../common/interfaces/content.interface';
import {
    ActionNavOverflowMenuComponent,
    ActionNavOverflowMenuComponentProps,
} from '../../action-nav-overflow-menu/action-nav-overflow-menu.component';
import { TableColumn } from '../../table/table.interface';

type UsersOnAccountTableHeadersTitles = Content['usersOnAccount']['usersOnAccountTable']['headers'];

export enum UsersOnAccountTableProps {
    UsrName = 'usrName',
    RoleType = 'roleType',
    Actions = 'actions',
}

export interface UserOnAccountTableRow {
    [UsersOnAccountTableProps.UsrName]: string;
    [UsersOnAccountTableProps.RoleType]: string;
    [UsersOnAccountTableProps.Actions]: ActionNavOverflowMenuComponentProps;
}

export const getUsersOnAccountTableCols = (
    headerTitles: UsersOnAccountTableHeadersTitles,
): TableColumn[] => [
    {
        title: headerTitles.user,
        dataKey: UsersOnAccountTableProps.UsrName,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.permissionType,
        dataKey: UsersOnAccountTableProps.RoleType,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.actions,
        dataKey: UsersOnAccountTableProps.Actions,
        sortable: false,
        visible: true,
        customCell: ActionNavOverflowMenuComponent,
    },
];
