import { Injectable } from '@angular/core';
import { NavOverflowGroup, NavOverflowItem } from '@vg-constellation/angular-16/nav-overflow';
import { ActionNavOverflowMenuWrapperComponentProps } from 'src/app/components/action-menu-wrapper/action-menu-wrapper.component';
import { ACCOUNT_ID_PARAM, APPLICATION_ROUTES } from 'src/app/constants/application-routes';
import { NavOverFlowWrapperAction } from 'src/app/enums/nav-overflow-wrapper-action.enum';
import { NavOverflowExtendedData } from 'src/app/interfaces/nav-overflow-extended-data.interface';
import { AccountTypeCodes, allowed401kAccountTypes } from 'src/common/constants/account-type-codes';

import { IneligibleReasons } from '../../../common/enums/ineligible-scenarios.enum';
import { ContentUtil } from '../../utils/content/content.util';
import { OwnAccountTileInputDataProps } from '../../utils/sort/sort.service';
import { ZciOverflowMenuService } from '../zci-overflow-menu/zci-overflow-menu.service';

@Injectable({
    providedIn: 'root',
})
export class OwnAccountsService {
    private readonly content = ContentUtil.content;

    private readonly noAgentsAssigned: number = 0;

    private readonly addAgentsMenuGroup: NavOverflowGroup[] = [
        { items: [this.navOverflowItemsService.zciOverflowItems.addUserStepperFlowFromAccount] },
    ];

    private readonly defaultMenuGroup: NavOverflowGroup[] = [
        {
            items: [
                this.navOverflowItemsService.zciOverflowItems.addUserStepperFlowFromAccount,
                this.navOverflowItemsService.zciOverflowItems.editUsers,
                this.navOverflowItemsService.zciOverflowItems.removeUsers,
            ],
        },
    ];

    constructor(private readonly navOverflowItemsService: ZciOverflowMenuService) {}

    getNavOverflowConfig(
        acct: OwnAccountTileInputDataProps,
        ineligibleReasonData?: IneligibleReasons,
        hasIneligibleError?: boolean,
    ): ActionNavOverflowMenuWrapperComponentProps {
        if (
            this.isAccount401k({
                acct,
                allowedTypes: allowed401kAccountTypes,
                accountTypeCode: AccountTypeCodes[acct.accType],
            })
        ) {
            return this.prepareNavOverflowWrapperData(NavOverFlowWrapperAction.NO_ACTION);
        }
        const navOverflowMenu = this.getNavOverflowMenu(
            acct.agentsCount,
            acct.accId,
            ineligibleReasonData,
            hasIneligibleError,
        );
        return this.prepareNavOverflowWrapperData(
            NavOverFlowWrapperAction.NAV_OVERFLOW_MENU,
            navOverflowMenu,
        );
    }

    private prepareNavOverflowWrapperData(
        navAction: NavOverFlowWrapperAction,
        navOverflowMenu: NavOverflowExtendedData = null,
    ): ActionNavOverflowMenuWrapperComponentProps {
        return {
            navOverflowExtendedData: navOverflowMenu,
            navAction: navAction,
        };
    }

    private isAccount401k({
        acct,
        allowedTypes,
        accountTypeCode,
    }: {
        acct: OwnAccountTileInputDataProps;
        allowedTypes: AccountTypeCodes[];
        accountTypeCode: AccountTypeCodes;
    }): boolean {
        return !acct?.accId && !acct?.servAgreeId && allowedTypes.includes(accountTypeCode);
    }

    getNavOverflowMenu(
        agentsCount: number,
        accId: string,
        ineligibleReason?: IneligibleReasons,
        hasIneligibleError?: boolean,
    ): NavOverflowExtendedData {
        let groups = this.defaultMenuGroup;

        if (agentsCount === this.noAgentsAssigned) {
            groups = this.addAgentsMenuGroup;
        }
        if (hasIneligibleError) {
            groups[0].items[0].routerLink = `/${APPLICATION_ROUTES.TECH_DIFF_REDIRECT}`;
        } else if (ineligibleReason) {
            groups[0].items[0].routerLink = `/${APPLICATION_ROUTES.INELIGIBLE.PATH}/${ineligibleReason}`;
        } else {
            groups[0].items[0].routerLink =
                this.navOverflowItemsService.zciOverflowItems.addUserStepperFlowFromAccount.routerLink;
        }

        groups = this.prepareOwnAccountNavOverFlowMenu(groups, accId);

        return {
            label: this.content.home.accountTile.actionsMenuLabel,
            labelId: 'single_account_actions',
            data: {
                groups,
            },
        };
    }

    prepareOwnAccountNavOverFlowMenu(
        groups: NavOverflowGroup[],
        accId: string,
    ): NavOverflowGroup[] {
        return groups.map((group) => {
            const items = group?.items.map((item) => this.transformRouterLink(item, accId));
            return { ...group, items };
        });
    }

    private transformRouterLink(item: NavOverflowItem, accId: string) {
        if (!item || Array.isArray(item?.routerLink)) return null;
        const routerLink = item?.routerLink?.replace(`:${ACCOUNT_ID_PARAM}`, accId);
        return { ...item, routerLink };
    }
}
