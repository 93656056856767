import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkComponent } from '@vg-constellation/angular-16/link';
import { Content } from '../../../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../../../utils/content/content.util';
import { environment } from 'src/environments/environment';
import { AdobeTagging } from '../../../../../utils/decorators/dev/adobe-launch';
import { adobeConfigData } from './gaf-complete-our-form-mvp-adobe';

@AdobeTagging(adobeConfigData)
@Component({
  selector: 'zci-gaf-complete-our-form-mvp',
  standalone: true,
    imports: [CommonModule, LinkComponent],
  templateUrl: './gaf-complete-our-form-mvp.component.html',
  styleUrls: ['./gaf-complete-our-form-mvp.component.scss']
})
export class GafCompleteOurFormMvpComponent {
    content: Content = ContentUtil.content;

    environment = environment;

    trackCompleteOurFormLink(): void {}
}
