import { Component, Directive, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, Subscription, tap } from 'rxjs';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { ContentUtil } from '../../../../utils/content/content.util';
import conentFormatUtils from '../../../../utils/content/content-format.util';
import { getAdobeConfigData } from './grantee-authorization-agreement-adobe';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { AccessPermissionsService } from '../../../../services/access-permissions/access-permissions.service';
import { TOKEN_PARAM } from '../../../../constants/application-routes';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '../../../../services/generic-error/error.service';
import { ErrorPage, errorPageMap } from '../../../../services/generic-error/error-page-utils';

@Directive()
class GranteeAuthorizationAgreementComponent implements OnInit, OnDestroy {
    readonly accessTypeSelection = AccessTypeSelection;

    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() locationChanged: EventEmitter<any>;

    granteeAuthorizationAgreement = ContentUtil.granteeFragmentsContent.trmAuthorizationAgreement;

    private subs: Subscription = new Subscription();

    constructor(
        private accessPermissionsService: AccessPermissionsService,
        private route: ActivatedRoute,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.granteeAuthorizationAgreement.text1 = conentFormatUtils.addClassToLinks(
            this.granteeAuthorizationAgreement.text1,
        );

        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
        this.scrollTop();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        const token = this.route.snapshot.params[TOKEN_PARAM];
        this.accessPermissionsService
            .acceptPermission(token)
            .pipe(
                tap((response) => {
                    this.nextStepReady.emit(!response.error);

                    if (response.error) {
                        this.errorService.showError(errorPageMap[response.error]);
                    }
                }),
                catchError(() => {
                    // TODO what to do here?
                    this.errorService.showError(ErrorPage.TECH_DIFF);
                    return Promise.reject();
                }),
            )
            .subscribe();
    }

    private scrollTop(): void {
        window.scrollTo(0, 0);
    }
}

@AdobeTagging(getAdobeConfigData('existing'))
@Component({
    selector: 'zci-existing-grantee-authorization-agreement',
    templateUrl: 'grantee-authorization-agreement.component.html',
    styleUrls: ['grantee-authorization-agreement.component.scss'],
    standalone: true,
    providers: [ErrorService, AccessPermissionsService],
})
export class ExistingGranteeAuthorizationAgreementComponent extends GranteeAuthorizationAgreementComponent {}

@AdobeTagging(getAdobeConfigData('new'))
@Component({
    selector: 'zci-new-grantee-authorization-agreement',
    templateUrl: 'grantee-authorization-agreement.component.html',
    styleUrls: ['grantee-authorization-agreement.component.scss'],
    standalone: true,
    providers: [ErrorService, AccessPermissionsService],
})
export class NewGranteeAuthorizationAgreementComponent extends GranteeAuthorizationAgreementComponent {}
