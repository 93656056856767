<zci-gaf-select-existing-user-mvp
    *ngIf="(userTypeSelection$ | async) === userTypeSelection.EXISTING"
    [nextStepClicked]="nextStepClicked"
    [accId]="accountId"
    (goToNextStep)="onNextStepReady($event)"
    (addSomeoneElse)="onAddSomeoneElse()"
></zci-gaf-select-existing-user-mvp>
<zci-gaf-add-someone-else-mvp
    *ngIf="(userTypeSelection$ | async) === userTypeSelection.NEW"
    [nextStepClicked]="nextStepClicked"
    (goToNextStep)="onNextStepReady($event)"
></zci-gaf-add-someone-else-mvp>

