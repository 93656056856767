import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GatekeeperService {
    constructor(private readonly http: HttpClient) {}

    isToggleActive(featureId: string): Observable<boolean> {
        return this.http.get<boolean>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.GATEKEEPER}`,
            {
                params: { featureId },
            },
        );
    }
}
