import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { MFEEligibilityEventDetail } from '@vanguard/mfe-events';
import { environment } from 'src/environments/environment';

const DPOC_SCRIPT_ID = 'uhnw_dpoc';

@Component({
    selector: 'zci-advisor',
    templateUrl: './advisor.component.html',
    styleUrls: ['./advisor.component.scss'],
})
export class AdvisorComponent implements OnInit {
    @HostBinding('class') get classNames() {
        return 'align-items-center align-items-sm-start';
    }

    @Input()
    layout: 'vertical' | 'horizontal' = 'vertical';

    @Input()
    name: string;

    @Input()
    phoneNumber: string;

    isDPOC: boolean = true;

    isInitialized: boolean = false;

    ngOnInit() {
        this.loadModuleScript(environment.UHNW_DPOC_URL);
    }

    private loadModuleScript(url: string): void {
        // to avoid loading scripts multiple times and to not run it during e2e
        if (document.getElementById(DPOC_SCRIPT_ID) || (window as any)?.Cypress) {
            return;
        }

        const script = document.createElement('script');
        script.id = DPOC_SCRIPT_ID;
        script.type = 'module';
        script.src = url;
        document.body.appendChild(script);
    }

    @HostListener('mfeEligibility', ['$event.detail'])
    eligibilityHandler(e: MFEEligibilityEventDetail) {
        this.isDPOC = e?.eligible;
        this.isInitialized = true;
    }
}
