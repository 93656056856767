import { AppModule } from 'src/app/app.module';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';

function AdobeTagging(configData) {
    return function (constructor: Function) {
        Object.keys(configData).forEach((methodName) => {
            const methodData = configData[methodName];
            const originalMethod = constructor.prototype[methodName];

            constructor.prototype[methodName] = function (...args) {
                if (originalMethod && typeof originalMethod === 'function') {
                    originalMethod.apply(this, args);
                }
                const adobeLaunchService =
                    AppModule.injector.get<AdobeLaunchService>(AdobeLaunchService);
                methodData.map((data) => {
                    adobeLaunchService[data.method].apply(adobeLaunchService, data.data);
                });
            };
        });
    };
}

export default AdobeTagging;
