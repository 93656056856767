<div>
    <div
        class="success-banner"
        *ngIf="(accessLevel$ | async) === accessTypeSelection.LIMITED_ACCESS">
        <c11n-banner
            size="small"
            variant="success"
            [isDetailsExpanded]="false"
            [hasCloseControl]="false"
            data-cy="add-another-person-confirmation-banner">
            <div class="c11n-text-md--crop" role="alert">
                {{ content.addUserFlow.confirmation.bannerMessage }}
            </div>
        </c11n-banner>
    </div>
    <div
        class="align-items-center"
        *ngIf="(accessLevel$ | async) === accessTypeSelection.INFORMATION_ONLY">
        <c11n-icon
            class="custom-green-icon"
            [name]="'success-fill'"
            size="medium"
            scale="larger"
            data-cy="add-another-person-confirmation-icon">
        </c11n-icon>
    </div>
    <div class="zci-container-md" [id]="adobeLocationData">
        <div
            *ngIf="(accessLevel$ | async) === accessTypeSelection.LIMITED_ACCESS"
            class="c11n-space-stack-6x c11n-space--force">
            <p class="c11n-text-lg-headline c11n-space-stack-3x" data-cy="page-title">
                {{ content.addUserFlow.confirmation.subTitle }}
            </p>
            <div>
                <ol
                    role="list"
                    class="c11n-list c11n-list--circles c11n-list--turquoise c11n-space-inline-left-3x">
                    <li class="c11n-space-stack-3x" data-cy="confirmation-content">
                        {{
                            content.addUserFlow.confirmation.message1
                                | replaceParam: addUserAccessNamePlaceHolder:(fullName$ | async)
                        }}
                    </li>
                    <li class="c11n-space-stack-3x" data-cy="confirmation-content">
                        {{
                            content.addUserFlow.confirmation.message2
                                | replaceParam: addUserAccessNamePlaceHolder:(fullName$ | async)
                        }}
                    </li>
                    <li class="c11n-space-stack-3x" data-cy="confirmation-content">
                        {{
                            content.addUserFlow.confirmation.message3
                                | replaceParam: addUserAccessNamePlaceHolder:(fullName$ | async)
                        }}
                        <a
                            c11n-link
                            class="c11n-text-md-bold"
                            variant="primary-reinforced"
                            [routerLink]="'/'"
                            (click)="resetGrantRevokePermissionState()"
                            data-cy="access-center-link"
                            >{{ content.addUserFlow.confirmation.message3link }}</a
                        >{{ content.addUserFlow.confirmation.message3period }}
                    </li>
                </ol>
            </div>
        </div>
        <div
            *ngIf="(accessLevel$ | async) === accessTypeSelection.INFORMATION_ONLY"
            class="c11n-space-stack-6x c11n-space--force">
            <h1
                class="c11n-text-2xl-headline c11n-space-stack-4x c11n-space--force c11n-space-inline-left-8x c11n-space-inline-right-8x"
                data-cy="page-title">
                {{
                    content.addUserFlow.confirmation.viewOnlyPageTitle
                        | replaceParam: addUserAccessNamePlaceHolder:(fullName$ | async)
                }}
            </h1>
        </div>
    </div>

    <div class="button-wrapper">
        <c11n-button
            buttonType="button"
            size="medium"
            variant="primary"
            [labelText]="content.addUserFlow.confirmation.addAnotherPersonButtonText"
            iconName="plus"
            iconPosition="leading"
            data-cy="add-another-person-button"
            (clickEvent)="goBackToTriage()"
            [fullWidth]="isMobileView$ | async"></c11n-button>
        <c11n-button
            buttonType="button"
            size="medium"
            variant="secondary"
            [labelText]="content.addUserFlow.confirmation.goToMyAccountsButtonText"
            data-cy="go-to-my-accounts-button"
            [fullWidth]="isMobileView$ | async"
            [href]="accountBalancesUrl"></c11n-button>
    </div>
</div>
