import { Component, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-16/modal-dialog';
import { Observable } from 'rxjs';
import { UserTypeSelection } from 'src/app/enums/user-type-selection.enum';
import { updateAddUserStepperSelectedUserType } from 'src/app/store/actions/add-user-stepper-flow.action';
import {
    selectAddUserStepperSelectedAccess,
    selectAddUserStepperSelectedUser,
    selectAddUserStepperSelectedUserName,
    selectAddUserStepperSelectedUserType,
} from 'src/app/store/selectors/grant-revoke-permissions.selector';

import { UserDetailsDto } from '../../../../../common/dtos/user-details.dto';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { GranteeIneligibleReasons } from '../../../../../common/enums/ineligible-scenarios.enum';
import { AccessLevelWithLabel } from '../../../../interfaces/access-level-with-label.interface';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { getAccessLevelsWithLabel } from '../../../../utils/content/content.util';
import { StepsOfAddUserToOneSelectedAccount } from '../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { AddUserStepBaseComponent } from '../add-user-step-base.component';

@Component({
    selector: 'zci-gaf-select-user-step',
    templateUrl: './gaf-select-user-step.component.html',
})
export class GafSelectUserStepComponent extends AddUserStepBaseComponent {
    userTypeSelection$: Observable<UserTypeSelection> = this.store.select(
        selectAddUserStepperSelectedUserType,
    );

    @ViewChild('selectUserLevelModalDialog', { static: false })
    selectUserLevelModalDialog!: ModalDialogComponent;

    readonly userTypeSelection = UserTypeSelection;

    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    @Input() accId: string;

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_SELECT_EXISTING_USER;

    granteeIneligibleReasons: GranteeIneligibleReasons[] = [];

    selectedLevelFormControlName: string = 'selectedLevel';

    selectedUser$: Observable<UserDetailsDto> = this.store.select(selectAddUserStepperSelectedUser);

    accessLevels: AccessLevelWithLabel[] = getAccessLevelsWithLabel(this.content);

    userName$: Observable<string> = this.store.select(selectAddUserStepperSelectedUserName);

    accessLevel$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    constructor(private store: Store, protected adobeLaunchService: AdobeLaunchService) {
        super(adobeLaunchService);
    }

    onNextStepLoad() {
        if (this.granteeIneligibleReasons.includes(GranteeIneligibleReasons.FOREIGN_ADDRESS)) {
            this.selectUserLevelModalDialog.openModalDialog();
        } else {
            this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.SELECT_ACCESS_TYPE);
        }
    }

    onContinueModal(): void {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.SELECT_ACCESS_TYPE);
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
            processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
        });
    }

    onUnsuccessfulAttempt() {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.UNSUCCESSFUL_ATTEMPT);
    }

    onBack() {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.TRIAGE);
    }

    onBackFromAddSomeoneElse() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.EXISTING }),
        );
    }

    onAddSomeoneElse() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.NEW }),
        );
    }
}
