import { AnalyticsCtaType } from '../../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeMethods } from '../../../../../services/adobe-launch/adobe-launch.methods';
import { AdobeAnalyticsProcessStep } from '../../../../../services/adobe-launch/adobe-launch.process';

export const adobeConfigData = {
    onBack: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GAF_TELL_US_ABOUT_THIS_PERSON,
                    ctaName: AdobeAnalyticsProcessStep.GAF_BACK,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],
};
