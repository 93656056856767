import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ContentUtil } from '../../utils/content/content.util';
import { Sort, SortDirection } from '../../utils/sort/sort.interfaces';
import { TableColumn } from './table.interface';

@Component({
    selector: 'zci-table',
    templateUrl: './table.component.html',
})
export class TableComponent {
    content = ContentUtil.content;

    @Input() tableColDef: TableColumn[] = [];

    @Input() tableData: { [key: string]: any }[] = [];

    @Input() ariaLabelledby: string = '';

    @Input() dataCy: string = 'zci-table';

    @Input() sortable: boolean = false;

    @Output() sort: EventEmitter<Sort> = new EventEmitter<Sort>();

    sortDirection = SortDirection;

    currentSortedColumn: Sort = {
        dataKey: null,
        sortDir: null,
    };

    sortColumn(dataKey: string) {
        const newSortDir =
            this.currentSortedColumn.sortDir === SortDirection.ASC
                ? SortDirection.DESC
                : SortDirection.ASC;

        const newSortedColumn: Sort = {
            dataKey,
            sortDir: newSortDir,
        };

        this.sort.emit(newSortedColumn);

        this.currentSortedColumn = newSortedColumn;
    }
}
