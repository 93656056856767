<div class="card-text">
    <div class="card-title">{{ title }}</div>
    <div class="description">{{ description }}</div>
</div>
<ng-container *ngIf="link && link.length > 1">
    <!--TO DO: Check with accessibility team for potential violation-->
    <a href="{{ link }}" class="click-styles">{{ title }}</a>
</ng-container>
<div class="card-icon">
    <c11n-icon name="link-right" size="small" altText=""></c11n-icon>
</div>
