import { environment } from 'src/environments/environment';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import configData from './add-user-triage-page-mvp-adobe';
import { Component, EventEmitter, Input } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { TriageMvpTileComponent } from '../../../tiles/triage-mvp-tile/triage-mvp-tile.component';
import {
    StepsOfAddUserToOneSelectedAccount
} from '../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { ContentUtil } from '../../../../utils/content/content.util';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { Store } from '@ngrx/store';
import {
    updateAddUserStepperSelectedUserType,
} from '../../../../store/actions/add-user-stepper-flow.action';
import { UserTypeSelection } from '../../../../enums/user-type-selection.enum';

@AdobeTagging(configData)
@Component({
    selector: 'zci-add-user-triage-page-mvp',
    templateUrl: 'add-user-triage-page-mvp.component.html',
    styleUrls: ['add-user-triage-page-mvp.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        CommonModule,
        TriageMvpTileComponent,
    ],
})
export class AddUserTriagePageMvpComponent {

    @Input() nextStepClicked: EventEmitter<any>;
    @Input() nextStepReady: EventEmitter<any>;

    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    environment = environment;

    content = ContentUtil.content;

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT;

    constructor(
        private store: Store,
    ) {
    }

    navigateSelectUser() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.EXISTING }),
        );
        this.nextStepReady.emit(true);
    }

    navigateAddSomeoneElse() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.NEW }),
        );
        this.nextStepReady.emit(true);
    }

    navigateToFormFromNonVGUser() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    navigateToFormFromOrgTrust() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }
}
