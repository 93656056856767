import { Pipe, PipeTransform } from '@angular/core';

import { AccountTypeMapper } from '../../services/account-type-mapper/account-type.mapper';
import { LoggerService } from '../../services/logger/logger.service';

@Pipe({
    name: 'mapAccType',
})
export class MapAccountTypePipe implements PipeTransform {
    constructor(
        private logger: LoggerService,
        private readonly accountTypeMapper: AccountTypeMapper,
    ) {}

    transform(code: string) {
        const userFriendlyValue = this.accountTypeMapper.mapCodeToAccountType(code);
        if (!userFriendlyValue) {
            this.logger.warn(
                `Mapping warn - Account Type | Unable to map ${code} to its user friendly value`,
            );
        }
        return userFriendlyValue;
    }
}
