import {AdobeMethods} from '../../../../../services/adobe-launch/adobe-launch.methods';
import {AdobePageName} from '../../../../../services/adobe-launch/adobe-launch.pages';
import {AdobeAnalyticsEvent} from '../../../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessSideStep,
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType
} from '../../../../../services/adobe-launch/adobe-launch.process';
import {
    AnalyticsCtaType,
    CtaCallUsLinkLocations,
    CtaNames
} from '../../../../../services/adobe-launch/adobe-launch.cta';

export const adobeConfigData = {
    ngOnInit: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_SELECT_USER],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_SELECT_USER,
                },
            ],
        },
    ],
    onNextStepLoad: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    ctaName: CtaNames.CONTINUE,
                    ctaType: AnalyticsCtaType.BUTTON,
                    location: CtaCallUsLinkLocations.ZCI_SELECT_PERSON_STEP,
                },
            ],
        },
    ],
    onAddSomeoneElse: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_SIDE_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processSideStep: AdobeAnalyticsProcessSideStep.GAF_ADD_SOMEONE_ELSE,
                },
            ],
        },
    ],
    navigateToForm: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    ctaName: CtaNames.COMPLETE_OUR_FORM,
                    ctaType: AnalyticsCtaType.LINK,
                    location: CtaCallUsLinkLocations.ZCI_SELECT_PERSON_STEP,
                },
            ],
        },
    ]
};
