import { Content } from '../../../../common/interfaces/content.interface';
import {
    ActionMenuWrapperComponent,
    ActionNavOverflowMenuWrapperComponentProps,
} from '../../action-menu-wrapper/action-menu-wrapper.component';
import { TableColumn } from '../../table/table.interface';
import { UserCountComponent, UserCountComponentProps } from '../../user-count/user-count.component';

type OwnAccountsTableHeaderTitles = Content['home']['ownAccountsTable']['headers'];

export enum OwnAccountsTableProps {
    AccName = 'accName',
    AccType = 'accType',
    UsrCount = 'usrCount',
    Actions = 'actions',
}

export interface OwnAccountsTableRow {
    [OwnAccountsTableProps.AccName]: string;
    [OwnAccountsTableProps.AccType]: string;
    [OwnAccountsTableProps.UsrCount]: UserCountComponentProps;
    [OwnAccountsTableProps.Actions]: ActionNavOverflowMenuWrapperComponentProps;
}

export function getOwnAccountsTableCols(headerTitles: OwnAccountsTableHeaderTitles): TableColumn[] {
    return [
        {
            title: headerTitles.accountName,
            dataKey: OwnAccountsTableProps.AccName,
            sortable: true,
            visible: true,
        },
        {
            title: headerTitles.accountType,
            dataKey: OwnAccountsTableProps.AccType,
            sortable: true,
            visible: true,
        },
        {
            title: headerTitles.users,
            dataKey: OwnAccountsTableProps.UsrCount,
            sortable: true,
            visible: true,
            customCell: UserCountComponent,
        },
        {
            title: headerTitles.actions,
            dataKey: OwnAccountsTableProps.Actions,
            sortable: false,
            visible: true,
            customCell: ActionMenuWrapperComponent,
        },
    ];
}
