import { ViewType } from 'src/app/interfaces/view-type.enum';
import { ClientType } from 'src/common/enums/client-type.enum';

import { AccountPermissionsState } from './account-permissions.state';

export const AccountPermissionsInitialState: AccountPermissionsState = {
    accountUsers: {
        data: {
            ownAccounts: [],
            observableAccounts: [],
            users: [],
            grantorInfo: null,
        },
        isLoading: false,
        error: null,
        hasLoaded: false,
    },
    selectedViewTypeForUsers: ViewType.Card,
    selectedViewTypeForMyAccounts: ViewType.Card,
    selectedViewTypeForOtherAccounts: ViewType.Card,
    clientType: ClientType.UNKNOWN,
    grantorIsIneligibleFor: [],
    hasIneligibleError: false,
};
