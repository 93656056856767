<div class="container zci-margin-top c11n-space-stack-5x c11n-space--force">
    <div class="c11n-space-stack-4x">
        <h1 class="zci-h1 c11n-space-stack-2x">{{ content?.home?.pageTitle }}</h1>
        <a
            class="c11n-text-sm"
            data-cy="about-page-link"
            c11n-link
            [variant]="'secondary-independent'"
            [routerLink]="'/' + appRoutes.ABOUT">
            {{ content?.home?.aboutPageLinkLabel }}
        </a>
    </div>

    <div class="c11n-space-stack-4x">
        <a
            c11n-link
            class="c11n-text-md"
            [variant]="'primary-independent'"
            [routerLink]="'/' + appRoutes.OWN_ACCOUNTS"
            data-cy="back-to-all-accounts-link">
            {{ content.usersOnAccount.backLink }}
        </a>
    </div>

    <div class="c11n-space-stack-4x">
        <p
            [innerHTML]="
                content.usersOnAccount.info
                    | replaceParam: 'accountName':(accName | async)
                    | replaceParam: 'usersCount':(usersCount | async)
            "></p>
    </div>

    <zci-users-on-account [accId]="accountId"></zci-users-on-account>
</div>
