export enum ErrorPage {
    TECH_DIFF,
    GRANTEE_CANT_COMPLETE_ONLINE,
    GRANTEE_PIN_EXPIRED,
    GRANTEE_TOO_MANY_ATTEMPTS,
    GRANTEE_HAS_PENDING_REQUEST,
    GRANTEE_FOREIGN_ADDRESS,
}

import { PERMISSION_REQUEST_ERRORS } from '../pin-validation/pin-validation.service';

export const errorPageMap: Partial<Record<PERMISSION_REQUEST_ERRORS, ErrorPage>> = {
    [PERMISSION_REQUEST_ERRORS.RECORD_NOT_FOUND]: ErrorPage.TECH_DIFF,
    [PERMISSION_REQUEST_ERRORS.RECORD_EXPIRED]: ErrorPage.GRANTEE_PIN_EXPIRED,
    [PERMISSION_REQUEST_ERRORS.TOO_MANY_ATTEMPTS]: ErrorPage.GRANTEE_TOO_MANY_ATTEMPTS,
};
