import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent, InputMask } from '@vg-constellation/angular-16/input';
import { HintErrorComponent } from '@vg-constellation/angular-16/hint-error';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { AdobeTagging } from '../../../../../utils/decorators/dev/adobe-launch';
import { ContentUtil } from '../../../../../utils/content/content.util';
import { CtaCallUsLinkLocations } from '../../../../../services/adobe-launch/adobe-launch.cta';
import { Store } from '@ngrx/store';
import { CONSTANTS } from '../../../../../../common/constants/constants';
import { LabelComponent } from '@vg-constellation/angular-16/label';
import { IMaskDirectiveModule } from 'angular-imask';
import { Observable, Subscription } from 'rxjs';
import { addNewUser } from '../../../../../store/actions/grant-revoke-permissions.action';
import { adobeConfigData } from './gaf-add-someone-else-mvp-adobe';
import {
    selectAddUserStepperSelectedAccount,
    selectAddUserStepperSelectedUser,
} from '../../../../../store/selectors/grant-revoke-permissions.selector';
import { UserDetailsDto } from '../../../../../../common/dtos/user-details.dto';
import { BannerComponent } from '@vg-constellation/angular-16/banner';
import {
    PendingRequestResponse,
    PendingRequestService,
} from '../../../../../services/pending-request/pending-request.service';
import { OwnAccountDTO } from '../../../../../../common/dtos/own-account.dto';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-add-someone-else-mvp',
    standalone: true,
    imports: [
        CommonModule,
        InputComponent,
        ReactiveFormsModule,
        LabelComponent,
        IMaskDirectiveModule,
        HintErrorComponent,
        BannerComponent,
    ],
    templateUrl: './gaf-add-someone-else-mvp.component.html',
    styleUrls: ['./gaf-add-someone-else-mvp.component.scss'],
})
export class GafAddSomeoneElseMvpComponent implements OnInit, OnDestroy {
    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() goToNextStep: EventEmitter<boolean> = new EventEmitter();

    readonly subs: Subscription = new Subscription();

    content = ContentUtil.content;

    firstNameControlName: string = 'firstName';

    lastNameControlName: string = 'lastName';

    emailControlName: string = 'email';

    phoneNumberControlName: string = 'phoneNumber';

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_ADD_SOMEONE_ELSE;

    InputMask = InputMask;

    isTherePendingRequest: boolean = false;

    personalInfoForm = new FormGroup({
        [this.firstNameControlName]: new FormControl(null, [
            Validators.pattern(CONSTANTS.NAME_PATTERN),
            Validators.required,
        ]),
        [this.lastNameControlName]: new FormControl(null, [
            Validators.required,
            Validators.pattern(CONSTANTS.NAME_PATTERN),
        ]),
        [this.emailControlName]: new FormControl(null, [
            Validators.required,
            Validators.pattern(CONSTANTS.EMAIL_VALIDATION_PATTERN),
        ]),
        [this.phoneNumberControlName]: new FormControl(null, [
            Validators.required,
            Validators.pattern(CONSTANTS.PHONE_NUMBER_VALIDATION_PATTERN),
        ]),
    });

    constructor(
        private readonly store: Store,
        private readonly pendingRequestService: PendingRequestService,
    ) {}

    sagId: string;

    ngOnInit(): void {
        this.subs.add(
            this.store
                .select(selectAddUserStepperSelectedAccount)
                .subscribe((account: OwnAccountDTO) => {
                    this.sagId = account.serviceAgreementId;
                }),
        );

        this.subs.add(
            this.store.select(selectAddUserStepperSelectedUser).subscribe((userDetails) => {
                this.setPersonalInfoFormValues(userDetails);
            }),
        );

        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    get firstNameFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.firstNameControlName);
    }

    get lastNameFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.lastNameControlName);
    }

    get emailFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.emailControlName);
    }

    get phoneNumberFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.phoneNumberControlName);
    }

    get getEmailAddressErrorMessage(): string | undefined {
        if (this.emailFormControl.hasError('required')) {
            return this.content?.addUserFlow.mvp.selectUser.addSomeoneElseForm
                .emailAddressRequiredError;
        }
        if (this.emailFormControl.hasError('pattern')) {
            return this.content?.addUserFlow.mvp.selectUser.addSomeoneElseForm
                .emailAddressPatternError;
        }
        return undefined;
    }

    get getPhoneNumberErrorMessage(): string | undefined {
        const isPhoneNumberEmpty: boolean = !/\d/gi.test(this.phoneNumberFormControl.value);
        if (isPhoneNumberEmpty) {
            this.phoneNumberFormControl.setErrors({ required: true });
        }
        if (this.phoneNumberFormControl.hasError('required')) {
            return this.content?.addUserFlow.mvp.selectUser.addSomeoneElseForm
                .phoneNumberRequiredError;
        }
        if (this.phoneNumberFormControl.hasError('pattern')) {
            return this.content?.addUserFlow.mvp.selectUser.addSomeoneElseForm
                .phoneNumberPatternError;
        }
        return undefined;
    }

    trackFormattingErrorOnEmailField(): void {
        if (this.emailFormControl.hasError('pattern')) {
            this.adobeTaggingForEmailError();
        }
    }

    trackFormattingErrorOnPhoneNumberField(): void {
        if (this.phoneNumberFormControl.hasError('pattern')) {
            this.adobeTaggingForPhoneNumberError();
        }
    }

    adobeTaggingForEmailError(): void {}

    adobeTaggingForPhoneNumberError(): void {}

    private hasPendingRequest(): Observable<PendingRequestResponse> {
        return this.pendingRequestService.checkRequestForNewUser(
            this.sagId,
            this.emailFormControl.value,
        );
    }

    private onNextStepLoad(): void {
        this.markAllControlsAsTouched();
        if (!this.personalInfoForm.valid) {
            this.goToNextStep.emit(false);
            return;
        }

        this.hasPendingRequest().subscribe((response) => {
            this.isTherePendingRequest = response.hasPendingRequest;

            this.goToNextStep.emit(!this.isTherePendingRequest);

            if (this.isTherePendingRequest) return;

            this.store.dispatch(
                addNewUser({
                    addedUser: {
                        isLoading: false,
                        error: null,
                        hasLoaded: true,
                        data: {
                            clientPoid: null,
                            firstName: this.firstNameFormControl.value,
                            lastName: this.lastNameFormControl.value,
                            middleName: null,
                            email: this.emailFormControl.value,
                            phoneNumber: this.phoneNumberFormControl.value,
                            citizenship: null,
                        },
                    },
                }),
            );
        });
    }

    private markAllControlsAsTouched(): void {
        Object.keys(this.personalInfoForm.controls).forEach((key) => {
            this.personalInfoForm.get(key).markAsTouched();
        });
    }

    private setPersonalInfoFormValues(userDetails: UserDetailsDto): void {
        const { firstName, lastName, email, phoneNumber } = userDetails;

        this.firstNameFormControl.patchValue(firstName);
        this.lastNameFormControl.patchValue(lastName);
        this.emailFormControl.patchValue(email);
        this.phoneNumberFormControl.patchValue(phoneNumber);
    }
}
