import { Component, EventEmitter, Output } from '@angular/core';
import { AnalyticsCtaType, CtaNames } from 'src/app/services/adobe-launch/adobe-launch.cta';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';

import { Content } from '../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../utils/content/content.util';
import { RemoveUserSteps } from './enums/remove-user-steps';
import { AdobeAnalyticsEvent } from '../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../services/adobe-launch/adobe-launch.process';

@Component({
    template: '',
})
export abstract class RemoveUserStepBaseComponent {
    content: Content = ContentUtil.content;

    @Output() stepChange = new EventEmitter<RemoveUserSteps>();

    @Output() contactUs = new EventEmitter<void>();

    constructor(protected readonly adobeLaunchService: AdobeLaunchService) {}

    onQuit(processStep: AdobeAnalyticsProcessStep): void {
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_ABANDONED, {
            processType: AdobeAnalyticsProcessType.RAF_REMOVE_ACCESS_FLOW,
            processStep,
            processAbandonmentType: AdobeAnalyticsProcessStep.RAF_QUIT,
        });
        this.stepChange.emit(RemoveUserSteps.QUIT);
    }

    onContactUs(location: string): void {
        this.adobeLaunchService.trackAction({
            location,
            ctaName: CtaNames.CONTACT_US,
            ctaType: AnalyticsCtaType.BUTTON,
        });

        this.contactUs.emit();
    }
}
