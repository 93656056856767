import { Injectable } from '@angular/core';
import { NavigationItem, NavigationMenuData } from '@vg-constellation/angular-16/navigation';
import { ZciNavigationItems } from 'src/app/interfaces/zci-navigation-items.interface';

import { AccountsUsersDto } from '../../../common/dtos/accounts-users.dto';
import { APPLICATION_ROUTES } from '../../constants/application-routes';
import { ContentUtil } from '../../utils/content/content.util';
import { ZciNavigationItemId } from './zci-navigation-item-id';

@Injectable({
    providedIn: 'root',
})
export class ZciNavigationService {
    getInitSelectedNavItem(
        accountsUsers: AccountsUsersDto,
        url: string,
    ): ZciNavigationItemId | null {
        const availableNavItems: NavigationMenuData = this.getAvailableNavItems(accountsUsers);
        const navItemIdMatchingWithURL: ZciNavigationItemId | null =
            this.getNavItemMatchingWithURL(url);
        const navItemIdBasedOnURL: ZciNavigationItemId | null = this.findNavItemInMenu(
            availableNavItems,
            navItemIdMatchingWithURL,
        );

        return navItemIdBasedOnURL ?? this.getDefaultNavItem(accountsUsers);
    }

    getAvailableNavItems(accountsUsers: AccountsUsersDto): NavigationMenuData {
        const navItems: NavigationMenuData = { items: [] };

        if (accountsUsers?.users?.length) {
            navItems.items.push(this.zciNavigationItems.users);
        }

        if (accountsUsers?.ownAccounts?.length) {
            navItems.items.push(this.zciNavigationItems.ownAccounts);
        }

        if (accountsUsers?.observableAccounts?.length) {
            navItems.items.push(this.zciNavigationItems.observableAccounts);
        }

        return navItems;
    }

    private getNavItemMatchingWithURL(url: string): ZciNavigationItemId | null {
        switch (url) {
            case `/${APPLICATION_ROUTES.USERS}`:
                return ZciNavigationItemId.USERS;
            case `/${APPLICATION_ROUTES.OWN_ACCOUNTS}`:
                return ZciNavigationItemId.OWN_ACCOUNTS;
            case `/${APPLICATION_ROUTES.OBSERVABLE_ACCOUNTS}`:
                return ZciNavigationItemId.OBSERVABLE_ACCOUNTS;
            default:
                return null;
        }
    }

    private getDefaultNavItem(accountsUsers: AccountsUsersDto): ZciNavigationItemId | null {
        if (accountsUsers?.ownAccounts?.length) {
            return ZciNavigationItemId.OWN_ACCOUNTS;
        }
        if (accountsUsers?.users?.length) {
            return ZciNavigationItemId.USERS;
        }
        if (accountsUsers?.observableAccounts?.length) {
            return ZciNavigationItemId.OBSERVABLE_ACCOUNTS;
        }
        return null;
    }

    private findNavItemInMenu(
        menu: NavigationMenuData,
        navItemId: string,
    ): ZciNavigationItemId | undefined {
        if (!menu?.items?.length || navItemId === null || typeof navItemId === 'undefined') {
            return undefined;
        }

        const foundNavItem: NavigationItem | undefined = menu.items.find(
            ({ id }: NavigationItem) => id === navItemId,
        );

        return foundNavItem?.id as ZciNavigationItemId;
    }

    get zciNavigationItems(): ZciNavigationItems {
        const accountsNavTabContent = ContentUtil.content.home.accountsNavTab;

        return {
            users: {
                labelText: accountsNavTabContent.usersItemTitle,
                id: ZciNavigationItemId.USERS,
                routerLink: APPLICATION_ROUTES.USERS,
            },
            ownAccounts: {
                labelText: accountsNavTabContent.myAccountsItemTitle,
                id: ZciNavigationItemId.OWN_ACCOUNTS,
                routerLink: APPLICATION_ROUTES.OWN_ACCOUNTS,
            },
            observableAccounts: {
                labelText: accountsNavTabContent.otherAccountsItemTitle,
                id: ZciNavigationItemId.OBSERVABLE_ACCOUNTS,
                routerLink: APPLICATION_ROUTES.OBSERVABLE_ACCOUNTS,
            },
        };
    }
}
