import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';

@Component({
    selector: 'zci-fake-step-one',
    templateUrl: './fake-step-one.component.html',
    standalone: true,
    styleUrls: ['./fake-step-one.component.css'],
    imports: [
        AsyncPipe,
        CommonModule
    ],
})
export class FakeStepOneComponent {

    @Input() nextStepClicked: EventEmitter<any>;
    @Input() nextStepReady: EventEmitter<any>;

    testClick(){
        this.nextStepReady.emit(true);
    }

}
