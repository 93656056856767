import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '@vg-constellation/angular-16/card';
import { IconComponent } from '@vg-constellation/angular-16/icon';
import { AccessLevelData } from '../../../interfaces/access-level-with-label.interface';
import { LinkComponent } from '@vg-constellation/angular-16/link';

@Component({
  selector: 'zci-access-level-tile',
  standalone: true,
    imports: [CommonModule, CardComponent, IconComponent, LinkComponent],
  templateUrl: './access-level-tile.component.html',
  styleUrls: ['./access-level-tile.component.scss']
})
export class AccessLevelTileComponent {
    @Input() data: AccessLevelData;
}
