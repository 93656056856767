import { Injectable } from '@angular/core';

import { CodeName } from '../../../common/interfaces/code-name.interface';
import { ContentUtil } from '../../utils/content/content.util';
import { ReferenceList } from '../../utils/reference-lists/reference-list.interface';
import { getReferenceListDescriptionByCode } from '../../utils/reference-lists/reference-list.util';

@Injectable({
    providedIn: 'root',
})
export class RoleTypeMapper {
    mapCodeToRoleType(code: string) {
        return getReferenceListDescriptionByCode(this.roleType, code);
    }

    get roleType(): ReferenceList[] {
        const roleTypeContent: CodeName[] = ContentUtil.content.roleTypes;

        return roleTypeContent.map((roleType: CodeName) => {
            return {
                codes: [roleType.code],
                description: roleType.name,
            };
        });
    }
}
