import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { APPLICATION_ROUTES } from 'src/app/constants/application-routes';

@Injectable({
    providedIn: 'root',
})
export class TechnicalDifficultiesRedirectGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(): boolean {
        this.router.navigate([APPLICATION_ROUTES.TECHNICAL_DIFFICULTIES], {
            state: { internal: true },
        });
        return false;
    }
}
