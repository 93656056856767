function Unsubscribe(target: any) {
    const originalOnDestroy = target.prototype.ngOnDestroy;
    let properties$: any[];

    target.prototype.ngOnDestroy = function (...args) {
        if (originalOnDestroy && typeof originalOnDestroy === 'function') {
            originalOnDestroy.apply(this, args);
        }

        properties$ = Object.keys(this)
            .filter((key) => {
                return this[key] && typeof this[key].unsubscribe === 'function';
            })
            .map((key) => {
                return this[key];
            });

        for (const sub of properties$) {
            sub.unsubscribe();
        }
    };
}

export default Unsubscribe;
