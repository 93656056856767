import { Injectable } from '@angular/core';
import { NavOverflowGroup } from '@vg-constellation/angular-16/nav-overflow';

import { NavOverflowExtendedData } from '../../interfaces/nav-overflow-extended-data.interface';
import { ContentUtil } from '../../utils/content/content.util';
import { ZciOverflowMenuService } from '../zci-overflow-menu/zci-overflow-menu.service';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private readonly content = ContentUtil.content;

    private readonly zciOverflowItems = this.navOverflowItemsService.zciOverflowItems;

    private defaultMenuGroup: NavOverflowGroup[] = [
        {
            items: [
                this.zciOverflowItems.editUserInAccount,
                this.zciOverflowItems.removeUserFromAccount,
            ],
        },
    ];

    private minifiedMenuGroup: NavOverflowGroup[] = [
        {
            items: [
                this.zciOverflowItems.editUserInAccount,
                this.zciOverflowItems.removeUserAccess,
            ],
        },
    ];

    constructor(private readonly navOverflowItemsService: ZciOverflowMenuService) {}

    getNavOverflowMenu(hasSelectedAccount: boolean): NavOverflowExtendedData {
        return {
            label: this.content.home.userTile.actionsMenuLabel,
            labelId: 'single_users_actions',
            data: {
                groups: hasSelectedAccount ? this.minifiedMenuGroup : this.defaultMenuGroup,
            },
        };
    }
}
