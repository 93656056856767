<zci-tile>
    <article class="single-status-list-tile">
        <ng-content></ng-content>
        <ul>
            <li
                *ngFor="let item of items"
                class="c11n-space-stack-3x c11n-text-sm single-status-list-tile__item">
                <strong>{{ item }}</strong>
                <ng-container *ngTemplateOutlet="statusIcon"> </ng-container>
            </li>
        </ul>
    </article>
</zci-tile>
