import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/components/pages/page.component';
import { APPLICATION_ROUTES } from 'src/app/constants/application-routes';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { TitleService } from 'src/app/services/title/title.service';

import { ContentUtil } from '../../../../../utils/content/content.util';

@Component({
    selector: 'zci-edit-user',
    templateUrl: './edit-users-page.component.html',
    styleUrls: ['./edit-users-page.component.scss'],
})
export class EditUsersPageComponent extends PageComponent implements OnInit {
    appRoutes = APPLICATION_ROUTES;

    content = ContentUtil.content;

    constructor(
        private readonly adobeLaunchService: AdobeLaunchService,
        private readonly titleService: TitleService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.content.accountInterstitialPages.editUser.pageTitle);
        this.adobeLaunchService.pageLoad(AdobePageName.OWN_ACCOUNTS_EDIT_USERS_PAGE);
    }
}
