import { catchError, firstValueFrom, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { APPLICATION_ROUTES, PUBLIC_ROUTES } from './constants/application-routes';

const successful = 'SUCCESSFUL';

interface CheckLogonResponse {
    state: string;
}

const publicPages: Array<string> = Object.values(PUBLIC_ROUTES);

export function initializeApp(http: HttpClient, window: Window) {
    if (window.location.pathname.includes(APPLICATION_ROUTES.GENERIC_ERROR)) {
        window.location.pathname = APPLICATION_ROUTES.OWN_ACCOUNTS;
        return () => Promise.resolve();
    }

    for (const key of publicPages) {
        if (window.location.pathname.includes(key)) {
            return () => Promise.resolve();
        }
    }

    return (): Promise<void> =>
        firstValueFrom(
            http.get(environment.CHECK_LOGON).pipe(
                map((response: CheckLogonResponse) => {
                    if (response.state === successful) {
                        window.document.body.hidden = false;
                    } else {
                        throw new Error('User is not logged in, redirecting to logon page');
                    }
                }),
                catchError(() => {
                    window.location.href = `${environment.LOGON_REDIRECT_URL}${environment.APP_URL}${window.location.pathname}`;
                    return Promise.reject(); // Ensure that app initialization is aborted since we're going to the (external) logon page.
                }),
            ),
        );
}
