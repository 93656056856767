import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-16/modal-dialog';
import { combineLatest, map, Observable } from 'rxjs';

import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';
import {
    getAddedUserLoadingState,
    selectLoadingState,
} from '../../../store/selectors/grant-revoke-permissions.selector';
import { ContentUtil } from '../../../utils/content/content.util';

@Component({
    selector: 'zci-step-wrapper',
    templateUrl: './step-wrapper.component.html',
    styleUrls: ['./step-wrapper.component.scss'],
})
export class StepWrapperComponent {
    @Input() title!: string;

    @Input() nextButtonLabelText!: string;

    @Input() currentStep: number;

    @Input() totalSteps: string[];

    @Input() titleMargin: string = '4x';

    @Input() showBackButton: boolean = true;

    @Input() showNextButton: boolean = true;

    @Input() onClick: () => void;

    @Output() clickNextButton = new EventEmitter<void>();

    @Output() clickBackButton = new EventEmitter<void>();

    @Output() clickContactUs = new EventEmitter<void>();

    @Output() clickQuit = new EventEmitter<void>();

    @ViewChild('quitButtonModalDialog', { static: false })
    quitButtonModalDialog!: ModalDialogComponent;

    isWaiting$: Observable<any> = combineLatest([
        this.store.select(getAddedUserLoadingState),
        this.store.select(selectLoadingState),
    ]).pipe(
        map(([addedUserLoadingState, loadingState]) => {
            return addedUserLoadingState || loadingState;
        }),
    );

    isMobileView$: Observable<boolean> = this.breakpointObserver.isMobileView$;

    content = ContentUtil.content;

    constructor(
        private readonly store: Store,
        private readonly breakpointObserver: BreakpointObserverService,
    ) {}

    openModalDialog(): void {
        this.quitButtonModalDialog.openModalDialog();
    }

    quit(): void {
        this.clickQuit.emit();
    }

    stayOnCurrentStep(): void {
        this.quitButtonModalDialog.closeDialogModal();
    }

    backButton(): void {
        this.clickBackButton.emit();
    }

    nextButton(): void {
        this.clickNextButton.emit();
    }

    contactUs(): void {
        this.clickContactUs.emit();
    }
}
