import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take, tap } from 'rxjs';

import { ACCOUNT_ID_PARAM, APPLICATION_ROUTES } from '../constants/application-routes';
import { loadAccountUsers } from '../store/actions/account-permissions-page.action';
import { selectAccountUsers } from '../store/selectors/account-permissions.selector';

@Injectable({
    providedIn: 'root',
})
export class UsersOnAccountGuard implements CanActivate {
    constructor(private readonly store: Store, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.store.select(selectAccountUsers).pipe(
            tap((accountUsers) => {
                if (!accountUsers.hasLoaded && !accountUsers.isLoading) {
                    this.store.dispatch(loadAccountUsers());
                }
            }),
            filter((accountUsers) => accountUsers.hasLoaded),
            map((accountUsers) => {
                /* istanbul ignore next */
                const accountFound = !!accountUsers?.data?.ownAccounts?.find(
                    (acc) => acc.accountId === route.params[ACCOUNT_ID_PARAM],
                );
                if (!accountFound) {
                    this.router.navigate([APPLICATION_ROUTES.OWN_ACCOUNTS]);
                }
                return accountFound;
            }),
            take(1),
        );
    }
}
