import { IneligibleReasons } from '../../common/enums/ineligible-scenarios.enum';
import { Content } from '../../common/interfaces/content.interface';
import { roleTypes } from './role-types';

export const content: Content = {
    appTitle: 'Access permissions',
    titleSeparator: ' | ',
    global: {
        backLink: 'Back',
        table: {
            sortableHeader: {
                tooltip: {
                    asc: 'Sorted ascending',
                    desc: 'Sorted descending',
                    default: 'Sortable',
                },
                ariaLabel: {
                    asc: 'Sorted ascending',
                    desc: 'Sorted descending',
                    default: 'Sortable',
                },
            },
            actions: {
                noAction: 'No action',
            },
        },
    },
    home: {
        pageTitle: 'Access permissions',
        aboutPageLinkLabel: 'What are account permissions?',
        accountTile: {
            zeroAgentsLabel: 'No users assigned',
            oneAgentLabel: 'user',
            multipleAgentsLabel: 'users',
            actionsMenuLabel: 'Actions',
            actionItems: {
                addUsers: 'Add users',
                editUsers: 'Edit users',
                removeUsers: 'Remove users',
            },
        },
        observableAccountTile: {
            actionItems: {
                removeAccessFromAccount: 'Remove access',
            },
            actionsMenuLabel: 'Actions',
        },
        userTile: {
            actionsMenuLabel: 'Actions',
            actionItems: {
                default: {
                    addUserToAccount: 'Add to an account',
                    editUser: 'Edit access',
                    removeUserFromAccount: 'Remove from account',
                },
                drillDown: {
                    removeAccess: 'Remove access',
                },
            },
        },
        userTable: {
            headers: {
                user: 'User',
                accountName: 'Account name',
                accountType: 'Account type',
                permissionType: 'Access type',
                actions: '',
            },
        },
        ownAccountsTable: {
            headers: {
                accountName: 'Account name',
                accountType: 'Account type',
                users: 'Users',
                actions: '',
            },
        },
        otherAccountTable: {
            headers: {
                accountName: 'Account name',
                accountType: 'Account type',
                accessType: 'Access type',
                actions: '',
            },
        },
        accountsNavTab: {
            ariaLabel: 'Accounts navigation bar',
            navigationTabAriaLabel: 'Navigation tab',
            myAccountsItemTitle: 'My accounts',
            otherAccountsItemTitle: 'Accounts I have access to',
            usersItemTitle: 'Users',
        },
        loadMoreAccounts: 'Load {{}} more accounts',
        loadMoreUsers: 'Load {{}} more users',
    },
    about: {
        pageTitle: 'About account access',
        h1Description:
            'You can grant individuals or organizations permission to access your accounts. Based on the access level you choose, they can view account information, trade investments, or completely manage your account on your behalf.',
        checkMarkAltText: 'This action is allowed',
        emptyCellAriaLabel: 'This action is not allowed',
        tableCompareAllowedActions: {
            title: 'Access comparison',
            description:
                'Use this chart to compare the actions allowed under each access level. If you need more help selecting a level, call Vanguard at 877-662-7447 or contact your {{contactPersonType}}.',
            headers: {
                horizontal: [
                    'Authorization',
                    'Information access',
                    'Limited access',
                    'Full authority',
                ],
                vertical: [
                    'Obtain account information online or by phone',
                    'Buy, sell, and exchange Vanguard mutual funds and trade stocks, bonds, and other securities',
                    'Sell assets and have the proceeds sent to you or the bank account on record',
                    'Write checks from your account',
                    'Change ownership of your account',
                    'Change account beneficiaries',
                    'Change bank information on the account',
                    'Sell assets and have the proceeds made payable to a third party or mailed to a new address',
                ],
            },
            matrix: [
                [true, true, true],
                [false, true, true],
                [false, true, true],
                [false, false, true],
                [false, false, true],
                [false, false, true],
                [false, false, true],
                [false, false, true],
            ],
        },
        tableEligibleAccounts: {
            title: 'Eligible accounts',
            description:
                'Some account types have restricted access. Use this chart to determine which access levels can be granted to your accounts.',
            headers: {
                horizontal: [
                    'Eligible accounts',
                    'Information access',
                    'Limited access',
                    'Full authority',
                ],
                vertical: [
                    'Individual nonretirement',
                    'Joint',
                    'IRA',
                    'Non-ERISA 403(b)(7)',
                    'Individual 401(k)',
                    'Organization/corporate',
                    'Trust',
                    'UGMA/UTMA, custodial, or other education savings',
                    'Vanguard Retirement Investment Program',
                ],
            },
            matrix: [
                [true, true, true],
                [true, true, true],
                [true, true, true],
                [true, true, true],
                [true, true, false],
                [true, true, false],
                [true, true, false],
                [true, true, false],
                [true, true, false],
            ],
        },
    },
    userInterstitialPages: {
        addUser: {
            pageTitle: 'Add account access to an existing user',
        },
        editUser: {
            pageTitle: 'Edit user access',
        },
        removeUser: {
            pageTitle: 'Remove user access',
        },
    },
    referenceLists: {
        accountType: {
            individual: 'Individual',
            joint: 'Joint',
            trust: 'Trust',
            rolloverIRA: 'Rollover IRA',
            traditionalIRA: 'Traditional IRA',
            simpleIRA: 'Simple IRA',
            sepIRA: 'SEP IRA',
            rothIRA: 'Roth IRA',
            inheritedIRA: 'Inherited IRA',
            inheritedRothIRA: 'Inherited Roth IRA',
            rothConversionIRA: 'Roth conversion IRA',
            ugma: 'UGMA',
            utma: 'UTMA',
            keogh: 'Keogh',
            moneyPurchasePensionPlan: 'Money purchase pension plan',
            profitSharingPlan: 'Profit sharing plan',
            '403b7': '403(b)7',
            educationSavingsAccount: 'Education savings account',
            individual401k: 'Individual 401(k)',
            individualRoth401k: 'Individual Roth 401(k)',
            account: 'Account',
            custodian: 'Custodian',
            guardian: 'Guardian',
            charitableEndowment: 'Charitable endowment',
            estate: 'Estate',
            unknown: 'Unknown',
            participant: 'Employer plan',
            other: 'Other',
            outsideHoldings: 'Outside holdings',
            outsideInvestment: 'Outside investment',
        },
    },
    accountInterstitialPages: {
        addUser: {
            pageTitle: 'Add authorized users',
        },
        editUser: {
            pageTitle: 'Edit user access',
        },
        removeUser: {
            pageTitle: 'Remove user access',
        },
        removeAccessFromAccount: {
            pageTitle: 'Remove my access',
            pageHeading: 'Contact your {{contactPersonType}}',
            pageDescription:
                'If you want to remove your access from someone else’s account, your personal {{contactPersonType}} can help.',
        },
    },
    commonInterstitialPages: {
        withOnlineFlow: {
            pageTitle: ['To give access to an existing Vanguard client use this', 'online process'],
            paragraph1: 'For all other access requests, contact your {{contactPersonType}}',
        },
        withoutOnlineFlow: {
            pageTitle: 'Contact your {{contactPersonType}}',
            paragraph1:
                'If you want to add or remove an authorized user—or make any other changes to the access permissions on your accounts—your personal {{contactPersonType}} can help.',
        },
        paragraph2: 'Have questions about access types or eligible accounts?',
        paragraph3: [
            'Use our',
            'access comparison table',
            'to compare permission levels and available accounts.',
        ],
    },
    usersOnAccount: {
        pageTitle: 'Users on account',
        backLink: 'Back to all accounts',
        info: 'The account <strong>{{accountName}}</strong> has {{usersCount}}:',
        oneUser: 'user',
        multipleUsers: 'users',
        usersOnAccountTable: {
            headers: {
                user: 'User',
                permissionType: 'Access type',
                actions: '',
            },
        },
    },
    viewSelector: {
        cardViewLabel: 'Card view',
        tableViewLabel: 'Table view',
    },
    stepper: {
        nextButtonLabelText: 'Next',
        cancelButtonLabelText: 'Cancel',
        previousButtonLabelText: 'Previous',
        backButtonLabelText: 'Back',
        quitButtonLabelText: 'Quit',
        submitButtonLabelText: 'Submit',
    },
    addUserFlow: {
        addUserPageTitle: 'Add user',
        stepperTitles: [
            'Triage',
            'Select user',
            'Select access type',
            'Access agreement',
            'Review and submit',
        ],
        contactUs: 'Contact us',
        representativeCardHeading: 'For all other access needs:',
        representativeCardParagraph:
            'Your {{contactPersonType}} will help you with all other requests, including granting full access or access to an organization or trust.',
        mvp: {
            stepper: {
                backButtonLabelText: 'Back',
                nextButtonLabelText: 'Continue',
                submitButtonLabelText: 'Submit',
                agreementNextButtonText: 'Agree and continue',
                viewSteps: 'View steps',
                ariaCurrent: 'step',
                triage: 'Grantee type',
                accessLevel: 'Access level',
                selectUser: 'Person',
                accessAgreement: 'Agreement',
                reviewAndSubmit: 'Review & submit',
            },
            triage: {
                pageTitle: 'Who are you giving access to your account?',
                existingGrantee: {
                    title: 'An individual that I’ve already given access permissions to another one of my accounts',
                    description: '',
                },
                newGrantee: {
                    title: 'An individual who has their own Vanguard account (an existing Vanguard client)',
                    description: '',
                },
                nonVgGrantee: {
                    title: 'An individual without a Vanguard account',
                    description: 'We will take you to our form.',
                },
                orgTrust: {
                    title: 'An entire organization or trust',
                    description: 'We will take you to our form.',
                },
            },
            accessLevel: {
                title: 'Choose an access level to add',
                infoOnly: {
                    accessLevel: 'Information only',
                    completion: 'Online completion',
                    info: ['Obtain account information online or by phone.'],
                },
                limited: {
                    accessLevel: 'Limited authority',
                    completion: 'Online completion',
                    info: [
                        'Obtain account information online or by phone.',
                        'Buy, sell, exchange and trade within your account.',
                        'Send investment proceeds to you or the bank account on record.',
                    ],
                },
                fullAuthority: {
                    accessLevel: 'Full authority',
                    completion: 'Form completion',
                    notaryRequired: 'Notary required',
                    info: [
                        'Obtain account information online or by phone.',
                        'Buy, sell, exchange and trade within your account.',
                        'Send investment proceeds to anyone.',
                        'Open new accounts and sign your tax forms.',
                        'Write checks from your account.',
                        'Change account ownership, beneficiaries, bank information, and addresses.',
                    ],
                },
                infoOnlyModal: {
                    headingText: 'Your routing and account number will be visible',
                    bodyText:
                        'Giving access to a Vanguard Cash Plus Account shows both your routing and account numbers. This makes it possible to transact on third party websites. Monitor your account to ensure this information is used appropriately.',
                },
                limitedModal: {
                    headingText: 'Note: Online transactions are not available for advised accounts',
                    bodyText:
                        'You have selected to give limited authority to an advised account. For future reference, the authorized person will need to call Vanguard to transact.',
                },
                modal: {
                    primaryButtonText: 'Continue',
                    secondaryButtonText: 'Back',
                },
                cashPlusModal: {
                    headingText: 'Your routing and account number will be visible',
                    bodyText:
                        'Giving access to a Vanguard Cash Plus Account shows both your routing and account numbers. This makes it possible to transact on third party websites. Monitor your account to ensure this information is used appropriately.',
                },
            },
            selectUser: {
                tileTitle: 'Select the person to give access to',
                validationMessage: {
                    required: 'Please choose a person from the list or add someone else',
                    userNotFound: [
                        'This information provided does not match our records.',
                        'For your security and theirs, please verify the information with the person you are adding before trying again.',
                    ],
                    personExistsOnAccessCenter:
                        'This person already exists in your access permissions. Select <strong>back</strong> to choose this person.',
                    personHasAccessToAccount:
                        'This person already has access to this account. Please enter the information of a new person.',
                    personHasForeignAddress:
                        'For giving access to someone with a foreign address, ',
                    personHasForeignAddressFormLink: 'please use our form',
                },
                addSomeoneElseLink: 'Add someone else',
                addSomeoneElseForm: {
                    title: 'Tell us about this person',
                    description: 'Enter the information of the person you are giving access to.',
                    firstNameLabel: 'First name',
                    firstNameRequiredError: 'Please enter their first name',
                    lastNameLabel: 'Last name',
                    lastNameRequiredError: 'Please enter their last name',
                    emailAddressLabel: 'Email address',
                    emailAddressRequiredError: 'Please enter their email address',
                    emailAddressPatternError: 'Enter a valid email address',
                    phoneNumberLabel: 'Phone number',
                    phoneNumberRequiredError: 'Please enter their phone number',
                    phoneNumberPatternError: 'Enter a valid phone number',
                    incorrectNameError:
                        "Please enter only letters, numbers, or special characters - . ' &",
                },
                addSomeoneElsePendingRequestBanner: {
                    text1: 'This person already has a pending access request on this account.',
                    text2: 'Either remind them to accept the request by resending your unique pin, or enter the details of someone new.',
                },
                completeOurForm: {
                    title: 'Note: ',
                    preText: 'If you are giving access to an associated or control person, ',
                    anchorText: 'complete our form',
                    postText: ' instead.',
                },
            },
            accessAgreement: {
                limitedAccessLevelAgreement: {
                    title: 'Authorization agreement',
                    importantPreface: 'IMPORTANT: ',
                    importantTextContent:
                        'You are granting another person (the "grantee") authority to act on your designated accounts. By signing below, you authorize the grantee\'s limited authority to act on the Vanguard mutual fund and Vanguard Brokerage Accounts you have designated in accordance with the terms below and any additional terms that Vanguard requires to affect the authorization.',
                    authorizationHeading: 'Authorization by the account owner',
                    acknowledgeAuthorityText:
                        "I acknowledge that the authority I am giving the grantee will result in the grantee's authority to perform the following transactions by mail, phone, and online on the account(s) I have designated, subject to Vanguard policies and procedures:",
                    firstPortionListItem1:
                        'Purchase, exchange, and sell Vanguard mutual funds within my Vanguard accounts.',
                    firstPortionListItem2:
                        'Purchase, sell (including short sales), and trade in stocks, bonds, and other securities and contracts relating to the same.',
                    firstPortionListItem3:
                        'Engage in margin and/or option trading if established by me.',
                    firstPortionListItem4:
                        'Transfer or invest assets between Vanguard accounts owned solely by me, including my revocable trust if I am the sole trustee.',
                    firstPortionListItem5:
                        'Request that distributions from my accounts be payable to me and sent to my bank account or address on record.',
                    firstPortionListItem6:
                        'Obtain information on my Vanguard mutual fund and Vanguard Brokerage Account(s), including historical account information.',
                    understandAuthorityText:
                        'I understand that the authority granted herein is limited to the level of authority specified above. The grantee won’t have authority to take any other action, including, but not limited to:',
                    secondPortionListItem1:
                        'Change the address on record or bank information on my accounts.',
                    secondPortionListItem2:
                        'Transfer or invest assets into a different registration.',
                    secondPortionListItem3:
                        'Redeem assets by phone, online, or by wire transfer to anyone other than me or my address on record.',
                    secondPortionListItem4: 'Use checkwriting privileges on my accounts.',
                    secondPortionListItem5: 'Add, change, or delete beneficiary designations.',
                    secondPortionListItem6:
                        'Sign a new account application or otherwise open a new registration on my behalf.',
                    secondPortionListItem7:
                        'Sign any tax information or reporting form required by federal or state taxing authorities, including IRS Form W-9 and any substitute IRS Form W-9.',
                    secondPortionListItem8:
                        'Request a distribution from a Vanguard Brokerage plan account.',
                    secondPortionListItem9:
                        'Appoint another agent to act on my behalf or register on my behalf or change my online access through vanguard.com.',
                    secondPortionListItem10:
                        'Consent to and contract with any advice service offered by Vanguard, including, but not limited to, Vanguard Personal Advisor Services®.',
                    understandLimitedPowerOfAttorneyText:
                        'I understand that this Limited Power of Attorney shall be governed and construed in accordance with the laws of the Commonwealth of Pennsylvania, without reference to principles of conflicts of laws, and for such purposes (1) shall be deemed to constitute a power contained in an instrument used in a commercial transaction that authorizes an agency relationship granted exclusively to facilitate the transfer of stock, bonds, and other assets; (2) is durable; (3) may be exercised independently of any other agent I have previously or in the future may appoint or designate unless revoked; (4) is subject to the duties and limitations on liability and disclosure obligations provided by 20 PA.C.S. § 5601.3; and (5) includes, but is not limited to, the power to engage in stock, bond, and other securities transactions as specified by 20 PA.C.S. § 5603.',
                    liabilityReleaseContentParagraph1:
                        'I agree that neither The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, nor any of their respective affiliates are responsible for monitoring whether my agent is an Investment Adviser (as defined in Section 202(a)(11) of the U.S. Investment Advisers Act) or properly registered as an Investment Adviser with the SEC or state(s). I understand that none of these entities, nor their respective affiliates, perform any services for Investment Advisers to satisfy their custody rule requirements 17 CFR § 275.206(4)-2.',
                    liabilityReleaseContentParagraph2:
                        'I agree that none of The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, or any of their respective affiliates will be held responsible for my decisions or for the investment recommendations or decisions of my agent(s), and is under no duty whatsoever to question any instructions received from the agent(s) or the suitability of any transactions requested by him/her/them.',
                    liabilityReleaseContentParagraph3:
                        'I agree to indemnify and hold harmless The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, their affiliates, and each of the investment company members of The Vanguard Group and their respective officers, employees, and agents (collectively, Vanguard) from acting on instructions—whether oral, written, or online—reasonably believed by Vanguard to have originated from my agent(s), and from all acts of my agent(s) involving the Vanguard mutual fund accounts or Vanguard Brokerage Accounts covered by this authorization.',
                    disabilityDisclaimerTextHeading:
                        'In the event of my disability or incapacity, this authorization will remain in full force and effect. This authorization will terminate only when one of the following occurs:',
                    disabilityDisclaimerBulletPoint1:
                        'Vanguard receives notice of my revocation. I understand that I have the right to revoke this authorization at any time and, if I have named multiple agents, I understand that I must specifically identify the agent(s) whose authority I am revoking. I further understand that each authorization covering the same accounts and the same level of authority (limited or full agents) submitted to Vanguard will revoke any prior authorization only to the extent indicated in such subsequent authorization. Any revocation will not affect any liability resulting from transactions initiated before Vanguard has had a reasonable amount of time to act upon such notice.',
                    disabilityDisclaimerBulletPoint2:
                        'Vanguard receives notice of my death. I understand that the authority granted under this authorization terminates at my death. My death will not affect any liability resulting from transactions initiated before Vanguard has had a reasonable amount of time to act upon such notice.',
                    fraudulentOrIllegalActivityText:
                        'I understand that Vanguard reserves the right to reject, at any time, instructions that Vanguard believes to be suspicious, fraudulent or otherwise unauthorized, or illegal.',
                    acknowledgementThatAgreementHasBeenReadText:
                        'I have read this authorization in its entirety or had it explained to me, and I understand its contents.',
                    signingAsATrusteeAcknowledgementText:
                        'If I am signing as trustee, I confirm that I have the authority to appoint one or more agents and to delegate authority under the trust document and/or applicable law.',
                    cancellationAcknowledgementNoticeText:
                        'I understand that this request will be canceled, and no grant of authority will be effected, if all necessary actions by the grantee have not been completed or provided to Vanguard in good order within 14 days after the business day on which my request is deemed submitted.',
                    iUnderstandThatText: 'I understand that clicking ',
                    trusteeText:
                        'If I am signing as trustee, I confirm that I have the authority to appoint one or more agents and to delegate authority under the trust document and/or applicable law.',
                    canceledText:
                        'I understand that this request will be canceled, and no grant of authority will be effected, if all necessary actions by the grantee have not been completed or provided to Vanguard in good order within 14 days after the business day on which my request is deemed submitted.',
                    agreeAndContinueBoldText: 'agree and continue',
                    restOfClickingAgreeAndContinueText:
                        ', I am authorizing the grant of limited authority on the designated Vanguard account(s), all under the terms described above.',
                    agreeAndContinueButtonText: 'Agree and continue',
                },
                infoAccessLevelAgreement: {
                    title: 'Authorization agreement',
                    importantPreface: 'IMPORTANT: ',
                    importantTextContent:
                        'You are authorizing another person (the "grantee") to access information only on your account(s). By signing below, you authorize the grantee’s information access to those Vanguard mutual fund and Vanguard Brokerage Account(s) you have designated, in accordance with the terms below.',
                    listItem1:
                        'I authorize the grantee online and phone access to information, including historical account information, for the Vanguard mutual fund and Vanguard Brokerage Account(s) I have designated.',
                    listItem2:
                        'I acknowledge that the grantee I have identified is not authorized to enter into or request any transaction on any of my accounts nor to add, delete, or change any account option.',
                    listItem3: 'I understand that I can revoke this authorization at any time.',
                    listItem4:
                        'I acknowledge that the authority I have granted to the grantee shall remain in effect until Vanguard receives notice of my revocation or my death and Vanguard has had a reasonable amount of time to act upon such notice.',
                    listItem5:
                        'I understand and agree that this request is my responsibility and at my risk. I agree to indemnify and hold The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, each of the investment company members of The Vanguard Group, and their respective affiliates, employees, agents, and officers, harmless from any liability resulting from providing to the grantee any information I have authorized in this request.',
                    iUnderstandThatText: 'I understand that clicking ',
                    agreeAndContinueBoldText: 'agree and continue',
                    restOfClickingAgreeAndContinueText:
                        ', I am authorizing the grantee to access information on the accounts I have designated, all under the terms described above.',
                    agreeAndContinueButtonText: 'Agree and continue',
                },
            },
            reviewAndSubmit: {
                title: 'Review and submit',
                description: '{{userName}} is gaining {{accessLevelAccess}} to this account:',
                note: 'Note:',
                noteDescriptionPartOne:
                    ' If {{firstName}} wants to act on behalf of the other owner of your joint account, that owner must also add {{firstName}} to the account in their own ',
                noteDescriptionPartTwo: 'access permissions',
                noteDescriptionPartThree: '.',
            },
        },
        step1: {
            pageTitle: 'Who are you giving access to your account?',
            existingGrantee: {
                title: 'An individual who already has access permissions to one or more of my accounts',
                description: '',
            },
            newGrantee: {
                title: 'An individual who has their own Vanguard account',
                description: 'We’ll ask you their account number later.',
            },
            nonVgGrantee: {
                title: 'An individual without a Vanguard account',
                description: 'We will take you to our form.',
            },
            orgTrust: {
                title: 'An organization or trust',
                description: 'We will take you to our form.',
            },
            triageBackButtonText: 'Back',
        },
        step2: {
            tileTitle: "Select who you'd like to give access to",
            validationMessage: {
                required: 'Please choose a person from the list or add someone else',
                userNotFound: [
                    'This information provided does not match our records.',
                    'For your security and theirs, please verify the information with the person you are adding before trying again.',
                ],
                personExistsOnAccessCenter:
                    'This person already exists in your access permissions. Select <strong>back</strong> to choose this person.',
                personHasAccessToAccount:
                    'This person already has access to this account. Please enter the information of a new person.',
                personHasForeignAddress: 'For giving access to someone with a foreign address, ',
                personHasForeignAddressFormLink: 'please use our form',
            },
            addSomeoneElseLink: 'Add someone else',
            addSomeoneElseForm: {
                title: 'Tell us about this person',
                description:
                    'Enter their information exactly how they are registered with Vanguard. All fields are required.',
                firstNameLabel: 'First name',
                firstNameHint: 'Please exclude any prefixes (Dr., Capt., Mr., Mrs., Ms., etc)',
                firstNameRequiredError: 'Please enter their first name',
                lastNameLabel: 'Last name',
                lastNameHint: 'Please exclude any suffixes (Jr., Sr., III, etc)',
                lastNameRequiredError: 'Please enter their last name',
                dateOfBirthLabel: 'Date of birth',
                dateOfBirthHint: 'MMDDYYYY',
                dateOfBirthRequiredError: 'Please enter their birth date',
                dateOfBirthPatternError: 'Please enter their birth date in MM/DD/YYYY format',
                accountNumberLabel: 'Vanguard account number',
                accountNumberHint:
                    'The account number is 6, 8 or 11 digits, including any leading zeros. 529 clients: select the question mark for additional information.',
                accountNumberRequiredError: 'Please enter their account number',
                accountNumberPatternError:
                    'The account number is 6, 8, or 11 digits, including any leading zeros',
                accountNumberTooltip:
                    'If your statement shows a dash in the account number, type in the digits <strong>after</strong> the dash.<br><br>529 accounts: Please include the digits <strong>before and after</strong> the dash.',
            },
            completeOurForm: {
                title: 'Note: ',
                preText: 'If you are giving access to an associated or control person, ',
                anchorText: 'complete our form',
                postText: ' instead.',
            },
        },
        step3: {
            title: "Select {{userName}}'s access level",
            validationMessage: {
                required: 'Please select an access level',
            },
        },
        step4: {
            title: 'Access agreement',
            nextButtonLabelText: 'Agree and next',
            informationOnly:
                '<b>IMPORTANT:</b> You are authorizing another person (the "grantee") to access information only on your account(s). By signing below, you authorize the grantee’s information access to those Vanguard mutual fund and Vanguard Brokerage Account(s) you have designated, in accordance with the terms below.' +
                '<ol>' +
                '<li>I authorize the grantee online and phone access to information, including historical account information, for the Vanguard mutual fund and Vanguard Brokerage Account(s) I have designated.</li>' +
                '<li>I acknowledge that the grantee I have identified is not authorized to enter into or request any transaction on any of my accounts nor to add, delete, or change any account option.</li>' +
                '<li>I understand that I can revoke this authorization at any time.</li>' +
                '<li>I acknowledge that the authority I have granted to the grantee shall remain in effect until Vanguard receives notice of my revocation or my death and Vanguard has had a reasonable amount of time to act upon such notice.</li>' +
                '<li>I understand and agree that this request is my responsibility and at my risk. I agree to indemnify and hold The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, each of the investment company members of The Vanguard Group, and their respective affiliates, employees, agents, and officers, harmless from any liability resulting from providing to the grantee any information I have authorized in this request.</li>' +
                '</ol>' +
                'I understand that clicking <b>agree and next,</b> I am authorizing the grantee to access information on the accounts I have designated, all under the terms described above.',
            limitedAccess:
                '<b>IMPORTANT:</b> You are granting another person (the "grantee") authority to act on your designated accounts. By signing below, you authorize the grantee’s limited authority to act on the Vanguard mutual fund and Vanguard Brokerage Accounts you have designated in accordance with the terms below and any additional terms that Vanguard requires to effect the authorization.<br><br><b>Limited Agent authority on future accounts you establish:</b><br>' +
                '<ul>' +
                '<li>If you establish an account <i>under a different ownership</i> and want to grant limited access to that account (e.g., you establish an individual non-retirement account when you previously designated your limited agent to have authority over an organization account) you’ll need to complete a separate Limited Agent Authorization.</li>' +
                '<li>If you establish an account <i>under the same ownership</i> as an account you’ve previously designated your limited agent to have authority (e.g., you establish an individually owned non-retirement account and you previously designated your limited agent to have authority over your IRA account), you don’t need to complete a separate Limited Agent Authorization. However, you may need to take additional action to grant your agent access.</li>' +
                '</ul>' +
                '<b>Authorization by the account owner</b><br><br> I acknowledge that the authority I am giving the grantee will result in the grantee’s authority to perform the following transactions by mail, phone, and online on the account(s) I have designated, subject to Vanguard policies and procedures:' +
                '<ol>' +
                '<li>Purchase, exchange, and sell Vanguard mutual funds within my Vanguard accounts.</li>' +
                '<li>Purchase, sell (including short sales), and trade in stocks, bonds, and other securities and contracts relating to the same.</li>' +
                '<li>Engage in margin and/or option trading if established by me.</li>' +
                '<li>Transfer or invest assets between Vanguard accounts owned solely by me, including my revocable trust if I am the sole trustee.</li>' +
                '<li>Request that distributions from my accounts be payable to me and sent to my bank account or address on record.</li>' +
                '<li>Obtain information on my Vanguard mutual fund and Vanguard Brokerage Account(s), including historical account information.</li>' +
                '<li>For Cash Plus Accounts only, your agent will also have access to routing and account numbers. This will give them the ability to make transactions on a third-party website.</li>' +
                '</ol>' +
                'I understand that the authority granted herein is limited to the level of authority specified above. The grantee won’t have authority to take any other action, including, but not limited to:' +
                '<ol>' +
                '<li>Change the address on record or bank information on my accounts.</li>' +
                '<li>Transfer or invest assets between Vanguard accounts that are not owned solely by me, including my revocable trust if I am not the sole trustee.</li>' +
                '<li>Redeem assets by phone, online, or by wire transfer to anyone other than me or my address on record.</li>' +
                '<li>Use checkwriting privileges on my accounts.</li>' +
                '<li>Add, change, or delete beneficiary designations.</li>' +
                '<li>Sign a new account application or otherwise open a new registration on my behalf.</li>' +
                '<li>Sign any tax information or reporting form required by federal or state taxing authorities, including IRS Form W-9 and any substitute IRS Form W-9.</li>' +
                '<li>Request a distribution from a Vanguard Brokerage plan account.</li>' +
                '<li>Appoint another agent to act on my behalf or register on my behalf or change my online access through vanguard.com.</li>' +
                '<li>Consent to and contract with any advice service offered by Vanguard, including, but not limited to, Vanguard Personal Advisor Services®.</li>' +
                '</ol>' +
                'I understand that this Limited Power of Attorney shall be governed and construed in accordance with the laws of the Commonwealth of Pennsylvania, without reference to principles of conflicts of laws, and for such purposes (1) shall be deemed to constitute a power contained in an instrument used in a commercial transaction that authorizes an agency relationship granted exclusively to facilitate the transfer of stock, bonds, and other assets; (2) is durable; (3) may be exercised independently of any other agent I have previously or in the future may appoint or designate unless revoked; (4) is subject to the duties and limitations on liability and disclosure obligations provided by 20 PA.C.S. § 5601.3; and (5) includes, but is not limited to, the power to engage in stock, bond, and other securities transactions as specified by 20 PA.C.S. § 5603.' +
                '<br><br>I agree that neither The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, nor any of their respective affiliates are responsible for monitoring whether my agent is an Investment Adviser (as defined in Section 202(a)(11) of the U.S. Investment Advisers Act) or properly registered as an Investment Adviser with the SEC or state(s). I understand that none of these entities, nor their respective affiliates, perform any services for Investment Advisers to satisfy their custody rule requirements 17 CFR § 275.206(4)-2.' +
                '<br><br>I agree that none of The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, or any of their respective affiliates will be held responsible for my decisions or for the investment recommendations or decisions of my agent(s), and is under no duty whatsoever to question any instructions received from the agent(s) or the suitability of any transactions requested by him/her/them.' +
                '<br><br>I agree to indemnify and hold harmless The Vanguard Group, Inc., Vanguard Fiduciary Trust Company, Vanguard Marketing Corporation, their affiliates, and each of the investment company members of The Vanguard Group and their respective officers, employees, and agents (collectively, Vanguard) from acting on instructions—whether oral, written, or online—reasonably believed by Vanguard to have originated from my agent(s), and from all acts of my agent(s) involving the Vanguard mutual fund accounts or Vanguard Brokerage Accounts covered by this authorization.' +
                '<br><br>In the event of my disability or incapacity, this authorization will remain in full force and effect. This authorization will terminate only when one of the following occurs:<br><br>' +
                '<ul>' +
                '<li><b>Vanguard receives notice of my revocation.</b> I understand that I have the right to revoke this authorization at any time and, if I have named multiple agents, I understand that I must specifically identify the agent(s) whose authority I am revoking. I further understand that each authorization covering the same accounts and the same level of authority (limited or full agents) submitted to Vanguard will revoke any prior authorization only to the extent indicated in such subsequent authorization. Any revocation will not affect any liability resulting from transactions initiated before Vanguard has had a reasonable amount of time to act upon such notice.</li>' +
                '<li><b>Vanguard receives notice of my death.</b> I understand that the authority granted under this authorization terminates at my death. My death will not affect any liability resulting from transactions initiated before Vanguard has had a reasonable amount of time to act upon such notice.</li>' +
                '</ul>' +
                'I understand that Vanguard reserves the right to reject, at any time, instructions that Vanguard believes to be suspicious, fraudulent or otherwise unauthorized, or illegal.' +
                '<br><br>I have read this authorization in its entirety or had it explained to me, and I understand its contents.' +
                '<br><br>If I am signing as trustee, I confirm that I have the authority to appoint one or more agents and to delegate authority under the trust document and/or applicable law.' +
                '<br><br>I understand that this request will be canceled, and no grant of authority will be effected, if all necessary actions by the grantee have not been completed or provided to Vanguard in good order within 14 days after the business day on which my request is deemed submitted.' +
                '<br><br>I understand that clicking <b>agree and next,</b> I am authorizing the grant of limited authority on the designated Vanguard account(s), all under the terms described above.',
        },
        step5: {
            title: 'Review and submit',
            description: '{{userName}} is gaining {{accessLevelAccess}} to this account:',
            note: 'Note:',
            noteDescriptionPartOne:
                ' If {{firstName}} wants to act on behalf of the other owner of your joint account, that owner must also add {{firstName}} to the account in their own ',
            noteDescriptionPartTwo: 'access permissions',
            noteDescriptionPartThree: '.',
        },
        confirmation: {
            bannerMessage: 'Your limited authority request has been submitted.',
            pageTitle: 'Your access request has been\u00A0submitted',
            subTitle: 'What happens next?',
            message1: 'Let {{fullName}} know you’re giving them access to your account.',
            message2:
                '{{fullName}} must e-sign to accept your request on vanguard.com within 14 days, or the request will be canceled.',
            message3:
                'Once {{fullName}} e-signs, they will have access to your account. The changes will be reflected in both your and their',
            message3link: 'access permissions',
            message3period: '.',
            addAnotherPersonButtonText: 'Add another person',
            goToMyAccountsButtonText: 'Go to my account',
            viewOnlyPageTitle: '{{fullName}} now has information only access to your account',
            newUserSubTitle: 'What’s next?',
            newUserMessage1Top: 'Give this unique pin to {{firstName}}',
            newUserMessage1Pin: '9XwC74',
            newUserMessage1ButtonContent: ' Copy pin',
            newUserMessage1PinSubheading:
                'We sent a copy of the pin to your secure message center.',
            newUserMessage1PinBottom: 'This pin is',
            newUserMessage1PinBottomBold: 'case sensitive.',
            newUserMessage2:
                'We will email {{firstName}} a link to accept your request using the pin you give them. The request and pin will expire in 14 days.',
            newUserMessage3: 'Once {{firstName}} accepts, they will have access to your account.',
            newUserMessage3Pt2: 'Both of your',
            newUserMessage3PtBold: 'access permissions',
            newUserMessage3Pt3: 'will reflect the changes.',
            returnToAccessPermissionsButtonText: 'Return to access permissions',
            copyPinButton: 'Copy pin',
        },
        unsuccessfulAttempt: {
            returnLink: 'Return to access permissions',
            pageTitle: 'Too many unsuccessful attempts',
            paragraph: [
                'In order to protect our client’s information, we are stopping any',
                'additional attempts.',
                'Before you try again, check with the person you are trying to add',
                'to ensure the information is correct.',
            ],
        },
    },
    removeAccessFlow: {
        stepperTitles: ['Select user', 'Review and Submit'],
        selectUser: {
            title: 'Whose access would you like to remove?',
            validationMessage: {
                required: 'Please choose a person from the list',
            },
            fullAuthorityNote: {
                title: 'Note: ',
                description:
                    'To remove someone with full authority, call us at 800-345-1344 M‑F, 8 a.m. to 8 p.m., Eastern time. For individuals with speech or hearing limitations, please utilize relay service, video relay service, and/or 711 to call us.',
            },
        },
        review: {
            title: 'Review and submit',
            description: '{{granteeName}} is being removed from these accounts:',
        },
        confirmation: {
            title: '{{granteeName}} was removed',
            returnButtonText: 'Return to access permissions',
        },
        contactUs: 'Contact us',
    },
    technicalDifficulties: {
        pageTitle: 'Give us a call',
        paragraph: ['We are experiencing technical difficulties.'],
        pageContent:
            'For immediate help, contact your {{contactPersonType}} or call 800-345-1344. We’re available Monday through Friday 8&nbsp;a.m. to 8&nbsp;p.m., Eastern time.',
        returnLink: 'Return to profile & account settings',
    },
    ineligibleScenarios: {
        [IneligibleReasons.ZERO_BALANCE]: {
            pageTitle: `You can't give someone access to a zero balance account`,
            pageContent: 'Try again after funding the account or select a different account.',
            buttonText: 'Return to access permissions',
        },
        [IneligibleReasons.WEB_REG_OR_REREG_HOLD]: {
            pageTitle: 'You are ineligible to add someone right now',
            pageContent:
                'For your security, we don’t allow you to give new access when you have set up or reset your online access within the last seven days. Try again later.',
            buttonText: 'Return to access permissions',
        },
        [IneligibleReasons.ADDRESS_HOLD]: {
            pageTitle: 'You are ineligible to add someone right now',
            pageContent:
                'For your security, we don’t allow you to give new access when you have changed your address within the last seven days. Try again later.',
            buttonText: 'Return to access permissions',
        },
        [IneligibleReasons.TRUST_OR_ORGANIZATION_ACCOUNT]: {
            pageTitle: 'Let’s take you to our form',
            pageContent:
                'Giving someone access to an organization or trust account requires our form. Select <b>continue</b> to open the form.',
            buttonText: 'Continue',
            navLinkText: 'Return to access permissions',
        },
        [IneligibleReasons.PLEDGED_ACCOUNT]: {
            pageTitle: 'You can’t give access to a pledged account',
            pageContent: 'Try again with a different account.',
            buttonText: 'Return to access permissions',
        },
        [IneligibleReasons.NONRESIDENT_ALIEN]: {
            pageTitle: 'You are ineligible to add someone',
            pageContent:
                'You are not able to give access to this account because either you or another owner of the account is a nonresident alien.',
            buttonText: 'Return to access permissions',
        },
        [IneligibleReasons.SUBJECT_TO_B_NOTICE]: {
            pageTitle: 'Give us a call',
            pageContent:
                "The taxpayer identification number (TIN) on your Vanguard account doesn’t match IRS records. Call us at 877-662-7447 to resolve before giving access. We're available M-F, 8 a.m. to 8 p.m., ET.",
            buttonText: 'Return to access permissions',
        },
    },
    globalRMSupportContactInfo: {
        id: '-1',
        name: 'Wealth Management support team',
        email: 'vanguard@fs.vanguard.com',
        phone: '800-345-1344',
        phoneExt: '',
    },
    RMInfoTile: {
        workingHours: 'M-F, 8 a.m. to 6 p.m., ET to manage all your account access requests.',
    },
    roleTypes: roleTypes.roles,
    compareAccessLevel: {
        title: 'Compare access levels',
        note: 'Note: The list of permissions per access level is not exhaustive. Full permissions will be displayed in the authorization agreement.',
        fullAuthorityBannerText: [
            'To grant full authority, complete our',
            'agent authorization form',
            '. Select <strong>contact us</strong> for assistance with the form.',
        ],
    },
    contactPersonType: {
        rm: 'Relationship Manager',
        ia: 'Senior Wealth Management Associate',
        default: 'Vanguard representative',
    },
    accessLevels: {
        informationOnly: 'Information only',
        limitedOnly: 'Limited access',
    },
    selectAccessLevelModal: {
        headingText: 'Note: Online transactions are not available for advised accounts',
        body: 'You have selected to give {{accessLevelAccess}} authority to an advised account. For future reference, {{userName}} will need to call Vanguard to transact.',
        primaryButtonText: 'Continue',
        secondaryButtonText: 'Cancel',
    },
    quitModal: {
        headingText: 'You have unsaved changes. Are you sure you want to quit?',
        primaryButtonText: 'Yes',
        secondaryButtonText: 'No',
    },
    selectUserLevelModalDialog: {
        body: 'Giving access to this person requires a form. Select continue to go to the form.',
        primaryButtonText: 'Continue',
        secondaryButtonText: 'Back',
    },
};
