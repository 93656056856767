import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, take } from 'rxjs';

import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { selectAddUserStepperSelectedAccess } from '../../../../store/selectors/grant-revoke-permissions.selector';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { StepsOfAddUserToOneSelectedAccount } from '../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { AddUserStepBaseComponent } from '../add-user-step-base.component';
import { adobeConfigData } from './gaf-access-agreement-step-adobe';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-access-agreement-step',
    templateUrl: './gaf-access-agreement-step.component.html',
    styleUrls: ['./gaf-access-agreement-step.component.scss'],
})
export class GafAccessAgreementStepComponent
    extends AddUserStepBaseComponent
    implements OnInit, OnDestroy
{
    readonly accessTypeSelection = AccessTypeSelection;

    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    selectedAccess$: Observable<AccessTypeSelection> = this.store.select(
        selectAddUserStepperSelectedAccess,
    );

    isInfoOnly: boolean = false;

    isLimitedOnly: boolean = false;

    location: CtaCallUsLinkLocations;

    private subs: Subscription = new Subscription();

    constructor(private store: Store, protected adobeLaunchService: AdobeLaunchService) {
        super(adobeLaunchService);
    }

    ngOnInit(): void {
        this.subs.add(
            this.selectedAccess$.pipe(take(1)).subscribe((accessType) => {
                switch (accessType) {
                    case this.accessTypeSelection.INFORMATION_ONLY:
                        this.adobeLaunchService.pageLoad(
                            AdobePageName.GAF_AGREEMENT_STEP_INFO_ONLY_ACCESS,
                        );
                        this.isInfoOnly = true;
                        this.location = CtaCallUsLinkLocations.ZCI_ACCESS_AGREEMENT_INFO_ONLY;
                        break;
                    case this.accessTypeSelection.LIMITED_ACCESS:
                        this.adobeLaunchService.pageLoad(
                            AdobePageName.GAF_AGREEMENT_STEP_LIMITED_ACCESS,
                        );
                        this.isLimitedOnly = true;
                        this.location = CtaCallUsLinkLocations.ZCI_ACCESS_AGREEMENT_LIMITED;
                        break;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onNextStepLoad(): void {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.REVIEW_AND_SUBMIT);
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
            processStep: AdobeAnalyticsProcessStep.GAF_STEP_AGREE_AND_NEXT,
        });
    }

    onBack(): void {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.SELECT_ACCESS_TYPE);
    }
}
