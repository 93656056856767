export enum AccountTypeCodes {
    INDIVIDUAL = 'INDIVIDUAL',
    JOINT = 'JOINT',
    JOINT_COMMUNITY_PROPERTY = 'JOINT_COMMUNITY_PROPERTY',
    JOINT_COMMUNITY_PROPERTY_WROS = 'JOINT_COMMUNITY_PROPERTY_WROS',
    JOINT_MARITAL_PROPERTY = 'JOINT_MARITAL_PROPERTY',
    JOINT_MARITAL_PROPERTY_WROS = 'JOINT_MARITAL_PROPERTY_WROS',
    JOINT_TENANTS_IN_COMMON = 'JOINT_TENANTS_IN_COMMON',
    JOINT_TENANTS_WROS = 'JOINT_TENANTS_WROS',
    JOINT_TENANTS_ENTIRETY_WROS = 'JOINT_TENANTS_ENTIRETY_WROS',
    TRUST = 'TRUST',
    UNDER_WILL_TRUST = 'UNDER_WILL_TRUST',
    IIG_TRUST = 'IIG_TRUST',
    BANKRUPTCY_TRUST = 'BANKRUPTCY_TRUST',
    LIVING_TRUST = 'LIVING_TRUST',
    GRANTOR_TRUST = 'GRANTOR_TRUST',
    ROLLOVER_IRA = 'ROLLOVER_IRA',
    TRADITIONAL_IRA = 'TRADITIONAL_IRA',
    SIMPLE_IRA = 'SIMPLE_IRA',
    SEP_IRA = 'SEP_IRA',
    SEP_IRA_SINGLE_PARTICIPANT = 'SEP_IRA_SINGLE_PARTICIPANT',
    SEP_IRA_MULTIPLE_PARTICIPANT = 'SEP_IRA_MULTIPLE_PARTICIPANT',
    ROTH_IRA = 'ROTH_IRA',
    INHERITED_IRA = 'INHERITED_IRA',
    INHERITED_ROTH_IRA = 'INHERITED_ROTH_IRA',
    CONVERSION_ROTH_IRA = 'CONVERSION_ROTH_IRA',
    UGMA = 'UGMA',
    UTMA = 'UTMA',
    KEOGH = 'KEOGH',
    AMENDED_MPPP = 'AMENDED_MPPP',
    DEDUCTIBLE_MPPP = 'DEDUCTIBLE_MPPP',
    CONTRIB_MPPP = 'CONTRIB_MPPP',
    NON_DEDUCTIBLE_MPPP = 'NON_DEDUCTIBLE_MPPP',
    DEDUCTIBLE_PSP = 'DEDUCTIBLE_PSP',
    ROLLOVER_PSP = 'ROLLOVER_PSP',
    AMENDED_PSP = 'AMENDED_PSP',
    NON_ERISA_403B7 = 'NON_ERISA_403B7',
    ERISA_403B7 = 'ERISA_403B7',
    EDUCATION_SAVINGS = 'EDUCATION_SAVINGS',
    INDIVIDUAL_401K = 'INDIVIDUAL_401K',
    INDIVIDUAL_401K_SUSPENSE = 'INDIVIDUAL_401K_SUSPENSE',
    ROTH_INDIVIDUAL_401K = 'ROTH_INDIVIDUAL_401K',
    SMALL_MARKET_401K = 'SMALL_MARKET_401K',
    AMENDED_VRP = 'AMENDED_VRP',
    GUST_AMENDED_VRP = 'GUST_AMENDED_VRP',
    POOLED_VRIP = 'POOLED_VRIP',
    VRIP = 'VRIP',
    VANGUARD_BUSINESS_ACCOUNT_SERVICE = 'VANGUARD_BUSINESS_ACCOUNT_SERVICE',
    FIDUCIARY = 'FIDUCIARY',
    CUSTODIAN = 'CUSTODIAN',
    CUSTODIAN_MINOR = 'CUSTODIAN_MINOR',
    ATTORNEY_IN_FACT = 'ATTORNEY_IN_FACT',
    USUFRUCT = 'USUFRUCT',
    GUARDIAN = 'GUARDIAN',
    ESCROW_AGENT = 'ESCROW_AGENT',
    HOSPITAL = 'HOSPITAL',
    SCHOOL_DISTRICT = 'SCHOOL_DISTRICT',
    ENDOWMENT = 'ENDOWMENT',
    DEFINED_BENEFIT_PENSION_PLAN = 'DEFINED_BENEFIT_PENSION_PLAN',
    ESTATE = 'ESTATE',
    EXECUTOR_ESTATE = 'EXECUTOR_ESTATE',
    LIFE_TENTANT_ESTATE = 'LIFE_TENTANT_ESTATE',
    BANK_NOMINEE = 'BANK_NOMINEE',
    PARTNERSHIP = 'PARTNERSHIP',
    IIG_CORPORATION = 'IIG_CORPORATION',
    CORPORATION = 'CORPORATION',
    CORPORATION_BANKRUPTCY = 'CORPORATION_BANKRUPTCY',
    EXEMPT_UNICORPORATED_ORGANIZATION = 'EXEMPT_UNICORPORATED_ORGANIZATION',
    FOUNDATION = 'FOUNDATION',
    NONEXEMPT_UNINCOPORATED_ORGANIZATOIN = 'NONEXEMPT_UNINCOPORATED_ORGANIZATOIN',
    BROKER_DEALER = 'BROKER_DEALER',
    PROFESSIONAL_CORPORATION_ASSOCIATION = 'PROFESSIONAL_CORPORATION_ASSOCIATION',
    PROFESSIONAL_CORPORATION = 'PROFESSIONAL_CORPORATION',
    PROFESSIONAL_ASSOCIATION = 'PROFESSIONAL_ASSOCIATION',
    SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
    RETIREMENT_TRUST_PSP = 'RETIREMENT_TRUST_PSP',
    RETIREMENT_TRUST_401K = 'RETIREMENT_TRUST_401K',
    RETIREMENT_TRUST_403B7 = 'RETIREMENT_TRUST_403B7',
    RETIREMENT_TRUST_457 = 'RETIREMENT_TRUST_457',
    RETIREMENT_TRUST_PENSION_PLAN = 'RETIREMENT_TRUST_PENSION_PLAN',
    RETIREMENT_TRUST_MPPP = 'RETIREMENT_TRUST_MPPP',
    RETIREMENT_TRUST_401K_PSP = 'RETIREMENT_TRUST_401K_PSP',
    RETIREMENT_TRUST_DEFERRED_COMPENSATION = 'RETIREMENT_TRUST_DEFERRED_COMPENSATION',
    AUDIT_97 = 'AUDIT_97',
    PARTICIPANT = 'PARTICIPANT',
    OTHER = 'OTHER',
    SUNGARD = 'SUNGARD',
    OUTSIDE_HOLDINGS = 'OUTSIDE_HOLDINGS',
    OUTSIDE_INVESTMENT = 'OUTSIDE_INVESTMENT',
    GENERAL_PARTNER = 'GENERAL_PARTNER',
    LIMITED_PARTNER = 'LIMITED_PARTNER',
    VANGUARD_529_PLAN = 'VANGUARD_529_PLAN',
    UNKNOWN = 'UNKNOWN',
    AUDIT_98 = 'AUDIT_98',
    CONTRIB_PSP = 'CONTRIB_PSP',
    NON_DEDUCTIBLE_PSP = 'NON_DEDUCTIBLE_PSP',
    RETIREMENT_403B_PLAN = 'RETIREMENT_403B_PLAN',
    ROLLOVER_MPPP = 'ROLLOVER_MPPP',
}

export const allowed401kAccountTypes = [AccountTypeCodes.PARTICIPANT, AccountTypeCodes.UNKNOWN];

export const trustAccountTypes = {
    [AccountTypeCodes.BANKRUPTCY_TRUST]: AccountTypeCodes.BANKRUPTCY_TRUST,
    [AccountTypeCodes.FIDUCIARY]: AccountTypeCodes.FIDUCIARY,
    [AccountTypeCodes.GRANTOR_TRUST]: AccountTypeCodes.GRANTOR_TRUST,
    [AccountTypeCodes.IIG_TRUST]: AccountTypeCodes.IIG_TRUST,
    [AccountTypeCodes.LIVING_TRUST]: AccountTypeCodes.LIVING_TRUST,
    [AccountTypeCodes.RETIREMENT_TRUST_401K]: AccountTypeCodes.RETIREMENT_TRUST_401K,
    [AccountTypeCodes.RETIREMENT_TRUST_401K_PSP]: AccountTypeCodes.RETIREMENT_TRUST_401K_PSP,
    [AccountTypeCodes.RETIREMENT_TRUST_403B7]: AccountTypeCodes.RETIREMENT_TRUST_403B7,
    [AccountTypeCodes.RETIREMENT_TRUST_457]: AccountTypeCodes.RETIREMENT_TRUST_457,
    [AccountTypeCodes.RETIREMENT_TRUST_DEFERRED_COMPENSATION]:
        AccountTypeCodes.RETIREMENT_TRUST_DEFERRED_COMPENSATION,
    [AccountTypeCodes.RETIREMENT_TRUST_MPPP]: AccountTypeCodes.RETIREMENT_TRUST_MPPP,
    [AccountTypeCodes.RETIREMENT_TRUST_PENSION_PLAN]:
        AccountTypeCodes.RETIREMENT_TRUST_PENSION_PLAN,
    [AccountTypeCodes.RETIREMENT_TRUST_PSP]: AccountTypeCodes.RETIREMENT_TRUST_PSP,
    [AccountTypeCodes.UNDER_WILL_TRUST]: AccountTypeCodes.UNDER_WILL_TRUST,
};

export const organizationAccountTypes = {
    [AccountTypeCodes.BANK_NOMINEE]: AccountTypeCodes.BANK_NOMINEE,
    [AccountTypeCodes.BROKER_DEALER]: AccountTypeCodes.BROKER_DEALER,
    [AccountTypeCodes.CORPORATION]: AccountTypeCodes.CORPORATION,
    [AccountTypeCodes.CORPORATION_BANKRUPTCY]: AccountTypeCodes.CORPORATION_BANKRUPTCY,
    [AccountTypeCodes.ENDOWMENT]: AccountTypeCodes.ENDOWMENT,
    [AccountTypeCodes.EXEMPT_UNICORPORATED_ORGANIZATION]:
        AccountTypeCodes.EXEMPT_UNICORPORATED_ORGANIZATION,
    [AccountTypeCodes.FOUNDATION]: AccountTypeCodes.FOUNDATION,
    [AccountTypeCodes.GENERAL_PARTNER]: AccountTypeCodes.GENERAL_PARTNER,
    [AccountTypeCodes.HOSPITAL]: AccountTypeCodes.HOSPITAL,
    [AccountTypeCodes.IIG_CORPORATION]: AccountTypeCodes.IIG_CORPORATION,
    [AccountTypeCodes.LIMITED_PARTNER]: AccountTypeCodes.LIMITED_PARTNER,
    [AccountTypeCodes.NONEXEMPT_UNINCOPORATED_ORGANIZATOIN]:
        AccountTypeCodes.NONEXEMPT_UNINCOPORATED_ORGANIZATOIN,
    [AccountTypeCodes.PARTNERSHIP]: AccountTypeCodes.PARTNERSHIP,
    [AccountTypeCodes.POOLED_VRIP]: AccountTypeCodes.POOLED_VRIP,
    [AccountTypeCodes.PROFESSIONAL_ASSOCIATION]: AccountTypeCodes.PROFESSIONAL_ASSOCIATION,
    [AccountTypeCodes.PROFESSIONAL_CORPORATION]: AccountTypeCodes.PROFESSIONAL_CORPORATION,
    [AccountTypeCodes.PROFESSIONAL_CORPORATION_ASSOCIATION]:
        AccountTypeCodes.PROFESSIONAL_CORPORATION_ASSOCIATION,
    [AccountTypeCodes.SCHOOL_DISTRICT]: AccountTypeCodes.SCHOOL_DISTRICT,
    [AccountTypeCodes.SMALL_MARKET_401K]: AccountTypeCodes.SMALL_MARKET_401K,
    [AccountTypeCodes.SOLE_PROPRIETORSHIP]: AccountTypeCodes.SOLE_PROPRIETORSHIP,
    [AccountTypeCodes.VANGUARD_529_PLAN]: AccountTypeCodes.VANGUARD_529_PLAN,
    [AccountTypeCodes.VANGUARD_BUSINESS_ACCOUNT_SERVICE]:
        AccountTypeCodes.VANGUARD_BUSINESS_ACCOUNT_SERVICE,
};

export const individualOrOrganizationAccountTypes = {
    [AccountTypeCodes.ESCROW_AGENT]: AccountTypeCodes.ESCROW_AGENT,
    [AccountTypeCodes.ESTATE]: AccountTypeCodes.ESTATE,
    [AccountTypeCodes.EXECUTOR_ESTATE]: AccountTypeCodes.EXECUTOR_ESTATE,
    [AccountTypeCodes.GUARDIAN]: AccountTypeCodes.GUARDIAN,
};

export const otherIneligibleAccountTypes = {
    [AccountTypeCodes.AMENDED_MPPP]: AccountTypeCodes.AMENDED_MPPP,
    [AccountTypeCodes.AMENDED_PSP]: AccountTypeCodes.AMENDED_PSP,
    [AccountTypeCodes.AMENDED_VRP]: AccountTypeCodes.AMENDED_VRP,
    [AccountTypeCodes.ATTORNEY_IN_FACT]: AccountTypeCodes.ATTORNEY_IN_FACT,
    [AccountTypeCodes.AUDIT_97]: AccountTypeCodes.AUDIT_97,
    [AccountTypeCodes.AUDIT_98]: AccountTypeCodes.AUDIT_98,
    [AccountTypeCodes.CONTRIB_MPPP]: AccountTypeCodes.CONTRIB_MPPP,
    [AccountTypeCodes.DEDUCTIBLE_MPPP]: AccountTypeCodes.DEDUCTIBLE_MPPP,
    [AccountTypeCodes.DEDUCTIBLE_PSP]: AccountTypeCodes.DEDUCTIBLE_PSP,
    [AccountTypeCodes.CONTRIB_PSP]: AccountTypeCodes.CONTRIB_PSP,
    [AccountTypeCodes.DEFINED_BENEFIT_PENSION_PLAN]: AccountTypeCodes.DEFINED_BENEFIT_PENSION_PLAN,
    [AccountTypeCodes.ERISA_403B7]: AccountTypeCodes.ERISA_403B7,
    [AccountTypeCodes.GUST_AMENDED_VRP]: AccountTypeCodes.GUST_AMENDED_VRP,
    [AccountTypeCodes.KEOGH]: AccountTypeCodes.KEOGH,
    [AccountTypeCodes.NON_DEDUCTIBLE_MPPP]: AccountTypeCodes.NON_DEDUCTIBLE_MPPP,
    [AccountTypeCodes.NON_DEDUCTIBLE_PSP]: AccountTypeCodes.NON_DEDUCTIBLE_PSP,
    [AccountTypeCodes.NON_ERISA_403B7]: AccountTypeCodes.NON_ERISA_403B7,
    [AccountTypeCodes.OUTSIDE_HOLDINGS]: AccountTypeCodes.OUTSIDE_HOLDINGS,
    [AccountTypeCodes.OUTSIDE_INVESTMENT]: AccountTypeCodes.OUTSIDE_INVESTMENT,
    [AccountTypeCodes.PARTICIPANT]: AccountTypeCodes.PARTICIPANT,
    [AccountTypeCodes.RETIREMENT_403B_PLAN]: AccountTypeCodes.RETIREMENT_403B_PLAN,
    [AccountTypeCodes.ROLLOVER_MPPP]: AccountTypeCodes.ROLLOVER_MPPP,
    [AccountTypeCodes.ROLLOVER_PSP]: AccountTypeCodes.ROLLOVER_PSP,
    [AccountTypeCodes.UNKNOWN]: AccountTypeCodes.UNKNOWN,
    [AccountTypeCodes.USUFRUCT]: AccountTypeCodes.USUFRUCT,
    [AccountTypeCodes.VRIP]: AccountTypeCodes.VRIP,
    [AccountTypeCodes.OTHER]: AccountTypeCodes.OTHER,
};
