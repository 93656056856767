<zci-step-wrapper
    [title]="content.addUserFlow.step2.addSomeoneElseForm.title"
    [nextButtonLabelText]="content.stepper.nextButtonLabelText"
    [titleMargin]="errorState === ErrorState.NO_ERROR ? '1_5x' : '3x'"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickQuit)="onQuit(location)"
    (clickContactUs)="onContactUs(location)"
    [totalSteps]="content.addUserFlow.stepperTitles"
    [currentStep]="currentStep">
    <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
    <c11n-modal-dialog
        [variant]="'basic'"
        [primaryButtonLabel]="modalButtons[0]"
        [secondaryButtonLabel]="modalButtons[1]"
        [headingText]="errorContent"
        [hideBody]="true"
        (primaryClick)="onContinueModal()"
        [maxWidth]="660"
        [useContentWidth]="false"
        #selectUserLevelModalDialog
        data-cy="error-modal">
    </c11n-modal-dialog>
    <form [formGroup]="personalInfoForm" class="form">
        <c11n-banner
            class="c11n-space-stack-3x"
            [size]="errorState === ErrorState.USER_NOT_FOUND ? 'medium' : 'small'"
            variant="error"
            [headingText]="content.addUserFlow.step2.validationMessage.userNotFound[0]"
            [hasCloseControl]="false"
            [hasDetails]="false"
            *ngIf="isBannerShown"
            data-cy="client-search-error-banner">
            <div class="c11n-text-md--crop">
                <span [innerHTML]="errorContent"></span>
            </div>
        </c11n-banner>
        <fieldset>
            <legend class="c11n-space-stack-4x">
                {{ content.addUserFlow.step2.addSomeoneElseForm.description }}
            </legend>
            <div class="c11n-space-stack-5x">
                <label c11n-label [for]="firstNameControlName" size="medium">{{
                    content.addUserFlow.step2.addSomeoneElseForm.firstNameLabel
                }}</label>
                <c11n-input
                    size="medium"
                    [hasError]="firstNameFormControl.invalid && firstNameFormControl.touched">
                    <input
                        c11nInput
                        type="text"
                        id="firstName"
                        name="firstName"
                        [formControlName]="firstNameControlName"
                        aria-describedby="firstName-hint" />
                </c11n-input>
                <c11n-hint-error
                    [hasError]="
                        firstNameFormControl.hasError('required') && firstNameFormControl.touched
                    "
                    [hintText]="content.addUserFlow.step2.addSomeoneElseForm.firstNameHint"
                    [errorText]="
                        content.addUserFlow.step2.addSomeoneElseForm.firstNameRequiredError
                    "
                    size="medium"
                    hintErrorId="firstName-hint"></c11n-hint-error>
            </div>

            <div class="c11n-space-stack-5x">
                <label c11n-label [for]="lastNameControlName" size="medium">{{
                    content.addUserFlow.step2.addSomeoneElseForm.lastNameLabel
                }}</label>
                <c11n-input
                    size="medium"
                    [hasError]="lastNameFormControl.invalid && lastNameFormControl.touched">
                    <input
                        c11nInput
                        type="text"
                        id="lastName"
                        name="lastName"
                        [formControlName]="lastNameControlName"
                        aria-describedby="lastName-hint" />
                </c11n-input>
                <c11n-hint-error
                    [hasError]="
                        lastNameFormControl.hasError('required') && lastNameFormControl.touched
                    "
                    [hintText]="content.addUserFlow.step2.addSomeoneElseForm.lastNameHint"
                    [errorText]="content.addUserFlow.step2.addSomeoneElseForm.lastNameRequiredError"
                    size="medium"
                    hintErrorId="lastName-hint"></c11n-hint-error>
            </div>

            <div class="c11n-space-stack-5x date-of-birth-field">
                <label c11n-label [for]="dateOfBirthControlName" size="medium">{{
                    content.addUserFlow.step2.addSomeoneElseForm.dateOfBirthLabel
                }}</label>
                <c11n-input
                    size="medium"
                    [hasError]="dateOfBirthFormControl.invalid && dateOfBirthFormControl.touched">
                    <input
                        c11nInput
                        type="text"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        [formControlName]="dateOfBirthControlName"
                        [imask]="InputMask.DATE_MDY_SLASH"
                        (blur)="trackFormattingErrorOnDobField()"
                        aria-describedby="dateOfBirth-hint" />
                </c11n-input>
                <c11n-hint-error
                    [hintText]="content.addUserFlow.step2.addSomeoneElseForm.dateOfBirthHint"
                    size="medium"
                    hintErrorId="dateOfBirth-hint"
                    [hasError]="dateOfBirthFormControl.touched && dateOfBirthFormControl.invalid"
                    [errorText]="getDateOfBirthErrorMessage">
                </c11n-hint-error>
            </div>

            <div class="c11n-space-stack-5x acc-number-field">
                <label c11n-label [for]="accNumberControlName" size="medium">
                    <c11n-tooltip
                        [labelText]="
                            content.addUserFlow.step2.addSomeoneElseForm.accountNumberLabel
                        "
                        direction="right">
                        <span
                            c11nTooltipContent
                            [innerHTML]="
                                content.addUserFlow.step2.addSomeoneElseForm.accountNumberTooltip
                            "></span>
                    </c11n-tooltip>
                </label>
                <c11n-input
                    size="medium"
                    [hasError]="accNumberFormControl.invalid && accNumberFormControl.touched">
                    <input
                        c11nInput
                        type="text"
                        id="accNumber"
                        name="accNumber"
                        [formControlName]="accNumberControlName"
                        (blur)="trackFormattingErrorOnAccountField()"
                        aria-describedby="accNumber-hint" />
                </c11n-input>
                <c11n-hint-error
                    [hintText]="content.addUserFlow.step2.addSomeoneElseForm.accountNumberHint"
                    size="medium"
                    hintErrorId="accNumber-hint"
                    [hasError]="accNumberFormControl.touched && accNumberFormControl.invalid"
                    [errorText]="getAccNumberErrorMessage"></c11n-hint-error>
            </div>
        </fieldset>
    </form>
</zci-step-wrapper>
