import { Sort, SortDirection } from './sort.interfaces';

function convertToLowerCase(value: string | number): string | number {
    if (typeof value === 'string') {
        return value.toLowerCase();
    }

    return value;
}

function getPositionBySortDirection({
    current,
    next,
    sortDir,
}: {
    current: string | number;
    next: string | number;
    sortDir: SortDirection;
}): 1 | -1 | 0 {
    const isASC: boolean = sortDir === SortDirection.ASC;
    const currentAsLowerCase: string | number = convertToLowerCase(current);
    const nextAsLowerCase: string | number = convertToLowerCase(next);

    if (currentAsLowerCase > nextAsLowerCase) {
        return isASC ? 1 : -1;
    }

    if (currentAsLowerCase < nextAsLowerCase) {
        return isASC ? -1 : 1;
    }

    return 0;
}

function ascDescSortingBySortEvent<T extends { [key: string]: any }>(
    dataSource: T[],
    sortEvent: Sort,
): T[] {
    const { sortDir, dataKey } = sortEvent;

    return dataSource.sort((element1: T, element2: T) =>
        getPositionBySortDirection({
            current: element1[dataKey],
            next: element2[dataKey],
            sortDir,
        }),
    );
}

export default {
    ascDescSortingBySortEvent,
    getPositionBySortDirection,
};
