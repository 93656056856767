import { Component, Input } from '@angular/core';
import { NavOverFlowWrapperAction } from 'src/app/enums/nav-overflow-wrapper-action.enum';
import { ContentUtil } from 'src/app/utils/content/content.util';

import { NavOverflowExtendedData } from '../../interfaces/nav-overflow-extended-data.interface';

export interface ActionNavOverflowMenuWrapperComponentProps {
    navOverflowExtendedData: NavOverflowExtendedData;
    navAction: NavOverFlowWrapperAction;
}

@Component({
    selector: 'zci-action-menu-wrapper',
    templateUrl: './action-menu-wrapper.component.html',
})
export class ActionMenuWrapperComponent implements ActionNavOverflowMenuWrapperComponentProps {
    @Input() navOverflowExtendedData: NavOverflowExtendedData;

    @Input() navAction: NavOverFlowWrapperAction;

    NavOverFlowWrapperAction = NavOverFlowWrapperAction;

    content = ContentUtil.content;
}
