export const modifiedGranteeContent = {
    frmcGranteeFlow: {
        navQuit: 'Quit',
        frmcUniquePin: {
            heading: 'Enter your unique pin',
            subheading: 'Contact the person giving you access if they have not given you the unique pin.',
            frmfUniquePin: {
                labelText: 'Pin',
                hintText: 'Your pin is case sensitive.',
                errEnterPin: 'Please enter the pin. Your pin is case-sensitive.',
                errEnterValidPin: 'Please enter a valid pin. Your pin is case-sensitive.'
            },
            navContinue: 'Continue'
        },
        frmcRegulatoryQuestions: {
            heading: 'Please answer the following questions',
            body: 'These questions are required by industry regulations.',
            frmfYouOrSpouseVgEmployee: {
                heading: 'Are you, or is your spouse, a Vanguard employee?',
                errPleaseChooseAResponse: 'Please choose a response',
                frmfYes: 'Yes',
                frmfNo: 'No'
            },
            frmfMemberOfStockXchange: {
                heading: 'Are you associated with a member of a stock exchange, FINRA member firm, or municipal securities dealer?',
                errPleaseChooseAResponse: 'Please choose a response',
                frmfYes: 'Yes',
                frmfNo: 'No'
            },
            frmfMemberPerSecRule144: {
                heading: 'Are you a control person or an affiliate of a public company as defined in Security and Exchange Commission (SEC) Rule 144?',
                errPleaseChooseAResponse: 'Please choose a response',
                frmfYes: 'Yes',
                frmfNo: 'No'
            },
            navContinue: 'Continue',
            navBack: 'Back',
            navFaq: 'Frequently asked questions'
        },
        scssInformationOnlyAccess: {
            heading: 'You now have information only access to this account',
            subheading: 'You will now see this account on your dashboard when you log in.',
            navViewMyDashboard: 'View my dashboard'
        },
        scssLimitedAuthority: {
            heading: 'You now have limited authority to this account',
            subheading: 'You will now see this account on your dashboard.',
            navViewMyDashboard: 'View my dashboard'
        },
        errTooManyUnsuccessfulAttempts: {
            heading: 'Too many unsuccessful attempts',
            text1: "<p>In order to protect our client's information, we are stopping any additional attempts.</p>\n" +
                '<p>In order to gain access to this account, the person granting it must repeat the authorization process.</p>\n',
            navReturnToAccessPermissions: 'Return to access permissions'
        },
        errForeignAddressOnlineProcess: {
            heading: 'Because you have a foreign address, this process can’t be completed online',
            text1: '<p>You need to contact the person giving you access and ask them to complete an Agent Authorization form, then send it to Vanguard. Along with the form, you’ll need to provide some additional information to complete the process.</p>\n'
        },
        wrnControlAssociatedPersonDialog: {
            heading: 'We are taking you to our form',
            text1: 'You selected that you are a control and/or associated person. For regulatory reasons, you can only be given access through our form.',
            navContinue: 'Continue',
            navBack: 'Back'
        }
    },
    infmForeignAddress: {
        heading: 'Because you have a foreign address, you can’t be given access online. Select continue to go to our form.',
        navContinue: 'Continue',
        navBack: 'Back'
    },
    errProcessCanNotCompleteOnline: {
        heading: 'This process can’t be completed online',
        text1: '<p>You need to contact the person giving you access and ask them to complete an Agent Authorization form, then send it to Vanguard. Along with the form, you’ll need to provide some additional information to complete the process.</p>\n',
        navGoToMyDashboard: 'Go to my dashboard'
    },
    errExperiencingTechnicalDifficulties: {
        heading: 'We’re experiencing technical difficulties',
        text1: '<p>We apologize for the inconvenience. Please try again later.</p>\n',
        navGoToMyDashboard: 'Go to my dashboard'
    },
    errPinExpired: {
        heading: 'The pin that you entered has expired',
        text1: '<p>Contact the person giving you access and ask them to request access for you again to create a new pin.</p>\n',
        navGoToMyDashboard: 'Go to my dashboard'
    },
    trmAuthorizationAgreement: {
        heading: 'Authorization agreement',
        text1: "<p><b>IMPORTANT:</b> You are accepting the grant of authority to act on another person's (the &quot;grantor&quot;) account(s). By signing below, you accept the grant of limited authority to act on the Vanguard mutual fund and Vanguard Brokerage Account(s) the grantor has designated, in accordance with the terms below and any additional terms that Vanguard requires in order to effect the authorization.</p>\n" +
            '<p><b>Grantee/agent acknowledgment</b></p>\n' +
            "<p>I agree to notify Vanguard promptly if there is any change to my affiliation status (i.e., associated person or control person) during the time I am acting as grantee under the grantor's authorization.</p>\n" +
            '<p>I acknowledge that the authority I am accepting from the grantor will result in my authority to perform the following transactions, by mail, by phone, and online, on the account(s) the grantor has designated, subject to Vanguard policies and procedures:</p>\n' +
            '<ol>\n' +
            '<li>Purchase, exchange, and sell Vanguard mutual funds within the grantor’s Vanguard accounts.</li>\n' +
            '<li>Purchase, sell (including short sales), and trade in stocks, bonds, and other securities and contracts relating to the same.</li>\n' +
            '<li>Engage in margin and/or option trading if established by the account owner(s).</li>\n' +
            "<li>Transfer or invest assets between Vanguard accounts owned solely by the grantor, including the grantor's revocable trust if the grantor is the sole trustee.</li>\n" +
            "<li>Request that distributions from the grantor's accounts be payable to the grantor and sent to the grantor's bank account or address on record.</li>\n" +
            "<li>Obtain information on the grantor's Vanguard mutual fund and Vanguard Brokerage Account(s), including historical account information.</li>\n" +
            '</ol>\n' +
            '<p>I understand that the authority granted herein is limited to the level of authority specified above. As a grantee, I won’t have authority to take any other action, including, but not limited to:&nbsp;</p>\n' +
            '<ol>\n' +
            "<li>Change the address on record or bank information on the grantor's accounts.</li>\n" +
            '<li>Transfer or invest assets into a different registration.</li>\n' +
            "<li>Redeem assets by phone, online, or by wire transfer to anyone other than to the grantor or the grantor's address on record.</li>\n" +
            '<li>Use checkwriting privileges on the accounts.</li>\n' +
            '<li>Add, change, or delete beneficiary designations.</li>\n' +
            "<li>Sign a new account application or otherwise open a new registration on the grantor's behalf.</li>\n" +
            '<li>Sign any tax information or reporting form required by federal or state taxing authorities, including IRS Form W-9 and any substitute IRS Form W-9.</li>\n' +
            '<li>Request a distribution from a Vanguard Brokerage plan account.</li>\n' +
            "<li>Appoint another agent to act on the grantor's behalf or register for web access on the grantor’s behalf or change the grantor's online access through vanguard.com.</li>\n" +
            '<li>Consent to and contract with any advice service offered by Vanguard, including, but not limited to, Vanguard Personal Advisor Services®.</li>\n' +
            '</ol>\n' +
            '<p class="c11n-text">The following statement <a href="url">applies</a> to agents who may engage in option trading on behalf of the grantor:</p>\n' +
            '<p>I understand the investment objectives and trading plan of the registered account owner(s) and will only use trading strategies that are consistent with these objectives and plans. I acknowledge that I have received from the registered account owner(s) (or I already possess) copies of the Vanguard Brokerage Account Application and Vanguard Brokerage Account Agreement (Agreement), and I have read and understand them. I also acknowledge that if the registered account owner(s) are approved for options trading, they will be sent the booklet, Characteristics and Risks of Standardized Options. I agree to read the booklet prior to conducting any trades in the account of the registered account owner(s). I also accept all the terms and conditions of the Option Agreement.</p>\n' +
            '<p>I acknowledge receiving a copy of the Agreement, which contains a predispute arbitration clause highlighted on page 16. By signing below, I agree to be bound by the terms of the Agreement.</p>\n' +
            "<p>I, the agent, have read this power of attorney and am the person identified as the agent for the grantor. I hereby acknowledge that, in the absence of a specific provision to the contrary in the power of attorney or in 20 PA.C.S. Ch. 56, when I act as agent, I shall act in accordance with the principal's reasonable expectations to the extent actually known by me and, otherwise, in the principal's best interest, act in good faith and act only within the scope of authority granted to me by the principal in the power of attorney.</p>\n" +
            '<p>I understand that I may resign my position as agent at any time by notifying the principal and Vanguard in writing.</p>\n' +
            '<p>I understand that clicking <b>agree and submit</b>, I am accepting the grant of limited authority to act on the grantor’s designated Vanguard accounts, all under the terms described above.</p>\n',
        navAgreeSubmit: 'Agree and submit',
        navAgreeContinue: 'Agree and continue'
    },
    lstOnlineAccessRequired: {
        heading: 'Online access is required to continue',
        subheading: 'Follow these steps to accept your access request. Already have online access? Skip to step 2.',
        navGetStarted: 'Get started',
        listiRegister: {
            heading: 'Register for online access',
            text1: 'You’ll need your account number and Social Security or Employer Identification Number.'
        },
        listiSelectHaveOneVgLogin: {
            heading: 'Return to the email and select “I have a vanguard.com login”',
            text1: 'Once you receive confirmation of your new online access, return to the email you started with. This time, you can select that you have a vanguard.com login.'
        },
        listiLoginAndAccept: 'Log in and accept your access request',
        lblEstimatedTimeToComplete: 'Estimated time to complete',
        lblMinutes: '${minutes} minutes'
    },
    lblAcceptInformationOnlyAccess: 'Accept information only access',
    lblAcceptLimitedAccess: 'Accept limited access',
    lblGranteeFlowProgressIndicatorSteps: {
        labelText: 'Step ${currentStep} of ${totalSteps}',
        lblUniquePin: 'Unique pin',
        lblRegulatoryQuestions: 'Regulatory questions',
        lblAuthorizationAgreement: 'Authorization agreement'
    },
    faqgrFrequentlyAskedQuestions: {
        heading: 'Frequently asked questions',
        navClose: 'Close',
        faqWhatIsAssociatedPerson: {
            heading: 'What is an associated person?',
            text1: '<p>An associated person is anyone affiliated with a member of a stock exchange, FINRA member firm, or municipal securities dealer. This includes:</p>\n' +
                '<ul class="c11n-list">\n' +
                '<li>A partner, officer, director, or branch manager of a member (or any person with a similar status or role).</li>\n' +
                '<li>A person directly/indirectly controlling, controlled by, or under common control of a member.</li>\n' +
                '<li>An employee or agent of a member.</li>\n' +
                '</ul>\n'
        },
        faqWhatIsControlPerson: {
            heading: 'What is a control person or affiliate?',
            text1: '<p>A control person or an affiliate is a person who directly or indirectly controls, is controlled by, or is under common control of a publicly traded company.</p>\n' +
                '<p>This includes but is not limited to: officers and members of the board of directors, 10% or more shareholders, and policymaking executives of a publicly traded company.</p>\n' +
                '<p>Additionally, this includes:</p>\n' +
                '<ul class="c11n-list">\n' +
                '<li>Anyone living in the same household as the control person.</li>\n' +
                '<li>Any trust or estate where a control person, relative, or spouse collectively own 10% or more of the beneficial interest or serve as trustee, executor, or in any similar capacity.&nbsp;</li>\n' +
                '<li>Any corporation or other organization (other than the issuer) where a control person, relative, or spouse is the beneficial owner collectively of 10% or more of any class of equity securities or 10% or more of the equity interest.</li>\n' +
                '</ul>\n' +
                '<p>For more information, see SEC Rule 144A.</p>\n'
        }
    }
}