import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { AnalyticsCtaType, CtaCallUsLinkLocations, CtaNames } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessSideStep, AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType
} from '../../../../services/adobe-launch/adobe-launch.process';

export const adobeConfigData = {
    ngOnInit: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_SELECT_ACCESS_LEVEL],
        },
    ],

    navigateToForm: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    ctaName: CtaNames.FULL_AUTHORITY,
                    ctaType: AnalyticsCtaType.LINK,
                    location: CtaCallUsLinkLocations.ZCI_SELECT_ACCESS_STEP,
                },
            ],
        },

    ],

    openCashPlusModalDialog: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_SIDE_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processSideStep: AdobeAnalyticsProcessSideStep.GAF_CASH_PLUS_MODAL,
                },
            ],
        },

    ],

    selectInfoOnlyAndNavigate: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_SELECT_ACCESS_INFO,
                },
            ],
        },
    ],

    selectLimitedAndNavigate: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_SELECT_ACCESS_LIMITED,
                },
            ],
        },
    ],

};
