import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AddSomeoneElseDto } from '../../../common/dtos/add-someone-else.dto';

@Injectable({
    providedIn: 'root',
})
export class AddSomeoneElseService {
    constructor(private readonly httpClient: HttpClient) {}

    gatherPinAndDto(addSomeoneElseDto: AddSomeoneElseDto): Observable<{ uniquePin: string }> {
        return this.httpClient.post<{ uniquePin: string }>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.ADD_SOMEONE_ELSE}`,
            addSomeoneElseDto,
        );
    }
}
