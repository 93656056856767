import { Component, Input, OnInit } from '@angular/core';
import {
    StepIndicatorStepAlign,
    StepIndicatorStepLabelDisplay,
} from '@vg-constellation/angular-16/step-indicator';
import { Observable } from 'rxjs';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer/breakpoint-observer.service';

@Component({
    selector: 'zci-responsive-step-indicator',
    templateUrl: './responsive-step-indicator.component.html',
})
export class ResponsiveStepIndicatorComponent implements OnInit {
    @Input() hideStepNumbers: boolean = false;

    @Input() stepAlign: StepIndicatorStepAlign = 'center';

    @Input() stepLabelDisplay: StepIndicatorStepLabelDisplay = 'none';

    @Input() stepLabels: string[];

    @Input() currentStep: number = 1;

    isMobileView$: Observable<boolean>;

    constructor(private readonly breakpointObserver: BreakpointObserverService) {}

    ngOnInit(): void {
        this.isMobileView$ = this.breakpointObserver.isMobileView$;
    }
}
