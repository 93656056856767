import { UserTileInputDataProps } from 'src/app/components/tiles/user-tile/user-tile.component';
import { AccountPermissionDTO } from 'src/common/dtos/account-permission.dto';
import { GranteeDTO } from 'src/common/dtos/grantee.dto';
import { OwnAccountDTO } from 'src/common/dtos/own-account.dto';
import { UserDetailsDto } from 'src/common/dtos/user-details.dto';
import { ClientType } from 'src/common/enums/client-type.enum';

import { RoleType } from '../../../common/constants/role-type.enum';
import { RegisteredRoleDTO } from '../../../common/dtos/account-roles.dto';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import { ContentUtil } from '../content/content.util';
import { ObservableAccountTileInputDataProps } from '../sort/sort.service';

const getUsersFromAccountData = (accountData: OwnAccountDTO[]): GranteeDTO[][] => {
    return accountData?.map((a) => a.grantees);
};

const extractUsersFromAccountData = (accountData: OwnAccountDTO[]): GranteeDTO[] => {
    return [].concat.apply([], getUsersFromAccountData(accountData));
};

const constructUserName = (detail: UserDetailsDto) => {
    return [detail.firstName, detail.middleName, detail.lastName].reduce((acc, nxt) => {
        return [acc, nxt].join(' ');
    });
};

const getContactPersonType = (type: ClientType) => {
    switch (+type) {
        case ClientType.MANAGED:
            return ContentUtil.content.contactPersonType.ia;
        case ClientType.INDIVIDUAL:
            return ContentUtil.content.contactPersonType.rm;
        default:
            return ContentUtil.content.contactPersonType.default;
    }
};

const getAccountsByClientPoid = (accounts: OwnAccountDTO[], clientPoid: string) => {
    return accounts.filter(
        (acc) =>
            acc.grantees.filter((user) => user.clientPoid === clientPoid).length ||
            acc.owners.filter((user) => user.clientPoid === clientPoid).length,
    );
};

const getUserByClientPoid = (
    grantees: GranteeDTO[],
    coOwners: RegisteredRoleDTO[],
    clientPoid: string,
) => {
    return (
        grantees.find((grantee) => grantee.clientPoid === clientPoid) ||
        coOwners.find((coOwner) => coOwner.clientPoid === clientPoid)
    );
};

const getTransformedObservableCollection = (
    obsAccounts: AccountPermissionDTO[],
): ObservableAccountTileInputDataProps[] => {
    if (!obsAccounts) return [];
    return obsAccounts.map((data) => {
        return {
            accName: data.accountName,
            accType: data.accountType,
            roleType: data.roleType,
        };
    });
};

const getTransformedUsersCollection = (account: OwnAccountDTO[], userDetails: UserDetailsDto[]) => {
    const users: UserTileInputDataProps[] = [];
    if (userDetails?.length && account?.length) {
        userDetails.forEach((detail) => {
            const accountsByPoid = getAccountsByClientPoid(account, detail.clientPoid);
            accountsByPoid.forEach((accountByPoid) => {
                if (accountByPoid) {
                    const userFromAccount: GranteeDTO | RegisteredRoleDTO = getUserByClientPoid(
                        accountByPoid.grantees,
                        accountByPoid.owners,
                        detail.clientPoid,
                    );
                    const items: UserTileInputDataProps = {
                        accId: accountByPoid.accountId,
                        accName: accountByPoid.accountName,
                        accType: accountByPoid.accountType,
                        roleType: userFromAccount.roleType,
                        usrName: constructUserName(detail),
                    };

                    if ('accessLevel' in userFromAccount) {
                        items.accessLevel = userFromAccount.accessLevel;
                    }

                    users.push(items);
                }
            });
        });
    }
    return users;
};

const filterOutGranteesOrCoOwnersOnAccountFromUsers = (
    userDetails: UserDetailsDto[] | undefined,
    grantees: GranteeDTO[] | undefined,
    coOwners: RegisteredRoleDTO[] | undefined,
): UserDetailsDto[] => {
    if (!userDetails || userDetails.length === 0) {
        return [];
    } else if ((!grantees || grantees.length === 0) && (!coOwners || coOwners.length === 0)) {
        return userDetails;
    }

    return userDetails.filter(
        (userDetail: UserDetailsDto) =>
            !grantees.find((grantee: GranteeDTO) => grantee.clientPoid === userDetail.clientPoid) &&
            !coOwners.find(
                (coOwner: RegisteredRoleDTO) => coOwner.clientPoid === userDetail.clientPoid,
            ),
    );
};

/**
 * Returns list of users that filtered using grantees
 * @returns UserDetailsDto[]
 */
const filterOutGranteesOnAccountFromUsers = (
    userDetails: UserDetailsDto[] | undefined,
    grantees: GranteeDTO[] | undefined,
): UserDetailsDto[] => {
    if (!userDetails || userDetails.length === 0) {
        return [];
    } else if (!grantees || grantees.length === 0) {
        return userDetails;
    }
    const filteredByAccessLevel = [...grantees].filter(
        (grantee) => grantee.roleType === RoleType.GIPR || grantee.roleType === RoleType.GLAA,
    );
    return userDetails.filter((user: UserDetailsDto) =>
        filteredByAccessLevel.some((grantee: GranteeDTO) => grantee.clientPoid == user.clientPoid),
    );
};

const isExistingGrantee = (user: UserDetailsDto, userDetails: UserDetailsDto[]): boolean => {
    return !!userDetails.find((usr) => usr.clientPoid === user.clientPoid);
};

const getAccessLevel = (
    grantees: Array<GranteeDTO>,
    clientPoid: string,
    warn: Function,
): AccessTypeSelection | null => {
    const grantee: GranteeDTO = grantees.find((grantee) => grantee.clientPoid === clientPoid);

    if (!grantee) {
        warn(`Can't find grantee for revoke access for grantees: ${ JSON.stringify(grantees) } and clientPoid: ${ clientPoid }`);
        return null;
    }

    const roleType = grantee.roleType;
    switch (roleType) {
        case RoleType.GIPR:
            return AccessTypeSelection.INFORMATION_ONLY;
        case RoleType.GLAA:
            return AccessTypeSelection.LIMITED_ACCESS;
        default:
            return null;
    }
};

export default {
    extractUsersFromAccountData,
    getAccountsByClientPoid,
    getTransformedUsersCollection,
    getTransformedObservableCollection,
    constructUserName,
    filterOutGranteesOrCoOwnersOnAccountFromUsers,
    getContactPersonType,
    isExistingGrantee,
    filterOutGranteesOnAccountFromUsers,
    getAccessLevel,
};
