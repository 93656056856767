export const APP_PREFIX = 'ZCI';
export enum Deployment {
  LOCAL = 'local',
  TEST = 'SAT',
  PROD = 'PROD',
}

export enum Site {
  RETAIL_EXTERNAL= 'retailExternal',
  RETAIL_INTERNAL= 'retailInternal',
}
