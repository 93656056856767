<ng-container>
    <c11n-details
        [expandLabelText]="content.compareAccessLevel.title"
        [collapseLabelText]="content.compareAccessLevel.title"
        [ariaLabelText]="content.compareAccessLevel.title"
        (detailsToggle)="onDetailsToggle($event)">
        <ng-container *ngIf="showDetailsContent">
            <div class="note">
                {{ content.compareAccessLevel.note }}
            </div>
            <ng-container
                *ngFor="let accessType of allowedActions.accessTypes; let accessTypeIndex = index">
                <section class="access-comparison__cards">
                    <zci-single-status-list-tile
                        [items]="getAllowedActions(accessTypeIndex)"
                        [statusIcon]="checkedIcon">
                        <h2 class="c11n-text-md-bold c11n-space-stack-2x">
                            {{ accessType }}
                        </h2>
                        <c11n-banner
                            *ngIf="
                            accessType === accessTypeSelection.FULL_AUTHORITY &&
                            showFullAuthorityBanner
                        "
                            class="c11n-space-stack-2x"
                            size="small"
                            variant="warning"
                            [hasCloseControl]="false"
                            data-cy="full-authority-banner">
                            <p class="c11n-text-md--crop">
                                {{ this.content.compareAccessLevel.fullAuthorityBannerText[0] }}
                                <a
                                    tabindex="0"
                                    class="c11n-link pointer"
                                    c11n-link
                                    variant="primary-reinforced"
                                    (click)="handleAgentAuthorizationFormUrl()"
                                ><span class="c11n-link__content">{{
                                        this.content.compareAccessLevel.fullAuthorityBannerText[1]
                                    }}</span>
                                </a>
                                <span
                                    [innerHTML]="
                                    this.content.compareAccessLevel.fullAuthorityBannerText[2]
                                "></span>
                            </p>
                        </c11n-banner>
                    </zci-single-status-list-tile>
                </section>
            </ng-container>
        </ng-container>


        <ng-template #checkedIcon>
            <span class="visually-hidden">{{ content.about.checkMarkAltText }}</span>
            <c11n-icon
                custom-c11n
                class="custom-c11n-svg--fill-turquoise custom-c11n-svg--size-large"
                [name]="'success-fill'"></c11n-icon
            >
        </ng-template>
    </c11n-details>
</ng-container>
