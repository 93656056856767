import { Directive, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

import { CustomTableCell } from '../components/table/table.interface';

@Directive({
    selector: '[zciCustomCell]',
})
export class CustomCellDirective implements OnInit, OnDestroy {
    customCell: CustomTableCell;

    @Input() set zciCustomCell(customCell) {
        this.customCell = customCell;
    }

    constructor(private vcr: ViewContainerRef) {}

    ngOnInit() {
        const componentRef = this.vcr.createComponent(this.customCell.component);
        if (this.customCell.data) {
            Object.entries(this.customCell.data).forEach(([key, value]: [string, any]) => {
                componentRef.instance[key] = value;
            });
        }
    }

    ngOnDestroy() {
        this.vcr.clear();
    }
}
