import { createAction } from '@ngrx/store';
import { ViewType } from 'src/app/interfaces/view-type.enum';

export const updateSelectedViewTypeForUsers = createAction(
    '[View Type] Update Selected View Type for Users',
    (viewType: ViewType) => ({ viewType }),
);

export const updateSelectedViewTypeForMyAccounts = createAction(
    '[View Type] Update Selected View Type for My Accounts',
    (viewType: ViewType) => ({ viewType }),
);

export const updateSelectedViewTypeForOtherAccounts = createAction(
    '[View Type] Update Selected View Type for Other Accounts',
    (viewType: ViewType) => ({ viewType }),
);
