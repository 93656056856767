import { HttpErrorResponse } from '@angular/common/http';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserTileInputDataProps } from 'src/app/components/tiles/user-tile/user-tile.component';
import { ViewType } from 'src/app/interfaces/view-type.enum';
import { AccountPermissionDTO } from 'src/common/dtos/account-permission.dto';
import { AccountsUsersDto } from 'src/common/dtos/accounts-users.dto';
import { OwnAccountDTO } from 'src/common/dtos/own-account.dto';
import { UserDetailsDto } from 'src/common/dtos/user-details.dto';
import { ClientType } from 'src/common/enums/client-type.enum';

import { IneligibleReasons } from '../../../common/enums/ineligible-scenarios.enum';
import utils from '../../utils/account-permission/account-permission.util';
import { ObservableAccountTileInputDataProps } from '../../utils/sort/sort.service';
import { AccountPermissionsState } from '../state/account-permissions.state';
import { ApiState } from '../state/api.state';
import { StoreConstant } from '../store.constant';

export const selectClientType = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): ClientType => state.clientType,
);

export const selectContactPersonType = createSelector(
    selectClientType,
    (type: ClientType): string => {
        return utils.getContactPersonType(type);
    },
);

export const selectAccountUsers = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): ApiState<AccountsUsersDto> => state.accountUsers,
);

export const selectOwnAccounts = createSelector(
    selectAccountUsers,
    (accountUsers: ApiState<AccountsUsersDto>): OwnAccountDTO[] => {
        return accountUsers?.data?.ownAccounts ?? [];
    },
);

export const selectUserInfo = createSelector(
    selectAccountUsers,
    (accountUsers: ApiState<AccountsUsersDto>): UserDetailsDto => {
        return accountUsers?.data?.grantorInfo ?? null;
    },
);

export const selectObservableAccounts = createSelector(
    selectAccountUsers,
    (accountUsers: ApiState<AccountsUsersDto>): AccountPermissionDTO[] => {
        return accountUsers?.data?.observableAccounts ?? [];
    },
);

export const selectMaskedObservableAccounts = createSelector(
    selectObservableAccounts,
    (observableAccounts: AccountPermissionDTO[]): ObservableAccountTileInputDataProps[] => {
        return utils.getTransformedObservableCollection(observableAccounts);
    },
);

export const selectUserDetails = createSelector(
    selectAccountUsers,
    (accountUsers: ApiState<AccountsUsersDto>): UserDetailsDto[] => {
        return accountUsers?.data?.users ?? [];
    },
);

export const selectUsersWithNames = createSelector(
    selectOwnAccounts,
    selectUserDetails,
    (acc: OwnAccountDTO[], userDetails: UserDetailsDto[]): UserTileInputDataProps[] => {
        return utils.getTransformedUsersCollection(acc, userDetails);
    },
);

export const selectUsersOnAccount = (accountId) =>
    createSelector(
        selectUsersWithNames,
        (userWithNames: UserTileInputDataProps[]): UserTileInputDataProps[] => {
            return userWithNames.filter((user) => user.accId === accountId);
        },
    );

export const selectAccountById = (id: string) =>
    createSelector(
        selectAccountUsers,
        (accountUsers: ApiState<AccountsUsersDto>): OwnAccountDTO | undefined => {
            return accountUsers?.data?.ownAccounts?.find((acc) => acc.accountId === id);
        },
    );

export const getLoadingState = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): boolean => state.accountUsers.isLoading,
);

export const getSelectedViewTypeForUsers = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): ViewType => state.selectedViewTypeForUsers,
);

export const getSelectedViewTypeForMyAccounts = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): ViewType => state.selectedViewTypeForMyAccounts,
);

export const getSelectedViewTypeForOtherAccounts = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): ViewType => state.selectedViewTypeForOtherAccounts,
);

export const selectAccountPermissionFlowApiErrorState = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): HttpErrorResponse => state.accountUsers?.error,
);

export const selectNonGrantedUsersOnAccount = (accountId: string) =>
    createSelector(
        selectUserDetails,
        selectAccountById(accountId),
        (userDetails: UserDetailsDto[], ownAccount: OwnAccountDTO): UserDetailsDto[] => {
            return utils.filterOutGranteesOrCoOwnersOnAccountFromUsers(
                userDetails,
                ownAccount?.grantees,
                ownAccount?.owners,
            );
        },
    );

/**
 * Selector that returns granted users on account
 * @returns UserDetailsDto[]
 */
export const selectGrantedUsersOnAccount = (accountId: string) =>
    createSelector(
        selectUserDetails,
        selectAccountById(accountId),
        (userDetails: UserDetailsDto[], ownAccount: OwnAccountDTO): UserDetailsDto[] => {
            return utils.filterOutGranteesOnAccountFromUsers(userDetails, ownAccount?.grantees);
        },
    );

export const selectGrantorIsIneligibleFor = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): Array<IneligibleReasons> => state.grantorIsIneligibleFor,
);

export const selectApiCallError = createSelector(
    createFeatureSelector(StoreConstant.reducers.accountPermissions),
    (state: AccountPermissionsState): boolean => state.hasIneligibleError,
);
export const selectedAccountIsIneligibleFor = (accountId: string) =>
    createSelector(
        selectAccountById(accountId),
        (ownAccount: OwnAccountDTO): Array<IneligibleReasons> => {
            return ownAccount.isIneligible;
        },
    );
