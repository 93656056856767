<zci-view-selector
    *ngIf="!isMobileView"
    [(ngModel)]="viewType"
    (ngModelChange)="onViewTypeChange()"
    [location]="content.home.accountsNavTab.usersItemTitle"
    class="c11n-space-stack-4x"></zci-view-selector>
<ng-container *ngIf="viewType === ViewType.Card">
    <div class="row g-4 c11n-space-stack-5x">
        <div class="col-lg-6" *ngFor="let usr of usrsPaginated.data$ | async">
            <zci-user-tile
                [accName]="usr.accName"
                [accType]="usr.accType"
                [roleType]="usr.roleType"
                [usrName]="usr.usrName"></zci-user-tile>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <c11n-button
            *ngIf="usrsPaginated.hasMore$ | async"
            class="load-more-button"
            (clickEvent)="usrsPaginated.loadMore()"
            [labelText]="loadMoreUsersLabel$ | async"
            [buttonType]="'button'"
            size="medium"
            variant="secondary"
            colorMode="on-light"
            [isWaiting]="usrsPaginated.isLoading$ | async"
            data-cy="load-more-button">
        </c11n-button>
    </div>
</ng-container>

<ng-container *ngIf="viewType === ViewType.Table">
    <zci-table
        [tableColDef]="usrsTableCols"
        [tableData]="usrsDataTable$ | async"
        [ariaLabelledby]="''"
        [dataCy]="'zci-table'"
        [sortable]="true"
        (sort)="onColumnSort($event)"></zci-table>
</ng-container>
