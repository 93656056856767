<table
    class="c11n-table c11n-table--small c11n-text-sm-table-narrow"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.data-cy]="dataCy">
    <div class="c11n-table__symbols" *ngIf="sortable">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" width="8" height="14">
            <symbol id="c11n-table-sort-arrow" viewBox="0 0 8 14">
                <path
                    d="m.1455522 5.8535523 3.5008945 3.5008941c.195262.1952629.5118446.1952629.7071066 0l3.5008945-3.5008941c.314982-.3149824.091899-.8535533-.3535533-.8535533H.4991056c-.4454524 0-.6685358.5385709-.3535534.8535533z"></path>
            </symbol>
        </svg>
    </div>

    <thead class="c11n-table__thead-th">
        <tr class="c11n-table__tr">
            <th
                *ngFor="let colHeader of tableColDef"
                id="colHeader.dataKey"
                scope="col"
                class="c11n-table__thead-th c11n-table__thead-th--align-left"
                [ngClass]="{
                    'c11n-table__tbody-th--sortable': sortable,
                    ascending: currentSortedColumn.sortDir === sortDirection.ASC,
                    descending: currentSortedColumn.sortDir === sortDirection.DESC,
                    'c11n-table__thead-th--active-sort':
                        currentSortedColumn.dataKey === colHeader.dataKey
                }"
                data-cy="zci-table-header-cell">
                <button
                    type="button"
                    (click)="sortColumn(colHeader.dataKey)"
                    class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                    [title]="
                        colHeader.dataKey
                            | ascDescLabel
                                : currentSortedColumn
                                : content.global.table.sortableHeader.tooltip
                    "
                    [attr.aria-label]="
                        colHeader.dataKey
                            | ascDescLabel
                                : currentSortedColumn
                                : content.global.table.sortableHeader.ariaLabel
                    "
                    *ngIf="colHeader.visible">
                    <span class="c11n-link__content">
                        <span class="c11n-link__text">{{ colHeader.title }}</span>
                        <svg
                            viewBox="0 0 8 14"
                            focusable="false"
                            aria-hidden="true"
                            *ngIf="colHeader.sortable">
                            <use xlink:href="#c11n-table-sort-arrow" #sortAsc></use>
                            <use xlink:href="#c11n-table-sort-arrow" #sortDesc></use>
                        </svg>
                    </span>
                </button>
            </th>
        </tr>
    </thead>

    <tbody class="c11n-table__tbody-th">
        <tr *ngFor="let row of tableData" data-cy="zci-table-body-row">
            <td
                class="c11n-table__td c11n-table__td--align-left align-middle"
                [ngClass]="{ 'c11n-table__td--sortable': sortable }"
                *ngFor="let colHeader of tableColDef">
                <ng-container *ngIf="colHeader.visible">
                    <ng-container *ngIf="colHeader.customCell; else defaultCell">
                        <ng-container
                            *zciCustomCell="{
                                component: colHeader.customCell,
                                data: row[colHeader.dataKey]
                            }"></ng-container>
                    </ng-container>

                    <ng-template #defaultCell>
                        <ng-container *ngIf="false">
                            <ng-container *ngIf="colHeader.dataKey === 'accName'">
                                <zci-pending-chip [isInline]="true"></zci-pending-chip>
                            </ng-container>
                        </ng-container>
                        {{ row[colHeader.dataKey] }}
                    </ng-template>
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>
