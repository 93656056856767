import { AnalyticsCtaType } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';

export const adobeConfigData = {
    ngOnInit: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.RAF_SELECT_USER],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_START,
                {
                    processType: AdobeAnalyticsProcessType.RAF_REMOVE_ACCESS_FLOW,
                },
            ],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.RAF_REMOVE_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.RAF_SELECT_USER_TO_REMOVE,
                },
            ],
        },
    ],
    onBack: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.RAF_SELECT_USER_TO_REMOVE,
                    ctaName: AdobeAnalyticsProcessStep.RAF_BACK,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],

    onNextStepLoad: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS,
                {
                    processType: AdobeAnalyticsProcessType.RAF_REMOVE_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.RAF_NEXT,
                },
            ],
        },
    ],
};
