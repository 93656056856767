import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DrawerComponent } from '@vg-constellation/angular-16/drawer';
import { BehaviorSubject, Observable } from 'rxjs';

import { OwnAccountDTO } from '../../../../common/dtos/own-account.dto';
import { UserDetailsDto } from '../../../../common/dtos/user-details.dto';
import { Content } from '../../../../common/interfaces/content.interface';
import { APPLICATION_ROUTES } from '../../../constants/application-routes';
import { BreakpointObserverService } from '../../../services/breakpoint-observer/breakpoint-observer.service';
import {
    selectRemoveUserStepperSelectedAccounts,
    selectRemoveUserStepperSelectedUser,
} from '../../../store/selectors/grant-revoke-permissions.selector';
import { ContentUtil } from '../../../utils/content/content.util';
import { RemoveUserSteps } from '../../stepper-steps/remove-access-flow/enums/remove-user-steps';
import { VgnService } from '../../../services/vgn/vgn.service';

@Component({
    selector: 'zci-remove-user-stepper-page',
    templateUrl: './remove-user-stepper-page.component.html',
    styleUrls: ['./remove-user-stepper-page.component.scss'],
})
export class RemoveUserStepperPageComponent implements OnInit, OnDestroy{
    @Input() navigateOnSubmit: () => void;

    @ViewChild('rafDrawer', { static: false }) drawerComponent!: DrawerComponent;

    @HostBinding('class.push') pushContent: boolean = false;

    @HostBinding('class.raf-drawer-open') title: string;

    content: Content = ContentUtil.content;

    contactPhone: string = ContentUtil.globalRMSupportContactPhoneNumber;

    contactTitle: string = ContentUtil.wealthManagementSupportContent;

    drawerOpen: boolean = false;

    isMobileView$: Observable<boolean> = this.breakpointObserver.isMobileView$;

    isTabletView$: Observable<boolean> = this.breakpointObserver.isTabletView$;

    stepperSteps = RemoveUserSteps;

    currentStep$ = new BehaviorSubject<RemoveUserSteps>(RemoveUserSteps.SELECT_USER);

    constructor(
        private readonly breakpointObserver: BreakpointObserverService,
        private readonly router: Router,
        private readonly store: Store,
        private readonly vgnService: VgnService,
    ) {}

    ngOnInit(): void {
        this.vgnService.setMinimalNav(true);
    }

    ngOnDestroy(): void {
        this.vgnService.setMinimalNav(false);
    }

    selectedUser$: Observable<UserDetailsDto> = this.store.select(
        selectRemoveUserStepperSelectedUser,
    );

    selectedAccounts$: Observable<Array<OwnAccountDTO>> = this.store.select(
        selectRemoveUserStepperSelectedAccounts,
    );

    handleQuitClick(): void {
        this.router.navigate([APPLICATION_ROUTES.OWN_ACCOUNTS]);
    }

    onStepChange(nextStep: RemoveUserSteps): void {
        this.scrollTop();
        const stepWithinStepper = () => this.currentStep$.next(nextStep);

        const stepNavigations: Record<RemoveUserSteps, () => void> = {
            [RemoveUserSteps.QUIT]: this.handleQuitClick.bind(this),
            [RemoveUserSteps.SELECT_USER]: stepWithinStepper,
            [RemoveUserSteps.REVIEW]: stepWithinStepper,
        };

        stepNavigations[nextStep]();
    }

    private scrollTop(): void {
        window.scrollTo(0, 0);
    }

    onDrawerStateChange(event: any): void {
        this.drawerOpen = event.isDrawerOpen;
        if (!this.drawerComponent.isModal) {
            this.pushContent = event.isDrawerOpen;
        }
    }

    toggleDrawer(): void {
        if (this.drawerOpen) {
            this.drawerComponent.closeDrawer();
        } else {
            this.drawerComponent.openDrawer();
        }
    }
}
