import { CommonModule, I18nPluralPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler,
    Injector,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BannerModule } from '@vg-constellation/angular-16/banner';
import { ButtonModule } from '@vg-constellation/angular-16/button';
import { CheckboxModule } from '@vg-constellation/angular-16/checkbox';
import { ChipModule } from '@vg-constellation/angular-16/chip';
import { DetailsModule } from '@vg-constellation/angular-16/details';
import { DrawerModule } from '@vg-constellation/angular-16/drawer';
import { HintErrorModule } from '@vg-constellation/angular-16/hint-error';
import { IconModule } from '@vg-constellation/angular-16/icon';
import { InputModule } from '@vg-constellation/angular-16/input';
import { LabelModule } from '@vg-constellation/angular-16/label';
import { LinkModule } from '@vg-constellation/angular-16/link';
import { ModalDialogModule } from '@vg-constellation/angular-16/modal-dialog';
import { NavOverflowModule } from '@vg-constellation/angular-16/nav-overflow';
import { NavigationModule } from '@vg-constellation/angular-16/navigation';
import { RadioModule } from '@vg-constellation/angular-16/radio';
import { SpinnerModule } from '@vg-constellation/angular-16/spinner';
import { StepIndicatorModule } from '@vg-constellation/angular-16/step-indicator';
import { TooltipModule } from '@vg-constellation/angular-16/tooltip';
import { IMaskModule } from 'angular-imask';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AccessComparisonComponent } from './components/access-comparison/access-comparison.component';
import { ActionMenuWrapperComponent } from './components/action-menu-wrapper/action-menu-wrapper.component';
import { ActionNavOverflowMenuComponent } from './components/action-nav-overflow-menu/action-nav-overflow-menu.component';
import { BackLinkComponent } from './components/back-link/back-link.component';
import { DynamicRouteComponent } from './components/dynamic-route/dynamic-route.component';
import { InterstitialPageContentComponent } from './components/interstitial-page-content/interstitial-page-content.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { AccountPermissionsPageComponent } from './components/pages/account-permissions-page/account-permissions-page.component';
import { AddUserConfirmationPageComponent } from './components/pages/add-user-confirmation-page/add-user-confirmation-page.component';
import { AddUserStepperPageComponent } from './components/pages/add-user-stepper-page/add-user-stepper-page.component';
import { AddUserToAccountPageComponent } from './components/pages/add-user-to-account-page/add-user-to-account-page.component';
import { IneligiblePageComponent } from './components/pages/ineligible-page/ineligible-page.component';
import { AddUsersPageComponent } from './components/pages/interstitial-pages/accounts-interstitial-pages/add-users-page/add-users-page.component';
import { EditUsersPageComponent } from './components/pages/interstitial-pages/accounts-interstitial-pages/edit-users-page/edit-users-page.component';
import { RemoveFromAccountPageComponent } from './components/pages/interstitial-pages/accounts-interstitial-pages/remove-from-account-page/remove-from-account-page.component';
import { RemoveUsersPageComponent } from './components/pages/interstitial-pages/accounts-interstitial-pages/remove-users-page/remove-users-page.component';
import { AddExistingUserPageComponent } from './components/pages/interstitial-pages/users-interstitial-pages/add-existing-user-page/add-existing-user-page.component';
import { EditUserAccessPageComponent } from './components/pages/interstitial-pages/users-interstitial-pages/edit-user-access-page/edit-user-access-page.component';
import { RemoveUserAccessPageComponent } from './components/pages/interstitial-pages/users-interstitial-pages/remove-user-access-page/remove-user-access-page.component';
import { RemoveUserConfirmationPageComponent } from './components/pages/remove-user-confirmation-page/remove-user-confirmation-page.component';
import { RemoveUserFromAccountPageComponent } from './components/pages/remove-user-from-account-page/remove-user-from-account-page.component';
import { RemoveUserStepperPageComponent } from './components/pages/remove-user-stepper-page/remove-user-stepper-page.component';
import { TechnicalDifficultiesPageComponent } from './components/pages/technical-difficulties-page/technical-difficulties-page.component';
import { UnsuccessfulAttemptPageComponent } from './components/pages/unsuccessful-attempt-page/unsuccessful-attempt-page.component';
import { UsersOnAccountPageComponent } from './components/pages/users-on-account-page/users-on-account-page.component';
import { PendingChipComponent } from './components/pending-chip/pending-chip.component';
import { AdvisorComponent } from './components/representative-card/advisor/advisor.component';
import { RepresentativeCardComponent } from './components/representative-card/representative-card.component';
import { ResponsiveStepIndicatorComponent } from './components/responsive-step-indicator/responsive-step-indicator.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { AddUserTriagePageComponent } from './components/stepper-steps/grant-access-flow/add-user-triage-page/add-user-triage-page.component';
import { GafAccessAgreementStepComponent } from './components/stepper-steps/grant-access-flow/gaf-access-agreement-step/gaf-access-agreement-step.component';
import { GafReviewStepComponent } from './components/stepper-steps/grant-access-flow/gaf-review-step/gaf-review-step.component';
import { GafSelectAccessStepComponent } from './components/stepper-steps/grant-access-flow/gaf-select-access-step/gaf-select-access-step.component';
import { GafAddSomeoneElseComponent } from './components/stepper-steps/grant-access-flow/gaf-select-user-step/gaf-add-someone-else/gaf-add-someone-else.component';
import { GafCompleteOurFormComponent } from './components/stepper-steps/grant-access-flow/gaf-select-user-step/gaf-complete-our-form/gaf-complete-our-form.component';
import { GafSelectExistingUserComponent } from './components/stepper-steps/grant-access-flow/gaf-select-user-step/gaf-select-existing-user/gaf-select-existing-user.component';
import { GafSelectUserStepComponent } from './components/stepper-steps/grant-access-flow/gaf-select-user-step/gaf-select-user-step.component';
import { RafReviewStepComponent } from './components/stepper-steps/remove-access-flow/raf-review-step/raf-review-step.component';
import { RafFullAuthNoteComponent } from './components/stepper-steps/remove-access-flow/raf-select-user-step/raf-full-auth-note/raf-full-auth-note.component';
import { RafSelectUserStepComponent } from './components/stepper-steps/remove-access-flow/raf-select-user-step/raf-select-user-step.component';
import { StepWrapperComponent } from './components/stepper-steps/step-wrapper/step-wrapper.component';
import { TableComponent } from './components/table/table.component';
import { ObservableAccountsComponent } from './components/tabs/observable-accounts/observable-accounts.component';
import { OwnAccountsComponent } from './components/tabs/own-accounts/own-accounts.component';
import { UsersComponent } from './components/tabs/users/users.component';
import { UsersOnAccountComponent } from './components/tabs/users-on-account/users-on-account.component';
import { ObservableAccountTileComponent } from './components/tiles/observable-account-tile/observable-account-tile.component';
import { OwnAccountTileComponent } from './components/tiles/own-account-tile/own-account-tile.component';
import { RmInfoTileComponent } from './components/tiles/rm-info-tile/rm-info-tile.component';
import { SingleStatusListTileComponent } from './components/tiles/single-status-list-tile/single-status-list-tile.component';
import { TileComponent } from './components/tiles/tile.component';
import { TriageTileComponent } from './components/tiles/triage-tile/triage-tile.component';
import { UserTileComponent } from './components/tiles/user-tile/user-tile.component';
import { UserCountComponent } from './components/user-count/user-count.component';
import { ViewSelectorComponent } from './components/view-selector/view-selector.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { CustomCellDirective } from './directives/custom-cell.directive';
import { KeyboardEventRegexValidateDirective } from './directives/keyboard-event-regex-validate.directive';
import { LoadingDirective } from './directives/loading.directive';
import { AscDescLabelPipe } from './pipes/asc-desc-label/asc-desc-label.pipe';
import { MapAccountTypePipe } from './pipes/map-account-type/map-account-type.pipe';
import { ReplaceParamPipe } from './pipes/replace-param/replace-param.pipe';
import { MapRoleTypePipe } from './pipes/role-type/map-role-type.pipe';
import { ContentService } from './services/content/content.service';
import { AppGlobalErrorHandler } from './services/error-hadler/global-error.handler';
import { AccountPermissionStoreModule } from './store/account-permissions-store.module';
import { initializeApp } from './app-initializer';
import { HttpRequestInterceptor } from './interceptors/with-credentials-interceptor';
import { StepperComponent } from './components/stepper/stepper.component';
import { GafConfirmationPageMvpComponent } from './components/pages/gaf-confirmation-page-mvp/gaf-confirmation-page-component-mvp';
import { GafMvpPinComponent } from './components/stepper-steps/grant-access-flow-mvp/gaf-mvp-pin-component/gaf-mvp-pin-component';
import { AddUserToAccountPageMvpComponent } from './components/pages/add-user-to-account-page-mvp/add-user-to-account-page-mvp.component';
import {
    ExistingGranteeSuccessPageComponent,
    NewGranteeSuccessPageComponent,
} from './components/stepper-steps/grantee-flow/grantee-success-page/grantee-success-page.component';
import { GranteePinInputComponent } from './components/stepper-steps/grantee-flow/grantee-pin-input/grantee-pin-input.component';
import { GranteeLandingPageComponent } from './components/pages/grantee-landing-page/grantee-landing-page.component';
import { GenericErrorComponent } from './components/pages/generic-error/generic-error.component';
import { GranteeWebRegPageComponent } from './components/pages/grantee-web-reg-page/grantee-web-reg-page.component';

@NgModule({
    declarations: [
        AppComponent,
        AccountPermissionsPageComponent,
        BackLinkComponent,
        OwnAccountTileComponent,
        TileComponent,
        ObservableAccountTileComponent,
        SearchFieldComponent,
        UsersOnAccountPageComponent,
        KeyboardEventRegexValidateDirective,
        AboutPageComponent,
        EditUsersPageComponent,
        AddUsersPageComponent,
        RemoveUsersPageComponent,
        OwnAccountsComponent,
        ObservableAccountsComponent,
        UsersComponent,
        UserTileComponent,
        LoadingIndicatorComponent,
        LoadingDirective,
        SingleStatusListTileComponent,
        MapRoleTypePipe,
        AddExistingUserPageComponent,
        EditUserAccessPageComponent,
        RemoveUserAccessPageComponent,
        MapAccountTypePipe,
        RemoveFromAccountPageComponent,
        TableComponent,
        CustomCellDirective,
        ActionNavOverflowMenuComponent,
        AscDescLabelPipe,
        ViewSelectorComponent,
        UserCountComponent,
        UsersOnAccountComponent,
        AddUserToAccountPageComponent,
        AddUserToAccountPageMvpComponent,
        AddUserStepperPageComponent,
        TechnicalDifficultiesPageComponent,
        RmInfoTileComponent,
        InterstitialPageContentComponent,
        GafSelectUserStepComponent,
        AutofocusDirective,
        ActionMenuWrapperComponent,
        DynamicRouteComponent,
        AccessComparisonComponent,
        GafSelectAccessStepComponent,
        ResponsiveStepIndicatorComponent,
        GafSelectExistingUserComponent,
        GafAddSomeoneElseComponent,
        AddUserConfirmationPageComponent,
        GafReviewStepComponent,
        GafAccessAgreementStepComponent,
        UnsuccessfulAttemptPageComponent,
        RepresentativeCardComponent,
        AdvisorComponent,
        IneligiblePageComponent,
        GafCompleteOurFormComponent,
        RemoveUserStepperPageComponent,
        RafSelectUserStepComponent,
        RafReviewStepComponent,
        RemoveUserConfirmationPageComponent,
        StepWrapperComponent,
        RemoveUserFromAccountPageComponent,
        PendingChipComponent,
        TriageTileComponent,
        AddUserTriagePageComponent,
        RafFullAuthNoteComponent,
        StepperComponent,
        GafMvpPinComponent,
        GafConfirmationPageMvpComponent,
        ExistingGranteeSuccessPageComponent,
        NewGranteeSuccessPageComponent,
        GranteePinInputComponent,
        GranteeLandingPageComponent,
        GenericErrorComponent,
        GranteeWebRegPageComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        LinkModule,
        IconModule,
        NavigationModule,
        NavOverflowModule,
        CheckboxModule,
        InputModule,
        ReactiveFormsModule,
        ButtonModule,
        SpinnerModule,
        StepIndicatorModule,
        AccountPermissionStoreModule,
        ReplaceParamPipe,
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.development, // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        FormsModule,
        RadioModule,
        ReactiveFormsModule,
        BannerModule,
        DetailsModule,
        LabelModule,
        HintErrorModule,
        TooltipModule,
        IMaskModule,
        DrawerModule,
        ModalDialogModule,
        ChipModule,
    ],
    providers: [
        ContentService,
        { provide: ErrorHandler, useClass: AppGlobalErrorHandler },
        { provide: Window, useValue: window },
        I18nPluralPipe,
        MapRoleTypePipe,
        MapAccountTypePipe,
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [HttpClient, Window],
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {
    static injector: Injector;

    constructor(injector: Injector) {
        AppModule.injector = injector;
    }
}
