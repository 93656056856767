import { Pipe, PipeTransform } from '@angular/core';

function replaceSingleParam(value: string, param: string, replaceValue: string): string {
    if (!value || !param || !replaceValue) {
        return value;
    }
    return value.replace(`{{${param}}}`, replaceValue);
}

@Pipe({
    name: 'replaceParam',
    standalone: true,
})
export class ReplaceParamPipe implements PipeTransform {
    transform(value: string, multipleParams: [param: string, replaceValue: string][]): string;
    transform(value: string, param: string, replaceValue: string): string;

    transform(
        value: string,
        singleOrMultipleParams: [param: string, replaceValue: string][] | string,
        replaceValue?: string,
    ): string {
        let transformedValue: string = value;

        if (Array.isArray(singleOrMultipleParams)) {
            singleOrMultipleParams.forEach(([param, newValue]) => {
                transformedValue = replaceSingleParam(transformedValue, param, newValue);
            });
        } else {
            transformedValue = replaceSingleParam(value, singleOrMultipleParams, replaceValue);
        }

        return transformedValue;
    }
}
