<div class="container zci-margin-top c11n-space-stack-10x c11n-space--force">
    <div class="c11n-space-stack-4x">
        <zci-back-link [link]="appRoutes.HOME"></zci-back-link>
    </div>
    <h1 class="zci-h1 c11n-space-stack-4x" data-cy="page-title">{{ content.about.pageTitle }}</h1>
    <p class="c11n-text-md c11n-space-stack-4x">
        {{ content.about.h1Description }}
    </p>
    <article class="c11n-space-stack-5x">
        <h2 id="tableTitleCompareAllowedActions" class="zci-h2 c11n-space-stack-4x">
            {{ content.about.tableCompareAllowedActions.title }}
        </h2>
        <div class="about-page__cards-container">
            <p class="c11n-space-stack-4x c11n-text-md">
                {{
                    content.about.tableCompareAllowedActions.description
                        | replaceParam: contactPersonTypePlaceHolder:(contactPersonType$ | async)
                }}
            </p>
            <section class="about-page__cards">
                <ng-container
                    *ngFor="let accessType of allowedActions.accessTypes; let accessTypeIndex = index">
                    <zci-single-status-list-tile
                        [items]="getAllowedActions(accessTypeIndex)"
                        [statusIcon]="checkedIcon">
                        <h3 class="c11n-text-md-bold c11n-space-stack-3x">
                            {{ accessType }}
                        </h3>
                    </zci-single-status-list-tile>
                </ng-container>
            </section>
        </div>
        <table
            class="c11n-table c11n-table--small"
            aria-labelledby="tableTitleCompareAllowedActions"
            data-cy="comparison-table">
            <caption class="c11n-space-stack-4x c11n-text-md">
                {{
                    content.about.tableCompareAllowedActions.description
                        | replaceParam: contactPersonTypePlaceHolder:(contactPersonType$ | async)
                }}
            </caption>
            <thead>
            <tr class="c11n-table__tr">
                <th
                    scope="col"
                    class="c11n-table__thead-th c11n-table__thead-th--align-center c11n-text-md"
                    *ngFor="
                        let header of content.about.tableCompareAllowedActions.headers.horizontal
                    ">
                    {{ header }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let header of allowedActions.actionNames; let headerIndex = index">
                <th scope="row" class="c11n-table__td c11n-table__tbody-th c11n-text-md">
                    {{ header }}
                </th>
                <td
                    class="c11n-table__td c11n-table__td--align-center c11n-text-md"
                    *ngFor="let isChecked of allowedActions.matrix[headerIndex]">
                    <ng-container
                        *ngIf="isChecked; then checkedIcon; else blankCell"></ng-container>
                </td>
            </tr>
            </tbody>
        </table>
    </article>
    <article>
        <h2 id="tableTitleEligibleAccounts" class="zci-h2 c11n-space-stack-4x">
            {{ content.about.tableEligibleAccounts.title }}
        </h2>
        <div class="about-page__cards-container">
            <p class="c11n-space-stack-4x c11n-text-md">
                {{ content.about.tableEligibleAccounts.description }}
            </p>
            <section class="about-page__cards">
                <ng-container
                    *ngFor="
                    let accessType of eligibleAccounts.accessTypes;
                    let accessTypeIndex = index
                ">
                    <zci-single-status-list-tile
                        [items]="getEligibleAccounts(accessTypeIndex)"
                        [statusIcon]="checkedIcon">
                        <h3 class="c11n-text-md-bold c11n-space-stack-3x">
                            {{ accessType }}
                        </h3>
                    </zci-single-status-list-tile>
                </ng-container>
            </section>
        </div>
        <table
            class="c11n-table c11n-table--small"
            aria-labelledby="tableTitleEligibleAccounts"
            data-cy="comparison-table">
            <caption class="c11n-space-stack-4x c11n-text-md">
                {{
                    content.about.tableEligibleAccounts.description
                }}
            </caption>
            <thead>
            <tr class="c11n-table__tr">
                <th
                    scope="col"
                    class="c11n-table__thead-th c11n-table__thead-th--align-center c11n-text-md"
                    *ngFor="let header of content.about.tableEligibleAccounts.headers.horizontal">
                    {{ header }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let header of eligibleAccounts.accountNames; let headerIndex = index">
                <th scope="row" class="c11n-table__td c11n-table__tbody-th c11n-text-md">
                    {{ header }}
                </th>
                <td
                    class="c11n-table__td c11n-table__td--align-center c11n-text-md"
                    *ngFor="let isChecked of eligibleAccounts.matrix[headerIndex]">
                    <ng-container *ngIf="isChecked; then checkedIcon; else blankCell">
                    </ng-container>
                </td>
            </tr>
            </tbody>
        </table>
    </article>
</div>

<ng-template #checkedIcon>
    <span class="visually-hidden">{{ content.about.checkMarkAltText }}</span>
    <c11n-icon
        custom-c11n
        class="custom-c11n-svg--fill-turquoise custom-c11n-svg--size-large"
        [name]="'success-fill'"></c11n-icon
></ng-template>

<ng-template #blankCell>
    <span class="visually-hidden">{{ content.about.emptyCellAriaLabel }}</span>
</ng-template>
