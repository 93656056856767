import { Component, Input } from '@angular/core';
import { Content } from '../../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../../utils/content/content.util';
import { APPLICATION_ROUTES } from '../../../../constants/application-routes';
import { LoggerService } from '../../../../services/logger/logger.service';

@Component({
    selector: 'zci-gaf-mvp-pin-component',
    templateUrl: 'gaf-mvp-pin-component.html',
})
export class GafMvpPinComponent {
    @Input() uniquePin: string;

    constructor(private readonly loggerService: LoggerService) {
    }

    protected readonly APPLICATION_ROUTES = APPLICATION_ROUTES;

    content: Content = ContentUtil.content;

    async copyPinToClipboard() {
        try {
            await navigator.clipboard.writeText(this.uniquePin);
        } catch (err) {
            this.loggerService.warn('Could not copy to clipboard');
        }

    }
}
