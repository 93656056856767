import { Component, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { resetGrantRevokePermissionState } from 'src/app/store/actions/grant-revoke-permissions.action';
import { ContentUtil } from 'src/app/utils/content/content.util';
import { Content } from 'src/common/interfaces/content.interface';

import {
    selectConfirmationNumber,
    selectUniquePin,
} from '../../../store/selectors/grant-revoke-permissions.selector';
import { PageComponent } from '../page.component';
import { PagesOfAddUserToOneSelectedAccount } from '../enums/pages-of-add-user-to-one-selected-account.enum';

@Component({
    selector: 'zci-add-user-to-account-page-mvp',
    templateUrl: './add-user-to-account-page-mvp.component.html',
    styleUrls: ['./add-user-to-account-page-mvp.component.scss'],
})
export class AddUserToAccountPageMvpComponent extends PageComponent implements OnDestroy {
    content: Content = ContentUtil.content;

    forceSurveyZeroHeight: boolean = true;

    constructor(
        private readonly store: Store,
        private logger: LoggerService,
        private window: Window,
    ) {
        super();
    }

    currentPage: PagesOfAddUserToOneSelectedAccount = PagesOfAddUserToOneSelectedAccount.STEPPER;

    pagesOfAddUserToOneSelectedAccount = PagesOfAddUserToOneSelectedAccount;

    @HostListener('window:MDigital_Form_Displayed')
    removeForcedHeightZero() {
        this.forceSurveyZeroHeight = false;
    }

    selectConfirmationNumber$: Observable<string> = this.store
        .select(selectConfirmationNumber)
        .pipe(
            tap((value) => {
                if (value) {
                    setTimeout(() => {
                        this.updatePageView();
                    }, 1000);
                }
            }),
        );

    selectUniquePin$: Observable<string> = this.store.select(selectUniquePin).pipe(
        tap((value) => {
            if (value) {
                setTimeout(() => {
                    this.updatePageView();
                }, 1000);
            }
        }),
    );

    updatePageView(): void {
        const nativeWindow: Window | any = this.window;
        if (
            nativeWindow?.KAMPYLE_ONSITE_SDK &&
            typeof nativeWindow.KAMPYLE_ONSITE_SDK?.updatePageView === 'function'
        ) {
            nativeWindow.KAMPYLE_ONSITE_SDK.updatePageView();
        } else {
            this.logger.warn('Failed to load Medallia SDK');
        }
    }

    changeCurrentPage(page: PagesOfAddUserToOneSelectedAccount) {
        this.currentPage = page;
    }

    resetGrantRevokePermissionState(): void {
        this.store.dispatch(resetGrantRevokePermissionState());
    }

    ngOnDestroy(): void {
        this.resetGrantRevokePermissionState();
    }
}
