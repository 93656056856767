import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { UserDetailsDto } from '../../../common/dtos/user-details.dto';
import {
    GranteeIneligibleReasons,
    IneligibleReasons
} from '../../../common/enums/ineligible-scenarios.enum';

export const ineligibleScenariosSuccessForGrantor = createAction(
    '[Ineligible Scenarios] Ineligible Scenarios For Grantor Success',
    props<{ data: Array<IneligibleReasons> }>(),
);

export const ineligibleScenariosErrorForGrantor = createAction(
    '[Ineligible Scenarios] Ineligible Scenarios For Grantor Error',
    props<{ error: HttpErrorResponse }>(),
);

export const ineligibleScenariosForGrantee = createAction(
    '[Ineligible Scenarios] Ineligible Scenarios For Grantee',
    props<{ userDetails: UserDetailsDto }>(),
);
export const ineligibleScenariosSuccessForGrantee = createAction(
    '[Ineligible Scenarios] Ineligible Scenarios For Grantee Success',
    props<{ granteeIneligibleReason: GranteeIneligibleReasons; userDetails: UserDetailsDto }>(),
);
export const ineligibleScenariosErrorForGrantee = createAction(
    '[Ineligible Scenarios] Ineligible Scenarios For Grantee Error',
    props<{ error: HttpErrorResponse }>(),
);

export const ineligibleScenariosForGranteeInGranteeFlow = createAction(
    '[Ineligible Scenarios ] Ineligibility Scenarios For Grantee Flow',
);
export const ineligibleScenariosForGranteeInGranteeFlowSuccess = createAction(
    '[Ineligible Scenarios] Ineligibility Scenarios For Grantee Flow Success',
    props<{ granteeIneligibleReason: GranteeIneligibleReasons }>(),
);
export const ineligibleScenariosForGranteeInGranteeFlowError = createAction(
    '[Ineligible Scenarios] Ineligibility Scenarios For Grantee Flow Error',
    props<{ error: HttpErrorResponse }>(),
);
