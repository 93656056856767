import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccessPermissionsApiEffect } from './effects/access-permissions-api.effect';
import { AccountPermissionsApiEffect } from './effects/account-permissions-api.effect';
import { GrantRevokePermissionApiEffect } from './effects/grant-revoke-permission-api.effect';
import { IneligibleScenariosApiEffect } from './effects/ineligible-scenarios-api.effect';
import { accountPermissionReducer } from './reducers/account-permissions.reducer';
import { grantRevokePermissionsReducer } from './reducers/grant-revoke-permissions.reducer';
import { StoreConstant } from './store.constant';
import { AddSomeoneElseApiEffect } from './effects/add-someone-else-api.effect';
import { granteeReducer } from './reducers/grantee.reducer';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forRoot({
            [StoreConstant.reducers.accountPermissions]: accountPermissionReducer,
            [StoreConstant.reducers.grantRevokePermissions]: grantRevokePermissionsReducer,
            [StoreConstant.reducers.granteeReducer]: granteeReducer,
        }),
        EffectsModule.forRoot([
            AccountPermissionsApiEffect,
            GrantRevokePermissionApiEffect,
            AccessPermissionsApiEffect,
            IneligibleScenariosApiEffect,
            AddSomeoneElseApiEffect,
        ]),
    ],
})
export class AccountPermissionStoreModule {}
