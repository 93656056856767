import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import { OwnAccountDTO } from '../../../../../common/dtos/own-account.dto';
import { UserDetailsDto } from '../../../../../common/dtos/user-details.dto';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { removePermission } from '../../../../store/actions/access-permissions.actions';
import { selectUserInfo } from '../../../../store/selectors/account-permissions.selector';
import { selectRemoveUserStepperData } from '../../../../store/selectors/grant-revoke-permissions.selector';
import accountPermissionUtils from '../../../../utils/account-permission/account-permission.util';
import utils from '../../../../utils/account-permission/account-permission.util';
import { Unsubscribe } from '../../../../utils/decorators/dev';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { RemoveUserSteps } from '../enums/remove-user-steps';
import { RemoveUserStepBaseComponent } from '../remove-user-step-base.component';
import { adobeConfigData } from './raf-review-step-adobe';
import { AdobeAnalyticsProcessStep } from '../../../../services/adobe-launch/adobe-launch.process';
import { LoggerService } from '../../../../services/logger/logger.service';

@AdobeTagging(adobeConfigData)
@Unsubscribe
@Component({
    selector: 'zci-raf-review-step',
    templateUrl: './raf-review-step.component.html',
    styleUrls: ['./raf-review-step.component.scss'],
})
export class RafReviewStepComponent extends RemoveUserStepBaseComponent {
    @Input() currentStep: RemoveUserSteps;

    @Input() selectedUser: UserDetailsDto;

    @Input() selectedAccounts: Array<OwnAccountDTO>;

    @Input() description: string;

    subs = new Subscription();

    constructUserName: Function = accountPermissionUtils.constructUserName;

    constructor(
        protected adobeLaunchService: AdobeLaunchService,
        private store: Store,
        private readonly logger: LoggerService,
    ) {
        super(adobeLaunchService);
    }

    onNextStepLoad() {
        this.subs.add(
            combineLatest([
                this.store.select(selectRemoveUserStepperData),
                this.store.select(selectUserInfo),
            ]).subscribe(([removeUserStepperData, grantorInfo]) => {
                this.store.dispatch(
                    removePermission({
                        granteeDetails: {
                            granteeId: removeUserStepperData.selectedUser.clientPoid,
                            granteeName:
                                removeUserStepperData.selectedUser.firstName +
                                ' ' +
                                removeUserStepperData.selectedUser.lastName,
                            accessLevel: utils.getAccessLevel(
                                removeUserStepperData.selectedAccounts[0].grantees,
                                removeUserStepperData.selectedUser.clientPoid,
                                this.logger.warn,
                            ),
                        },
                        grantorDetails: {
                            accountId: removeUserStepperData.selectedAccounts[0].accountId, // TODO unsafe, fix it
                            grantorName: grantorInfo.firstName + ' ' + grantorInfo.lastName,
                            serviceAgreementId:
                                removeUserStepperData.selectedAccounts[0].serviceAgreementId,
                        },
                    }),
                );
            }),
        );
    }

    onBack() {
        this.stepChange.emit(RemoveUserSteps.SELECT_USER);
    }

    protected readonly AdobeAnalyticsProcessStep = AdobeAnalyticsProcessStep;
}
