import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { COOKIES } from '../../../common/constants/constants';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class ZciCookieService {
    constructor(
        private readonly cookieService: CookieService,
        private readonly logger: LoggerService,
    ) {}

    get sPOID(): string {
        return this.getSPOIDFromCookies();
    }

    get nonProdPortNumber(): string {
        return this.getNonProdPortNumberFromCookies();
    }

    private getSPOIDFromCookies(): string | null {
        const clSpoid = this.cookieService.get(COOKIES.CL_SPOID);

        if (!clSpoid) {
            this.logger.warn(`Unable to identify sPOID`);
        }
        return clSpoid || null;
    }

    private getNonProdPortNumberFromCookies() {
        const nonProdPortNumber = this.cookieService.get(COOKIES.LOGONNONPRODPORT);
        const monolithPortNumber = this.cookieService.get(COOKIES.MONOLITH_TARGET);

        if (nonProdPortNumber) {
            this.logger.info('Using non-prod port');
            return nonProdPortNumber;
        } else {
            this.logger.info('Using monolith port');
            return monolithPortNumber;
        }
    }
}
