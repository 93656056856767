import { Component, Input } from '@angular/core';

import { ContentUtil } from '../../utils/content/content.util';

export interface UserCountComponentProps {
    url: string;
    usrCount: number;
}

@Component({
    selector: 'zci-user-count',
    templateUrl: './user-count.component.html',
})
export class UserCountComponent implements UserCountComponentProps {
    content = ContentUtil.content;

    noAgentsAssigned: number = 0;

    agentsCountMap: { [k: string]: string } = {
        '=1': this.content.home.accountTile.oneAgentLabel,
        other: this.content.home.accountTile.multipleAgentsLabel,
    };

    @Input() url: string;

    @Input() usrCount: number;
}
