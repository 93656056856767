import { Component, Directive, HostBinding, HostListener, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { BreakpointObserverService } from '../../../../services/breakpoint-observer/breakpoint-observer.service';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { ContentUtil } from '../../../../utils/content/content.util';
import { environment } from '../../../../../environments/environment';
import { selectGranteeAccessType } from '../../../../store/selectors/grantee.selector';
import { getAdobeConfigData } from './grantee-success-page-adobe';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { VgnService } from '../../../../services/vgn/vgn.service';
import { LoggerService } from '../../../../services/logger/logger.service';

@Directive()
class GranteeSuccessPageComponent implements OnInit {
    readonly accessTypeSelection = AccessTypeSelection;

    accessLevel$: Observable<AccessTypeSelection> = this.store.select(selectGranteeAccessType);

    forceSurveyZeroHeight: boolean = true;    

    @HostListener('window:MDigital_Form_Displayed')
    removeForcedHeightZero() {
        this.forceSurveyZeroHeight = false;
    }

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    dashboardUrl: string = environment.DASHBOARD_URL;

    granteeSuccessPageContentInfoOnly =
        ContentUtil.granteeFragmentsContent.frmcGranteeFlow.scssInformationOnlyAccess;
    granteeSuccessPageContentLimitedOnly =
        ContentUtil.granteeFragmentsContent.frmcGranteeFlow.scssLimitedAuthority;

    accessLevelContent$ = this.accessLevel$.pipe(
        map((accessLevel) => {
            if (accessLevel === AccessTypeSelection.INFORMATION_ONLY) {
                return this.granteeSuccessPageContentInfoOnly;
            } else {
                return this.granteeSuccessPageContentLimitedOnly;
            }
        }),
    );

    constructor(
        private readonly store: Store,
        private readonly breakpointObserverService: BreakpointObserverService,
        private readonly vgnService: VgnService,
        private window: Window,
        private logger: LoggerService,
    ) {}

    ngOnInit(): void {
        this.vgnService.setMinimalNav(false);
        this.scrollTop();
        setTimeout(() => {
            this.loadSurvey();
        }, 1000);
    }

    goToDashboard(): void {}

    private loadSurvey(): void {
        const nativeWindow: Window | any = this.window;
        if (
            nativeWindow?.KAMPYLE_ONSITE_SDK &&
            typeof nativeWindow.KAMPYLE_ONSITE_SDK?.updatePageView === 'function'
        ) {
            nativeWindow.KAMPYLE_ONSITE_SDK.updatePageView();
        } else {
            this.logger.warn('Failed to load Medallia SDK');
        }
    }

    private scrollTop(): void {
        window.scrollTo(0, 0);
    }
}

@AdobeTagging(getAdobeConfigData('existing'))
@Component({
    selector: 'zci-existing-grantee-success-page',
    templateUrl: './grantee-success-page.component.html',
    styleUrls: ['grantee-success-page.component.scss'],
})
export class ExistingGranteeSuccessPageComponent extends GranteeSuccessPageComponent {}

@AdobeTagging(getAdobeConfigData('new'))
@Component({
    selector: 'zci-new-grantee-success-page',
    templateUrl: './grantee-success-page.component.html',
    styleUrls: ['grantee-success-page.component.scss'],
})
export class NewGranteeSuccessPageComponent extends GranteeSuccessPageComponent {}
