import { AdobeMethods } from '../../../../../services/adobe-launch/adobe-launch.methods';
import { AdobeAnalyticsProcessStep } from '../../../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../../../services/adobe-launch/adobe-launch.cta';

export const adobeConfigData = {
    trackCompleteOurFormLink: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GAF_SELECT_PERSON,
                    ctaName: AdobeAnalyticsProcessStep.GAF_COMPLETE_OUR_FORM,
                    ctaType: AnalyticsCtaType.LINK,
                },
            ],
        },
    ],
}
