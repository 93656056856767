import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import { UserTypeSelection } from '../../../../enums/user-type-selection.enum';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { updateAddUserStepperSelectedUserType } from '../../../../store/actions/add-user-stepper-flow.action';
import { ContentUtil } from '../../../../utils/content/content.util';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { StepsOfAddUserToOneSelectedAccount } from '../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { AddUserStepBaseComponent } from '../add-user-step-base.component';
import configData from './add-user-triage-page-adobe';

@AdobeTagging(configData)
@Component({
    selector: 'zci-add-user-triage-page',
    templateUrl: './add-user-triage-page.component.html',
})
export class AddUserTriagePageComponent extends AddUserStepBaseComponent {
    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    environment = environment;

    content = ContentUtil.content;

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT;

    constructor(private store: Store, protected adobeLaunchService: AdobeLaunchService) {
        super(adobeLaunchService);
    }

    onBack(): void {
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.QUIT);
    }

    onNextStepLoad(): void {}

    navigateSelectUser() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.EXISTING }),
        );

        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.SELECT_USER);
    }

    navigateAddSomeoneElse() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.NEW }),
        );

        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.SELECT_USER);
    }

    navigateToFormFromNonVGUser() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    navigateToFormFromOrgTrust() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }
}
