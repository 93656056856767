import { Component, Input } from '@angular/core';

import { content } from '../../../../content/zci/content';
import { environment } from '../../../../environments/environment.development-internal';

@Component({
    selector: 'zci-triage-tile',
    templateUrl: './triage-tile.component.html',
    styleUrls: ['./triage-tile.component.scss'],
})
export class TriageTileComponent {
    @Input() title: string;

    @Input() description: string;

    @Input() link: string;

    protected readonly environment = environment;

    protected readonly content = content;
}
