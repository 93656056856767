import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ModalDialogComponent,
    ModalDialogEntrypointComponent,
} from '@vg-constellation/angular-16/modal-dialog';
import { BannerComponent } from '@vg-constellation/angular-16/banner';
import {
    RadioComponent,
    RadioGroupComponent,
    RadioInputComponent,
} from '@vg-constellation/angular-16/radio';
import { LinkComponent } from '@vg-constellation/angular-16/link';
import { environment } from 'src/environments/environment';
import {
    combineLatest,
    combineLatestWith,
    filter,
    map,
    Observable,
    Subscription,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { UserDetailsDto } from '../../../../../../common/dtos/user-details.dto';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { CtaCallUsLinkLocations } from '../../../../../services/adobe-launch/adobe-launch.cta';
import accountPermissionUtils from '../../../../../utils/account-permission/account-permission.util';
import { Store } from '@ngrx/store';
import { AdobeLaunchService } from '../../../../../services/adobe-launch/adobe-launch.service';
import * as accountPermissionsSelector from '../../../../../store/selectors/account-permissions.selector';
import * as grantRevokePermissionsSelector from '../../../../../store/selectors/grant-revoke-permissions.selector';
import { selectAddUserStepperSelectedAccount } from '../../../../../store/selectors/grant-revoke-permissions.selector';
import { updateAddUserStepperSelectedUser } from '../../../../../store/actions/add-user-stepper-flow.action';
import { ineligibleScenariosForGrantee } from '../../../../../store/actions/ineligible-scenarios.action';
import { Content } from '../../../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../../../utils/content/content.util';
import { GafCompleteOurFormMvpComponent } from '../gaf-complete-our-form-mvp/gaf-complete-our-form-mvp.component';
import { AdobeTagging } from '../../../../../utils/decorators/dev/adobe-launch';
import { adobeConfigData } from './gaf-select-existing-user-mvp-adobe';
import { PendingRequestService } from '../../../../../services/pending-request/pending-request.service';
import { OwnAccountDTO } from '../../../../../../common/dtos/own-account.dto';
import { ErrorService } from '../../../../../services/generic-error/error.service';
import { ErrorPage } from '../../../../../services/generic-error/error-page-utils';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-select-existing-user-mvp',
    standalone: true,
    imports: [
        CommonModule,
        ModalDialogEntrypointComponent,
        ModalDialogComponent,
        BannerComponent,
        RadioGroupComponent,
        RadioInputComponent,
        RadioComponent,
        LinkComponent,
        ReactiveFormsModule,
        GafCompleteOurFormMvpComponent,
    ],
    templateUrl: './gaf-select-existing-user-mvp.component.html',
    styleUrls: ['./gaf-select-existing-user-mvp.component.scss'],
})
export class GafSelectExistingUserMvpComponent implements OnInit, OnDestroy {
    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() goToNextStep: EventEmitter<boolean> = new EventEmitter<boolean>();

    content: Content = ContentUtil.content;

    environment = environment;

    radioGroupId: string = 'user-selection-group';

    selectedUserFormControlName = 'selectedUsr';

    usrs$: Observable<UserDetailsDto[]>;

    usrFormGroup: FormGroup = new FormGroup({
        [this.selectedUserFormControlName]: new FormControl(null, [Validators.required]),
    });

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_SELECT_EXISTING_USER;

    @ViewChild('selectUserLevelModalDialog', { static: false })
    selectUserLevelModalDialog!: ModalDialogComponent;

    get selectedUserFormControl(): AbstractControl {
        return this.usrFormGroup.get(this.selectedUserFormControlName);
    }

    constructUserName: Function = accountPermissionUtils.constructUserName;

    readonly subs: Subscription = new Subscription();

    @Input() accId: string;

    @Output() addSomeoneElse: EventEmitter<void> = new EventEmitter();

    constructor(
        private readonly store: Store,
        protected adobeLaunchService: AdobeLaunchService,
        protected pendingRequestService: PendingRequestService,
        private errorService: ErrorService,
    ) {}

    // currently selected user from the store (observable) - this comes from form selection AND store users
    currentUser$: Observable<UserDetailsDto>;

    // currently selected user from the store (object) - this is just a data object
    currentUser: UserDetailsDto;

    // currently selected user with ineligibility checked
    finalizedUser$: Observable<UserDetailsDto>;

    sagId: string;

    ngOnInit(): void {
        this.subs.add(
            this.store
                .select(selectAddUserStepperSelectedAccount)
                .subscribe((account: OwnAccountDTO) => {
                    this.sagId = account.serviceAgreementId;
                }),
        );

        const existingUsers$: Observable<UserDetailsDto[]> = this.store.select(
            accountPermissionsSelector.selectNonGrantedUsersOnAccount(this.accId),
        );
        const selectedUser$: Observable<UserDetailsDto> = this.store.select(
            grantRevokePermissionsSelector.selectAddUserStepperSelectedUser,
        );

        this.usrs$ = existingUsers$.pipe(
            combineLatestWith(selectedUser$),
            tap(this.patchFormValueWithSelectedUser.bind(this)),
            map(([existingUsers]) =>
                [...existingUsers].sort((u1, u2) =>
                    this.constructUserName(u1) > this.constructUserName(u2) ? 1 : -1,
                ),
            ),
            take(1),
        );

        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));

        this.onUserSelectionChange(existingUsers$);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onUserSelectionChange(existingUsers$): void {
        this.currentUser$ = combineLatest([
            existingUsers$,
            this.usrFormGroup.get(this.selectedUserFormControlName).valueChanges,
            // TODO type this
        ]).pipe(
            map(([existingUsers, currentUser]: any[]) => {
                if (!currentUser) {
                    return null;
                }

                return existingUsers.find(
                    (existingUser: UserDetailsDto) =>
                        existingUser.clientPoid === currentUser.clientPoid,
                );
            }),
        );

        this.finalizedUser$ = combineLatest([this.currentUser$, this.nextStepClicked]).pipe(
            // waiting for 'next' click as a trigger but omitting it for later
            map(([user]) => user),

            // waiting for 'granteeIneligibleReason' to be loaded
            filter((user) => 'granteeIneligibleReason' in user),

            // check and show error after loading
            tap((user) => this.checkGranteeIneligibility(user)),

            // only eligible users are allowed
            filter((user) => user?.granteeIneligibleReason === null),

            // checking pending request
            switchMap((user) => {
                return this.pendingRequestService
                    .checkRequestForExistingUser(this.sagId, user.clientPoid)
                    .pipe(
                        map((result) => {
                            if (result.hasPendingRequest) {
                                this.showPendingRequestError();
                                return null;
                            }
                            return user;
                        }),
                    );
            }),

            // only users without pending request are allowed
            filter((user) => !!user),
        );

        this.subs.add(
            this.finalizedUser$.subscribe((user) => {
                this.addEligibleUser(user);
            }),
        );

        this.subs.add(
            this.currentUser$.subscribe((user) => {
                this.currentUser = user;
            }),
        );
    }

    checkGranteeIneligibility(user: UserDetailsDto) {
        if (user?.granteeIneligibleReason) {
            this.showIneligibilityError();
            this.goToNextStep.emit(false);
        }
    }

    showPendingRequestError() {
        this.errorService.showError(ErrorPage.GRANTEE_HAS_PENDING_REQUEST);
    }

    showIneligibilityError(): void {
        this.selectUserLevelModalDialog.openModalDialog();
    }

    navigateToForm() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    addEligibleUser(user: UserDetailsDto): void {
        this.store.dispatch(
            updateAddUserStepperSelectedUser({
                selectedUser: user,
            }),
        );
        this.loadNextStep();
    }

    loadGranteeIneligibilityInfo(): void {
        this.store.dispatch(
            ineligibleScenariosForGrantee({
                userDetails: this.selectedUserFormControl.value,
            }),
        );
    }

    onAddSomeoneElse(): void {
        this.addSomeoneElse.emit();
    }

    private onNextStepLoad(): void {
        this.selectedUserFormControl.markAsDirty();

        if (!this.selectedUserFormControl.valid) {
            this.goToNextStep.emit(false);
            return;
        }

        const granteeIneligibleReason =
            this.currentUser && 'granteeIneligibleReason' in this.currentUser;
        this.currentUser && 'granteeIneligibleReason' in this.currentUser;

        if (!granteeIneligibleReason) {
            this.loadGranteeIneligibilityInfo();
        }
    }

    private patchFormValueWithSelectedUser([existingUsers, selectedUser]: [
        UserDetailsDto[],
        UserDetailsDto,
    ]): void {
        if (selectedUser) {
            existingUsers.forEach((existingUser: UserDetailsDto) => {
                if (existingUser.clientPoid === selectedUser.clientPoid) {
                    this.selectedUserFormControl.patchValue(existingUser);
                }
            });
        }
    }

    private loadNextStep(): void {
        this.goToNextStep.emit(true);
    }
}
