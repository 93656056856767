import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { IneligibleScenariosService } from '../../services/ineligible-scenarios/ineligible-scenarios.service';
import { accountUsersDataLoadedSuccess } from '../actions/account-permissions-api.action';
import {
    ineligibleScenariosForGrantee,
    ineligibleScenariosForGranteeInGranteeFlow,
} from '../actions/ineligible-scenarios.action';
import * as ineligibleScenarios from './../actions/ineligible-scenarios.action';

@Injectable({
    providedIn: 'root',
})
export class IneligibleScenariosApiEffect {
    constructor(
        private readonly ineligibleScenariosService: IneligibleScenariosService,
        private readonly store: Store,
        private readonly actions$: Actions,
    ) {}

    ineligibleScenariosForGrantor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountUsersDataLoadedSuccess),
            switchMap(() => {
                return this.ineligibleScenariosService.getIneligibleScenariosForGrantor().pipe(
                    map((response) => {
                        return ineligibleScenarios.ineligibleScenariosSuccessForGrantor({
                            data: response,
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            ineligibleScenarios.ineligibleScenariosErrorForGrantor({ error }),
                        );
                    }),
                );
            }),
        );
    });

    ineligibleScenariosForGrantee$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ineligibleScenariosForGrantee),
            switchMap((action) => {
                return this.ineligibleScenariosService
                    .getIneligibleScenariosForGrantee(action.userDetails)
                    .pipe(
                        map(({ granteeIneligibleReason }) => {
                            return ineligibleScenarios.ineligibleScenariosSuccessForGrantee({
                                granteeIneligibleReason,
                                userDetails: action.userDetails,
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                ineligibleScenarios.ineligibleScenariosErrorForGrantee({ error }),
                            );
                        }),
                    );
            }),
        );
    });

    ineligibleScenariosForGranteeInGranteeFlow$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ineligibleScenariosForGranteeInGranteeFlow),
            switchMap(() => {
                return this.ineligibleScenariosService
                    .getIneligibleScenariosForGranteeInGranteeFlow()
                    .pipe(
                        map(({ granteeIneligibleReason }) => {
                            return ineligibleScenarios.ineligibleScenariosForGranteeInGranteeFlowSuccess(
                                {
                                    granteeIneligibleReason,
                                },
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                ineligibleScenarios.ineligibleScenariosForGranteeInGranteeFlowError(
                                    { error },
                                ),
                            );
                        }),
                    );
            }),
        );
    });
}
