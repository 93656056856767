import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Content } from '../../../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../../../utils/content/content.util';

@Component({
    selector: 'zci-raf-full-auth-note',
    templateUrl: './raf-full-auth-note.component.html',
    styleUrls: ['./raf-full-auth-note.component.scss'],
})
export class RafFullAuthNoteComponent {
    content: Content = ContentUtil.content;

    environment = environment;
}
