<zci-tile
    [navOverflowMenuConfig]="navOverflowMenuConfig"
    [isRequestPending]="false"
    data-cy="own-account-tile">
    <p class="c11n-space-stack-1x zci-text--bold col" variant="secondary-independent">
        {{ accName }}
    </p>
    <p *ngIf="accType" class="c11n-space-stack-1x">
        {{ accType | mapAccType }}
    </p>
    <zci-user-count [url]="usersOnAccountPath" [usrCount]="agentsCount"></zci-user-count>
</zci-tile>
