<div class="container zci-margin-top c11n-space-stack-8x c11n-space--force">
    <div class="c11n-space-stack-4x">
        <zci-back-link [link]="'/' + backLink"></zci-back-link>
    </div>

    <div class="container col-xl-7 col-md-10 col-sm-12 mx-auto">
        <ng-container
            *ngIf="
            withOnlineFlow;
            then withOnlineFlowTemplate;
            else withoutOnlineFlowTemplate
        "></ng-container>

        <div class="c11n-space-stack-6x">
            <zci-rm-info-tile></zci-rm-info-tile>
        </div>

        <p class="zci-h2 c11n-space-stack-1_5x">
            {{ content.commonInterstitialPages.paragraph2 }}
        </p>

        <p class="c11n-text-lg">
            {{ content.commonInterstitialPages.paragraph3[0] }}
            <a
                c11n-link
                data-cy="access-comparison-link"
                [variant]="'primary-reinforced'"
                [routerLink]="'/' + appRoutes.ABOUT"
            >{{ content.commonInterstitialPages.paragraph3[1] }}</a
            >
            {{ content.commonInterstitialPages.paragraph3[2] }}
        </p>
    </div>
</div>

<ng-template #withOnlineFlowTemplate>
    <h1 class="zci-h1 c11n-space-stack-1_5x" data-cy="page-title">
        {{ content.commonInterstitialPages.withOnlineFlow.pageTitle[0] }}
        <a
            c11n-link
            tabindex="0"
            class="pointer"
            [variant]="'primary-reinforced'"
            data-cy="simple-online-process-link"
            (click)="handleOnlineProcessLink()"
            >{{ content.commonInterstitialPages.withOnlineFlow.pageTitle[1] }}
        </a>
    </h1>

    <p class="c11n-text-lg c11n-space-stack-6x">
        {{
            content.commonInterstitialPages.withOnlineFlow.paragraph1
                | replaceParam: contactPersonTypePlaceHolder:(contactPersonType$ | async)
        }}
    </p>
</ng-template>

<ng-template #withoutOnlineFlowTemplate>
    <h1 class="zci-h1 c11n-space-stack-1_5x" data-cy="page-title">
        {{
            content.commonInterstitialPages.withoutOnlineFlow.pageTitle
                | replaceParam: contactPersonTypePlaceHolder:(contactPersonType$ | async)
        }}
    </h1>

    <p class="c11n-text-lg c11n-space-stack-6x">
        {{
            content.commonInterstitialPages.withoutOnlineFlow.paragraph1
                | replaceParam: contactPersonTypePlaceHolder:(contactPersonType$ | async)
        }}
    </p>
</ng-template>
