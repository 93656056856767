import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PERMISSION_REQUEST_ERRORS } from '../pin-validation/pin-validation.service';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import { LoggerService } from '../logger/logger.service';

export interface TokenValidationResponse {
    error: PERMISSION_REQUEST_ERRORS;
    accessLevel?: AccessTypeSelection;
    isExistingClient?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class TokenValidationService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly loggerService: LoggerService,
    ) {}

    validateToken(token: string): Observable<TokenValidationResponse> {
        return this.httpClient
            .post<TokenValidationResponse>(
                `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.VALIDATE_TOKEN}`,
                {
                    token: token,
                },
            )
            .pipe(
                catchError(() => {
                    this.loggerService.error(
                        `${TokenValidationService.name}: error while validating token: ${token}`,
                    );
                    return of({ error: PERMISSION_REQUEST_ERRORS.RECORD_NOT_FOUND });
                }),
            );
    }
}
