<div class="tile-content">
    <ng-content></ng-content>
</div>

<div *ngIf="navOverflowMenuConfig" class="c11n-space-inline-left-2x">
    <zci-action-nav-overflow-menu
        [navOverflowMenuConfig]="navOverflowMenuConfig"></zci-action-nav-overflow-menu>
    <ng-container *ngIf="isRequestPending">
        <zci-pending-chip></zci-pending-chip>
    </ng-container>
</div>
