import {AdobeMethods} from '../../../services/adobe-launch/adobe-launch.methods';
import {AdobeAnalyticsEvent} from '../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType
} from '../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../services/adobe-launch/adobe-launch.cta';

export const adobeConfigData = {
    ngOnInit: [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_COMPLETE,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                },
            ],
        },
    ],

    tagGoToMyAccountsButtonInfoOnly: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location:AdobeAnalyticsProcessStep.GAF_ACCESS_REQUEST_SUBMITTED_INFO_ONLY,
                    ctaName: AdobeAnalyticsProcessStep.GAF_GO_TO_MY_ACCOUNT,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],

    tagGoToMyAccountsButtonLimited: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location:AdobeAnalyticsProcessStep.GAF_ACCESS_REQUEST_SUBMITTED_LIMITED,
                    ctaName: AdobeAnalyticsProcessStep.GAF_GO_TO_MY_ACCOUNT,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ]
}
