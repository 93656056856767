import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { retry } from 'rxjs';
import { LogLevel } from 'src/common/dtos/client-log.dto';

import { environment } from '../../../environments/environment';
import { SpoidDescrambler } from '@vanguard/vg-spoid-descrambler';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    constructor(
        private readonly httpClient: HttpClient,
        private injector: Injector,
    ) {}

    info(message: string) {
        this.logServerSide(LogLevel.INFO, message);
    }

    warn(message: string) {
        this.logServerSide(LogLevel.WARN, message);
    }

    error(message: string, stackTrace?: string) {
        this.logServerSide(LogLevel.ERROR, message, stackTrace);
    }

    private logServerSide(level: LogLevel, message: string, stackTrace?: string) {
        const log = {
            level: level,
            message: this.formatMessage(stackTrace ? `${message}: ${stackTrace}` : message),
        };
        this.httpClient
            .post(`${environment.ZCI_API_BASE_URL}/client-log`, log)
            .pipe(retry(2))
            .subscribe({
                next: () => {},
                error: console.log,
            });
    }

    formatMessage(message: string): string {
        // ZciCookieService already exports spoid in window
        // importing ZciCookieService here causes circular dependency
        // @ts-expect-error spoid is not defined in window
        return `Serverless: ${message} POID: ${SpoidDescrambler.descrambleSpoid(window.spoid)}`
    }
}
