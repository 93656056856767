<zci-gaf-select-existing-user
    *ngIf="(userTypeSelection$ | async) === userTypeSelection.EXISTING"
    [currentStep]="currentStep"
    [accId]="accId"
    (next)="onNextStepLoad()"
    (back)="onBack()"
    (addSomeoneElse)="onAddSomeoneElse()"
    (contactUs)="onContactUs()"
    (stepChange)="onQuit()">
</zci-gaf-select-existing-user>

<zci-gaf-add-someone-else
    *ngIf="(userTypeSelection$ | async) === userTypeSelection.NEW"
    [currentStep]="currentStep"
    [accId]="accId"
    (next)="onNextStepLoad()"
    (back)="onBack()"
    (unsuccessfulAttempt)="onUnsuccessfulAttempt()"
    (contactUs)="onContactUs()"
    (stepChange)="onQuit()">
</zci-gaf-add-someone-else>

<c11n-modal-dialog
    [variant]="'basic'"
    [primaryButtonLabel]="content.selectUserLevelModalDialog.primaryButtonText"
    [secondaryButtonLabel]="content.selectUserLevelModalDialog.secondaryButtonText"
    (primaryClick)="onContinueModal()"
    [headingText]="content.selectUserLevelModalDialog.body"
    [maxWidth]="660"
    [hasForm]="true"
    [useContentWidth]="false"
    #selectUserLevelModalDialog>
    <div c11n-modal-dialog-body></div>
</c11n-modal-dialog>
<zci-complete-our-form></zci-complete-our-form>
