<zci-step-wrapper
    [title]="content.addUserFlow.step2.tileTitle"
    [nextButtonLabelText]="content.stepper.nextButtonLabelText"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickQuit)="onQuit(location)"
    (clickContactUs)="onContactUs(location)"
    [totalSteps]="content.addUserFlow.stepperTitles"
    [currentStep]="currentStep">
    <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
    <c11n-modal-dialog
        [variant]="'basic'"
        [primaryButtonLabel]="content.selectUserLevelModalDialog.primaryButtonText"
        [secondaryButtonLabel]="content.selectUserLevelModalDialog.secondaryButtonText"
        [headingText]="content.selectUserLevelModalDialog.body"
        [hideBody]="true"
        (primaryClick)="navigateToForm()"
        [maxWidth]="660"
        [useContentWidth]="false"
        #selectUserLevelModalDialog
        data-cy="error-modal">
    </c11n-modal-dialog>
    <c11n-banner
        role="alert"
        class="c11n-space-stack-3x"
        [size]="'small'"
        [variant]="'error'"
        [hasCloseControl]="false"
        *ngIf="selectedUserFormControl.invalid && selectedUserFormControl.dirty"
        data-cy="foreign-address-error-banner">
        <div
            class="c11n-text-md--crop"
            *ngIf="selectedUserFormControl.invalid && selectedUserFormControl.dirty">
            {{ content.addUserFlow.step2.validationMessage.required }}
        </div>
    </c11n-banner>

    <div class="c11n-space-stack-2x">
        <c11n-radio-group
            [groupId]="radioGroupId"
            [hideLegend]="true"
            [legendText]="content.addUserFlow.step2.tileTitle"
            [formGroup]="usrFormGroup">
            <c11n-radio
                *ngFor="let usr of usrs$ | async; index as i"
                [focusLabel]="true"
                [labelText]="constructUserName(usr)">
                <input
                    c11nRadioInput
                    type="radio"
                    [name]="selectedUserFormControlName"
                    [value]="usr"
                    [formControlName]="selectedUserFormControlName" />
            </c11n-radio>
        </c11n-radio-group>
    </div>

    <button
        c11n-link
        class="c11n-text-md pointer"
        iconPosition="leading"
        variant="primary-independent"
        iconName="plus"
        (click)="onAddSomeoneElse()"
        data-cy="stepper-add-someone-else-button">
        {{ content.addUserFlow.step2.addSomeoneElseLink }}
    </button>
</zci-step-wrapper>
