import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError, Observable, of, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { updateAccessTypeInGranteeFlow } from '../store/actions/grantee-flow.actions';
import {
    TokenValidationResponse,
    TokenValidationService,
} from '../services/token-validation/token-validation.service';
import { ErrorService } from '../services/generic-error/error.service';
import { ErrorPage, errorPageMap } from '../services/generic-error/error-page-utils';

@Injectable({
    providedIn: 'root',
})
export class TokenValidationResolver implements Resolve<TokenValidationResponse> {
    constructor(
        private store: Store,
        private tokenValidationService: TokenValidationService,
        private errorService: ErrorService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<TokenValidationResponse> {
        return this.tokenValidationService.validateToken(route.params.token).pipe(
            tap((data: TokenValidationResponse) => {
                if (!data || !Object.hasOwn(data, 'error')) {
                    this.errorService.showError(ErrorPage.TECH_DIFF);
                    return;
                }

                if (Object.hasOwn(errorPageMap, data.error)) {
                    this.errorService.showError(errorPageMap[data.error]);
                    return;
                }

                this.store.dispatch(updateAccessTypeInGranteeFlow(data.accessLevel));
            }),
            catchError((error) => {
                this.errorService.showError(ErrorPage.TECH_DIFF);
                return of(error);
            }),
        );
    }
}
