import { Injectable } from '@angular/core';

import { APPLICATION_ROUTES } from '../../constants/application-routes';
import { ZciOverflowItems } from '../../interfaces/zci-overflow-items.interface';
import { ContentUtil } from '../../utils/content/content.util';
import { navigationEvents } from './navigation-events';

@Injectable({
    providedIn: 'root',
})
export class ZciOverflowMenuService {
    get zciOverflowItems(): ZciOverflowItems {
        const content = ContentUtil.content;

        return {
            addUserStepperFlowFromAccount: {
                labelText: content.home.accountTile.actionItems.addUsers,
                routerLink: `/${APPLICATION_ROUTES.ADD_USER_TO_ACCOUNT.FULL_PATH}`,
                eventId: navigationEvents.ADD_USERS_STEPPER_FLOW_FROM_ACCOUNT,
            },
            removeUserFromAccount: {
                labelText: content.home.userTile.actionItems.default.removeUserFromAccount,
                routerLink: `/${APPLICATION_ROUTES.REMOVE_USER_ACCESS.FULL_PATH}`,
                eventId: navigationEvents.REMOVE_USER_FROM_ACCOUNT,
            },
            editUserInAccount: {
                labelText: content.home.userTile.actionItems.default.editUser,
                routerLink: `/${APPLICATION_ROUTES.EDIT_USER_ACCESS.FULL_PATH}`,
                eventId: navigationEvents.EDIT_USER_IN_ACCOUNT,
            },
            editUsers: {
                labelText: content.home.accountTile.actionItems.editUsers,
                routerLink: `/${APPLICATION_ROUTES.EDIT_USERS.FULL_PATH}`,
                eventId: navigationEvents.EDIT_USERS,
            },
            removeUsers: {
                labelText: content.home.accountTile.actionItems.removeUsers,
                routerLink: `/${APPLICATION_ROUTES.REMOVE_USERS.FULL_PATH}`,
                eventId: navigationEvents.REMOVE_USERS,
            },
            removeUserAccess: {
                labelText: content.home.userTile.actionItems.drillDown.removeAccess,
                routerLink: `/${APPLICATION_ROUTES.REMOVE_USER_ACCESS.FULL_PATH}`,
                eventId: navigationEvents.REMOVE_USER_ACCESS,
            },
            removeAccessFromAccount: {
                labelText: content.home.observableAccountTile.actionItems.removeAccessFromAccount,
                routerLink: `/${APPLICATION_ROUTES.REMOVE_ACCESS_FROM_ACCOUNT.FULL_PATH}`,
                eventId: navigationEvents.REMOVE_ACCESS_FROM_ACCOUNT,
            },
        };
    }
}
