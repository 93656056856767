import { Pipe, PipeTransform } from '@angular/core';

import { Sort, SortDirection } from '../../utils/sort/sort.interfaces';

@Pipe({
    name: 'ascDescLabel',
})
export class AscDescLabelPipe implements PipeTransform {
    transform(
        column: string,
        currentSortedColumn: Sort,
        content: { asc: string; desc: string; default: string },
    ): string {
        if (column === currentSortedColumn.dataKey) {
            if (currentSortedColumn.sortDir === SortDirection.ASC) {
                return content.asc;
            }

            if (currentSortedColumn.sortDir === SortDirection.DESC) {
                return content.desc;
            }
        }

        return content.default;
    }
}
