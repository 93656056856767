import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsProcessStep } from '../../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../../services/adobe-launch/adobe-launch.cta';

export function getAdobeConfigData(granteeFlow: 'existing' | 'new') {
    let pageName: string;

    if (granteeFlow === 'new') {
        pageName = 'AdobePageName.NEW_GRANTEE_PAGE';
    } else {
        pageName = AdobePageName.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST;
    }

    return {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [pageName],
            },
        ],
        onNextStepLoad: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST,
                        ctaName: AdobeAnalyticsProcessStep.GAF_CONTINUE,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
        openFaqDrawer: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST,
                        ctaName: AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_FAQ_LINK,
                        ctaType: AnalyticsCtaType.LINK,
                        fireTrackLink: true,
                    },
                ],
            },
        ],
    };
}
