import {
    AnalyticsCtaType,
    CtaCallUsLinkLocations,
    CtaNames,
} from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../../services/adobe-launch/adobe-launch.events';
import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../../services/adobe-launch/adobe-launch.process';

const configData = {
    ngOnInit: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GAF_TRIAGE],
        },
    ],
    navigateSelectUser: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    ctaName: CtaNames.ALREADY_HAS_ACCESS_PERMISSIONS,
                    ctaType: AnalyticsCtaType.LINK,
                    location: CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT,
                },
            ],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_START,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                },
            ],
        },
    ],
    navigateAddSomeoneElse: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    ctaName: CtaNames.OWN_VANGUARD_ACCOUNT,
                    ctaType: AnalyticsCtaType.LINK,
                    location: CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT,
                },
            ],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_START,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                },
            ],
        },
    ],
    navigateToFormFromNonVGUser: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    ctaName: CtaNames.WITHOUT_VANGUARD_ACCOUNT,
                    ctaType: AnalyticsCtaType.LINK,
                    location: CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT,
                },
            ],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_START,
                {
                    processType: AdobeAnalyticsProcessType.GAF_LEGACY_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                },
            ],
        },
    ],
    navigateToFormFromOrgTrust: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    ctaName: CtaNames.ORGANIZATION_OR_TRUST,
                    ctaType: AnalyticsCtaType.LINK,
                    location: CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT,
                },
            ],
        },
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_START,
                {
                    processType: AdobeAnalyticsProcessType.GAF_LEGACY_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                },
            ],
        },
    ],
    onBack: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobePageName.GAF_TRIAGE,
                    ctaName: AdobeAnalyticsProcessStep.GAF_BACK,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],
};
export default configData;
