import { Injectable, Injector } from '@angular/core';

import { IneligibleReasons } from '../../../common/enums/ineligible-scenarios.enum';
import { MapAccountTypePipe } from '../../pipes/map-account-type/map-account-type.pipe';

export interface ObservableAccountTileInputDataProps {
    accName: string;
    accType: string;
    roleType: string;
}

export interface OwnAccountTileInputDataProps {
    accId: string;
    accName: string;
    accType: string;
    agentsCount: number;
    servAgreeId?: string;
    accountIsIneligibleFor: Array<IneligibleReasons>;
}

type DataProps = Pick<
    ObservableAccountTileInputDataProps | OwnAccountTileInputDataProps,
    'accName' | 'accType'
>;

@Injectable({
    providedIn: 'root',
})
export class SortService {
    private readonly mapAccountTypePipe: MapAccountTypePipe;

    constructor(private injector: Injector) {
        this.mapAccountTypePipe = this.injector.get<MapAccountTypePipe>(MapAccountTypePipe);
    }

    sortAccountsForCardView<T extends DataProps>(accounts: T[]): T[] {
        return [...accounts].sort((a1, a2) => {
            const firstLetter1 = a1.accName[0];
            const firstLetter2 = a2.accName[0];

            const firstLetterCompare = firstLetter1.localeCompare(firstLetter2);

            if (firstLetterCompare) return firstLetterCompare;

            const acc1Type = this.mapAccountTypePipe.transform(a1.accType);
            const acc2Type = this.mapAccountTypePipe.transform(a2.accType);

            const accTypeCompare = acc1Type.localeCompare(acc2Type);

            if (accTypeCompare) {
                return accTypeCompare;
            }

            const accNumber1 = Number(a1.accName.replace(/\D/g, ''));
            const accNumber2 = Number(a2.accName.replace(/\D/g, ''));

            return accNumber1 - accNumber2;
        });
    }
}
