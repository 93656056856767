import { Deployment } from '../constants';
import { environmentGeneric } from './environment.generic';

// Stryker disable all
export const production = {
    ...environmentGeneric,
    development: false,
    isProduction: true,
    ADOBE_LAUNCH_ENV: 'production',
    APP_URL: 'https://access-permissions.web.vanguard.com',
    ACCOUNT_SETTINGS_URL: 'https://personal1.vanguard.com/mnt-profile-account-settings/',
    CHECK_LOGON:
        'https://webservices.ecs.retp.c1.vanguard.com/lgo-common-logon-logoff-ecs-webservice/logon',
    LOGON_REDIRECT_URL: 'https://logon.vanguard.com?TARGET_OVERRIDE=',
    ZCI_API_BASE_URL: 'https://webservices.ecs.retp.c1.vanguard.com/zci-account-permission-center',
    AWS: {
        SECRET_ARN:
            'arn:aws:secretsmanager:us-east-1:811152268169:secret:AWS_ZCI_OAuthCAG_ZCI_PRD-jDfYTI',
        ADID_SECRET_ARN:
            'arn:aws:secretsmanager:us-east-1:811152268169:secret:ADS_ZCI_ADID_PRD_APP_SECRET-ctl555',
        AWS_PROFILE: 'vgi-retail-prod',
        AWS_REGION: 'us-east-1',
        ASSUME_ROLE_ARN: 'arn:aws:iam::287025219354:role/ZCIIamRole',
    },
    LEGACY_FLOW_URL: 'https://personal.vanguard.com/us/legals/ViewAccess',
    AEM_GRAPHQL: {
        BASE_URL: 'https://pi.aemgraphql.vanguard.com/graphql/execute.json',
        CONF_NAME: '/access-permissions',
        GRANTOR_CONTENT_QUERY_NAME: '/access_permissions',
        GRANTEE_CONTENT_QUERY_NAME: '/grantee-flow',
    },

    AGENT_AUTHORIZATION_FORM_URL:
        'https://personal1.vanguard.com/eel-esign-summary-webapp/?interviewId=38a19d98-cd32-43e4-8dd8-398982298e67&ELF_ENTRY_POINT=true',
    ACCOUNT_BALANCES_URL: 'https://personal.vanguard.com/us/MyHome',
    C3_URL: 'https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js',
    C3_CONFIG_DEPLOYMENT: Deployment.PROD,
    TEALEAF_URL: 'https://personal1.vanguard.com/zci-account-permission-center/tealeaf',
    APP_DYNAMICS_KEY: 'AD-AAB-ACY-SAZ',
    DASHBOARD_URL: 'https://dashboard.web.vanguard.com',
    GRANTEE_WEB_REG_URL: 'https://web-registration.vanguard.com',
};
