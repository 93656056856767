import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
    selector: 'zci-fake-step-two',
    templateUrl: './fake-step-two.component.html',
    standalone: true,
    styleUrls: ['./fake-step-two.component.css'],
})
export class FakeStepTwoComponent implements OnInit {
    @Input() nextStepClicked: EventEmitter<any>;
    @Input() nextStepReady: EventEmitter<any>; // TODO rename nextStepLoaded

    ngOnInit() {
        this.nextStepClicked.subscribe(()=>{
            this.onNextStepClicked()
            // this.nextStep.emit(true)
        })
    }

    onNextStepClicked(){
        setTimeout(()=>{
            this.nextStepReady.emit(true);
        }, 3000)
    }
}
