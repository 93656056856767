<div class="container zci-margin-top c11n-space-stack-10x c11n-space--force">
    <div class="c11n-space-stack-4x">
        <zci-back-link [link]="'/' + appRoutes.OBSERVABLE_ACCOUNTS"></zci-back-link>
    </div>
    <div class="col-xl-8 col-md-10 col-sm-12 mx-auto">
        <h1 class="zci-h1 c11n-space-stack-1_5x" data-cy="page-title">
            {{
                content.accountInterstitialPages.removeAccessFromAccount.pageHeading
                    | replaceParam: contactPersonTypePlaceHolder:(contactPersonType$ | async)
            }}
        </h1>

        <div class="c11n-text-lg c11n-space-stack-6x">
            <p
                [innerHTML]="
                content.accountInterstitialPages.removeAccessFromAccount.pageDescription
                    | replaceParam: contactPersonTypePlaceHolder:(contactPersonType$ | async)
            "></p>
        </div>

        <zci-rm-info-tile></zci-rm-info-tile>
    </div>

</div>
