import { Injectable } from '@angular/core';
import { APPLICATION_ROUTES } from '../../constants/application-routes';
import { Router } from '@angular/router';
import { ContentUtil } from '../../utils/content/content.util';
import { environment } from '../../../environments/environment.development-external';
import { ErrorPage } from './error-page-utils';

export interface ErrorContent {
    icon?: string;
    title: string;
    text: string;
    primaryButton: {
        label: string;
        url: string;
    };
    secondaryButton?: {
        label: string;
        url: string;
    };
    link?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    constructor(private router: Router) {}

    granteeContent = ContentUtil.granteeFragmentsContent;
    grantorContent = ContentUtil.grantorFragmentContent;

    errorContents: Record<string, ErrorContent> = {
        [ErrorPage.TECH_DIFF]: {
            title: this.granteeContent.errExperiencingTechnicalDifficulties.heading,
            text: this.granteeContent.errExperiencingTechnicalDifficulties.text1,
            primaryButton: {
                label: this.granteeContent.errExperiencingTechnicalDifficulties.navGoToMyDashboard,
                url: environment.DASHBOARD_URL,
            },
        },
        [ErrorPage.GRANTEE_CANT_COMPLETE_ONLINE]: {
            title: this.granteeContent.errProcessCanNotCompleteOnline.heading,
            text: this.granteeContent.errProcessCanNotCompleteOnline.text1,
            primaryButton: {
                label: this.granteeContent.errProcessCanNotCompleteOnline.navGoToMyDashboard,
                url: environment.DASHBOARD_URL,
            },
        },
        [ErrorPage.GRANTEE_PIN_EXPIRED]: {
            title: this.granteeContent.errPinExpired.heading,
            text: this.granteeContent.errPinExpired.text1,
            primaryButton: {
                label: this.granteeContent.errPinExpired.navGoToMyDashboard,
                url: environment.DASHBOARD_URL,
            },
        },
        [ErrorPage.GRANTEE_TOO_MANY_ATTEMPTS]: {
            title: this.granteeContent.frmcGranteeFlow.errTooManyUnsuccessfulAttempts.heading,
            text: this.granteeContent.frmcGranteeFlow.errTooManyUnsuccessfulAttempts.text1,
            primaryButton: {
                label: this.granteeContent.frmcGranteeFlow.errTooManyUnsuccessfulAttempts
                    .navReturnToAccessPermissions,
                url: location.origin,
            },
        },
        [ErrorPage.GRANTEE_HAS_PENDING_REQUEST]: {
            title: this.grantorContent.add_user.errGranteeAlreadyPendingRequest.heading,
            text: this.grantorContent.add_user.errGranteeAlreadyPendingRequest.text1,
            primaryButton: {
                label: this.granteeContent.frmcGranteeFlow.errTooManyUnsuccessfulAttempts
                    .navReturnToAccessPermissions,
                url: location.origin,
            },
            secondaryButton: {
                label: this.granteeContent.errPinExpired.navGoToMyDashboard,
                url: environment.DASHBOARD_URL,
            },
        },
        [ErrorPage.GRANTEE_FOREIGN_ADDRESS]: {
            title: this.granteeContent.frmcGranteeFlow.errForeignAddressOnlineProcess.heading,
            text: this.granteeContent.frmcGranteeFlow.errForeignAddressOnlineProcess.text1,
            primaryButton: {
                label: this.granteeContent.errPinExpired.navGoToMyDashboard,
                url: environment.DASHBOARD_URL,
            },
        },
    };

    showError(key: ErrorPage) {
        let finalKey = key;
        if (!Object.hasOwn(this.errorContents, finalKey)) {
            finalKey = ErrorPage.TECH_DIFF;
        }

        this.router.navigate([APPLICATION_ROUTES.GENERIC_ERROR], {
            state: {
                internal: true,
                errorContent: this.errorContents[finalKey],
                errorPage: finalKey,
            },
        });
    }
}
