import { Content } from '../../../../common/interfaces/content.interface';
import {
    ActionNavOverflowMenuComponent,
    ActionNavOverflowMenuComponentProps,
} from '../../action-nav-overflow-menu/action-nav-overflow-menu.component';
import { TableColumn } from '../../table/table.interface';

type UserTableHeaderTitles = Content['home']['userTable']['headers'];

export enum UsersTableProps {
    UsrName = 'usrName',
    AccName = 'accName',
    AccType = 'accType',
    RoleType = 'roleType',
    Actions = 'actions',
}

export interface UserTableRow {
    [UsersTableProps.UsrName]: string;
    [UsersTableProps.AccName]: string;
    [UsersTableProps.AccType]: string;
    [UsersTableProps.RoleType]: string;
    [UsersTableProps.Actions]: ActionNavOverflowMenuComponentProps;
}

export const getUsersTableCols = (headerTitles: UserTableHeaderTitles): TableColumn[] => [
    {
        title: headerTitles.user,
        dataKey: UsersTableProps.UsrName,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.accountName,
        dataKey: UsersTableProps.AccName,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.accountType,
        dataKey: UsersTableProps.AccType,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.permissionType,
        dataKey: UsersTableProps.RoleType,
        sortable: true,
        visible: true,
    },
    {
        title: headerTitles.actions,
        dataKey: UsersTableProps.Actions,
        sortable: false,
        visible: true,
        customCell: ActionNavOverflowMenuComponent,
    },
];
