import { Component, NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GATEKEEPER_TOGGLES } from '../common/constants/gatekeeper-toggles';
import { ZciRoutes } from './app-routing.config';
import { DynamicRouteComponent } from './components/dynamic-route/dynamic-route.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { AccountPermissionsPageComponent } from './components/pages/account-permissions-page/account-permissions-page.component';
import { IneligiblePageComponent } from './components/pages/ineligible-page/ineligible-page.component';
import { EditUsersPageComponent } from './components/pages/interstitial-pages/accounts-interstitial-pages/edit-users-page/edit-users-page.component';
import { RemoveFromAccountPageComponent } from './components/pages/interstitial-pages/accounts-interstitial-pages/remove-from-account-page/remove-from-account-page.component';
import { RemoveUsersPageComponent } from './components/pages/interstitial-pages/accounts-interstitial-pages/remove-users-page/remove-users-page.component';
import { AddExistingUserPageComponent } from './components/pages/interstitial-pages/users-interstitial-pages/add-existing-user-page/add-existing-user-page.component';
import { EditUserAccessPageComponent } from './components/pages/interstitial-pages/users-interstitial-pages/edit-user-access-page/edit-user-access-page.component';
import { RemoveUserAccessPageComponent } from './components/pages/interstitial-pages/users-interstitial-pages/remove-user-access-page/remove-user-access-page.component';
import { RemoveUserFromAccountPageComponent } from './components/pages/remove-user-from-account-page/remove-user-from-account-page.component';
import { TechnicalDifficultiesPageComponent } from './components/pages/technical-difficulties-page/technical-difficulties-page.component';
import { UsersOnAccountPageComponent } from './components/pages/users-on-account-page/users-on-account-page.component';
import { ObservableAccountsComponent } from './components/tabs/observable-accounts/observable-accounts.component';
import { OwnAccountsComponent } from './components/tabs/own-accounts/own-accounts.component';
import { UsersComponent } from './components/tabs/users/users.component';
import { APPLICATION_ROUTES, PUBLIC_ROUTES } from './constants/application-routes';
import { InternalNavigationGuard } from './guards/internal-navigation.guard';
import { TechnicalDifficultiesRedirectGuard } from './guards/technical-difficulties-redirect.guard';
import { UsersOnAccountGuard } from './guards/users-on-account.guard';
import { ContentResolver } from './resolvers/content.resolver';
import { AddUserToAccountPageMvpComponent } from './components/pages/add-user-to-account-page-mvp/add-user-to-account-page-mvp.component';
import { IneligibleScenariosResolver } from './resolvers/ineligible-scenarios.resolver';
import { GranteeLandingPageComponent } from './components/pages/grantee-landing-page/grantee-landing-page.component';
import { TokenValidationResolver } from './resolvers/validate-token.resolver';
import { AddUserToAccountPageComponent } from './components/pages/add-user-to-account-page/add-user-to-account-page.component';
import { GenericErrorComponent } from './components/pages/generic-error/generic-error.component';
import { GranteeWebRegPageComponent } from './components/pages/grantee-web-reg-page/grantee-web-reg-page.component';

const routes: ZciRoutes = [
    {
        path: '',
        resolve: {
            content: ContentResolver,
        },
        children: [
            {
                path: PUBLIC_ROUTES.GRANTEE_WEB_REG,
                component: GranteeWebRegPageComponent,
            },
            {
                path: APPLICATION_ROUTES.HOME,
                component: AccountPermissionsPageComponent,
                children: [
                    {
                        path: APPLICATION_ROUTES.USERS,
                        component: UsersComponent,
                    },
                    {
                        path: APPLICATION_ROUTES.OWN_ACCOUNTS,
                        component: OwnAccountsComponent,
                    },
                    {
                        path: APPLICATION_ROUTES.OBSERVABLE_ACCOUNTS,
                        component: ObservableAccountsComponent,
                    },
                ],
            },
            { path: APPLICATION_ROUTES.ABOUT, component: AboutPageComponent },
            {
                path: APPLICATION_ROUTES.USERS_ON_ACCOUNT.FULL_PATH,
                component: UsersOnAccountPageComponent,
                canActivate: [UsersOnAccountGuard],
            },
            {
                path: APPLICATION_ROUTES.EDIT_USERS.FULL_PATH,
                component: EditUsersPageComponent,
            },
            {
                path: APPLICATION_ROUTES.REMOVE_USERS.FULL_PATH,
                component: DynamicRouteComponent,
                canActivate: [UsersOnAccountGuard],
                data: {
                    dynamicRouteComponent: {
                        featureId: GATEKEEPER_TOGGLES.REMOVE_USER_ACCESS_FROM_ACCOUNT_ENABLED,
                        offComponent: <Type<Component>>RemoveUsersPageComponent,
                        onComponent: <Type<Component>>RemoveUserFromAccountPageComponent,
                    },
                },
            },
            {
                path: APPLICATION_ROUTES.ADD_EXISTING_USER.FULL_PATH,
                component: AddExistingUserPageComponent,
            },
            {
                path: APPLICATION_ROUTES.EDIT_USER_ACCESS.FULL_PATH,
                component: EditUserAccessPageComponent,
            },
            {
                path: APPLICATION_ROUTES.REMOVE_USER_ACCESS.FULL_PATH,
                component: RemoveUserAccessPageComponent,
            },
            {
                path: APPLICATION_ROUTES.REMOVE_ACCESS_FROM_ACCOUNT.FULL_PATH,
                component: RemoveFromAccountPageComponent,
            },
            {
                path: APPLICATION_ROUTES.ADD_USER_TO_ACCOUNT.FULL_PATH,
                component: DynamicRouteComponent,
                canActivate: [UsersOnAccountGuard],
                data: {
                    dynamicRouteComponent: {
                        featureId: GATEKEEPER_TOGGLES.MVP_ONE_TOGGLE_ENABLED,
                        offComponent: <Type<Component>>AddUserToAccountPageComponent,
                        onComponent: <Type<Component>>AddUserToAccountPageMvpComponent,
                    },
                },
            },
            {
                path: APPLICATION_ROUTES.INELIGIBLE.FULL_PATH,
                component: IneligiblePageComponent,
            },
            {
                path: APPLICATION_ROUTES.TECH_DIFF_REDIRECT,
                canActivate: [TechnicalDifficultiesRedirectGuard],
                component: TechnicalDifficultiesPageComponent,
            },
            {
                path: APPLICATION_ROUTES.TECHNICAL_DIFFICULTIES,
                component: TechnicalDifficultiesPageComponent,
                canDeactivate: [InternalNavigationGuard],
                canActivate: [InternalNavigationGuard],
            },
            {
                path: APPLICATION_ROUTES.ACCEPT_ACCESS,
                component: GranteeLandingPageComponent,
                resolve: {
                    validateToken: TokenValidationResolver,
                    ineligibleScenarios: IneligibleScenariosResolver,
                },
            },
            {
                path: APPLICATION_ROUTES.GENERIC_ERROR,
                component: GenericErrorComponent,
                canDeactivate: [InternalNavigationGuard],
                canActivate: [InternalNavigationGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
