import { Injectable } from '@angular/core';
import { ContentUtil } from 'src/app/utils/content/content.util';
import { ReferenceList } from 'src/app/utils/reference-lists/reference-list.interface';
import { getReferenceListDescriptionByCode } from 'src/app/utils/reference-lists/reference-list.util';
import { AccountTypeCodes } from 'src/common/constants/account-type-codes';

@Injectable({
    providedIn: 'root',
})
export class AccountTypeMapper {
    mapCodeToAccountType(code: string) {
        return getReferenceListDescriptionByCode(this.accountType, code);
    }

    get accountType(): ReferenceList[] {
        const accountTypeContent = ContentUtil.content.referenceLists.accountType;

        return [
            {
                codes: [AccountTypeCodes.INDIVIDUAL],
                description: accountTypeContent.individual,
            },
            {
                codes: [
                    AccountTypeCodes.JOINT,
                    AccountTypeCodes.JOINT_COMMUNITY_PROPERTY,
                    AccountTypeCodes.JOINT_COMMUNITY_PROPERTY_WROS,
                    AccountTypeCodes.JOINT_MARITAL_PROPERTY,
                    AccountTypeCodes.JOINT_MARITAL_PROPERTY_WROS,
                    AccountTypeCodes.JOINT_TENANTS_IN_COMMON,
                    AccountTypeCodes.JOINT_TENANTS_WROS,
                    AccountTypeCodes.JOINT_TENANTS_ENTIRETY_WROS,
                ],
                description: accountTypeContent.joint,
            },
            {
                codes: [
                    AccountTypeCodes.TRUST,
                    AccountTypeCodes.UNDER_WILL_TRUST,
                    AccountTypeCodes.IIG_TRUST,
                    AccountTypeCodes.BANKRUPTCY_TRUST,
                    AccountTypeCodes.LIVING_TRUST,
                    AccountTypeCodes.GRANTOR_TRUST,
                ],
                description: accountTypeContent.trust,
            },
            {
                codes: [AccountTypeCodes.ROLLOVER_IRA],
                description: accountTypeContent.rolloverIRA,
            },
            {
                codes: [AccountTypeCodes.TRADITIONAL_IRA],
                description: accountTypeContent.traditionalIRA,
            },
            {
                codes: [AccountTypeCodes.SIMPLE_IRA],
                description: accountTypeContent.simpleIRA,
            },
            {
                codes: [
                    AccountTypeCodes.SEP_IRA,
                    AccountTypeCodes.SEP_IRA_SINGLE_PARTICIPANT,
                    AccountTypeCodes.SEP_IRA_MULTIPLE_PARTICIPANT,
                ],
                description: accountTypeContent.sepIRA,
            },
            {
                codes: [AccountTypeCodes.ROTH_IRA],
                description: accountTypeContent.rothIRA,
            },
            {
                codes: [AccountTypeCodes.INHERITED_IRA],
                description: accountTypeContent.inheritedIRA,
            },
            {
                codes: [AccountTypeCodes.INHERITED_ROTH_IRA],
                description: accountTypeContent.inheritedRothIRA,
            },
            {
                codes: [AccountTypeCodes.CONVERSION_ROTH_IRA],
                description: accountTypeContent.rothConversionIRA,
            },
            {
                codes: [AccountTypeCodes.UGMA],
                description: accountTypeContent.ugma,
            },
            {
                codes: [AccountTypeCodes.UTMA],
                description: accountTypeContent.utma,
            },
            {
                codes: [AccountTypeCodes.KEOGH],
                description: accountTypeContent.keogh,
            },
            {
                codes: [
                    AccountTypeCodes.AMENDED_MPPP,
                    AccountTypeCodes.DEDUCTIBLE_MPPP,
                    AccountTypeCodes.CONTRIB_MPPP,
                    AccountTypeCodes.NON_DEDUCTIBLE_MPPP,
                ],
                description: accountTypeContent.moneyPurchasePensionPlan,
            },
            {
                codes: [
                    AccountTypeCodes.DEDUCTIBLE_PSP,
                    AccountTypeCodes.ROLLOVER_PSP,
                    AccountTypeCodes.AMENDED_PSP,
                ],
                description: accountTypeContent.profitSharingPlan,
            },
            {
                codes: [AccountTypeCodes.NON_ERISA_403B7, AccountTypeCodes.ERISA_403B7],
                description: accountTypeContent['403b7'],
            },
            {
                codes: [AccountTypeCodes.EDUCATION_SAVINGS],
                description: accountTypeContent.educationSavingsAccount,
            },
            {
                codes: [
                    AccountTypeCodes.INDIVIDUAL_401K,
                    AccountTypeCodes.INDIVIDUAL_401K_SUSPENSE,
                    AccountTypeCodes.SMALL_MARKET_401K,
                ],
                description: accountTypeContent.individual401k,
            },
            {
                codes: [AccountTypeCodes.ROTH_INDIVIDUAL_401K],
                description: accountTypeContent.individualRoth401k,
            },
            {
                codes: [
                    AccountTypeCodes.AMENDED_VRP,
                    AccountTypeCodes.GUST_AMENDED_VRP,
                    AccountTypeCodes.POOLED_VRIP,
                    AccountTypeCodes.VRIP,
                    AccountTypeCodes.VANGUARD_BUSINESS_ACCOUNT_SERVICE,
                    AccountTypeCodes.FIDUCIARY,
                    AccountTypeCodes.HOSPITAL,
                    AccountTypeCodes.SCHOOL_DISTRICT,
                    AccountTypeCodes.DEFINED_BENEFIT_PENSION_PLAN,
                    AccountTypeCodes.ESTATE,
                    AccountTypeCodes.LIFE_TENTANT_ESTATE,
                    AccountTypeCodes.BANK_NOMINEE,
                    AccountTypeCodes.PARTNERSHIP,
                    AccountTypeCodes.IIG_CORPORATION,
                    AccountTypeCodes.CORPORATION,
                    AccountTypeCodes.CORPORATION_BANKRUPTCY,
                    AccountTypeCodes.EXEMPT_UNICORPORATED_ORGANIZATION,
                    AccountTypeCodes.FOUNDATION,
                    AccountTypeCodes.NONEXEMPT_UNINCOPORATED_ORGANIZATOIN,
                    AccountTypeCodes.BROKER_DEALER,
                    AccountTypeCodes.PROFESSIONAL_CORPORATION_ASSOCIATION,
                    AccountTypeCodes.PROFESSIONAL_CORPORATION,
                    AccountTypeCodes.PROFESSIONAL_ASSOCIATION,
                    AccountTypeCodes.SOLE_PROPRIETORSHIP,
                    AccountTypeCodes.RETIREMENT_TRUST_PSP,
                    AccountTypeCodes.RETIREMENT_TRUST_401K,
                    AccountTypeCodes.RETIREMENT_TRUST_403B7,
                    AccountTypeCodes.RETIREMENT_TRUST_457,
                    AccountTypeCodes.RETIREMENT_TRUST_PENSION_PLAN,
                    AccountTypeCodes.RETIREMENT_TRUST_MPPP,
                    AccountTypeCodes.RETIREMENT_TRUST_401K_PSP,
                    AccountTypeCodes.RETIREMENT_TRUST_DEFERRED_COMPENSATION,
                    AccountTypeCodes.AUDIT_97,
                    AccountTypeCodes.SUNGARD,
                ],
                description: accountTypeContent.account,
            },
            {
                codes: [
                    AccountTypeCodes.CUSTODIAN,
                    AccountTypeCodes.CUSTODIAN_MINOR,
                    AccountTypeCodes.ATTORNEY_IN_FACT,
                    AccountTypeCodes.ESCROW_AGENT,
                ],
                description: accountTypeContent.custodian,
            },
            {
                codes: [AccountTypeCodes.USUFRUCT, AccountTypeCodes.GUARDIAN],
                description: accountTypeContent.guardian,
            },
            {
                codes: [AccountTypeCodes.ENDOWMENT],
                description: accountTypeContent.charitableEndowment,
            },
            {
                codes: [AccountTypeCodes.EXECUTOR_ESTATE],
                description: accountTypeContent.estate,
            },
            {
                codes: [AccountTypeCodes.UNKNOWN],
                description: accountTypeContent.unknown,
            },
            {
                codes: [AccountTypeCodes.PARTICIPANT],
                description: accountTypeContent.participant,
            },
            {
                codes: [AccountTypeCodes.OTHER],
                description: accountTypeContent.other,
            },
            {
                codes: [AccountTypeCodes.OUTSIDE_HOLDINGS],
                description: accountTypeContent.outsideHoldings,
            },
            {
                codes: [AccountTypeCodes.OUTSIDE_INVESTMENT],
                description: accountTypeContent.outsideInvestment,
            },
        ];
    }
}
