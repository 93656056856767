import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NavOverflowItem } from '@vg-constellation/angular-16/nav-overflow';

import { NavOverflowExtendedData } from '../../../interfaces/nav-overflow-extended-data.interface';
import { UsersService } from '../../../services/users/users.service';
import { ContentUtil } from '../../../utils/content/content.util';

export interface UserTileInputDataProps {
    accId?: string;
    accName?: string;
    accType?: string;
    roleType: string;
    usrName: string;
    accessLevel?: string;
}

@Component({
    selector: 'zci-user-tile',
    templateUrl: './user-tile.component.html',
})
export class UserTileComponent implements OnChanges, UserTileInputDataProps {
    content = ContentUtil.content;

    navOverflowMenuConfig: NavOverflowExtendedData;

    @Input() accName?: string;

    @Input() accType?: string;

    @Input() roleType: string;

    @Input() usrName: string;

    @Input() hasSelectedAccount: boolean = false;

    @Output() selectAction: EventEmitter<NavOverflowItem> = new EventEmitter();

    constructor(private readonly usersService: UsersService) {}

    ngOnChanges() {
        this.navOverflowMenuConfig = this.usersService.getNavOverflowMenu(this.hasSelectedAccount);
    }
}
