<zci-step-wrapper
    [title]="content.addUserFlow.step4.title"
    [nextButtonLabelText]="content.addUserFlow.step4.nextButtonLabelText"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickQuit)="onQuit(location)"
    (clickContactUs)="onContactUs(location)"
    [totalSteps]="content.addUserFlow.stepperTitles"
    [currentStep]="currentStep"
    data-cy="step-wrapper">
    <div
        class="scrollable-box c11n-text-md"
        tabindex="0"
        *ngIf="isInfoOnly"
        [innerHTML]="content.addUserFlow.step4.informationOnly"></div>
    <div
        class="scrollable-box c11n-text-md"
        tabindex="0"
        *ngIf="isLimitedOnly"
        [innerHTML]="content.addUserFlow.step4.limitedAccess"></div>
</zci-step-wrapper>
