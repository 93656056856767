import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, Subject, take, tap } from 'rxjs';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';

import { CONSTANTS } from '../../../../common/constants/constants';
import { Content } from '../../../../common/interfaces/content.interface';
import { ViewType } from '../../../interfaces/view-type.enum';
import { MapAccountTypePipe } from '../../../pipes/map-account-type/map-account-type.pipe';
import { MapRoleTypePipe } from '../../../pipes/role-type/map-role-type.pipe';
import { PaginationProp, PaginatorService } from '../../../services/paginator/paginator.service';
import { TitleService } from '../../../services/title/title.service';
import { UsersService } from '../../../services/users/users.service';
import { updateSelectedViewTypeForUsers } from '../../../store/actions/view-type.action';
import {
    getSelectedViewTypeForUsers,
    selectUsersWithNames,
} from '../../../store/selectors/account-permissions.selector';
import { ContentUtil } from '../../../utils/content/content.util';
import contentUtils from '../../../utils/content/content-format.util';
import { Sort } from '../../../utils/sort/sort.interfaces';
import sortUtils from '../../../utils/sort/sort.util';
import { TableColumn } from '../../table/table.interface';
import { UserTileInputDataProps } from '../../tiles/user-tile/user-tile.component';
import { TabComponent } from '../tab.component';
import { getUsersTableCols, UsersTableProps, UserTableRow } from './users-table.config';

@Component({
    selector: 'zci-users',
    templateUrl: './users.component.html',
    providers: [PaginatorService],
})
export class UsersComponent extends TabComponent implements OnInit, OnDestroy {
    content: Content = ContentUtil.content;

    usrs$: Observable<UserTileInputDataProps[]>;

    sortedUsers$: Observable<UserTileInputDataProps[]>;

    usrsDataTable$: Observable<UserTableRow[]>;

    usrsPaginated: PaginationProp<UserTileInputDataProps>;

    usrsTableCols: TableColumn[] = getUsersTableCols(this.content.home.userTable.headers);

    private readonly paginatorService: PaginatorService;

    private readonly store: Store;

    private readonly titleService: TitleService;

    private readonly mapAccountTypePipe: MapAccountTypePipe;

    private readonly mapRoleTypePipe: MapRoleTypePipe;

    private readonly adobeLaunchService: AdobeLaunchService;

    private readonly usersService: UsersService;

    private readonly subTitle: string = this.content.home.accountsNavTab.usersItemTitle;

    private destroy$: Subject<void> = new Subject();

    constructor(private readonly injector: Injector) {
        super(injector);
        this.paginatorService = this.injector.get<PaginatorService>(PaginatorService);
        this.store = this.injector.get<Store>(Store);
        this.titleService = this.injector.get<TitleService>(TitleService);
        this.mapAccountTypePipe = this.injector.get<MapAccountTypePipe>(MapAccountTypePipe);
        this.mapRoleTypePipe = this.injector.get<MapRoleTypePipe>(MapRoleTypePipe);
        this.usersService = this.injector.get<UsersService>(UsersService);
        this.adobeLaunchService = this.injector.get<AdobeLaunchService>(AdobeLaunchService);
    }

    get loadMoreUsersLabel$(): Observable<string> {
        return this.usrsPaginated.nextCount$.pipe(
            map((count: number) => {
                return contentUtils.getContentWithDynamicNumber(
                    this.content.home.loadMoreUsers,
                    count,
                );
            }),
        );
    }

    get destroy(): Subject<void> {
        return this.destroy$;
    }

    ngOnInit(): void {
        this.usrs$ = this.store.select(selectUsersWithNames);

        this.titleService.setTitle(this.subTitle);

        this.usrsDataTable$ = this.usrs$.pipe(map(this.mapToUserTableRow.bind(this)));

        const { hasMore$, isLoading$, nextCount$ } = this.paginatorService;

        this.sortedUsers$ = this.usrs$.pipe(map(this.sortUsersForCardView.bind(this)));

        const data$ = this.paginatorService.initPaginator<UserTileInputDataProps>(
            this.sortedUsers$,
            CONSTANTS.PAGINATOR_PAGE_SIZE,
        );

        this.usrsPaginated = {
            data$,
            hasMore$,
            isLoading$,
            nextCount$,
            loadMore: () => this.paginatorService.loadMore(),
        };

        this.restoreViewTypeSelection();
        this.subscribeOnBreakpointChange();
    }

    ngOnDestroy(): void {
        this.saveViewTypeSelection();
        this.destroy$.next();
        this.destroy$.complete();
    }

    sortUsersForCardView(users: UserTileInputDataProps[]): UserTileInputDataProps[] {
        return [...users].sort((u1, u2) => {
            const nameParts1 = u1.usrName.split(' ');
            const nameParts2 = u2.usrName.split(' ');

            if (nameParts1.length < 2 || nameParts2.length < 2) {
                return u1.usrName.localeCompare(u2.usrName);
            }

            const firstNameCompare = nameParts1[0].localeCompare(nameParts2[0]);

            if (firstNameCompare !== 0) {
                return firstNameCompare;
            }

            return nameParts1[1].localeCompare(nameParts2[1]);
        });
    }

    protected saveViewTypeSelection(): void {
        this.store.dispatch(updateSelectedViewTypeForUsers(this.viewType));
    }

    protected restoreViewTypeSelection(): void {
        this.store
            .select(getSelectedViewTypeForUsers)
            .pipe(take(1), tap(this.trackPageLoad))
            .subscribe((viewType) => {
                this.viewType = viewType;
                this.changeDetector.detectChanges();
            });
    }

    private trackPageLoad = (viewType: ViewType): void => {
        const pageName: AdobePageName = this.adobeLaunchService.isAdobeInitialized
            ? AdobePageName.USERS_PAGE
            : AdobePageName.USERS_HOME_PAGE;

        this.adobeLaunchService.pageLoad(pageName, this.getViewTypeForPageLoad(viewType));
    };

    private mapToUserTableRow(users: UserTileInputDataProps[]): UserTableRow[] {
        return users.map((user: UserTileInputDataProps) => ({
            [UsersTableProps.UsrName]: user.usrName,
            [UsersTableProps.AccName]: user.accName,
            [UsersTableProps.RoleType]: this.mapRoleTypePipe.transform(user.roleType),
            [UsersTableProps.AccType]: this.mapAccountTypePipe.transform(user.accType),
            [UsersTableProps.Actions]: {
                navOverflowMenuConfig: this.usersService.getNavOverflowMenu(false),
            },
        }));
    }

    onColumnSort(event: Sort) {
        this.usrsDataTable$ = this.usrsDataTable$.pipe(
            map((users: UserTableRow[]) => {
                return sortUtils.ascDescSortingBySortEvent<UserTableRow>(users, event);
            }),
        );
    }
}
