import { Component, OnDestroy, OnInit } from '@angular/core';
import { APPLICATION_ROUTES } from 'src/app/constants/application-routes';
import { ContentUtil } from 'src/app/utils/content/content.util';
import { Content } from 'src/common/interfaces/content.interface';

import { content } from '../../../../content/zci/content';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import { VgnService } from '../../../services/vgn/vgn.service';
import { PageComponent } from '../page.component';

@Component({
    selector: 'zci-unsuccessful-attempt-page',
    templateUrl: './unsuccessful-attempt-page.component.html',
})
export class UnsuccessfulAttemptPageComponent extends PageComponent implements OnInit, OnDestroy {
    constructor(
        private readonly vgnService: VgnService,
        private adobeLaunchService: AdobeLaunchService,
    ) {
        super();
    }

    content: Content = ContentUtil.content || content;

    appRoutes = APPLICATION_ROUTES;

    ngOnInit(): void {
        this.vgnService.setMinimalNav(true);
        this.adobeLaunchService.pageLoad(AdobePageName.UNSUCCESSFUL_ATTEMPT_PAGE);
    }

    ngOnDestroy(): void {
        this.vgnService.setMinimalNav(false);
    }
}
