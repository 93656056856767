import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { content } from '../../../content/zci/content';

import { modifiedGranteeContent } from '../../../content/zci/grantee-content-modified';
import { modifiedGrantorContent } from '../../../content/zci/grantor-content-modified';

import { catchError, map, of } from 'rxjs';
import { LoggerService } from '../logger/logger.service';
import { transform } from '../../../aem/aem-utils';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    constructor(
        private http: HttpClient,
        private logger: LoggerService,
    ) {}

    getContent() {
        return of(content);
    }

    getGranteeGraphqlContent() {
        if (environment.development) {
            return of(modifiedGranteeContent);
        }
        return this.http
            .get<Record<any, any>>(
                `${environment.AEM_GRAPHQL.BASE_URL}${environment.AEM_GRAPHQL.CONF_NAME}${environment.AEM_GRAPHQL.GRANTEE_CONTENT_QUERY_NAME}`,
            )
            .pipe(
                // TODO add runtime check for content?
                map(rawContent => transform(rawContent, [])),
                catchError((error) => {
                    this.logger.error('Failed to fetch new AEM content:', error);
                    return of(modifiedGranteeContent);
                }),
            );
    }

    getGrantorGraphqlContent() {
        if (environment.development) {
            return of(modifiedGrantorContent);
        }
        return this.http
            .get<Record<any, any>>(
                `${environment.AEM_GRAPHQL.BASE_URL}${environment.AEM_GRAPHQL.CONF_NAME}${environment.AEM_GRAPHQL.GRANTOR_CONTENT_QUERY_NAME}`,
            )
            .pipe(
                // TODO add runtime check for content?
                map(rawContent => transform(rawContent, [])),
                catchError((error) => {
                    this.logger.error('Failed to fetch new AEM content:', error);
                    return of(modifiedGrantorContent);
                }),
            );
    }
}
