import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { resetGrantRevokePermissionState } from 'src/app/store/actions/grant-revoke-permissions.action';

import { selectRemoveUserStepperSelectedUser } from '../../../store/selectors/grant-revoke-permissions.selector';
import accountPermissionUtils from '../../../utils/account-permission/account-permission.util';
import { ContentUtil } from '../../../utils/content/content.util';
import { AdobeTagging } from '../../../utils/decorators/dev/adobe-launch';
import { adobeConfigData } from './remove-user-confirmation-page-adobe';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-remove-user-confirmation-page',
    templateUrl: './remove-user-confirmation-page.component.html',
    styleUrls: ['./remove-user-confirmation-page.component.scss'],
})
export class RemoveUserConfirmationPageComponent implements OnInit, OnDestroy {
    constructUserName = accountPermissionUtils.constructUserName;

    content = ContentUtil.content;
    myAccountsUrl: string;

    selectedUserName$: Observable<string> = this.store
        .select(selectRemoveUserStepperSelectedUser)
        .pipe(map(this.constructUserName));

    constructor(
        private readonly store: Store,
    ) {}

    ngOnInit(): void {
        this.myAccountsUrl = window.location.origin + '/my-accounts';
    }

    ngOnDestroy(): void {
        this.store.dispatch(resetGrantRevokePermissionState());
    }
}
