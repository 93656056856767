import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ClientSearchQuery } from '../../../common/dtos/client-search.query.dto';
import { UserDetailsDto } from '../../../common/dtos/user-details.dto';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    constructor(private readonly httpClient: HttpClient) {}

    searchClient(query: ClientSearchQuery): Observable<UserDetailsDto> {
        return this.httpClient.post<UserDetailsDto>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.SEARCH_CLIENT}`,
            query,
        );
    }
}
