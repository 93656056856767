import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
    GrantPermissionResponseDTO,
    RemovePermissionResponseDTO,
} from '../../../common/dtos/access-permissions.dto';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import {
    GranteeDetailsInterface,
    GrantorDetailsInterface,
} from '../../../common/interfaces/access-permissions.interface';

export const grantPermission = createAction(
    '[Access Permissions] Grant Permissions',
    props<{
        granteeDetails: GranteeDetailsInterface;
        grantorDetails: GrantorDetailsInterface;
        accountName: string;
    }>(),
);

export const grantPermissionMvp = createAction(
    '[Access Permissions] Grant Permissions Mvp',
    props<{
        granteeDetails: GranteeDetailsInterface;
        grantorDetails: GrantorDetailsInterface;
        accountName: string;
    }>(),
);

export const grantPermissionSuccess = createAction(
    '[Access Permissions] Grant Permissions Success',
    props<{
        data: GrantPermissionResponseDTO;
        roleType: string;
        accessLevel: AccessTypeSelection;
    }>(),
);

export const grantPermissionError = createAction(
    '[Access Permissions] Grant Permissions Error',
    props<{ error: HttpErrorResponse }>(),
);

export const removePermission = createAction(
    '[Access Permissions] Remove Permissions',
    props<{
        granteeDetails: GranteeDetailsInterface;
        grantorDetails: GrantorDetailsInterface;
    }>(),
);

export const removePermissionSuccess = createAction(
    '[Access Permissions] Remove Permissions Success',
    props<{
        data: RemovePermissionResponseDTO;
    }>(),
);

export const removePermissionError = createAction(
    '[Access Permissions] Remove Permissions Error',
    props<{ error: HttpErrorResponse }>(),
);
