import { HttpErrorResponse } from '@angular/common/http';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { GranteeDTO } from '../../../common/dtos/grantee.dto';
import { OwnAccountDTO } from '../../../common/dtos/own-account.dto';
import { UserDetailsDto } from '../../../common/dtos/user-details.dto';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import { UserTypeSelection } from '../../enums/user-type-selection.enum';
import { AccessFlowStepper } from '../../interfaces/access-flow-stepper.interface';
import { AddUserStepperFlow } from '../../interfaces/add-user-stepper-flow.interface';
import { RemoveUserStepperFlow } from '../../interfaces/remove-user-stepper-flow.interface';
import accountPermissionUtils from '../../utils/account-permission/account-permission.util';
import * as contentUtils from '../../utils/content/content.util';
import { ApiState } from '../state/api.state';
import { GrantRevokePermissionsState } from '../state/grant-revoke-permissions.state';
import { StoreConstant } from '../store.constant';

export const selectAccessFlowStepperData = createSelector(
    createFeatureSelector(StoreConstant.reducers.grantRevokePermissions),
    (state: GrantRevokePermissionsState): AccessFlowStepper => {
        return state?.accessFlowStepper;
    },
);

export const selectAddUserStepperData = createSelector(
    selectAccessFlowStepperData,
    (stepperFlow: AccessFlowStepper): AddUserStepperFlow => {
        return stepperFlow?.addUser;
    },
);

export const selectAddUserStepperSelectedUserType = createSelector(
    selectAddUserStepperData,
    (addUserStepperFlowData: AddUserStepperFlow): UserTypeSelection => {
        return addUserStepperFlowData?.selectedUserType;
    },
);

export const selectAddUserStepperSelectedUser = createSelector(
    selectAddUserStepperData,
    (addUserStepperFlowData: AddUserStepperFlow): UserDetailsDto => {
        return addUserStepperFlowData?.selectedUserType === UserTypeSelection.EXISTING
            ? addUserStepperFlowData?.selectedUser
            : addUserStepperFlowData?.addedUser?.data;
    },
);

export const selectAddUserStepperAddedUser = createSelector(
    selectAddUserStepperData,
    (addUserStepperFlow: AddUserStepperFlow): ApiState<UserDetailsDto> => {
        return addUserStepperFlow?.addedUser;
    },
);

export const selectAddUserStepperSelectedUserName = createSelector(
    selectAddUserStepperSelectedUser,
    (selectedUser: UserDetailsDto): string =>
        selectedUser ? accountPermissionUtils.constructUserName(selectedUser) : '',
);

export const selectAddUserStepperSelectedUserFirstName = createSelector(
    selectAddUserStepperSelectedUser,
    (selectedUser: UserDetailsDto): string => selectedUser?.firstName || '',
);

export const selectAddUserStepperSelectedAccount = createSelector(
    selectAddUserStepperData,
    (addUserStepperFlowData: AddUserStepperFlow): OwnAccountDTO | null => {
        return addUserStepperFlowData?.selectedAccount;
    },
);

export const selectAddUserStepperSelectedAccountName = createSelector(
    selectAddUserStepperSelectedAccount,
    (selectedAccount: OwnAccountDTO | null): string => {
        return selectedAccount?.accountName || '';
    },
);

export const selectAddUserStepperSelectedAccountGrantees = createSelector(
    selectAddUserStepperSelectedAccount,
    (selectedAccount: OwnAccountDTO | null): GranteeDTO[] => {
        return selectedAccount?.grantees || [];
    },
);

export const selectAddUserStepperSelectedAccessLevel = createSelector(
    selectAddUserStepperData,
    (addUserStepperFlowData: AddUserStepperFlow): AccessTypeSelection | null => {
        return addUserStepperFlowData?.selectedAccess;
    },
);

export const selectAddUserStepperSelectedAccessLevelLabel = createSelector(
    selectAddUserStepperSelectedAccessLevel,
    (selectedAccessLevel: AccessTypeSelection | null): string =>
        selectedAccessLevel
            ? contentUtils.getAccessLevelLabelById(
                  selectedAccessLevel,
                  contentUtils.ContentUtil.content,
              )
            : '',
);

export const getAddedUserLoadingState = createSelector(
    selectAccessFlowStepperData,
    (stepperFlow: AccessFlowStepper): boolean => {
        return stepperFlow?.addUser?.addedUser?.isLoading;
    },
);

export const selectAddUserStepperSelectedAccess = createSelector(
    selectAddUserStepperData,
    (addUserStepperFlowData: AddUserStepperFlow): AccessTypeSelection => {
        return addUserStepperFlowData?.selectedAccess;
    },
);

export const selectSearchPersonAttemptCounter = createSelector(
    selectAddUserStepperData,
    (addUserStepperFlowData: AddUserStepperFlow): number => {
        return addUserStepperFlowData?.searchPersonAttemptCounter;
    },
);

export const selectGrantRevokePermissionFlowApiErrorState = createSelector(
    createFeatureSelector(StoreConstant.reducers.grantRevokePermissions),
    (state: GrantRevokePermissionsState): HttpErrorResponse | null => {
        return state.accessFlowStepper?.addUser?.addedUser?.error;
    },
);

export const selectGrantRevokePermissionHttpErrorState = createSelector(
    createFeatureSelector(StoreConstant.reducers.grantRevokePermissions),
    (state: GrantRevokePermissionsState): HttpErrorResponse | null => {
        return state.accessFlowStepper?.error;
    },
);

export const selectConfirmationNumber = createSelector(
    selectAccessFlowStepperData,
    (stepperFlow: AccessFlowStepper): string => {
        return stepperFlow?.confirmationNumber;
    },
);

export const selectUniquePin = createSelector(
    selectAccessFlowStepperData,
    (stepperFlow: AccessFlowStepper): string => {
        return stepperFlow?.uniquePin;
    },
);

export const selectLoadingState = createSelector(
    selectAccessFlowStepperData,
    (stepperFlow: AccessFlowStepper): boolean => {
        return stepperFlow?.isLoading;
    },
);

export const selectRemoveUserStepperData = createSelector(
    selectAccessFlowStepperData,
    (stepperFlow: AccessFlowStepper): RemoveUserStepperFlow => {
        return stepperFlow?.removeUser;
    },
);

export const selectRemoveUserStepperSelectedUser = createSelector(
    selectRemoveUserStepperData,
    (removeUserStepperFlowData: RemoveUserStepperFlow): UserDetailsDto => {
        return removeUserStepperFlowData?.selectedUser;
    },
);

export const selectRemoveUserStepperSelectedAccounts = createSelector(
    selectRemoveUserStepperData,
    (removeUserStepperFlowData: RemoveUserStepperFlow): Array<OwnAccountDTO> => {
        return removeUserStepperFlowData?.selectedAccounts;
    },
);
