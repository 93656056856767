<ng-container [ngSwitch]="usrCount">
    <p *ngSwitchCase="noAgentsAssigned" class="zci-text--disabled zci-text--bold">
        {{ content.home.accountTile.zeroAgentsLabel }}
    </p>
    <a
        *ngSwitchDefault
        c11n-link
        [routerLink]="url"
        data-cy="agents-count-link"
        variant="primary-reinforced">
        {{ usrCount }} {{ usrCount | i18nPlural: agentsCountMap }}
    </a>
</ng-container>
