import { Component } from '@angular/core';
import { ContentUtil } from '../../../utils/content/content.util';

@Component({
    selector: 'zci-rm-info-tile',
    templateUrl: './rm-info-tile.component.html',
    styleUrls: ['./rm-info-tile.component.scss'],
})
export class RmInfoTileComponent {
    content = ContentUtil.content;
    rmContactInfo = this.content.globalRMSupportContactInfo;
    phone = this.rmContactInfo.phone;

    callCRM(): void {
        window.open(`tel:{{ this.phone }}`, '_self');
    }
}
