import { UserTypeSelection } from 'src/app/enums/user-type-selection.enum';

import { GrantRevokePermissionsState } from './grant-revoke-permissions.state';

export const GrantRevokePermissionsInitialState: GrantRevokePermissionsState = {
    accessFlowStepper: {
        isLoading: false,
        error: null,
        addUser: {
            selectedAccount: null,
            selectedUserType: UserTypeSelection.EXISTING,
            selectedUser: null,
            selectedAccess: null,
            searchPersonAttemptCounter: 0,
        },
        editUser: null,
        removeUser: {
            selectedAccounts: [],
            selectedUser: null,
        },
        confirmationNumber: null,
        uniquePin: null,
    },
};
