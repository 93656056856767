import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'zci-single-status-list-tile',
    templateUrl: './single-status-list-tile.component.html',
    styleUrls: ['./single-status-list-tile.component.scss'],
})
export class SingleStatusListTileComponent {
    @Input() items: string[];

    /**
     * Icon displayed next to every item to provide visual feedback on the status of the items.
     */
    @Input() statusIcon: TemplateRef<unknown>;
}
