import { Component, EventEmitter, Output } from '@angular/core';

import { Content } from '../../../../common/interfaces/content.interface';
import {
    AdobeAnalyticsCallToAction,
    AnalyticsCtaType,
    CtaCallUsLinkLocations,
    CtaNames,
} from '../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../../services/adobe-launch/adobe-launch.events';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../../services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import { ContentUtil } from '../../../utils/content/content.util';
import { StepsOfAddUserToOneSelectedAccount } from '../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';

@Component({ template: '' })
export abstract class AddUserStepBaseComponent {
    content: Content = ContentUtil.content;

    @Output() stepChange: EventEmitter<StepsOfAddUserToOneSelectedAccount> =
        new EventEmitter<StepsOfAddUserToOneSelectedAccount>();

    @Output() contactUs: EventEmitter<void> = new EventEmitter<void>();

    abstract onNextStepLoad(): void;

    abstract onBack(): void;

    constructor(protected adobeLaunchService: AdobeLaunchService) {}

    onQuit(location?: string): void {
        if (location == CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT) {
            this.triggerAnalytics(location, CtaNames.QUIT, AnalyticsCtaType.BUTTON);
        } else {
            this.triggerProcessAbandonment(location);
        }
        this.stepChange.emit(StepsOfAddUserToOneSelectedAccount.QUIT);
    }

    onContactUs(location?: string): void {
        this.triggerAnalytics(location, CtaNames.CONTACT_US, AnalyticsCtaType.BUTTON);
        this.contactUs.emit();
    }

    private triggerAnalytics(location, ctaName, ctaType) {
        if (location) {
            const contactUsAction: AdobeAnalyticsCallToAction = {
                location,
                ctaName,
                ctaType,
            };
            this.adobeLaunchService.trackAction(contactUsAction);
        }
    }

    private triggerProcessAbandonment(location: string) {
        if (location) {
            const quitEvent = {
                processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                processStep: location as AdobeAnalyticsProcessStep,
                processAbandonmentType: AdobeAnalyticsProcessStep.GAF_QUIT,
            };
            this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_ABANDONED, quitEvent);
        }
    }
}
