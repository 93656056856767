import { createReducer, on } from '@ngrx/store';

import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import {
    grantPermissionSuccess,
    removePermissionSuccess,
} from '../actions/access-permissions.actions';
import {
    accountUsersDataLoadedError,
    accountUsersDataLoadedSuccess,
} from '../actions/account-permissions-api.action';
import { loadAccountUsers } from '../actions/account-permissions-page.action';
import {
    ineligibleScenariosErrorForGrantor,
    ineligibleScenariosSuccessForGrantee,
    ineligibleScenariosSuccessForGrantor,
} from '../actions/ineligible-scenarios.action';
import {
    updateSelectedViewTypeForMyAccounts,
    updateSelectedViewTypeForOtherAccounts,
    updateSelectedViewTypeForUsers,
} from '../actions/view-type.action';
import { AccountPermissionsInitialState } from '../state/account-permissions-initial.state';

const API_SUCCESS = {
    isLoading: false,
    error: null,
};

const API_ERROR = {
    data: null,
    isLoading: false,
};

export const accountPermissionReducer = createReducer(
    AccountPermissionsInitialState,
    on(loadAccountUsers, (state) => ({
        ...state,
        accountUsers: {
            ...state.accountUsers,
            isLoading: true,
            hasLoaded: false,
        },
    })),

    on(accountUsersDataLoadedSuccess, (state, { data }) => ({
        ...state,
        accountUsers: {
            ...API_SUCCESS,
            data: data.accountsUsers,
            hasLoaded: true,
        },
        hasIneligibleError: data.hasIneligibleError,
    })),

    on(accountUsersDataLoadedError, (state, { error }) => ({
        ...state,
        accountUsers: {
            ...API_ERROR,
            error,
            hasLoaded: true,
        },
    })),

    on(updateSelectedViewTypeForUsers, (state, { viewType }) => ({
        ...state,
        selectedViewTypeForUsers: viewType,
    })),

    on(updateSelectedViewTypeForMyAccounts, (state, { viewType }) => ({
        ...state,
        selectedViewTypeForMyAccounts: viewType,
    })),

    on(updateSelectedViewTypeForOtherAccounts, (state, { viewType }) => ({
        ...state,
        selectedViewTypeForOtherAccounts: viewType,
    })),

    on(grantPermissionSuccess, (state, { data, roleType, accessLevel }) => {
        const { accountId, serviceAgreementId, granteeId } = data;
        let newAccounts;
        if (accessLevel === AccessTypeSelection.INFORMATION_ONLY) {
            newAccounts = [
                ...state.accountUsers.data.ownAccounts.map((account) => {
                    if (
                        account.accountId === accountId &&
                        account.serviceAgreementId === serviceAgreementId
                    ) {
                        return {
                            ...account,
                            grantees: [
                                ...account.grantees,
                                {
                                    clientPoid: String(granteeId),
                                    roleType: roleType,
                                },
                            ],
                        };
                    }
                    return account;
                }),
            ];
            return {
                ...state,
                accountUsers: {
                    ...state.accountUsers,
                    data: {
                        ...state.accountUsers.data,
                        ownAccounts: newAccounts,
                    },
                },
            };
        } else {
            return { ...state };
        }
    }),

    on(ineligibleScenariosSuccessForGrantor, (state, { data }) => {
        return {
            ...state,
            grantorIsIneligibleFor: data,
        };
    }),

    on(ineligibleScenariosSuccessForGrantee, (state, { granteeIneligibleReason, userDetails }) => {
        return {
            ...state,
            accountUsers: {
                ...state.accountUsers,
                data: {
                    ...state.accountUsers.data,
                    users: [
                        ...state.accountUsers.data.users.map((user) => {
                            if (user.clientPoid === userDetails.clientPoid) {
                                return {
                                    ...user,
                                    granteeIneligibleReason,
                                };
                            }
                            return user;
                        }),
                    ],
                },
            },
        };
    }),

    on(ineligibleScenariosErrorForGrantor, (state) => {
        return {
            ...state,
            hasIneligibleError: true,
        };
    }),

    // this reducer removes a grantee with 'granteeId'
    // from an account with 'accountId' after the successful response from API
    on(removePermissionSuccess, (state, { data }) => {
        const { accountId, serviceAgreementId, granteeId } = data;

        const newAccounts = [
            ...state.accountUsers.data.ownAccounts.map((account) => {
                if (
                    account.accountId === accountId &&
                    account.serviceAgreementId === serviceAgreementId
                ) {
                    return {
                        ...account,
                        grantees: [
                            ...account.grantees.filter(
                                (grantee) => grantee.clientPoid !== granteeId,
                            ),
                        ],
                    };
                }
                return account;
            }),
        ];
        return {
            ...state,
            accountUsers: {
                ...state.accountUsers,
                data: {
                    ...state.accountUsers.data,
                    ownAccounts: newAccounts,
                },
            },
        };
    }),
);
