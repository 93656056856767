import { AppModule } from 'src/app/app.module';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';

function AdobeTrackAction(...params: Parameters<AdobeLaunchService['trackAction']>) {
    return function (target: object, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function (...args) {
            if (originalMethod && typeof originalMethod === 'function') {
                originalMethod.apply(this, args);
            }
            const adobeLaunchService =
                AppModule.injector.get<AdobeLaunchService>(AdobeLaunchService);
            adobeLaunchService.trackAction.apply(adobeLaunchService, params);
        };
    };
}

export default AdobeTrackAction;
