import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';

@Directive({
    selector: '[zciLoading]',
})
export class LoadingDirective {
    @Input()
    set zciLoading(loading: boolean) {
        this.containerRef.clear();

        if (loading) {
            this.containerRef.createComponent<LoadingIndicatorComponent>(LoadingIndicatorComponent);
        } else {
            this.containerRef.createEmbeddedView(this.templateRef);
        }
    }

    constructor(private templateRef: TemplateRef<any>, private containerRef: ViewContainerRef) {}
}
