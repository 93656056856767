import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ContentUtil } from '../../../../utils/content/content.util';
import { catchError, map, Subscription, tap } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CONSTANTS } from '../../../../../common/constants/constants';
import {
    PERMISSION_REQUEST_ERRORS,
    PinValidationService,
} from '../../../../services/pin-validation/pin-validation.service';
import { ActivatedRoute } from '@angular/router';
import { TOKEN_PARAM } from '../../../../constants/application-routes';
import { ErrorService } from '../../../../services/generic-error/error.service';
import { ErrorPage, errorPageMap } from '../../../../services/generic-error/error-page-utils';
import { AccessPermissionsService } from '../../../../services/access-permissions/access-permissions.service';

@Component({
    selector: 'zci-grantee-pin-input',
    templateUrl: 'grantee-pin-input.component.html',
    styleUrls: ['grantee-pin-input.component.scss'],
})
export class GranteePinInputComponent implements OnInit, OnDestroy {
    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isSubmitStep: boolean;

    granteePinContent = ContentUtil.granteeFragmentsContent.frmcGranteeFlow.frmcUniquePin;

    pinFormControlName: string = 'pin';

    pinForm = new FormGroup({
        [this.pinFormControlName]: new FormControl(null, [
            Validators.pattern(CONSTANTS.UNIQUE_PIN_VALIDATION_PATTERN),
            Validators.required,
        ]),
    });

    pinValue: string;
    tokenValue: string;

    readonly subs: Subscription = new Subscription();

    constructor(
        private pinValidationService: PinValidationService,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private accessPermissionsService: AccessPermissionsService,
    ) {}

    ngOnInit(): void {
        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
        this.route.paramMap
            .pipe(
                map((params) => {
                    this.tokenValue = params.get(TOKEN_PARAM);
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        this.pinValue = this.pinForm.get(this.pinFormControlName).value;
        this.pinForm.markAllAsTouched();

        if (!this.pinForm.valid) {
            this.nextStepReady.emit(false);
        } else {
            this.pinValidationService
                .validatePin(this.pinValue, this.tokenValue)
                .subscribe((response) => {
                    if (response.error === PERMISSION_REQUEST_ERRORS.PIN_IS_INCORRECT) {
                        this.pinFormControl.setErrors({ pattern: true });
                        this.nextStepReady.emit(false);
                        return;
                    }

                    if (Object.hasOwn(errorPageMap, response.error)) {
                        this.errorService.showError(errorPageMap[response.error]);
                        this.nextStepReady.emit(false);
                        return;
                    }

                    if (this.isSubmitStep) {
                        this.accessPermissionsService
                            .acceptPermission(this.tokenValue)
                            .pipe(
                                tap((response) => {
                                    this.nextStepReady.emit(!response.error);

                                    if (response.error) {
                                        this.errorService.showError(errorPageMap[response.error]);
                                    }
                                }),
                                catchError(() => {
                                    this.errorService.showError(ErrorPage.TECH_DIFF);
                                    return Promise.reject();
                                }),
                            )
                            .subscribe();
                    } else {
                        this.nextStepReady.emit(true);
                    }
                });
        }
    }

    get pinFormControl(): AbstractControl {
        return this.pinForm.get(this.pinFormControlName);
    }

    get getPinErrorMessage(): string | undefined {
        if (this.pinFormControl.hasError('required')) {
            return this.granteePinContent.frmfUniquePin.errEnterPin;
        }

        if (this.pinFormControl.hasError('pattern')) {
            return this.granteePinContent.frmfUniquePin.errEnterValidPin;
        }
        return undefined;
    }
}
