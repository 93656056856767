export const DEFAULT_PAGE = {
    is404: false,
    siteIdentifier: 'retail',
    platform: 'web',
    publicVsSecure: '',
    prefix: ':retail:web:ZCI:',
    userLocale: 'us',
    lang: 'en',
};

export const USER_DETECTED = {
    LOGIN_STATUS: 'logged in',
    NOT_APPLICABLE: 'n/a',
};
