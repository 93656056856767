import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import { Content } from '../../../common/interfaces/content.interface';
import { AccessLevelWithLabel } from '../../interfaces/access-level-with-label.interface';
import { GranteeContent, GrantorContent } from './aem-content-types';

export abstract class ContentUtil {
    private static _content: Content;

    private static _granteeFragmentsContent: GranteeContent
    private static _grantorFragmentContent: GrantorContent

    static get granteeFragmentsContent(): GranteeContent {
        return this._granteeFragmentsContent;
    }

    static set granteeFragmentsContent(content: GranteeContent) {
        this._granteeFragmentsContent = content;
    }

    static get grantorFragmentContent(): GrantorContent {
        return this._grantorFragmentContent;
    }

    static set grantorFragmentContent(content: GrantorContent) {
        this._grantorFragmentContent = content;
    }

    static get wealthManagementSupportTeamContent(): string {
        return this._grantorFragmentContent.add_user.navContactUs.hlpContactUs.cnfoWealthManagementSupportTeam.text1
    }

    static get wealthManagementSupportContent(): string {
        return this.wealthManagementSupportTeamContent.replace(' team', '');
    }

    static get globalRMSupportContactPhoneNumber(): string {
        return this._grantorFragmentContent.add_user.navContactUs.hlpContactUs.cnfoWealthManagementSupportTeam.text3;
    }

    static get content() {
        return this._content;
    }

    static set content(content: any) {
        this._content = content;
    }
}

export const returnAllowedCompareAccessLevelActionsList = (
    content: Content,
): { matrix: boolean[][]; actionNames: string[]; accessTypes: string[] } => {
    return {
        matrix: content.about.tableCompareAllowedActions.matrix,
        actionNames: content.about.tableCompareAllowedActions.headers.vertical,
        accessTypes: content.about.tableCompareAllowedActions.headers.horizontal.filter(
            (_, index) => index !== 0,
        ),
    };
};

export const returnAllowedActions = (column: number, content: Content): string[] => {
    const allowedActions = returnAllowedCompareAccessLevelActionsList(content);
    return allowedActions.actionNames.filter((_, row) => allowedActions.matrix[row][column]);
};

export const getAccessLevelsWithLabel = (content: Content): AccessLevelWithLabel[] => [
    {
        id: AccessTypeSelection.INFORMATION_ONLY,
        label: content.accessLevels.informationOnly,
    },
    {
        id: AccessTypeSelection.LIMITED_ACCESS,
        label: content.accessLevels.limitedOnly,
    },
];

export const getAccessLevelLabelById = (id: AccessTypeSelection, content: Content): string => {
    const accessLevel: AccessLevelWithLabel = getAccessLevelsWithLabel(content).find(
        (accessLevelWithLabel: AccessLevelWithLabel) => accessLevelWithLabel.id === id,
    ) as AccessLevelWithLabel;

    return accessLevel?.label || '';
};
