<div class="container zci-margin-top c11n-space-stack-10x c11n-space--force">
    <h1 class="zci-h1 c11n-space-stack-2x" data-cy="page-title">{{ content?.home?.pageTitle }}</h1>
    <a
        class="c11n-text-sm"
        data-cy="about-page-link"
        c11n-link
        [variant]="'secondary-independent'"
        [routerLink]="appRoutes.ABOUT">
        {{ content?.home?.aboutPageLinkLabel }}
    </a>
    <ng-container *zciLoading="isLoading$ | async">
        <div class="c11n-space-stack-4x">
            <c11n-navigation
                [ariaLabel]="content?.home?.accountsNavTab?.ariaLabel"
                [activeItem]="initNavItem$ | async"
                (activeItemChange)="handleNavItemChange($event)"
                [menuData]="navMenuData$ | async"
                data-cy="account-permission-nav">
            </c11n-navigation>
            <router-outlet></router-outlet>
        </div>
        <zci-tile>
            <zci-advisor
                [layout]="'horizontal'"
                [name]="contactTitle"
                [phoneNumber]="contactPhone"></zci-advisor>
        </zci-tile>
    </ng-container>
</div>


