import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/components/pages/page.component';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { TitleService } from 'src/app/services/title/title.service';

import { APPLICATION_ROUTES } from '../../../../../constants/application-routes';
import { ContentUtil } from '../../../../../utils/content/content.util';

@Component({
    selector: 'zci-remove-user-access-page',
    templateUrl: './remove-user-access-page.component.html',
})
export class RemoveUserAccessPageComponent extends PageComponent implements OnInit {
    content = ContentUtil.content;

    appRoutes = APPLICATION_ROUTES;

    constructor(
        private readonly adobeLaunchService: AdobeLaunchService,
        private titleService: TitleService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.content.userInterstitialPages.removeUser.pageTitle);
        this.adobeLaunchService.pageLoad(AdobePageName.USERS_REMOVE_ACCESS_PAGE);
    }
}
