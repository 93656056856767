import { ErrorPage } from '../../../services/generic-error/error-page-utils';
import { AdobeConfig } from '../../../utils/decorators/dev/adobe-launch/adobe-launch.interfaces';
import { AdobeMethods } from '../../../services/adobe-launch/adobe-launch.methods';
import { AnalyticsCtaType, CtaNames } from '../../../services/adobe-launch/adobe-launch.cta';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsProcessStep } from '../../../services/adobe-launch/adobe-launch.process';
import { AccessTypeSelection } from '../../../../common/enums/access-type-selection.enum';

export const getAdobeConfigData = (
    accessType: AccessTypeSelection,
): Record<ErrorPage, AdobeConfig> => {
    // TODO this is an example of tagging for different error pages
    //  each page's tags could be added here directly
    //  or made in separate files and imported to include in this array

    return {
        [ErrorPage.TECH_DIFF]: {
            ngOnInit: [
                {
                    method: AdobeMethods.PAGE_LOAD,
                    data: [''],
                },
            ],
            onPrimaryButtonClick: [
                {
                    method: AdobeMethods.TRACK_ACTION,
                    data: [
                        {
                            location: '',
                            ctaName: '',
                            ctaType: AnalyticsCtaType.BUTTON,
                        },
                    ],
                },
            ],
            onSecondaryButtonClick: [
                {
                    method: AdobeMethods.TRACK_ACTION,
                    data: [
                        {
                            location: '',
                            ctaName: '',
                            ctaType: AnalyticsCtaType.BUTTON,
                        },
                    ],
                },
            ],
        },
        [ErrorPage.GRANTEE_PIN_EXPIRED]: {},
        [ErrorPage.GRANTEE_CANT_COMPLETE_ONLINE]: {},
        [ErrorPage.GRANTEE_HAS_PENDING_REQUEST]: {
            ngOnInit: [
                {
                    method: AdobeMethods.PAGE_LOAD,
                    data: [AdobePageName.GRANTEE_HAS_PENDING_REQUEST],
                },
            ],
            onPrimaryButtonClick: [
                {
                    method: AdobeMethods.TRACK_ACTION,
                    data: [
                        {
                            location: 'grant_access_pending_request_error',
                            ctaName: CtaNames.RETURN_TO_ACCESS_PERMISSIONS,
                            ctaType: AnalyticsCtaType.BUTTON,
                        },
                    ],
                },
            ],
            onSecondaryButtonClick: [
                {
                    method: AdobeMethods.TRACK_ACTION,
                    data: [
                        {
                            location: 'grant_access_pending_request_error',
                            ctaName: AdobeAnalyticsProcessStep.GAF_GO_TO_MY_ACCOUNT,
                            ctaType: AnalyticsCtaType.BUTTON,
                        },
                    ],
                },
            ],
        },
        [ErrorPage.GRANTEE_TOO_MANY_ATTEMPTS]: {},
        [ErrorPage.GRANTEE_FOREIGN_ADDRESS]: {
            ngOnInit: [
                {
                    method: AdobeMethods.PAGE_LOAD,
                    data: [
                        accessType == AccessTypeSelection.INFORMATION_ONLY
                            ? AdobePageName.GRANTEE_FOREIGN_ADDRESS_ERROR_INFO_ONLY
                            : AdobePageName.GRANTEE_FOREIGN_ADDRESS_ERROR_LIMITED,
                    ],
                },
            ],
            onPrimaryButtonClick: [
                {
                    method: AdobeMethods.TRACK_ACTION,
                    data: [
                        {
                            location: 'foreign_address_error',
                            ctaName: CtaNames.GO_TO_MY_DASHBOARD,
                            ctaType: AnalyticsCtaType.BUTTON,
                        },
                    ],
                },
            ],
            onSecondaryButtonClick: [
                {
                    method: AdobeMethods.TRACK_ACTION,
                    data: [
                        {
                            location: '',
                            ctaName: '',
                            ctaType: AnalyticsCtaType.BUTTON,
                        },
                    ],
                },
            ],
        },
    };
};