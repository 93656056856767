<div class="container row d-flex justify-content-center zci-margin-top c11n-space-stack-10x c11n-space--force">
    <div
        [ngClass]="{
            'col-md-10 col-xl-6': currentPage === pagesOfAddUserToOneSelectedAccount.STEPPER,
            'col-md-10 col-xl-10': selectConfirmationNumber$ | async
        }">
        <ng-container *ngIf="(selectConfirmationNumber$ | async) === null">
            <ng-container [ngSwitch]="currentPage">
                <zci-add-user-stepper-page
                    *ngSwitchCase="pagesOfAddUserToOneSelectedAccount.STEPPER"
                    (toUnsuccessfulAttempt)="
                        changeCurrentPage(pagesOfAddUserToOneSelectedAccount.UNSUCCESSFUL_ATTEMPT)
                    "></zci-add-user-stepper-page>
                <zci-unsuccessful-attempt-page
                    *ngSwitchCase="
                        pagesOfAddUserToOneSelectedAccount.UNSUCCESSFUL_ATTEMPT
                    "></zci-unsuccessful-attempt-page>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="selectConfirmationNumber$ | async">
            <div class="col-12 no-padding survey-breakpoint-adjustment justify-content-xl-around">
                <div class="c11n-space-stack-6x survey-center-margin col-xl-7">
                    <zci-add-user-confirmation-page
                        class="d-flex col-12 survey-flex-spacing-adjustment">
                    </zci-add-user-confirmation-page>
                </div>
                <div
                    class="d-flex col-12 col-xl-5 survey-size-adjustment"
                    id="csat-embedded-survey"
                    [ngClass]="{ 'survey-height': forceSurveyZeroHeight }">
                </div>
            </div>
        </ng-container>
    </div>
</div>
