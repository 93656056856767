import { Component, Input } from '@angular/core';
import { OtherAccountsService } from 'src/app/services/other-accounts/other-accounts.service';
import { ZciOverflowMenuService } from 'src/app/services/zci-overflow-menu/zci-overflow-menu.service';

import { ContentUtil } from '../../../utils/content/content.util';
import { ObservableAccountTileInputDataProps } from '../../../utils/sort/sort.service';

@Component({
    selector: 'zci-observable-account-tile',
    templateUrl: './observable-account-tile.component.html',
})
export class ObservableAccountTileComponent implements ObservableAccountTileInputDataProps {
    content = ContentUtil.content;

    zciOverflowItems = this.navOverflowItemsService.zciOverflowItems;

    @Input() accName: string;

    @Input() accType: string;

    @Input() roleType: string;

    navOverflowMenuConfig = this.otherAccountsService.getNavOverflowMenu();

    constructor(
        private readonly navOverflowItemsService: ZciOverflowMenuService,
        private readonly otherAccountsService: OtherAccountsService,
    ) {}
}
