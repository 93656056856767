export enum AdobeAnalyticsEvent {
    CALL_TO_ACTION = 'callToAction',
    SUB_PAGE_VIEWED = 'subPageViewed',
    PAGE_LOADED = 'pageLoaded',
    PAGE_LOAD_COMPLETED = 'pageLoadCompleted',
    USER_DETECTED = 'userDetected',
    PROCESS_START = 'processStart',
    PROCESS_STEP_SUCCESS = 'processStepSuccess',
    PROCESS_SIDE_STEP_SUCCESS = 'processSideStepSuccess',
    PROCESS_ERROR = 'processError',
    PROCESS_ABANDONED = 'processAbandoned',
    PROCESS_COMPLETE = 'processComplete',
}
