export enum IneligibleReasons {
    ZERO_BALANCE = 1,
    WEB_REG_OR_REREG_HOLD,
    ADDRESS_HOLD,
    TRUST_OR_ORGANIZATION_ACCOUNT,
    PLEDGED_ACCOUNT,
    NONRESIDENT_ALIEN,
    SUBJECT_TO_B_NOTICE,
    MANAGED_ACCOUNT,
}

export enum GranteeIneligibleReasons {
    ASSOCIATED_OR_CONTROL_PERSON = 1,
    FOREIGN_ADDRESS,
}
