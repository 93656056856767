import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { selectContactPersonType } from 'src/app/store/selectors/account-permissions.selector';
import { CONTENT_PLACEHOLDERS } from 'src/common/constants/constants';
import { environment } from 'src/environments/environment';

import { APPLICATION_ROUTES } from '../../constants/application-routes';
import {
    AdobeAnalyticsCallToAction,
    AnalyticsCtaType,
} from '../../services/adobe-launch/adobe-launch.cta';
import { AdobePageName } from '../../services/adobe-launch/adobe-launch.pages';
import { ContentUtil } from '../../utils/content/content.util';

@Component({
    selector: 'zci-interstitial-page-content',
    templateUrl: './interstitial-page-content.component.html',
})
export class InterstitialPageContentComponent implements OnInit {
    content = ContentUtil.content;

    appRoutes = APPLICATION_ROUTES;

    contactPersonTypePlaceHolder: string = CONTENT_PLACEHOLDERS.CONTACT_PERSON_TYPE;

    contactPersonType$ = this.store.select(selectContactPersonType);

    @Input() backLink: string;

    @Input() withOnlineFlow: boolean = false;

    constructor(private store: Store, private adobeLaunchService: AdobeLaunchService) {}

    ngOnInit(): void {
        this.adobeLaunchService.pageLoad(
            AdobePageName.OWN_ACCOUNTS_ADD_AUTHORIZED_USERS_PAGE_INTERSTITIAL,
        );
    }

    handleOnlineProcessLink(): void {
        const ctaName: string = 'online_process';
        const ctaType: AnalyticsCtaType = AnalyticsCtaType.LINK;
        const location: string = 'interstitial_page_for_add_users';
        const callToActionData: AdobeAnalyticsCallToAction = {
            ctaName,
            ctaType,
            location,
        };

        this.adobeLaunchService.trackAction(callToActionData);

        window.open(environment.LEGACY_FLOW_URL, '_self');
    }
}
