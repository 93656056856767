<ng-container [ngSwitch]="isExternal">
    <a
        *ngSwitchCase="false"
        data-cy="back-link-angular"
        c11n-link
        [class]="classNames"
        [iconPosition]="'leading'"
        [variant]="'primary-independent'"
        [iconName]="linkIconName"
        [routerLink]="link">
        {{ content.global.backLink }}
    </a>
    <a
        *ngSwitchCase="true"
        data-cy="back-link-external"
        c11n-link
        [class]="classNames"
        [iconPosition]="'leading'"
        [variant]="'primary-independent'"
        [iconName]="linkIconName"
        [href]="link">
        {{ content.global.backLink }}
    </a>
</ng-container>
