<zci-view-selector
    *ngIf="!isMobileView"
    [(ngModel)]="viewType"
    (ngModelChange)="onViewTypeChange()"
    class="c11n-space-stack-4x"
    [location]="content.usersOnAccount.pageTitle"></zci-view-selector>

<ng-container *ngIf="viewType === ViewType.Card">
    <div class="row g-4 c11n-space-stack-5x c11n-space--force">
        <div class="col-lg-6" *ngFor="let user of usrsOnAccountPaginated?.data$ | async">
            <zci-user-tile
                [roleType]="user.roleType"
                [usrName]="user.usrName"
                [hasSelectedAccount]="true"></zci-user-tile>
        </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="usrsOnAccountPaginated.hasMore$ | async">
        <c11n-button
            class="load-more-button"
            (clickEvent)="usrsOnAccountPaginated.loadMore()"
            [labelText]="loadMoreUsersLabel$ | async"
            [buttonType]="'button'"
            size="medium"
            variant="secondary"
            colorMode="on-light"
            [isWaiting]="usrsOnAccountPaginated.isLoading$ | async"
            data-cy="user-on-account-load-more-button">
        </c11n-button>
    </div>
</ng-container>

<ng-container *ngIf="viewType === ViewType.Table">
    <div class="c11n-space-stack-5x c11n-space--force">
        <zci-table
            [tableColDef]="usrsOnAccountTableCols"
            [tableData]="usrsOnAccountDataTable$ | async"
            [ariaLabelledby]="''"
            [dataCy]="'zci-table'"
            [sortable]="true"
            (sort)="onColumnSort($event)"></zci-table>
    </div>
</ng-container>
