import { createReducer, on } from '@ngrx/store';

import {
    grantPermission,
    grantPermissionError,
    grantPermissionSuccess,
    removePermission,
    removePermissionError,
    removePermissionSuccess,
} from '../actions/access-permissions.actions';
import {
    updateAddUserStepperAddedUser,
    updateAddUserStepperSelectedAccess,
    updateAddUserStepperSelectedAccount,
    updateAddUserStepperSelectedUser,
    updateAddUserStepperSelectedUserType,
} from '../actions/add-user-stepper-flow.action';
import {
    cdmDataLoadedError,
    cdmDataLoadedSuccess,
} from '../actions/grant-revoke-permission.api.action';
import {
    addNewUser,
    incrementSearchPersonAttemptCounter,
    resetGrantRevokePermissionState,
    resetGrantRevokePermissionStateExceptAttemptCounter,
    resetSearchPersonAttemptCounter,
    searchClient,
} from '../actions/grant-revoke-permissions.action';
import {
    ineligibleScenariosErrorForGrantee,
    ineligibleScenariosForGrantee,
    ineligibleScenariosSuccessForGrantee,
} from '../actions/ineligible-scenarios.action';
import {
    updateRemoveUserStepperSelectedAccount,
    updateRemoveUserStepperSelectedUser,
} from '../actions/remove-user-stepper-flow.action';
import { GrantRevokePermissionsInitialState } from '../state/grant-revoke-permissions-initial.state';
import { gatherPinAndDto, gatherPinAndDtoError, gatherPinAndDtoSuccess } from '../actions/add-someone-else.action';


export const grantRevokePermissionsReducer = createReducer(
    GrantRevokePermissionsInitialState,

    on(updateAddUserStepperSelectedUserType, (state, { selectedUserType }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                selectedUserType,
            },
        },
    })),

    on(updateAddUserStepperSelectedUser, (state, { selectedUser }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                selectedUser: selectedUser,
            },
        },
    })),

    on(updateAddUserStepperAddedUser, (state, { addedUser }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                addedUser: {
                    data: addedUser,
                    isLoading: false,
                    error: null,
                    hasLoaded: true,
                },
            },
        },
    })),

    on(updateAddUserStepperSelectedAccount, (state, { selectedAccount }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                selectedAccount,
            },
        },
    })),

    on(updateAddUserStepperSelectedAccess, (state, { selectedAccess }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                selectedAccess,
            },
        },
    })),

    on(resetGrantRevokePermissionState, () => GrantRevokePermissionsInitialState),

    on(resetGrantRevokePermissionStateExceptAttemptCounter, (state) => {
        return {
            ...GrantRevokePermissionsInitialState,
            accessFlowStepper: {
                ...GrantRevokePermissionsInitialState.accessFlowStepper,
                addUser: {
                    ...GrantRevokePermissionsInitialState.accessFlowStepper.addUser,
                    searchPersonAttemptCounter:
                        state?.accessFlowStepper?.addUser?.searchPersonAttemptCounter,
                },
                confirmationNumber:
                    GrantRevokePermissionsInitialState.accessFlowStepper.confirmationNumber,
            },
        };
    }),

    on(incrementSearchPersonAttemptCounter, (state) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                searchPersonAttemptCounter:
                    /* istanbul ignore next */
                    state?.accessFlowStepper?.addUser?.searchPersonAttemptCounter + 1,
            },
        },
    })),

    on(resetSearchPersonAttemptCounter, (state) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                searchPersonAttemptCounter: 0,
            },
        },
    })),

    on(searchClient, (state) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                addedUser: { ...state.accessFlowStepper.addUser.addedUser, isLoading: true },
            },
        },
    })),

    on(gatherPinAndDto, (state) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: true,
        },
    })),

    on(gatherPinAndDtoError, (state, { error}) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            error,
        },
    })),

    on(gatherPinAndDtoSuccess, (state, { data }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            uniquePin: data,
        },
    })),

    on(addNewUser, (state, {addedUser}) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                addedUser,
            },
        },
    })),

    on(cdmDataLoadedSuccess, (state, { client }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                addedUser: {
                    ...state.accessFlowStepper.addUser.addedUser,
                    data: client,
                    isLoading: false,
                    error: null,
                    hasLoaded: true,
                },
            },
        },
    })),
    on(cdmDataLoadedError, (state, { error }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            addUser: {
                ...state.accessFlowStepper.addUser,
                addedUser: {
                    ...state.accessFlowStepper.addUser.selectedUser,
                    data: null,
                    isLoading: false,
                    error,
                    hasLoaded: true,
                },
            },
        },
    })),

    on(grantPermission, (state) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: true,
            error: null,
        },
    })),

    on(grantPermissionSuccess, (state, { data }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            error: null,
            confirmationNumber: data.confirmationNumber,
        },
    })),

    on(grantPermissionError, (state, { error }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            error,
        },
    })),

    on(ineligibleScenariosForGrantee, (state) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: true,
            error: null,
        },
    })),

    on(ineligibleScenariosSuccessForGrantee, (state, { granteeIneligibleReason, userDetails }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            error: null,
            addUser: {
                ...state.accessFlowStepper.addUser,
                selectedUser: {
                    ...userDetails,
                    granteeIneligibleReason,
                },
            },
        },
    })),

    on(ineligibleScenariosErrorForGrantee, (state, { error }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            error,
        },
    })),

    on(updateRemoveUserStepperSelectedAccount, (state, { selectedAccount }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            removeUser: {
                ...state.accessFlowStepper.removeUser,
                selectedAccounts: [selectedAccount],
            },
        },
    })),

    on(updateRemoveUserStepperSelectedUser, (state, { selectedUser }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            removeUser: {
                ...state.accessFlowStepper.removeUser,
                selectedUser: selectedUser,
            },
        },
    })),

    on(removePermission, (state) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: true,
            error: null,
        },
    })),
    on(removePermissionSuccess, (state, { data }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            error: null,
            confirmationNumber: data.confirmationNumber,
        },
    })),

    on(removePermissionError, (state, { error }) => ({
        ...state,
        accessFlowStepper: {
            ...state.accessFlowStepper,
            isLoading: false,
            error,
        },
    })),
);
