import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    MEDIA_QUERY_BREAKPOINT_MD,
    MEDIA_QUERY_BREAKPOINT_XXL,
} from './breakpoint-observer.constants';

@Injectable({
    providedIn: 'root',
})
export class BreakpointObserverService {
    isMobileView$: Observable<boolean> = this.breakpointObserver
        .observe(MEDIA_QUERY_BREAKPOINT_MD)
        .pipe(map((result: BreakpointState) => !result.matches));

    isTabletView$: Observable<boolean> = this.breakpointObserver
        .observe(MEDIA_QUERY_BREAKPOINT_XXL)
        .pipe(map((result: BreakpointState) => !result.matches));

    constructor(private breakpointObserver: BreakpointObserver) {}
}
