import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { UserDetailsDto } from '../../../common/dtos/user-details.dto';

export const cdmDataLoadedSuccess = createAction(
    '[Client Search API] Client Search Loaded Success',
    props<{ client: UserDetailsDto }>(),
);
export const cdmDataLoadedError = createAction(
    '[Client Search API] Client Search Loaded Error',
    props<{ error: HttpErrorResponse }>(),
);
