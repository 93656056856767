import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, Subject, take, tap } from 'rxjs';
import { MapAccountTypePipe } from 'src/app/pipes/map-account-type/map-account-type.pipe';
import { MapRoleTypePipe } from 'src/app/pipes/role-type/map-role-type.pipe';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { OtherAccountsService } from 'src/app/services/other-accounts/other-accounts.service';
import { PaginationProp, PaginatorService } from 'src/app/services/paginator/paginator.service';
import { TitleService } from 'src/app/services/title/title.service';
import {
    getSelectedViewTypeForOtherAccounts,
    selectMaskedObservableAccounts,
} from 'src/app/store/selectors/account-permissions.selector';
import { CONSTANTS } from 'src/common/constants/constants';

import { ViewType } from '../../../interfaces/view-type.enum';
import { updateSelectedViewTypeForOtherAccounts } from '../../../store/actions/view-type.action';
import { ContentUtil } from '../../../utils/content/content.util';
import contentUtils from '../../../utils/content/content-format.util';
import { Sort } from '../../../utils/sort/sort.interfaces';
import { ObservableAccountTileInputDataProps, SortService } from '../../../utils/sort/sort.service';
import sortUtils from '../../../utils/sort/sort.util';
import { TableColumn } from '../../table/table.interface';
import { TabComponent } from '../tab.component';
import {
    getOtherAccountsTableCols,
    OtherAccountsTableProps,
    OtherAccountTableRow,
} from './other-account-table.config';

@Component({
    selector: 'zci-observable-accounts',
    templateUrl: './observable-accounts.component.html',
    providers: [PaginatorService],
})
export class ObservableAccountsComponent extends TabComponent implements OnInit, OnDestroy {
    content = ContentUtil.content;

    private readonly mapRoleTypePipe: MapRoleTypePipe;

    private readonly mapAccountTypePipe: MapAccountTypePipe;

    private readonly titleService: TitleService;

    private readonly sortService: SortService;

    private readonly store: Store;

    private readonly paginatorService: PaginatorService;

    private readonly otherAccountsService: OtherAccountsService;

    private readonly adobeLaunchService: AdobeLaunchService;

    accsPaginated: PaginationProp<ObservableAccountTileInputDataProps>;

    otherAccs$: Observable<ObservableAccountTileInputDataProps[]>;

    accountsForTiles$: Observable<ObservableAccountTileInputDataProps[]>;

    otherAcctsDataTable$: Observable<OtherAccountTableRow[]>;

    otherAcctsTableCols: TableColumn[] = getOtherAccountsTableCols(
        this.content.home.otherAccountTable.headers,
    );

    private destroy$: Subject<void> = new Subject();

    constructor(private injector: Injector) {
        super(injector);
        this.paginatorService = this.injector.get<PaginatorService>(PaginatorService);
        this.store = this.injector.get<Store>(Store);
        this.titleService = this.injector.get<TitleService>(TitleService);
        this.otherAccountsService = this.injector.get<OtherAccountsService>(OtherAccountsService);
        this.mapAccountTypePipe = this.injector.get<MapAccountTypePipe>(MapAccountTypePipe);
        this.mapRoleTypePipe = this.injector.get<MapRoleTypePipe>(MapRoleTypePipe);
        this.adobeLaunchService = this.injector.get<AdobeLaunchService>(AdobeLaunchService);
        this.sortService = this.injector.get<SortService>(SortService);
    }

    get loadMoreAccountsLabel$(): Observable<string> {
        return this.accsPaginated.nextCount$.pipe(
            map((count: number) => {
                return contentUtils.getContentWithDynamicNumber(
                    this.content.home.loadMoreAccounts,
                    count,
                );
            }),
        );
    }

    ngOnInit(): void {
        this.otherAccs$ = this.store.select(selectMaskedObservableAccounts);

        this.accountsForTiles$ = this.otherAccs$.pipe(
            map((accs) => this.sortService.sortAccountsForCardView(accs)),
        );

        this.otherAcctsDataTable$ = this.otherAccs$.pipe(
            map(this.mapToOtherAccountTableRow.bind(this)),
        );
        this.titleService.setTitle(this.content.home.accountsNavTab.otherAccountsItemTitle);
        const { hasMore$, isLoading$, nextCount$ } = this.paginatorService;
        const data$ = this.paginatorService.initPaginator<ObservableAccountTileInputDataProps>(
            this.accountsForTiles$,
            CONSTANTS.PAGINATOR_PAGE_SIZE,
        );
        this.accsPaginated = {
            data$,
            hasMore$,
            isLoading$,
            nextCount$,
            loadMore: () => this.paginatorService.loadMore(),
        };

        this.restoreViewTypeSelection();
        this.subscribeOnBreakpointChange();
    }

    onColumnSort(sortObj: Sort) {
        this.otherAcctsDataTable$ = this.otherAcctsDataTable$.pipe(
            map((accts: OtherAccountTableRow[]) => {
                return sortUtils.ascDescSortingBySortEvent<OtherAccountTableRow>(accts, sortObj);
            }),
        );
    }

    ngOnDestroy(): void {
        this.saveViewTypeSelection();
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected saveViewTypeSelection(): void {
        this.store.dispatch(updateSelectedViewTypeForOtherAccounts(this.viewType));
    }

    protected get destroy(): Subject<void> {
        return this.destroy$;
    }

    protected restoreViewTypeSelection(): void {
        this.store
            .select(getSelectedViewTypeForOtherAccounts)
            .pipe(take(1), tap(this.trackPageLoad))
            .subscribe((viewType) => {
                this.viewType = viewType;
                this.changeDetector.detectChanges();
            });
    }

    private trackPageLoad = (viewType: ViewType): void => {
        const pageName: AdobePageName = this.adobeLaunchService.isAdobeInitialized
            ? AdobePageName.OBSERVABLE_ACCOUNTS_PAGE
            : AdobePageName.OBSERVABLE_ACCOUNTS_HOME_PAGE;

        this.adobeLaunchService.pageLoad(pageName, this.getViewTypeForPageLoad(viewType));
    };

    private mapToOtherAccountTableRow(
        otherAccs: ObservableAccountTileInputDataProps[],
    ): OtherAccountTableRow[] {
        return otherAccs.map((acct: ObservableAccountTileInputDataProps) => ({
            [OtherAccountsTableProps.AccName]: acct.accName,
            [OtherAccountsTableProps.RoleType]: this.mapRoleTypePipe.transform(acct.roleType),
            [OtherAccountsTableProps.AccType]: this.mapAccountTypePipe.transform(acct.accType),
            [OtherAccountsTableProps.Actions]: {
                navOverflowMenuConfig: this.otherAccountsService.getNavOverflowMenu(),
            },
        }));
    }
}
