import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AccountsInfo } from '../../../common/dtos/accounts-info.dto';

@Injectable({
    providedIn: 'root',
})
export class AccountPermissionsService {
    constructor(private readonly httpClient: HttpClient) {}

    getAccountUsers(): Observable<AccountsInfo> {
        return this.httpClient.get<AccountsInfo>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.GET_ACCOUNT_USERS}`,
        );
    }
}
