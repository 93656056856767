import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { InputMask } from '@vg-constellation/angular-16/input';
import { ModalDialogComponent } from '@vg-constellation/angular-16/modal-dialog';
import { combineLatestWith, filter, map, Observable, Subscription, switchMap, take } from 'rxjs';
import { CtaCallUsLinkLocations } from 'src/app/services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from 'src/app/services/adobe-launch/adobe-launch.events';
import { AdobePageName } from 'src/app/services/adobe-launch/adobe-launch.pages';
import {
    AdobeAnalyticsErrorCode,
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from 'src/app/services/adobe-launch/adobe-launch.process';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { UserDetailsDto } from 'src/common/dtos/user-details.dto';
import { environment } from 'src/environments/environment';

import { CONSTANTS } from '../../../../../../common/constants/constants';
import { ClientSearchQuery } from '../../../../../../common/dtos/client-search.query.dto';
import { GranteeDTO } from '../../../../../../common/dtos/grantee.dto';
import { GranteeIneligibleReasons } from '../../../../../../common/enums/ineligible-scenarios.enum';
import {
    incrementSearchPersonAttemptCounter,
    resetSearchPersonAttemptCounter,
    searchClient,
} from '../../../../../store/actions/grant-revoke-permissions.action';
import * as accountPermissionsSelector from '../../../../../store/selectors/account-permissions.selector';
import {
    selectAddUserStepperAddedUser,
    selectAddUserStepperSelectedAccountGrantees,
    selectAddUserStepperSelectedUser,
    selectSearchPersonAttemptCounter,
} from '../../../../../store/selectors/grant-revoke-permissions.selector';
import utils from '../../../../../utils/account-permission/account-permission.util';
import { ContentUtil } from '../../../../../utils/content/content.util';
import { AdobeTagging } from '../../../../../utils/decorators/dev/adobe-launch';
import { StepsOfAddUserToOneSelectedAccount } from '../../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { AddUserStepBaseComponent } from '../../add-user-step-base.component';
import { adobeConfigData } from './gaf-add-someone-else-adobe';

export enum ErrorState {
    NO_ERROR,
    USER_NOT_FOUND,
    USER_EXIST_ON_LIST,
    USER_HAS_ACCESS,
    HAS_FOREIGN_ADDRESS,
    HAS_ASSOCIATED_OR_CONTROLLED_PERSON,
}

const isError = (e: ErrorState): boolean => e !== ErrorState.NO_ERROR;

interface ClientSearchResultErrorState {
    user: UserDetailsDto;
    errorState?: ErrorState;
}

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-add-someone-else',
    templateUrl: './gaf-add-someone-else.component.html',
    styleUrls: ['./gaf-add-someone-else.component.scss'],
})
export class GafAddSomeoneElseComponent
    extends AddUserStepBaseComponent
    implements OnInit, OnDestroy
{
    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    content = ContentUtil.content;

    InputMask = InputMask;

    usersOnAccessCenter$: Observable<UserDetailsDto[]>;

    usersOnAccount$: Observable<GranteeDTO[]>;

    private subs: Subscription = new Subscription();

    firstNameControlName = 'firstName';

    lastNameControlName = 'lastName';

    errorContent = '';

    modalButtons: [string, string] = this.getModalButtons(ErrorState.NO_ERROR);

    dateOfBirthControlName = 'dateOfBirth';

    accNumberControlName = 'accNumber';

    errorState: ErrorState = ErrorState.NO_ERROR;

    ErrorState = ErrorState;

    isBannerShown: boolean = false;

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_ADD_SOMEONE_ELSE;

    granteeIneligibleReason: GranteeIneligibleReasons[] = [];

    @ViewChild('selectUserLevelModalDialog', { static: false })
    selectUserLevelModalDialog!: ModalDialogComponent;

    environment = environment;

    private nextButtonClicked = false;

    personalInfoForm: FormGroup = new FormGroup({
        [this.firstNameControlName]: new FormControl(null, [Validators.required]),
        [this.lastNameControlName]: new FormControl(null, [Validators.required]),
        [this.dateOfBirthControlName]: new FormControl(null, [
            Validators.required,
            Validators.pattern(CONSTANTS.DATE_OF_BIRTH_VALIDATION_PATTERN),
        ]),
        [this.accNumberControlName]: new FormControl(null, [
            Validators.required,
            Validators.pattern(CONSTANTS.ACCOUNT_NUMBER_VALIDATION_PATTERN),
        ]),
    });

    @Input() accId: string;

    @Output() next: EventEmitter<void> = new EventEmitter();

    @Output() back: EventEmitter<void> = new EventEmitter();

    @Output() unsuccessfulAttempt: EventEmitter<void> = new EventEmitter();

    constructor(
        private readonly store: Store,
        protected readonly adobeLaunchService: AdobeLaunchService,
    ) {
        super(adobeLaunchService);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.adobeLaunchService.pageLoad(AdobePageName.GAF_TELL_US_ABOUT_THIS_PERSON_PAGE);

        this.usersOnAccessCenter$ = this.store.select(
            accountPermissionsSelector.selectNonGrantedUsersOnAccount(this.accId),
        );

        this.usersOnAccount$ = this.store.select(selectAddUserStepperSelectedAccountGrantees);

        this.subs.add(
            this.store
                .select(selectAddUserStepperAddedUser)
                .pipe(
                    filter(
                        (selectedUser) =>
                            selectedUser?.hasLoaded &&
                            !selectedUser?.isLoading &&
                            this.nextButtonClicked,
                    ),
                    switchMap((selectedUser) =>
                        this.getSearchResultWithErrorState(selectedUser.data),
                    ),
                )
                .subscribe(this.handleClientSearchResult),
        );

        this.subs.add(
            this.store.select(selectAddUserStepperSelectedUser).subscribe((userDetails) => {
                if (userDetails?.accNumber) {
                    this.setPersonalInfoFormValues(userDetails);
                }
            }),
        );

        this.subs.add(
            this.store.select(selectSearchPersonAttemptCounter).subscribe((counter: number) => {
                if (counter > CONSTANTS.SEARCH_PERSON_ATTEMPT_MAX) {
                    this.store.dispatch(resetSearchPersonAttemptCounter());
                    this.unsuccessfulAttempt.emit();
                }
            }),
        );
    }

    showError(errorState: ErrorState) {
        this.errorState = errorState;
        this.errorContent = this.getErrorContent(errorState);
        this.modalButtons = this.getModalButtons(errorState);

        switch (errorState) {
            case ErrorState.USER_NOT_FOUND:
            case ErrorState.USER_EXIST_ON_LIST:
            case ErrorState.USER_HAS_ACCESS:
                this.showBanner();
                break;
            case ErrorState.HAS_FOREIGN_ADDRESS:
            case ErrorState.HAS_ASSOCIATED_OR_CONTROLLED_PERSON:
                this.activateModal();
        }
    }

    showBanner() {
        this.isBannerShown = true;
    }

    getErrorContent(errorState: ErrorState): string {
        switch (errorState) {
            // todo refactor
            case ErrorState.USER_NOT_FOUND:
                return this.content.addUserFlow.step2.validationMessage.userNotFound[1];
            case ErrorState.USER_EXIST_ON_LIST:
                return this.content.addUserFlow.step2.validationMessage.personExistsOnAccessCenter;
            case ErrorState.USER_HAS_ACCESS:
                return this.content.addUserFlow.step2.validationMessage.personHasAccessToAccount;
            case ErrorState.HAS_FOREIGN_ADDRESS:
                return this.content.selectUserLevelModalDialog.body;
            case ErrorState.HAS_ASSOCIATED_OR_CONTROLLED_PERSON:
                return this.content.selectUserLevelModalDialog.body;
            default:
                return '';
        }
    }

    getModalButtons(errorState: ErrorState): [string, string] {
        const defaultButtons: [string, string] = [
            this.content.selectUserLevelModalDialog.primaryButtonText,
            this.content.selectUserLevelModalDialog.secondaryButtonText,
        ];

        const backButtonOnly: [string, string] = [
            this.content.selectUserLevelModalDialog.secondaryButtonText,
            '',
        ];

        switch (errorState) {
            // todo use it if we change everything to modals, remove 'backButtonOnly' if not
            case ErrorState.HAS_FOREIGN_ADDRESS:
            case ErrorState.HAS_ASSOCIATED_OR_CONTROLLED_PERSON:
                return defaultButtons;
            default:
                return backButtonOnly;
        }
    }

    get firstNameFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.firstNameControlName);
    }

    get lastNameFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.lastNameControlName);
    }

    get dateOfBirthFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.dateOfBirthControlName);
    }

    get accNumberFormControl(): AbstractControl {
        return this.personalInfoForm.get(this.accNumberControlName);
    }

    get getDateOfBirthErrorMessage(): string | undefined {
        const isDobEmpty = !/\d/gi.test(this.dateOfBirthFormControl.value);
        if (isDobEmpty) {
            this.dateOfBirthFormControl.setErrors({ required: true });
        }
        if (this.dateOfBirthFormControl.hasError('required')) {
            return this.content.addUserFlow.step2.addSomeoneElseForm.dateOfBirthRequiredError;
        }
        if (this.dateOfBirthFormControl.hasError('pattern')) {
            return this.content.addUserFlow.step2.addSomeoneElseForm.dateOfBirthPatternError;
        }
        return undefined;
    }

    get getAccNumberErrorMessage(): string | undefined {
        if (this.accNumberFormControl.hasError('required')) {
            return this.content.addUserFlow.step2.addSomeoneElseForm.accountNumberRequiredError;
        }
        if (this.accNumberFormControl.hasError('pattern')) {
            return this.content.addUserFlow.step2.addSomeoneElseForm.accountNumberPatternError;
        }
        return undefined;
    }

    onBack() {
        this.back.emit();
    }

    onNextStepLoad(): void {
        this.markAllControlsAsTouched();
        this.nextButtonClicked = true;
        if (!this.personalInfoForm.valid) {
            return;
        }
        this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
            processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
            processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
        });
        this.executeClientSearch();
    }

    activateModal(): void {
        this.selectUserLevelModalDialog.openModalDialog();
    }

    navigateToForm() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    onContinueModal(): void {
        switch (this.errorState) {
            case ErrorState.HAS_FOREIGN_ADDRESS:
            case ErrorState.HAS_ASSOCIATED_OR_CONTROLLED_PERSON:
                this.navigateToForm();
                break;
        }
    }

    private executeClientSearch() {
        this.store.dispatch(searchClient({ clientSearchQuery: this.convertFormDataIntoQuery() }));
    }

    private setPersonalInfoFormValues(userDetails: UserDetailsDto): void {
        const { firstName, lastName, dateOfBirth, accNumber } = userDetails;

        this.firstNameFormControl.patchValue(firstName);
        this.lastNameFormControl.patchValue(lastName);
        this.dateOfBirthFormControl.patchValue(dateOfBirth);
        this.accNumberFormControl.patchValue(accNumber);
    }

    private getModalErrorState = (user: UserDetailsDto): Observable<ErrorState> => {
        return this.usersOnAccessCenter$.pipe(
            combineLatestWith(this.usersOnAccount$),
            take(1),
            map((data) => {
                return this.getErrorState(data, user);
            }),
        );
    };

    private getSearchResultWithErrorState = (
        user: UserDetailsDto,
    ): Observable<ClientSearchResultErrorState> =>
        this.getModalErrorState(user).pipe(
            map((errorState: ErrorState) => ({
                user,
                errorState,
            })),
        );

    // eslint-disable-next-line max-statements
    private handleClientSearchResult = ({
        user,
        errorState,
    }: ClientSearchResultErrorState): void => {
        const isUserValid: boolean = !!user;

        if (isError(errorState)) {
            this.showError(errorState);
            if (ErrorState.USER_NOT_FOUND === errorState) {
                this.store.dispatch(incrementSearchPersonAttemptCounter());
            }
        } else {
            if (isUserValid) {
                this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_STEP_SUCCESS, {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
                });
                this.loadNextStep();
                this.store.dispatch(resetSearchPersonAttemptCounter());
                return;
            }
        }
    };

    trackFormattingErrorOnDobField(): void {
        if (this.dateOfBirthFormControl.hasError('pattern')) {
            this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_ERROR, {
                errorCode: AdobeAnalyticsErrorCode.DOB_FORMAT_ERROR,
                processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
            });
        }
    }

    trackFormattingErrorOnAccountField(): void {
        if (this.accNumberFormControl.hasError('pattern')) {
            this.adobeLaunchService.trackProcess(AdobeAnalyticsEvent.PROCESS_ERROR, {
                errorCode: AdobeAnalyticsErrorCode.ACC_NUMBER_ERROR,
                processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                processStep: AdobeAnalyticsProcessStep.GAF_NEXT,
            });
        }
    }

    private loadNextStep = (): void => {
        this.next.emit();
    };

    private convertFormDataIntoQuery(): ClientSearchQuery {
        return {
            firstName: this.firstNameFormControl.value,
            lastName: this.lastNameFormControl.value,
            dateOfBirth: this.dateOfBirthFormControl.value,
            accNumber: this.accNumberFormControl.value,
        };
    }

    private markAllControlsAsTouched(): void {
        Object.keys(this.personalInfoForm.controls).forEach((key) => {
            this.personalInfoForm.get(key).markAsTouched();
        });
    }

    private getErrorState = (
        [existingUsers, usersOnAccount]: [UserDetailsDto[], GranteeDTO[]],
        user: UserDetailsDto,
    ): ErrorState => {
        if (!user || Object.keys(user).length === 0) {
            this.adobeLaunchService.pageLoad(
                AdobePageName.GAF_TELL_US_ABOUT_THIS_PERSON_INFO_DOES_NOT_MATCH_ERROR,
            );
            return ErrorState.USER_NOT_FOUND;
        }
        if (usersOnAccount.find((usr) => usr.clientPoid === user.clientPoid)) {
            this.adobeLaunchService.pageLoad(
                AdobePageName.GAF_TELL_US_ABOUT_THIS_PERSON_EXISTING_ACCESS_ERROR,
            );
            return ErrorState.USER_HAS_ACCESS;
        }
        if (utils.isExistingGrantee(user, existingUsers)) {
            return ErrorState.USER_EXIST_ON_LIST;
        }
        if (user.granteeIneligibleReason === GranteeIneligibleReasons.FOREIGN_ADDRESS) {
            return ErrorState.HAS_FOREIGN_ADDRESS;
        }
        if (
            user.granteeIneligibleReason === GranteeIneligibleReasons.ASSOCIATED_OR_CONTROL_PERSON
        ) {
            return ErrorState.HAS_ASSOCIATED_OR_CONTROLLED_PERSON;
        }
        return ErrorState.NO_ERROR;
    };
}
