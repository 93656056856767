<div class="centered-column">
    <c11n-icon
        class="custom-green-icon"
        [name]="'success-fill'"
        size="medium"
        scale="larger"
        data-cy="remove-another-person-confirmation-icon">
    </c11n-icon>
    <h1 class="c11n-text-xl-headline" aria-live="polite" data-cy="page-title">
        {{
            content.removeAccessFlow.confirmation.title
                | replaceParam: 'granteeName':(selectedUserName$ | async)
        }}
    </h1>
    <c11n-button
        buttonType="button"
        size="medium"
        variant="primary"
        [labelText]="content.removeAccessFlow.confirmation.returnButtonText"
        [href]="myAccountsUrl"
        data-cy="return-to-access-permissions-button"></c11n-button>
</div>
