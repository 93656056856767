import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ViewType } from 'src/app/interfaces/view-type.enum';
import {
    AdobeAnalyticsCallToAction,
    AnalyticsCtaType,
} from 'src/app/services/adobe-launch/adobe-launch.cta';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';

import { ContentUtil } from '../../utils/content/content.util';

@Component({
    selector: 'zci-view-selector',
    templateUrl: './view-selector.component.html',
    styleUrls: ['./view-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ViewSelectorComponent,
            multi: true,
        },
    ],
})
export class ViewSelectorComponent implements ControlValueAccessor {
    ViewType = ViewType;

    cardViewLabel = ContentUtil.content.viewSelector.cardViewLabel;

    tableViewLabel = ContentUtil.content.viewSelector.tableViewLabel;

    @Input() location: string;

    private value: ViewType;

    get viewType(): ViewType {
        return this.value;
    }

    set viewType(v: ViewType) {
        if (v !== this.value) {
            this.value = v;
            this.change(v);
        }
    }

    constructor(private readonly adobeLaunchService: AdobeLaunchService) {}

    onChange: any = () => {};

    onTouched: any = () => {};

    writeValue(value: ViewType) {
        if (value !== this.value) {
            this.value = value;
        }
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    handleViewTypeChange(view: ViewType): void {
        this.change(view);
        this.track(view);
    }

    change(value: ViewType) {
        this.value = value;
        this.onChange(value);
        this.onTouched(value);
    }

    track(view: ViewType): void {
        const ctaType: AnalyticsCtaType = AnalyticsCtaType.RADIO_BUTTON;
        const ctaName: string = this.getLabel(view);
        const callToActionData: AdobeAnalyticsCallToAction = {
            ctaName,
            ctaType,
            location: this.location,
        };

        this.adobeLaunchService.trackAction(callToActionData);
    }

    getLabel(view: ViewType): string {
        if (view === ViewType.Card) {
            return this.cardViewLabel;
        }

        return this.tableViewLabel;
    }
}
