import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-16/modal-dialog';
import { combineLatest, combineLatestWith, map, Observable, Subscription, take, tap } from 'rxjs';
import { AdobeLaunchService } from 'src/app/services/adobe-launch/adobe-launch.service';
import { environment } from 'src/environments/environment';

import { UserDetailsDto } from '../../../../../../common/dtos/user-details.dto';
import { GranteeIneligibleReasons } from '../../../../../../common/enums/ineligible-scenarios.enum';
import { CtaCallUsLinkLocations } from '../../../../../services/adobe-launch/adobe-launch.cta';
import { updateAddUserStepperSelectedUser } from '../../../../../store/actions/add-user-stepper-flow.action';
import { ineligibleScenariosForGrantee } from '../../../../../store/actions/ineligible-scenarios.action';
import * as accountPermissionsSelector from '../../../../../store/selectors/account-permissions.selector';
import * as grantRevokePermissionsSelector from '../../../../../store/selectors/grant-revoke-permissions.selector';
import accountPermissionUtils from '../../../../../utils/account-permission/account-permission.util';
import { Unsubscribe } from '../../../../../utils/decorators/dev';
import { AdobeTagging } from '../../../../../utils/decorators/dev/adobe-launch';
import { StepsOfAddUserToOneSelectedAccount } from '../../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { AddUserStepBaseComponent } from '../../add-user-step-base.component';
import { ErrorState } from '../gaf-add-someone-else/gaf-add-someone-else.component';
import { adobeConfigData } from './gaf-select-existing-user-adobe';

@Unsubscribe
@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-select-existing-user',
    templateUrl: './gaf-select-existing-user.component.html',
})
export class GafSelectExistingUserComponent extends AddUserStepBaseComponent implements OnInit {
    environment = environment;

    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    radioGroupId: string = 'user-selection-group';

    selectedUserFormControlName = 'selectedUsr';

    usrs$: Observable<UserDetailsDto[]>;

    usrFormGroup: FormGroup = new FormGroup({
        [this.selectedUserFormControlName]: new FormControl(null, [Validators.required]),
    });

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_SELECT_EXISTING_USER;

    noIneligibilityInfo = false;

    granteeIneligibleReason: GranteeIneligibleReasons = null;

    modalState: ErrorState = ErrorState.NO_ERROR;

    nextButtonClicked = false;

    @ViewChild('selectUserLevelModalDialog', { static: false })
    selectUserLevelModalDialog!: ModalDialogComponent;

    get selectedUserFormControl(): AbstractControl {
        return this.usrFormGroup.get(this.selectedUserFormControlName);
    }

    constructUserName: Function = accountPermissionUtils.constructUserName;

    readonly subs: Subscription = new Subscription();

    @Input() accId: string;

    @Output() next: EventEmitter<void> = new EventEmitter();

    @Output() back: EventEmitter<void> = new EventEmitter();

    @Output() addSomeoneElse: EventEmitter<void> = new EventEmitter();

    constructor(private readonly store: Store, protected adobeLaunchService: AdobeLaunchService) {
        super(adobeLaunchService);
    }

    ngOnInit() {
        const existingUsers$: Observable<UserDetailsDto[]> = this.store.select(
            accountPermissionsSelector.selectNonGrantedUsersOnAccount(this.accId),
        );
        const selectedUser$: Observable<UserDetailsDto> = this.store.select(
            grantRevokePermissionsSelector.selectAddUserStepperSelectedUser,
        );

        this.usrs$ = existingUsers$.pipe(
            combineLatestWith(selectedUser$),
            tap(this.patchFormValueWithSelectedUser.bind(this)),
            map(([existingUsers]) =>
                [...existingUsers].sort((u1, u2) =>
                    this.constructUserName(u1) > this.constructUserName(u2) ? 1 : -1,
                ),
            ),
            take(1),
        );

        this.usrFormGroup.get(this.selectedUserFormControlName).valueChanges.subscribe(() => {
            this.nextButtonClicked = false;
        });

        this.onUserSelectionChange(existingUsers$);
    }

    onUserSelectionChange(existingUsers$): void {
        this.subs.add(
            combineLatest([
                existingUsers$,
                this.usrFormGroup.get(this.selectedUserFormControlName).valueChanges,
            ]).subscribe(([existingUsers, currentUser]: any[]) => {
                if (currentUser) {
                    const user: UserDetailsDto = existingUsers.find(
                        (existingUser: UserDetailsDto) =>
                            existingUser.clientPoid === currentUser.clientPoid,
                    );
                    const granteeIneligibleReason = 'granteeIneligibleReason' in user;

                    this.noIneligibilityInfo = !granteeIneligibleReason;

                    if (granteeIneligibleReason) {
                        this.granteeIneligibleReason = user.granteeIneligibleReason;

                        if (this.nextButtonClicked) {
                            this.checkGranteeIneligibility();
                        }
                    }
                }
            }),
        );
    }

    onNextStepLoad(): void {
        this.selectedUserFormControl.markAsDirty();

        if (this.noIneligibilityInfo) {
            this.loadGranteeIneligibilityInfo();
        } else {
            this.checkGranteeIneligibility();
        }

        this.nextButtonClicked = true;
    }

    checkGranteeIneligibility() {
        if (this.granteeIneligibleReason) {
            this.showIneligibilityError();
        } else {
            this.addEligibleUser();
        }
    }

    showIneligibilityError(): void {
        this.selectUserLevelModalDialog.openModalDialog();
    }

    navigateToForm() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    addEligibleUser(): void {
        if (this.selectedUserFormControl.valid) {
            this.store.dispatch(
                updateAddUserStepperSelectedUser({
                    selectedUser: this.selectedUserFormControl.value,
                }),
            );
            this.loadNextStep();
        }
    }

    loadGranteeIneligibilityInfo(): void {
        this.store.dispatch(
            ineligibleScenariosForGrantee({
                userDetails: this.selectedUserFormControl.value,
            }),
        );
    }

    onBack(): void {
        this.back.emit();
    }

    onAddSomeoneElse(): void {
        this.addSomeoneElse.emit();
    }

    private patchFormValueWithSelectedUser([existingUsers, selectedUser]: [
        UserDetailsDto[],
        UserDetailsDto,
    ]): void {
        if (selectedUser) {
            existingUsers.forEach((existingUser: UserDetailsDto) => {
                if (existingUser.clientPoid === selectedUser.clientPoid) {
                    this.selectedUserFormControl.patchValue(existingUser);
                }
            });
        }
    }

    private loadNextStep(): void {
        this.next.emit();
    }

    protected readonly ErrorState = ErrorState;
}
