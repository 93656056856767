import { Component, Input } from '@angular/core';

import { NavOverflowExtendedData } from '../../interfaces/nav-overflow-extended-data.interface';
import { ContentUtil } from '../../utils/content/content.util';

export interface ActionNavOverflowMenuComponentProps {
    navOverflowMenuConfig: NavOverflowExtendedData;
}
@Component({
    selector: 'zci-action-nav-overflow-menu',
    templateUrl: './action-nav-overflow-menu.component.html',
})
export class ActionNavOverflowMenuComponent implements ActionNavOverflowMenuComponentProps {
    @Input() navOverflowMenuConfig: NavOverflowExtendedData;

    content = ContentUtil.content;
}
