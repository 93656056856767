import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, Subject, take, tap } from 'rxjs';

import CONSTANTS from '../../../../common/constants/constants';
import { Content } from '../../../../common/interfaces/content.interface';
import { ViewType } from '../../../interfaces/view-type.enum';
import { MapRoleTypePipe } from '../../../pipes/role-type/map-role-type.pipe';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import { PaginationProp, PaginatorService } from '../../../services/paginator/paginator.service';
import { UsersService } from '../../../services/users/users.service';
import { updateSelectedViewTypeForMyAccounts } from '../../../store/actions/view-type.action';
import {
    getSelectedViewTypeForMyAccounts,
    selectUsersOnAccount,
} from '../../../store/selectors/account-permissions.selector';
import { ContentUtil } from '../../../utils/content/content.util';
import contentUtils from '../../../utils/content/content-format.util';
import { Sort } from '../../../utils/sort/sort.interfaces';
import sortUtils from '../../../utils/sort/sort.util';
import { TableColumn } from '../../table/table.interface';
import { UserTileInputDataProps } from '../../tiles/user-tile/user-tile.component';
import { TabComponent } from '../tab.component';
import {
    getUsersOnAccountTableCols,
    UserOnAccountTableRow,
    UsersOnAccountTableProps,
} from './users-on-account-table.config';

@Component({
    selector: 'zci-users-on-account',
    templateUrl: './users-on-account.component.html',
    providers: [PaginatorService],
})
export class UsersOnAccountComponent extends TabComponent implements OnInit, OnDestroy {
    @Input() accId: string;

    content: Content = ContentUtil.content;

    usrsOnAccount$: Observable<UserTileInputDataProps[]>;

    usrsOnAccountPaginated: PaginationProp<UserTileInputDataProps>;

    usrsOnAccountDataTable$: Observable<UserOnAccountTableRow[]>;

    usrsOnAccountTableCols: TableColumn[] = getUsersOnAccountTableCols(
        this.content.usersOnAccount.usersOnAccountTable.headers,
    );

    private destroy$: Subject<void> = new Subject();

    private readonly usersService: UsersService;

    private readonly paginatorService: PaginatorService;

    private readonly store: Store;

    private readonly mapRoleTypePipe: MapRoleTypePipe;

    private readonly adobeLaunchService: AdobeLaunchService;

    get loadMoreUsersLabel$(): Observable<string> {
        return this.usrsOnAccountPaginated.nextCount$.pipe(
            map((count: number) => {
                return contentUtils.getContentWithDynamicNumber(
                    this.content.home.loadMoreUsers,
                    count,
                );
            }),
        );
    }

    get destroy(): Subject<void> {
        return this.destroy$;
    }

    constructor(private readonly injector: Injector) {
        super(injector);
        this.usersService = this.injector.get<UsersService>(UsersService);
        this.paginatorService = this.injector.get<PaginatorService>(PaginatorService);
        this.store = this.injector.get<Store>(Store);
        this.mapRoleTypePipe = this.injector.get<MapRoleTypePipe>(MapRoleTypePipe);
        this.adobeLaunchService = this.injector.get<AdobeLaunchService>(AdobeLaunchService);
    }

    ngOnInit(): void {
        this.usrsOnAccount$ = this.store.select(selectUsersOnAccount(this.accId));
        this.loadUserOnAccountPaginatedData();
        this.loadUserOnAccountDataTable();

        this.restoreViewTypeSelection();

        this.subscribeOnBreakpointChange();
    }

    ngOnDestroy(): void {
        this.saveViewTypeSelection();
        this.destroy$.next();
        this.destroy$.complete();
    }

    loadUserOnAccountPaginatedData(): void {
        const { hasMore$, isLoading$, nextCount$ } = this.paginatorService;
        const data$ = this.paginatorService.initPaginator<UserTileInputDataProps>(
            this.usrsOnAccount$,
            CONSTANTS.PAGINATOR_PAGE_SIZE,
        );

        this.usrsOnAccountPaginated = {
            data$,
            hasMore$,
            isLoading$,
            nextCount$,
            loadMore: () => this.paginatorService.loadMore(),
        };
    }

    loadUserOnAccountDataTable() {
        this.usrsOnAccountDataTable$ = this.usrsOnAccount$.pipe(
            map(this.mapToUsersOnAccountTableRow.bind(this)),
        );
    }

    mapToUsersOnAccountTableRow(usrs: UserTileInputDataProps[]): UserOnAccountTableRow[] {
        return usrs.map((usr: UserTileInputDataProps) => ({
            [UsersOnAccountTableProps.UsrName]: usr.usrName,
            [UsersOnAccountTableProps.RoleType]: this.mapRoleTypePipe.transform(usr.roleType),
            [UsersOnAccountTableProps.Actions]: {
                navOverflowMenuConfig: this.usersService.getNavOverflowMenu(true),
            },
        }));
    }

    onColumnSort(event: Sort) {
        this.usrsOnAccountDataTable$ = this.usrsOnAccountDataTable$.pipe(
            map((usrs: UserOnAccountTableRow[]) => {
                return sortUtils.ascDescSortingBySortEvent<UserOnAccountTableRow>(usrs, event);
            }),
        );
    }

    protected saveViewTypeSelection(): void {
        this.store.dispatch(updateSelectedViewTypeForMyAccounts(this.viewType));
    }

    protected restoreViewTypeSelection(): void {
        this.store
            .select(getSelectedViewTypeForMyAccounts)
            .pipe(take(1), tap(this.trackPageLoad))
            .subscribe((viewType) => {
                this.viewType = viewType;
                this.changeDetector.detectChanges();
            });
    }

    private trackPageLoad = (viewType: ViewType): void => {
        this.adobeLaunchService.pageLoad(
            AdobePageName.OWN_ACCOUNTS_USERS_ON_ACCOUNT_PAGE,
            this.getViewTypeForPageLoad(viewType),
        );
    };
}
