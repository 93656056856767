import { StepperAdobeTag } from './flow-config.interface';
import { AdobeMethods } from '../../services/adobe-launch/adobe-launch.methods';
import {
    AdobeAnalyticsProcessStep,
    AdobeAnalyticsProcessType,
} from '../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType, CtaNames } from '../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsEvent } from '../../services/adobe-launch/adobe-launch.events';

export const adobeTagging: Record<StepperAdobeTag, (l: string) => Array<any>> = {
    onBack: (location) => [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: location,
                    ctaName: AdobeAnalyticsProcessStep.GAF_BACK,
                    ctaType: AnalyticsCtaType.BUTTON,
                },
            ],
        },
    ],
    onQuit: (location) => [
        {
            method: AdobeMethods.TRACK_PROCESS,
            data: [
                AdobeAnalyticsEvent.PROCESS_ABANDONED,
                {
                    processType: AdobeAnalyticsProcessType.GAF_GRANT_ACCESS_FLOW,
                    processStep: location,
                    processAbandonmentType: AdobeAnalyticsProcessStep.GAF_QUIT,
                },
            ],
        },
    ],
    onContactUs: (location) => [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: location,
                    ctaName: CtaNames.CONTACT_US,
                    ctaType: AnalyticsCtaType.LINK,
                },
            ],
        },
    ],
};
