import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as addSomeoneElseActions from '../actions/add-someone-else.action';
import { catchError, map, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AddSomeoneElseService } from '../../services/add-someone-else/add-someone-else.service';
import { gatherPinAndDtoError, gatherPinAndDtoSuccess } from '../actions/add-someone-else.action';


@Injectable({
    providedIn: 'root',
})
export class AddSomeoneElseApiEffect {
    constructor(
        private readonly addSomeoneElseService: AddSomeoneElseService,
        private readonly actions$: Actions,
    ) {}

    gatherPinAndDto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addSomeoneElseActions.gatherPinAndDto),
            switchMap((action) => {
                return this.addSomeoneElseService.gatherPinAndDto(action.addSomeoneElseDto)
                    .pipe(
                    map((response) => {
                        return gatherPinAndDtoSuccess({data: response.uniquePin});
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(gatherPinAndDtoError({ error }));
                    }),
                );
            }),
        );
    });
}
