import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, map, Observable, take, tap } from 'rxjs';

import { UserDetailsDto } from '../../../../../common/dtos/user-details.dto';
import { ACCOUNT_ID_PARAM } from '../../../../constants/application-routes';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { updateRemoveUserStepperSelectedUser } from '../../../../store/actions/remove-user-stepper-flow.action';
import * as accountPermissionsSelector from '../../../../store/selectors/account-permissions.selector';
import * as grantRevokePermissionsSelector from '../../../../store/selectors/grant-revoke-permissions.selector';
import accountPermissionUtils from '../../../../utils/account-permission/account-permission.util';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { RemoveUserSteps } from '../enums/remove-user-steps';
import { RemoveUserStepBaseComponent } from '../remove-user-step-base.component';
import { adobeConfigData } from './raf-select-user-step-adobe';
import { AdobeAnalyticsProcessStep } from '../../../../services/adobe-launch/adobe-launch.process';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-raf-select-user-step',
    templateUrl: './raf-select-user-step.component.html',
    styleUrls: ['./raf-select-user-step.component.scss'],
})
export class RafSelectUserStepComponent extends RemoveUserStepBaseComponent implements OnInit {
    @Input() currentStep: RemoveUserSteps;

    @Output() next: EventEmitter<void> = new EventEmitter();

    @Output() back: EventEmitter<void> = new EventEmitter();

    accountId: string;

    users$: Observable<UserDetailsDto[]>;

    radioGroupId: string = 'user-selection-group';

    selectUserFormControlName: string = 'selectedUser';

    selectUserFormGroup: FormGroup = new FormGroup({
        [this.selectUserFormControlName]: new FormControl(null, [Validators.required]),
    });

    constructUserName: Function = accountPermissionUtils.constructUserName;

    get selectUserFormControl(): AbstractControl {
        return this.selectUserFormGroup.get(this.selectUserFormControlName);
    }

    constructor(
        protected readonly adobeLaunchService: AdobeLaunchService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly store: Store,
    ) {
        super(adobeLaunchService);
    }

    /**
     * Component lifecycle
     */
    ngOnInit(): void {
        this.accountId = this.activatedRoute.snapshot.params[ACCOUNT_ID_PARAM];
        const existingUsers$ = this.store.select(
            accountPermissionsSelector.selectGrantedUsersOnAccount(this.accountId),
        );
        const selectedUser$: Observable<UserDetailsDto> = this.store.select(
            grantRevokePermissionsSelector.selectRemoveUserStepperSelectedUser,
        );

        this.users$ = existingUsers$.pipe(
            combineLatestWith(selectedUser$),
            tap(this.patchFormValueWithSelectedUser.bind(this)),
            map(([existingUsers]) =>
                [...existingUsers].sort((u1, u2) =>
                    this.constructUserName(u1) > this.constructUserName(u2) ? 1 : -1,
                ),
            ),
            take(1),
        );
    }

    /**
     * Moves the ui flow to the next step
     */
    onNextStepLoad(): void {
        this.selectUserFormControl.markAsDirty();
        if (this.selectUserFormControl.valid) {
            this.store.dispatch(
                updateRemoveUserStepperSelectedUser({
                    selectedUser: this.selectUserFormControl.value,
                }),
            );
            this.stepChange.emit(RemoveUserSteps.REVIEW);
        }
    }

    /**
     * Moves the ui flow to the previous step
     */
    onBack(): void {
        this.stepChange.emit(RemoveUserSteps.QUIT);
    }

    private patchFormValueWithSelectedUser([existingUsers, selectedUser]: [
        UserDetailsDto[],
        UserDetailsDto,
    ]): void {
        if (selectedUser) {
            existingUsers.forEach((existingUser: UserDetailsDto) => {
                if (existingUser.clientPoid === selectedUser.clientPoid) {
                    this.selectUserFormControl.patchValue(existingUser);
                }
            });
        }
    }

    protected readonly AdobeAnalyticsProcessStep = AdobeAnalyticsProcessStep;
}
