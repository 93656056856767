<zci-step-wrapper
    [title]="
        content.addUserFlow.step3.title
            | replaceParam: 'userName':constructUserName(selectedUser$ | async)
    "
    [nextButtonLabelText]="content.stepper.nextButtonLabelText"
    (clickNextButton)="onNextStepLoad()"
    (clickBackButton)="onBack()"
    (clickQuit)="onQuit(location)"
    (clickContactUs)="onContactUs(location)"
    [totalSteps]="content.addUserFlow.stepperTitles"
    [currentStep]="currentStep">
    <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
    <c11n-modal-dialog
        [variant]="'basic'"
        [headingText]="content.selectAccessLevelModal.headingText"
        [primaryButtonLabel]="content.selectAccessLevelModal.primaryButtonText"
        [secondaryButtonLabel]="content.selectAccessLevelModal.secondaryButtonText"
        (primaryClick)="onContinueModal()"
        [maxWidth]="660"
        [useContentWidth]="false"
        #selectAccessModalDialogue>
        <div c11n-modal-dialog-body>
            <p class="c11n-text-md c11n-space-stack-2x" data-cy="accessModal">
                {{
                    content.selectAccessLevelModal.body
                        | replaceParam
                            : [
                                  ['userName', userName$ | async],
                                  ['accessLevelAccess', accessLevel$ | async | lowercase]
                              ]
                }}
            </p>
        </div>
    </c11n-modal-dialog>
    <c11n-banner
        role="alert"
        class="c11n-space-stack-3x"
        size="small"
        variant="error"
        [hasCloseControl]="false"
        *ngIf="selectedLevelFormControl.invalid && selectedLevelFormControl.dirty">
        <div class="c11n-text-md--crop">
            {{ content.addUserFlow.step3.validationMessage.required }}
        </div>
    </c11n-banner>
    <div class="c11n-space-stack-2x">
        <c11n-radio-group
            [groupId]="radioGroupId"
            [hideLegend]="true"
            [legendText]="
                content.addUserFlow.step3.title
                    | replaceParam: 'userName':constructUserName(selectedUser$ | async)
            "
            [formGroup]="levelFormGroup">
            <c11n-radio
                *ngFor="let level of accessLevels; index as i"
                [focusLabel]="true"
                [labelText]="level.label">
                <input
                    c11nRadioInput
                    type="radio"
                    [name]="selectedLevelFormControlName"
                    [value]="level.id"
                    [formControlName]="selectedLevelFormControlName" />
            </c11n-radio>
        </c11n-radio-group>
    </div>
    <zci-access-comparison
        data-cy="access-comparison-detail"
        [showFullAuthorityBanner]="true"
        (detailsToggle)="onDetailsToggle($event)"></zci-access-comparison>
</zci-step-wrapper>
